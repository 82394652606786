import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showFilesSettings } from "../../redux/settings/settings.actions";

import { useLocation, useNavigate, Outlet } from "react-router-dom";

import { db, storage, fieldValue } from "../../firebase/firebase.utils";

import { motion } from "framer-motion";

import InvitetoPremium from "../../components/marketing/invite-to-premium.component";

import TaskAddButton from "../../components/task-add-btn/task-add-btn.component";

import BoxLayer from "../../components/retro/box-layer/box-layer.component";
import { MdAddToDrive } from "react-icons/md";

import {
  BsCheckLg,
  BsXCircle,
  BsPencilSquare,
  BsTrash,
  BsFolderFill,
} from "react-icons/bs";
import { FaGoogleDrive } from "react-icons/fa";

// import FilesVisual from "../../assets/illustration/undraw_folder_re_apfp.svg";
import FilesVisual from "../../assets/illustration/undraw_folder_re_apfp.svg";

import "./files.styles.scss";

import moment from "moment";

const variants = {
  open: {
    opacity: 0,
    x: "-100%",
    width: "594px",
    height: "calc(100% - 90px)",
    borderRadius: "6px",
    right: "0px",
    translateX: "36%",
    translateY: "-2%",
  },
  closed: { opacity: 1, x: 0 },
};

const FilesView = ({ station }) => {
  const users = useSelector((state) => state.user.users);
  const settingsOn = useSelector((state) => state.settings.showFilesSettings);
  const dispatch = useDispatch();
  // const history = useNavigate();
  const location = useLocation();
  const currentSpaceId = location.pathname.split("/")[2];
  const currentStationId = location.pathname.split("/")[4];
  const [state, setState] = useState([]);
  const [deleteOpacity, setDeleteOpacity] = useState(0);

  const [gDriveIds, setGDriveIds] = useState([]);

  const [gDriveName, setGDriveName] = useState("");
  const [gDriveId, setGDriveId] = useState("");

  const [viewMode, setViewMode] = useState("#grid");

  const [noteImageItems, setNoteImageItems] = useState([]);
  const [noteImageUrls, setNoteImageUrls] = useState([]);

  const [lightbox, setLightbox] = useState({ name: "", img: "" });
  const [showlightbox, setShowLightbox] = useState(false);

  const [showDriveModal, setShowDriveModal] = useState(false);

  const [showSettings, setShowSettings] = useState(false);
  useEffect(() => {
    if (settingsOn) {
      setShowSettings(true);
    } else {
      setShowSettings(false);
    }
  }, [settingsOn]);

  const handleCloseSettings = () => {
    dispatch(showFilesSettings());
  };

  useEffect(() => {
    const listRef = storage.ref(
      `space/${currentSpaceId}/stations/${currentStationId}/notesImages/`
    );

    // Find all the prefixes and items.
    listRef
      .listAll()
      .then((res) => {
        res.prefixes.forEach((folderRef) => {
          // All the prefixes under listRef.
          // You may call listAll() recursively on them.
        });
        // let imgitems = [];
        res.items.forEach((item) => {
          // All the items under listRef.
          setNoteImageItems((prev) => [...prev, item]);
          let urlRef = storage.ref(item.fullPath);
          urlRef.getDownloadURL().then((url) => {
            setNoteImageUrls((prev) => [...prev, url]);
          });
        });
      })
      .catch((error) => {
        // Uh-oh, an error occurred!
      });
  }, [currentSpaceId, currentStationId]);

  console.log("NOTEIMAGEITEMS ARE", noteImageItems);

  useEffect(() => {
    //mounts
    const unsub = db
      .collection("space")
      .doc(currentSpaceId)
      .collection("stations")
      .doc(currentStationId)
      .onSnapshot((doc) => {
        if (doc && doc != []) {
          setGDriveIds(doc.get("gDriveIds"));
        }
      });

    return () => {
      unsub();
    };
  }, [currentSpaceId, currentStationId]);

  const handleDeleteFile = (filename) => {
    // Create a reference to the file to delete
    var fileRef = storage.ref(
      `space/${currentSpaceId}/stations/${currentStationId}/notesImages/${filename}`
    );

    // Delete the file
    fileRef
      .delete()
      .then(() => {
        // File deleted successfully
      })
      .catch((error) => {
        // Uh-oh, an error occurred!
      });

    const copiedArr1 = [...noteImageItems];
    const copiedArr2 = [...noteImageUrls];

    const findIndex = copiedArr1.findIndex((img) => img === filename);

    copiedArr1.splice(findIndex, 1);
    setNoteImageItems(copiedArr1);

    copiedArr2.splice(findIndex, 1);
    setNoteImageUrls(copiedArr2);
  };

  // useEffect(() => {
  //   //mounts
  //   const unsub = db
  //     .collection("space")
  //     .doc(currentSpaceId)
  //     .collection("stations")
  //     .doc(currentStationId)
  //     .onSnapshot((doc) => {
  //       setGCalId(doc.get("gDriveId"));
  //     });

  //   return () => {
  //     unsub();
  //   };
  // }, [currentSpaceId, currentStationId]);

  const handleGDriveSave = () => {
    // let convertRef = gDriveId
    //   .replace("drive/folders/", "embeddedfolderview?id=")
    //   .replace("?usp=share_link", "");

    const newDriveItem = {
      name: gDriveName,
      link: gDriveId
        .replace("drive/folders/", "embeddedfolderview?id=")
        .replace("?usp=drive_link", "")
        .replace("?usp=sharing", ""),
      isOpen: true,
    };

    // setGDriveIds((prev) => [...prev, newDriveItem]);

    db.collection("space")
      .doc(currentSpaceId)
      .collection("stations")
      .doc(currentStationId)
      .update({
        gDriveIds: fieldValue.arrayUnion(newDriveItem),
      });

    setShowDriveModal(false);
    setGDriveName("");
    setGDriveId("");
  };
  const handleGDriveRemove = (link) => {
    const copiedgDriveIds = [...gDriveIds];

    const findIndex = copiedgDriveIds.findIndex((item) => item.link === link);
    copiedgDriveIds.splice(findIndex, 1);

    db.collection("space")
      .doc(currentSpaceId)
      .collection("stations")
      .doc(currentStationId)
      .update({
        gDriveIds: copiedgDriveIds,
      });
  };

  // Google Drive Share button gives this = https://drive.google.com/drive/folders/10bhbrIMtHQCIIPjs3FMwnb3HYPgJbb9X?usp=share_link
  // To work, must be converted to this =   https://drive.google.com/embeddedfolderview?id=10bhbrIMtHQCIIPjs3FMwnb3HYPgJbb9X

  return (
    <div className="FilesView">
      {/* <iframe src="https://drive.google.com/drive/folders/10bhbrIMtHQCIIPjs3FMwnb3HYPgJbb9X#grid" width="100%" height="100%" /> */}
      <div className="drives_container">
        <details className="drive_container" open={noteImageItems.length}>
          <summary>Images from Notes</summary>
          <div
            className={
              viewMode === "#grid" ? "files_files" : "files_files-listmode"
            }
          >
            {noteImageItems.length < 1 && (
              <h5 style={{ padding: "20px", fontWeight: "500" }}>
                No images have been added to any note in this project...
              </h5>
            )}
            {noteImageItems.map((item, index) => (
              <div className="files_file" key={index}>
                {/* <img src={item} /> */}
                <img
                  src={noteImageUrls[index]}
                  className="files_imagefile"
                  onClick={() =>
                    setLightbox({
                      name: item.name,
                      img: noteImageUrls[index],
                    }) & setShowLightbox(true)
                  }
                />
                <div className="files_file-info">
                  <p>{item.name}</p>
                  <button onClick={() => handleDeleteFile(item.name)}>
                    <BsTrash />
                  </button>
                </div>
              </div>
            ))}
          </div>
          {/* {lightbox && (
            <div
              className="files_lightbox"
              onClick={() => setLightbox({ name: "", img: "" })}
            >
              <img src={lightbox.img} />
              <p>{lightbox.name}</p>
            </div>
          )} */}
          {showlightbox && (
            <BoxLayer setLayer={setShowLightbox}>
              <div className="files_lightbox">
                <img src={lightbox.img} />
                <p>{lightbox.name}</p>
              </div>
            </BoxLayer>
          )}
        </details>
        {gDriveIds?.map((gfolder, index) => (
          <details
            className="drive_container"
            key={gfolder.link}
            open={gfolder.isOpen}
          >
            <summary>{gfolder.name}</summary>
            <div className="iframe_container">
              <iframe
                src={gfolder.link + viewMode}
                width="100%"
                height="100%"
              ></iframe>
            </div>
          </details>
        ))}
        {!gDriveIds && (
          <div className="no_drive_found">
            {FilesVisual && <img src={FilesVisual} width="525" height="300" />}
            <h4>
              It looks like no files or drive folders have been added so far.{" "}
              <br /> You can add images directly into your notes and they'll
              appear here too. <br /> To add google drive shortcuts, check the
              menu on the right.
            </h4>
          </div>
        )}
      </div>
      <motion.button
        className="addnote__btn"
        animate={showDriveModal ? "open" : "closed"}
        variants={variants}
        transition={{ type: "ease-in-out", duration: 0.4 }}
        onClick={() => setShowDriveModal(!showDriveModal)}
      >
        <MdAddToDrive size="1.8em" />
      </motion.button>
      <div className="calendar__settings__win">
        {showSettings && (
          <BoxLayer setLayer={handleCloseSettings}>
            <div className="filesListCol">
              <div className="filesListCol-showas">
                <p>Show as</p>
                <div className="filesListCol-showas-options">
                  <div className="filesListCol-radio-line">
                    <input
                      type="radio"
                      name="viewtype"
                      checked={viewMode === "#grid"}
                      value="#grid"
                      onChange={(e) => setViewMode(e.target.value)}
                    />
                    <label>Grid</label>
                  </div>
                  <div className="filesListCol-radio-line">
                    <input
                      type="radio"
                      name="viewtype"
                      value=""
                      onChange={(e) => setViewMode(e.target.value)}
                    />
                    <label>List</label>
                  </div>
                </div>
              </div>
              <div className="files__drives">
                <h3>Drive Folders</h3>
                <div className="files__list">
                  {gDriveIds?.map((gfolder, index) => (
                    <ul
                      className="files__list-item"
                      key={gfolder.link}
                      index={index}
                    >
                      <div className="files__list-title">
                        <BsFolderFill />
                        <h5>{gfolder.name}</h5>
                      </div>
                      <div className="files__list-btngroup">
                        {/* <button>
                    <BsPencilSquare />
                  </button> */}
                        <button
                          value={gfolder.link}
                          onClick={(e) => handleGDriveRemove(e.target.value)}
                        >
                          <BsTrash />
                        </button>
                      </div>
                    </ul>
                  ))}
                </div>
              </div>
            </div>
          </BoxLayer>
        )}
      </div>
      {showDriveModal && (
        <BoxLayer setLayer={() => setShowDriveModal(!showDriveModal)}>
          <div className="filesModal">
            <div className="files__drives">
              <h3>Drive Folders</h3>
            </div>
            <div className="files__gDrive-Form">
              <input
                onChange={(e) => setGDriveName(e.target.value)}
                type="text"
                placeholder="Title"
                value={gDriveName}
              ></input>
              <input
                onChange={(e) => setGDriveId(e.target.value)}
                type="text"
                placeholder="Google Drive Share ID"
                value={gDriveId}
              ></input>
              <button
                className="files__savebtn"
                onClick={() => handleGDriveSave(gDriveId)}
              >
                <BsCheckLg />
                Add
              </button>
              {/* {gDriveId !== "" && (
                <div>
                  <button
                    className="cal__gCal-clearBtn"
                    onClick={() => setGDriveId("")}
                  >
                    <BsXCircle />
                  </button>
                </div>
              )} */}
            </div>

            <div className="files__gDrive-list">
              <ul className="files__gDrive-ul">
                <li>Type a title for your folder</li>
                <li>Go to your Google Drive,</li>
                <li>Right-click on the folder,</li>
                <li>Share > Copy link</li>
                <li>Paste above and confirm.</li>
              </ul>
            </div>
          </div>
        </BoxLayer>
      )}
      <Outlet />
      <TaskAddButton />
    </div>
  );
};

export default FilesView;
