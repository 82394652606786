import React, { useEffect, useState } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faMicrophone,
//   faVideo,
//   faDesktop,
//   faVideoSlash,
//   faMicrophoneSlash,
// } from "@fortawesome/free-solid-svg-icons";
import {
  FaMicrophone,
  FaVideo,
  FaDesktop,
  FaVideoSlash,
  FaMicrophoneSlash,
  FaPhoneSlash,
} from "react-icons/fa";
import {
  BsFullscreen,
  BsFullscreenExit
} from "react-icons/bs";
import { Tooltip } from "react-tooltip";
import "./MeetingFooter.scss";
const MeetingFooter = (props) => {
  const [streamState, setStreamState] = useState({
    mic: true,
    video: false,
    screen: false,
  });
  const micClick = () => {
    setStreamState((currentState) => {
      return {
        ...currentState,
        mic: !currentState.mic,
      };
    });
  };

  // function stopStreamedVideo(videoElem) {
  //   const stream = videoElem.srcObject;
  //   const tracks = stream.getTracks();

  //   tracks.forEach((track) => {
  //     track.stop();
  //   });

  //   videoElem.srcObject = null;
  // }

  const endCallClick = () => {
    try {
      // let tracks = this.setStreamState.current.srcObject.getTracks()
      // tracks.forEach(track => track.stop())
      const video = document.querySelector('video');
      // A video's MediaStream object is available through its srcObject attribute
      const mediaStream = video.srcObject;
      // Through the MediaStream, you can get the MediaStreamTracks with getTracks():
      const tracks = mediaStream.getTracks();
      // Or stop all like so:
      tracks.forEach(track => track.stop())
    } catch (e) { }
    props.setCallStatus(false);
    // window.location.href = "/"
    const relativepath = window.location.pathname
    window.history.pushState(null, "", relativepath)
  };

  const onVideoClick = () => {
    setStreamState((currentState) => {
      return {
        ...currentState,
        video: !currentState.video,
      };
    });
  };

  const onScreenClick = () => {
    props.onScreenClick(setScreenState);
  };

  const onFullScreenClick = () => {
    props.setMeetingFullScreen(!props.meetingFullScreen)
  };

  const setScreenState = (isEnabled) => {
    setStreamState((currentState) => {
      return {
        ...currentState,
        screen: isEnabled,
      };
    });
  };
  useEffect(() => {
    props.onMicClick(streamState.mic);
  }, [streamState.mic]);
  useEffect(() => {
    props.onVideoClick(streamState.video);
  }, [streamState.video]);
  return (
    <div className={props.meetingFullScreen ? "meet_meeting-footerFS" : "meet_meeting-footer"}>
      <div
        className={(props.meetingFullScreen ? "meet_meeting-iconsFS " : "meet_meeting-icons ") + (!streamState.mic ? "meet_active" : "")}
        data-tip={streamState.mic ? "Mute Audio" : "Unmute Audio"}
        onClick={micClick}
      >
        {/* <FontAwesomeIcon
          icon={!streamState.mic ? faMicrophoneSlash : faMicrophone}
          title="Mute"
        /> */}
        {!streamState.mic ? <FaMicrophoneSlash title="Mute" /> : <FaMicrophone title="Mute" />}
      </div>
      <div
        className={(props.meetingFullScreen ? "meet_meeting-iconsFS " : "meet_meeting-icons ") + (!streamState.video ? "meet_active" : "")}
        data-tip={streamState.video ? "Hide Video" : "Show Video"}
        onClick={onVideoClick}
      >
        {/* <FontAwesomeIcon icon={!streamState.video ? faVideoSlash : faVideo} /> */}
        {!streamState.video ? <FaVideoSlash /> : <FaVideo />}
      </div>
      {props.meetingFullScreen && <div
        className={props.meetingFullScreen ? "meet_meeting-iconsFS" : "meet_meeting-icons"}
        data-tip="Share Screen"
        onClick={onScreenClick}
        disabled={streamState.screen}
      >
        {/* <FontAwesomeIcon icon={faDesktop} /> */}
        <FaDesktop />
      </div>}
      <div
        className={props.meetingFullScreen ? "meet_meeting-iconsFS" : "meet_meeting-icons"}
        data-tip="End Call"
        onClick={endCallClick}
        disabled={streamState.screen}
      >
        {/* <FontAwesomeIcon icon={faDesktop} /> */}
        <FaPhoneSlash />
      </div>
      <div
        className={props.meetingFullScreen ? "meet_meeting-iconsFS" : "meet_meeting-icons"}
        data-tip="FullScreen"
        onClick={onFullScreenClick}
        disabled={streamState.screen}
      >
        {/* <FontAwesomeIcon icon={faDesktop} /> */}
        {props.meetingFullScreen ? <BsFullscreenExit /> : <BsFullscreen />}
      </div>
      <Tooltip />
    </div>
  );
};

export default MeetingFooter;
