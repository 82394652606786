import * as React from "react"
const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={289} height={293} {...props}>
    <title>{"face/Angry with Fang"}</title>
    <path
      fill="#000"
      fillRule="evenodd"
      d="M196.547 185.914c1.48-1.933 5.08 6.48 5.814 7.51l.035.046c3.204 3.877 5.82 9.814 6.19 14.792.011.149.011.293.011.437 5.136-.705 10.273-1.423 15.408-2.211 1.986-1.493 4.765-1.03 7.454.329 5.997 3.027 3.078 12.644-3.383 12.49-17.47-.416-35.313 1.827-52.637 3.846-8.912 1.039-17.805 2.14-26.669 3.55-8.28 1.315-16.748 3.173-25.129 3.535-.457.019-.648-.662-.236-.874 7.39-3.795 15.83-6.093 23.807-8.316 8.633-2.405 17.33-4.663 26.088-6.57 7.86-1.71 15.76-2.99 23.677-4.143a6.133 6.133 0 0 1-.17-.48c-1.004-3.369-.978-7.234-1.192-10.784l-.124-1.885c-.196-3.247-.39-9.38 1.056-11.272Zm-25.733-41.547c7.552 4.59 19.058 15.293 10.934 26.673-9.562 10.033-23.33-1.471-30.114-6.674-6.29-4.032-13.359 6.339-7.522 11.047 49.024 36.295 68.919-29.846 30.522-36.443-4.228-.727-5.798 3.977-3.82 5.397Zm-121.412-40.4c12.565-1.917 25.436-2.137 38.132-1.503a404.71 404.71 0 0 1 30.06 2.623c2.457.308 6.249 1.714 8.583 1.074 7.132-1.953 13.903 4.796 11.936 11.937-1.951 7.086-10.312 8.955-15.69 4.699a204.293 204.293 0 0 0-7.695-2.547 10.391 10.391 0 0 1-.894 2.394c-.458.87-.9 1.746-1.317 2.635.09-.142-.601 1.515-.62 1.563l-.553 1.443c-.963 2.488-2.041 4.848-4.656 6.137-3.595 1.772-9.235.795-10.688-3.433-2.27-4.447-.768-10.659.533-15.265l.1-.354c-15.68-3.729-31.621-6.525-47.231-10.318-.51-.124-.562-.999 0-1.085ZM235.894 87.24c.816-1.632 2.666-1.64 3.767-.105 2.447 3.405-3.033 8.282-5.32 10.549l-.229.228c-5.14 5.216-11.41 9.42-17.627 13.221a129.346 129.346 0 0 1-3.957 2.306c.246.496.47 1 .653 1.516.941 2.657.96 5.832.111 8.519-1.766 5.6-6.465 10.075-12.559 10.07-3.214-.004-7.019-3.043-6.584-6.585.155-1.26.282-2.603.475-3.944-4.286 1.427-9.549-.245-12.275-3.778-3.79-4.91-2.748-12.96 3.056-16.01 4.268-2.242 8.98-2.808 13.602-3.993a194.348 194.348 0 0 0 13.635-4.03c4.43-1.485 8.837-3.078 13.238-4.65a126.502 126.502 0 0 1 6.547-2.157c.99-.294 2.415-1.068 3.467-1.157Z"
    />
  </svg>
)
export default SvgComponent
