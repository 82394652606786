import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $generateHtmlFromNodes } from "@lexical/html";
import { useEffect, useState } from "react";

export default function HtmlSerializerPlugin({ setHtmlfromSerializer }) {
    const [editor] = useLexicalComposerContext();
    let [html, setHtml] = useState("");
    useEffect(() => {
        const removeUpdateListener = editor.registerUpdateListener(
            ({ editorState }) => {
                editorState.read(() => {
                    const htmlString = $generateHtmlFromNodes(editor, null);
                    setHtml(htmlString);
                    setHtmlfromSerializer(htmlString)
                    // Do something.
                    console.log("HTMLSERIAL", htmlString)
                });
            }
        );
        return () => {
            removeUpdateListener();
        };
    }, [editor]);

    return <pre></pre>;
}
