import React, { useState, useEffect } from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// import {
//   db,
//   fieldValue,
// } from "../../../firebase/firebase.utils";

import { RRule, RRuleSet, rrulestr, datetime } from "rrule";

import { BsCheck } from "react-icons/bs";
import { IoCheckmarkOutline } from "react-icons/io5";

import "./recurring.styles.scss";

const Recurring = ({
  initialRRule,
  modifiedRRule,
  setModifiedRRule,
  rruleString,
  startDateRef,
  refrrule,
  setRefRRule,
}) => {
  // const { RRule } = require("rrule");
  const [showcustom, setShowCustom] = useState(false);

  const [freq, setFreq] = useState(3);
  const [interval, setInterval] = useState(1);

  const today = new Date();
  const todaysYear = today.getUTCFullYear();
  const todaysMonth = today.getUTCMonth() + 1;
  const todaysDate = today.getUTCDate();
  const todaysHour = today.getUTCHours();
  const todaysMinutes = today.getUTCMinutes();

  const nowRefDate = datetime(
    todaysYear,
    todaysMonth,
    todaysDate,
    todaysHour,
    todaysMinutes
  );

  // const [startDate, setStartDate] = useState();
  const [dtstart, setDtStart] = useState(null);
  const [until, setUntil] = useState(null);
  const [untilPicker, setUntilPicker] = useState(null);
  const [count, setCount] = useState(null);
  const [byweekday, setByWeekDay] = useState([]);
  const [bymonthday, setByMonthDay] = useState(null);
  const [byyearday, setByYearDay] = useState(null);

  const [bymonth, setByMonth] = useState(null);

  const [refrruleText, setRefRruleText] = useState("");

  const [bymonthdayPicker, setByMonthDayPicker] = useState(today);

  const [rruleText, setRruleText] = useState("");

  const [endType, setEndType] = useState("Never");

  const [showMoreSettings, setShowMoreSettings] = useState(false);

  ////

  const [rule, setRule] = useState({
    freq: "DAILY",
    interval: 1,
  });

  const [occur, setOccur] = useState(new RRule());

  useEffect(() => {
    setRruleText(modifiedRRule?.toText());
  }, [modifiedRRule]);

  useEffect(() => {
    // START DATE
    if (initialRRule.options.dtstart.length > 5) {
      setDtStart(initialRRule.options.dtstart);
    } else {
      if (startDateRef) {
        setDtStart(
          datetime(
            startDateRef.getUTCFullYear(),
            startDateRef.getUTCMonth() + 1,
            startDateRef.getUTCDate(),
            startDateRef.getUTCHours(),
            startDateRef.getUTCMinutes()
          )
        );
      } else {
        setDtStart(nowRefDate);
      }
    }
    // REQUIRED ONES
    if (initialRRule.options.interval) {
      setInterval(`${initialRRule.options.interval}`);
    }

    // OPTIONAL ONES
    if (initialRRule.options.until) {
      setUntil(initialRRule.options.until);
      // setUntilPicker
      // setEndType
      // setShowMoreSettings
    }
    if (initialRRule.options.count) {
      setCount(initialRRule.options.count);
      // setEndType
      // setShowMoreSettings
    }
    if (initialRRule.options.byweekday) {
      setByWeekDay(initialRRule.options.byweekday);
    }
    if (initialRRule.options.bymonthday) {
      setByMonthDay(initialRRule.options.bymonthday);
      // setByMonthDayPicker
    }
    if (initialRRule.options.byyearday) {
      setByYearDay(initialRRule.options.byyearday);
    }
    if (initialRRule.options.bymonthday) {
      setByMonthDay(initialRRule.options.bymonthday);
    }
    if (initialRRule.options.bymonth) {
      setByMonth(initialRRule.options.bymonth);
      // setByMonthDayPicker
    }
  }, [initialRRule, startDateRef]);

  useEffect(() => {
    setModifiedRRule(
      new RRule({
        dtstart: dtstart,
        freq: freq,
        interval,
        byweekday: byweekday,
        bymonthday: bymonthday,
        bymonth: bymonth,
        byyearday: byyearday,
        until: until,
        count: count,
      })
    );
  }, [freq, interval, byweekday, bymonthday, bymonth, byyearday, until, count]);

  const handlesetShowCustom = (e) => {
    e.preventDefault();
    setShowCustom(!showcustom);
  };

  const handlesetShowMoreSettings = (e) => {
    e.preventDefault();
    setShowMoreSettings(!showMoreSettings);
  };

  const handleRecurringConfirm = (e) => {
    e.preventDefault();
    // if (freq === "daily") {
    //   if (endType === "Never") {
    //     setRefRRule(dailyRule.toString());
    //   } else if (endType === "On") {
    //     setRefRRule(dailyRuleUntil.toString());
    //   } else if (endType === "After") {
    //     setRefRRule(dailyRuleCount.toString());
    //   }
    // } else if (freq === "weekly") {
    //   if (endType === "Never") {
    //     setRefRRule(weeklyRule.toString());
    //   } else if (endType === "On") {
    //     setRefRRule(weeklyRuleUntil.toString());
    //   } else if (endType === "After") {
    //     setRefRRule(weeklyRuleCount.toString());
    //   }
    // } else if (freq === "monthly") {
    //   if (endType === "Never") {
    //     setRefRRule(monthlyRule.toString());
    //   } else if (endType === "On") {
    //     setRefRRule(monthlyRuleUntil.toString());
    //   } else if (endType === "After") {
    //     setRefRRule(monthlyRuleCount.toString());
    //   }
    // } else if (freq === "monthly") {
    //   if (endType === "Never") {
    //     setRefRRule(yearlyRule.toString());
    //   } else if (endType === "On") {
    //     setRefRRule(yearlyRuleUntil.toString());
    //   } else if (endType === "After") {
    //     setRefRRule(yearlyRuleCount.toString());
    //   }
    // }
    // setFreq("");
  };

  // //DAILY
  // const dailyRule = new RRule({
  //   freq: RRule.DAILY,
  //   interval: interval,
  //   dtstart: new Date(2023, 0, 2, 9, 30, 0, 0),
  // });

  // //WEEKLY
  // const weeklyRule = new RRule({
  //   freq: RRule.WEEKLY,
  //   interval: interval,
  //   byweekday: byweekday.map((v) => RRule[v]),
  //   dtstart: new Date(2023, 0, 2, 9, 30, 0, 0),
  // });

  // //MONTHLY
  // const monthlyRule = new RRule({
  //   freq: RRule.MONTHLY,
  //   interval: interval,
  //   dtstart: new Date(2023, 0, 2, 9, 30, 0, 0),
  // });

  // //YEARLY
  // const yearlyRule = new RRule({
  //   freq: RRule.YEARLY,
  //   interval: interval,
  //   dtstart: new Date(2023, 0, 2, 9, 30, 0, 0),
  // });

  // const yearly = yearlyRule.all();
  // console.log(yearlyRule.toString());

  // rrule: {
  //   freq: "monthly",
  //   interval: 1,
  //   byweekday: ["mo", "fr"],
  //   dtstart: "2020-08-01T10:30:00" // will also accept '20120201T103000'
  //   // until: "2020-09-01" // will also accept '20120201'
  // },

  const freqs = [
    { label: "Monthly", value: "MONTHLY" },
    { label: "Weekly", value: "WEEKLY" },
    { label: "daily", value: "DAILY" },
  ];

  const handleChangeFreq = (e) => {
    e.preventDefault();
    setFreq(`${e.target.value}`);
    setInterval(1);
    if (e.target.value === "3") {
      setByWeekDay([]);
      setByMonthDay(null);
      setByMonth(null);
      setByYearDay(null);
    }
    if (e.target.value === "2") {
      setByWeekDay([0, 2]);
      setByMonthDay(null);
      setByMonth(null);
      setByYearDay(null);
    }
    if (e.target.value === "1") {
      setByMonthDayPicker(today);
      setByMonthDay(today.getDate());
      setByWeekDay([]);
    }
    if (e.target.value === "0") {
      setByMonthDayPicker(today);
      setByMonthDay(today.getDate());
      setByMonth(today.getMonth() + 1);
      setByYearDay(null);
      setByWeekDay([]);
    }
  };

  const handleSliderChange = (e) => {
    e.preventDefault();
    setInterval(e.target.value);
    // setRule({ ...rule, interval: e.target.value });
  };

  const days = [
    { label: "Mon", value: "MO" },
    { label: "Tue", value: "TU" },
    { label: "Wed", value: "WE" },
    { label: "Thu", value: "TH" },
    { label: "Fri", value: "FR" },
    { label: "Sat", value: "SA" },
    { label: "Sun", value: "SU" },
  ];

  const handleChangeByWeekday = (e) => {
    e.preventDefault();
    if (byweekday.includes(e.target.value / 1)) {
      setByWeekDay((pre) => {
        return [...pre.filter((day) => day !== e.target.value / 1)];
      });
    } else {
      setByWeekDay((pre) => [...pre, e.target.value / 1]);
    }
  };

  const handleSetFirstDayofMonth = (e) => {
    e.preventDefault();
    // var date = new Date();
    // var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    // setByMonthDay(firstDay);
    // setRule({
    //   ...rule,
    //   bymonthday: firstDay.getDate(),
    // });
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    setByMonthDayPicker(firstDay);
    setByMonthDay(1);
    setRule({
      ...rule,
      bymonthday: firstDay.getDate(),
    });
  };

  const handleSetLastDayofMonth = (e) => {
    e.preventDefault();
    var date = new Date();
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    setByMonthDayPicker(lastDay);
    setByMonthDay(-1);
    setRule({
      ...rule,
      bymonthday: -1,
    });
  };

  const handleSetFirstDayofYear = (e) => {
    e.preventDefault();
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), 0, 1);
    setByMonthDayPicker(firstDay);
    setByYearDay(1);
    setRule({
      ...rule,
      bymonth: 1,
      bymonthday: 1,
    });
  };

  const handleSetLastDayofYear = (e) => {
    e.preventDefault();
    var date = new Date();
    var lastDay = new Date(date.getFullYear(), 11, 31);
    setByMonthDayPicker(lastDay);
    setByYearDay(-1);
    setRule({
      ...rule,
      bymonth: 12,
      bymonthday: 31,
    });
  };

  const handleSetEndCount = (e) => {
    setEndType(e.target.value);
    setCount(10);
    setUntil(null);
    setUntilPicker(null);
  };

  const handleSetEndUntil = (e) => {
    setEndType(e.target.value);

    const futuredate = new Date(
      new Date().getTime() + 30 * 24 * 60 * 60 * 1000
    );
    setUntil(
      datetime(
        futuredate.getFullYear(),
        futuredate.getMonth() + 1,
        futuredate.getDate()
      )
    );
    setUntilPicker(futuredate);

    setCount(null);
  };

  const handleSetEndNever = (e) => {
    setEndType(e.target.value);
    setUntil(null);
    setUntilPicker(null);
    setCount(null);
  };

  // Don't render the form until the RRule string has been fetched
  if (!initialRRule) {
    return null;
  }

  return (
    <div className="recurring">
      <div className="recurring__options">
        <button
          value={3}
          className={freq === "3" ? "recurring_freq-active" : "recurring_freq"}
          onClick={handleChangeFreq}
        >
          Daily
        </button>
        <button
          value={2}
          className={freq === "2" ? "recurring_freq-active" : "recurring_freq"}
          onClick={handleChangeFreq}
        >
          Weekly
        </button>
        <button
          value={1}
          className={freq === "1" ? "recurring_freq-active" : "recurring_freq"}
          onClick={handleChangeFreq}
        >
          Monthly
        </button>
        <button
          value={0}
          className={freq === "0" ? "recurring_freq-active" : "recurring_freq"}
          onClick={handleChangeFreq}
        >
          Yearly
        </button>
        {/* <button onClick={handlesetShowCustom}>Custom</button> */}
      </div>
      {freq === "3" && (
        <div class="recurring_mainsettings">
          <div class="timerangeslider">
            <input
              type="range"
              min="1"
              max="45"
              defaultValue="1"
              value={interval}
              class="timerangeslider"
              id="myRange"
              onChange={handleSliderChange}
            ></input>
            {/* <p>Every {interval === 1 ? `day` : `other ${interval} days`}</p> */}
            <p>{rruleText}</p>
          </div>
        </div>
      )}
      {freq === "2" && (
        <div class="recurring_mainsettings">
          <div class="timerangeslider">
            <input
              type="range"
              min="1"
              max="12"
              defaultValue="1"
              value={interval}
              class="timerangeslider"
              id="myRange"
              onChange={handleSliderChange}
            ></input>
            {/* <p>Every {interval === 1 ? `week` : `other ${interval} weeks`}</p> */}
            <p>{rruleText}</p>
          </div>
          <div className="recurring_weekdays">
            <label>Days</label>
            <button
              className={
                byweekday.includes(0)
                  ? "recurring_weekday-active"
                  : "recurring_weekday"
              }
              value={0}
              onClick={handleChangeByWeekday}
            >
              Mo
            </button>
            <button
              className={
                byweekday.includes(1)
                  ? "recurring_weekday-active"
                  : "recurring_weekday"
              }
              value={1}
              onClick={handleChangeByWeekday}
            >
              Tu
            </button>
            <button
              className={
                byweekday.includes(2)
                  ? "recurring_weekday-active"
                  : "recurring_weekday"
              }
              value={2}
              onClick={handleChangeByWeekday}
            >
              We
            </button>
            <button
              className={
                byweekday.includes(3)
                  ? "recurring_weekday-active"
                  : "recurring_weekday"
              }
              value={3}
              onClick={handleChangeByWeekday}
            >
              Th
            </button>
            <button
              className={
                byweekday.includes(4)
                  ? "recurring_weekday-active"
                  : "recurring_weekday"
              }
              value={4}
              onClick={handleChangeByWeekday}
            >
              Fr
            </button>
            <button
              className={
                byweekday.includes(5)
                  ? "recurring_weekday-active"
                  : "recurring_weekday"
              }
              value={5}
              onClick={handleChangeByWeekday}
            >
              Sa
            </button>
            <button
              className={
                byweekday.includes(6)
                  ? "recurring_weekday-active"
                  : "recurring_weekday"
              }
              value={6}
              onClick={handleChangeByWeekday}
            >
              Su
            </button>
          </div>
        </div>
      )}
      {freq === "1" && (
        <div class="recurring_mainsettings">
          <div class="timerangeslider">
            <input
              type="range"
              min="1"
              max="12"
              defaultValue="1"
              value={interval}
              class="timerangeslider"
              id="myRange"
              onChange={handleSliderChange}
            ></input>
            {/* <p>Every {interval === 1 ? `month` : `other ${interval} months`}</p> */}
            <p>{rruleText}</p>
          </div>
          <div className="recurring_weekdays">
            <label>Days</label>
            <button onClick={handleSetFirstDayofMonth}>First Day</button>
            {/* <button>On Day</button> */}
            <DatePicker
              // popperProps={{ strategy: "fixed" }}
              wrapperClassName="datepicker"
              // isClearable
              placeholderText="On Day"
              // value={bymonthday}
              // showTimeSelect
              timeFormat="p"
              dateFormat="dd"
              selected={bymonthdayPicker}
              // onChange={(date) => setByMonthDay(date)}
              onChange={(date) =>
                setByMonthDayPicker(date) &
                setByMonthDay(date.getDate()) &
                setRule({
                  ...rule,
                  bymonthday: date ? date.getDate() : undefined,
                })
              }
            />
            <button onClick={handleSetLastDayofMonth}>Last Day</button>
          </div>
        </div>
      )}
      {freq === "0" && (
        <div class="recurring_mainsettings">
          <div class="timerangeslider">
            <input
              type="range"
              min="1"
              max="5"
              defaultValue="1"
              value={interval}
              class="timerangeslider"
              id="myRange"
              onChange={handleSliderChange}
            ></input>
            {/* <p>Every {interval === 1 ? `year` : `other ${interval} years`}</p> */}
            <p>{rruleText}</p>
          </div>
          <div className="recurring_weekdays">
            <label>Days</label>
            <button onClick={handleSetFirstDayofYear}>First Day</button>
            {/* <button>On Day</button> */}
            <DatePicker
              // popperProps={{ strategy: "fixed" }}
              wrapperClassName="datepicker"
              // isClearable
              placeholderText="On Day"
              // value={event.endDate}
              // showTimeSelect
              timeFormat="p"
              dateFormat="MMMM d"
              selected={bymonthdayPicker}
              onChange={(date) =>
                setByMonthDayPicker(date) &
                setByMonth(date.getMonth() + 1) &
                setByMonthDay(date.getDate()) &
                setRule({
                  ...rule,
                  bymonthday: date ? date.getDate() : undefined,
                })
              }
            />
            <button onClick={handleSetLastDayofYear}>Last Day</button>
          </div>
        </div>
      )}
      {showMoreSettings && (
        <div className="recurring__end">
          <label>Ends</label>
          <div className="recurring__endrow">
            <div className="recurring__endrowoption">
              <input
                type="radio"
                name="endtype"
                id="never"
                value="Never"
                checked={endType === "Never"}
                onChange={handleSetEndNever}
              ></input>{" "}
              <label for="never">Never</label>
            </div>
            <div className="recurring__endrowdate">
              <input
                type="radio"
                name="endtype"
                id="on"
                value="On"
                checked={endType === "On"}
                onChange={handleSetEndUntil}
              ></input>{" "}
              <label for="never">on</label>
              <DatePicker
                // popperProps={{ strategy: "fixed" }}
                // wrapperClassName="datepicker"
                // isClearable
                placeholderText="Select end date"
                // value={event.endDate}
                // showTimeSelect
                timeFormat="p"
                dateFormat="MMMM d, yyyy"
                selected={untilPicker}
                onChange={(date) =>
                  setUntilPicker(date) &
                  setUntil(
                    datetime(
                      date.getFullYear(),
                      date.getMonth() + 1,
                      date.getDate()
                    )
                  ) &
                  setCount(null)
                }
              />
            </div>
            <div className="recurring__endrowoption">
              <input
                type="radio"
                name="endtype"
                id="after"
                value="After"
                checked={endType === "After"}
                onChange={handleSetEndCount}
              ></input>{" "}
              <label for="after">After</label>
              <input
                type="number"
                min="2"
                className="inverval_number"
                // placeholder="2"
                // defaultValue="10"
                value={count}
                onChange={(e) => setCount(e.target.value)}
              ></input>
              <label for="freq">times</label>
            </div>
          </div>
        </div>
      )}
      <button
        onClick={(e) => handlesetShowMoreSettings(e)}
        className="recurring__showmore-btn"
      >
        {showMoreSettings ? "less details" : "more details"}
      </button>
      {/* <p
        style={{
          position: "absolute",
          bottom: "30px",
          left: "-80px",
          color: "gray",
        }}
      >
        {rruleString}
      </p> */}
    </div>
  );
};

export default Recurring;
