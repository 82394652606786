import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import "./home.styles.scss";
import "../../components/leads-clients/leads-clients.styles.scss";

import NoSpaceData from "../../components/noSpaceData/no-space-data.component";
import SpaceData from "../../components/spaceData/space-data.component";
import SectionBox from "../../components/section-box/section-box.component";
import FavoriteStations from "../../components/favorite-stations/favorite-stations.component";
import AssignedTasks from "../../components/assigned-tasks/assigned-tasks.component";
import WelcomeHome from "../../components/welcome-home/welcome-home.component";

import SharedWithMe from "../../components/stations/sharedwithme.component";

import MiniCalendar from "../../components/calendar/mini-calendar.component";

import HomeChart from "../../components/charts/HomeChart.component";
import Habits from "../../components/habits/habits.component";
import Journal from "../../components/journal/journal.component";

import TaskAddButton from "../../components/task-add-btn/task-add-btn.component";

const Home = () => {
  const spaceData = useSelector((state) => state.space.spaceData);
  const currentUser = useSelector((state) => state.user.currentUser);

  const [journalTab, setJournalTab] = useState(true);
  const [habitsTab, setHabitsTab] = useState(true);
  const [analyticsTab, setAnalyticsTab] = useState(false);

  const [activeTab, setActiveTab] = useState("habits");

  const [numOfPrTasks, setNumOfPrTasks] = useState("0");
  const [numOfPrToday, setNumOfPrTasksToday] = useState("0");
  const [numOfPrTasksPastDue, setNumOfPrTasksPastDue] = useState("0");

  function iOS() {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  }

  const isSupportedBrowser = iOS();

  useEffect(() => {
    if (!isSupportedBrowser) {
      if (Notification.permission === "granted") {
        //if we have given permission before
        console.log(
          "Permission has already granted, may receive notifications"
        );
        // const notify = new Notification(
        //   "Permission granted, may receive notifications"
        // );
      } else {
        Notification.requestPermission().then((response) => {
          if (response === "granted") {
            const notify = new Notification(
              "Permission granted, may receive notifications now. 😊"
            );
          } else {
            console.log("User haven't given permission");
          }
        });
      }
    }
  }, []);

  // const sendNotification = () => {
  //   new Notification("This comes from Antlogy");
  // };
  const sendNotification = () => {
    new Notification("This comes from Antlogy", {
      body: "Hey this is the body text of a notification",
    });
  };

  return (
    <div className="home">
      <div className="home__welcomeHome">
        <WelcomeHome numOfPrTasks={numOfPrTasks} />
      </div>
      <div className="home__content">
        <div className="home__zerocolumn">
          <h3>Workspaces</h3>
          {spaceData.length !== 0 ? <SpaceData /> : <NoSpaceData />}
          {/* <h3>Shared With Me</h3> */}
          {/* <SharedWithMe /> */}
          <h3>Pinned Projects</h3>
          <div className="home__favorite">
            <div className="home__favoriteStations">
              <FavoriteStations />
            </div>
          </div>
          {/* <div className="home__notiftest">
            <button onClick={sendNotification}>Notification Test Button</button>
          </div> */}
        </div>
        <div className="home__content-container">
          <div className="home__firstcolumn">
            {/* <SectionBox
            title="Pinned projects"
            // subTitle="Pinned projects"
          >
            <div className="home__favorite">
              <div className="home__favoriteStations">
                <FavoriteStations />
              </div>
            </div>
          </SectionBox> */}
            <div className={journalTab ? "journalactive" : "journalinactive"}>
              <div
                className="widgetheader"
                onClick={() => setJournalTab(!journalTab)}
              >
                <h3>Journal</h3>
                <span className="widgetheader-chevron">&#8250;</span>
              </div>
              {/* <p>List of the Days</p> */}
              <Journal currentUser={currentUser} />
            </div>
            <div className={habitsTab ? "habitsactive" : "habitsinactive"}>
              <div className="client__header-tabs">
                <div
                  className={
                    activeTab === "habits"
                      ? "widgetheader"
                      : "widgetheader-nonactive"
                  }
                  onClick={() => setActiveTab("habits")}
                >
                  <h3>Habits</h3>
                  <span
                    className="widgetheader-chevron"
                    onClick={() => setHabitsTab(!habitsTab)}
                  >
                    &#8250;
                  </span>
                </div>
                <div
                  className={
                    activeTab === "analytics"
                      ? "widgetheader"
                      : "widgetheader-nonactive"
                  }
                  onClick={() => setActiveTab("analytics")}
                >
                  <h3>Analytics</h3>
                  <span
                    className="widgetheader-chevron"
                    onClick={() => setHabitsTab(!habitsTab)}
                  >
                    &#8250;
                  </span>
                </div>
              </div>
              {habitsTab && (
                <>
                  {activeTab === "habits" && (
                    <Habits currentUser={currentUser} />
                  )}
                  {activeTab === "analytics" && (
                    <HomeChart
                      currentUser={currentUser}
                      spaceData={spaceData}
                    />
                  )}
                </>
              )}

              {/* {spaceData.length !== 0 ? <SpaceData /> : <NoSpaceData />} */}
            </div>
            {/* <div className={analyticsTab ? "chartactive" : "chartinactive"}>
              <div
                className="widgetheader"
                onClick={() => setAnalyticsTab(!analyticsTab)}
              >
                <h3>Analytics</h3>
                <span className="widgetheader-chevron">&#8250;</span>
              </div>
              {analyticsTab && (
                <HomeChart currentUser={currentUser} spaceData={spaceData} />
              )}
            </div> */}
          </div>
          {/* <SectionBox
          title="My Tasks"
          subTitle="Undone tasks that are assigned to you"
        >

        </SectionBox> */}
          <AssignedTasks
            setNumOfPrTasks={setNumOfPrTasks}
            setNumOfPrTasksToday={setNumOfPrTasksToday}
            setNumOfPrTasksPastDue={setNumOfPrTasksPastDue}
            currentUser={currentUser}
          />
          {/* <MiniCalendar /> */}
        </div>
      </div>
      <TaskAddButton />
    </div>
  );
};

export default Home;
