import React, { useEffect, useState } from "react";
import avatarParts from "./avatarParts";
import "./AvatarCreator.scss";
import { db } from "../../firebase/firebase.utils";

const AvatarCreator = ({ currentUser }) => {
  const [showAvatarCreator, setShowAvatarCreator] = useState(false);
  const [avatar, setAvatar] = useState({
    head: "afro",
    accessories: "none",
    body: "blazerBlackTee",
    facialHair: "noneFacialHair",
    face: "blank",
    skinColor: "FFDBB4",
    hairColor: "000000",
  });

  const hairColors = [
    "000000",
    "2C1B18",
    "4A312C",
    "724133",
    "A55728",
    "B58143",
    "C93305",
    "D6B370",
    "E8E1E1",
    "ECDCBF",
    "F59797",
  ];

  const skinColors = [
    "694D3D",
    "AE5D29",
    "D08B5B",
    "EDB98A",
    "FFDBB4",
    "FFD11B",
  ];

  const handleChange = (category, value) => {
    setAvatar({
      ...avatar,
      [category]: value,
    });
  };

  useEffect(() => {
    if (currentUser.imgAvatar) {
      setAvatar(currentUser.imgAvatar);
    }
  }, [currentUser]);

  const saveAvatar = async () => {
    try {
      await db
        .collection("users")
        .doc(currentUser.uid)
        .update({ imgAvatar: avatar });
      alert("Avatar saved successfully!");
    } catch (error) {
      console.error("Error saving avatar: ", error);
      alert("Error saving avatar.");
    }
  };

  const createRandomAvatar = () => {
    const randomValue = (category) => {
      const keys = Object.keys(avatarParts[category]);
      return keys[Math.floor(Math.random() * keys.length)];
    };

    setAvatar({
      head: randomValue("head"),
      accessories: randomValue("accessories"),
      body: randomValue("body"),
      facialHair: randomValue("facialHair"),
      face: randomValue("face"),
      skinColor: skinColors[Math.floor(Math.random() * skinColors.length)],
      hairColor: hairColors[Math.floor(Math.random() * hairColors.length)],
    });
  };

  const renderHead = ({viewBox}) => {
    const Component = avatarParts.head[avatar.head];
    return Component ? (
      <Component
        color={`#${avatar.hairColor}`}
        fill={`#${avatar.hairColor}`}
        width="100%"
        height="100%"
        viewBox={viewBox}
      />
    ) : null;
  };

  const renderFace = ({viewBox}) => {
    const Component = avatarParts.face[avatar.face];
    return Component ? (
      <Component
        color={`#${avatar.skinColor}`}
        width="100%"
        height="100%"
        viewBox={viewBox}
      />
    ) : null;
  };

  const renderFacialHair = ({viewBox}) => {
    const Component = avatarParts.facialHair[avatar.facialHair];
    return Component ? (
      <Component
        color={`#${avatar.hairColor}`}
        width="100%"
        height="100%"
        viewBox={viewBox}
      />
    ) : null;
  };

  const renderAccessories = ({viewBox}) => {
    const Component = avatarParts.accessories[avatar.accessories];
    return Component ? (
      <Component width="100%" height="100%" viewBox={viewBox} />
    ) : null;
  };

  const renderBody = ({viewBox}) => {
    const Component = avatarParts.body[avatar.body];
    return Component ? (
      <Component
        color={`#${avatar.skinColor}`}
        width="100%"
        height="100%"
        viewBox={viewBox}
      />
    ) : null;
  };

  return (
    <div>
      <button onClick={() => setShowAvatarCreator(!showAvatarCreator)}  className="custom-avatar">
        {!showAvatarCreator && <div className="avatar-preview">
            <div className="avatar-container">
              <div className="avatar-face">{renderFace({viewBox: "0 0 2048 2048"})}</div>
              <div className="avatar-facialhair">{renderFacialHair({viewBox: "0 0 2048 2048"})}</div>
              <div className="avatar-accessories">{renderAccessories({viewBox: "0 0 2048 2048"})}</div>
              <div className="avatar-head">{renderHead({viewBox: "0 0 2048 2048"})}</div>
              <div className="avatar-body">{renderBody({viewBox: "0 0 2048 2048"})}</div>
            </div>
          </div>}
      </button>
      {showAvatarCreator && (
        <div className="custom-avatar">
          <div className="avatar-preview">
            <div className="avatar-container">
              <div className="avatar-face">{renderFace({viewBox: "0 0 1024 1024"})}</div>
              <div className="avatar-facialhair">{renderFacialHair({viewBox: "0 0 1024 1024"})}</div>
              <div className="avatar-accessories">{renderAccessories({viewBox: "0 0 1024 1024"})}</div>
              <div className="avatar-head">{renderHead({viewBox: "0 0 1024 1024"})}</div>
              <div className="avatar-body">{renderBody({viewBox: "0 0 1024 1024"})}</div>
            </div>
          </div>
          <div>
            <div>
              <label>Accessories:</label>
              <select
                onChange={(e) => handleChange("accessories", e.target.value)}
                value={avatar.accessories}
              >
                {Object.keys(avatarParts.accessories).map((key) => (
                  <option key={key} value={key}>
                    {key}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label>Body (Clothing):</label>
              <select
                onChange={(e) => handleChange("body", e.target.value)}
                value={avatar.body}
              >
                {Object.keys(avatarParts.body).map((key) => (
                  <option key={key} value={key}>
                    {key}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label>Face:</label>
              <select
                onChange={(e) => handleChange("face", e.target.value)}
                value={avatar.face}
              >
                {Object.keys(avatarParts.face).map((key) => (
                  <option key={key} value={key}>
                    {key}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label>Facial Hair:</label>
              <select
                onChange={(e) => handleChange("facialHair", e.target.value)}
                value={avatar.facialHair}
              >
                {Object.keys(avatarParts.facialHair).map((key) => (
                  <option key={key} value={key}>
                    {key}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label>Head:</label>
              <select
                onChange={(e) => handleChange("head", e.target.value)}
                value={avatar.head}
              >
                {Object.keys(avatarParts.head).map((key) => (
                  <option key={key} value={key}>
                    {key}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label>Skin Color:</label>
              <select
                onChange={(e) => handleChange("skinColor", e.target.value)}
                value={avatar.skinColor}
              >
                {skinColors.map((color) => (
                  <option key={color} value={color}>
                    {color}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label>Hair Color:</label>
              <select
                onChange={(e) => handleChange("hairColor", e.target.value)}
                value={avatar.hairColor}
              >
                {hairColors.map((color) => (
                  <option key={color} value={color}>
                    {color}
                  </option>
                ))}
              </select>
            </div>
            <button onClick={createRandomAvatar}>Create Random</button>
            <button onClick={saveAvatar}>Save Avatar</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AvatarCreator;
