import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { Chess } from 'chess.js';

import { Chessboard } from 'react-chessboard';

import { db, fieldValue } from "../../firebase/firebase.utils";

import "./chess.styles.scss";

import {
  BsArrowCounterclockwise,
  BsCup,
  BsArrowDownUp,
} from "react-icons/bs";

export default function CoachingChessBoard({ boardWidth }) {
  const chessboardRef = useRef();
  const [game, setGame] = useState(new Chess());
  const [onlinefen, setOnlineFen] = useState("start");
  const [fen, setFen] = useState("start");

  const [arrows, setArrows] = useState([]);

  console.log("gameobject", game)

  // const history = useNavigate();
  const location = useLocation();
  const currentSpaceId = location.pathname.split("/")[2];
  const currentStationId = location.pathname.split("/")[4];

  const [boardOrientation, setBoardOrientation] = useState('white');

  const [themedarkcolor, setThemeDarkColor] = useState('#4B7399');
  const [themelightcolor, setThemeLightColor] = useState('#EAE9D2');

  const [rightClickedSquares, setRightClickedSquares] = useState({});

  const pieces = ['wP', 'wN', 'wB', 'wR', 'wQ', 'wK', 'bP', 'bN', 'bB', 'bR', 'bQ', 'bK'];
  const customPieces = () => {
    const returnPieces = {};
    pieces.map((p) => {
      returnPieces[p] = ({ squareWidth }) => (
        <div
          style={{
            width: squareWidth,
            height: squareWidth,
            backgroundImage: `url(/media/${p}.png)`,
            backgroundSize: '100%',
          }}
        />
      );
      return null;
    });
    return returnPieces;
  };

  const [piecesPreference, setPiecesPreference] = useState(customPieces());

  function onChangeTheme(event) {
    if (event.target.value === "Green") {
      setThemeDarkColor('#779952');
      setThemeLightColor('#edeed1')
    }
    if (event.target.value === "Blue") {
      setThemeDarkColor('#4B7399');
      setThemeLightColor('#EAE9D2')
    }
    if (event.target.value === "Mono") {
      setThemeDarkColor('#787878');
      setThemeLightColor('#F2F3F5')
    }
    if (event.target.value === "Brown") {
      setThemeDarkColor('#B58863');
      setThemeLightColor('#F0D9B5')
    }
  }

  // const [currentgame, setCurrentGame] = useState(game);
  // useEffect(() => {
  //   //mounts
  //   const unsub = db
  //     .collection("space")
  //     .doc(currentSpaceId)
  //     .collection("stations")
  //     .doc(currentStationId)
  //     .collection("chess")
  //     .doc("Currentgame")
  //     .onSnapshot((doc) => {
  //       // console.log("Current data: ", doc.data());
  //       setCurrentGame(doc.data());
  //       // setGame(currentboard)
  //     });
  //   return () => {
  //     unsub();
  //   };
  // }, []);


  useEffect(() => {
    //mounts
    const unsub = db
      .collection("space")
      .doc(currentSpaceId)
      .collection("stations")
      .doc(currentStationId)
      .collection("chess")
      .doc("Currentgame")
      .onSnapshot((doc) => {
        // console.log("Current data: ", doc.data());
        setOnlineFen(doc.get("fen"));
        // setGame(currentboard)
      });
    return () => {
      unsub();
    };
  }, [game]);

  // const [currentarrows, setCurrentArrows] = useState([['a3', 'a5'], ['g1', 'f3']]);
  // useEffect(() => {
  //   //mounts
  //   const unsub = db
  //     .collection("space")
  //     .doc(currentSpaceId)
  //     .collection("stations")
  //     .doc(currentStationId)
  //     .collection("chess")
  //     .doc("Arrows")
  //     .get()
  //     .then((querySnapshot) => {
  //       const arrowz = querySnapshot.data().arrows;
  //       // const arrowRef = arrowz.map(arrow => arrow);
  //       // console.log("Current data: ", arrowz);
  //       // const arrowzref = arrowz.arrowsnew;
  //       // const plainarr = arrowzref.toString();
  //       // console.log("Current data2: ", plainarr);
  //       setCurrentArrows([arrowz]);
  //     });
  //   // .onSnapshot((doc) => {
  //   //   const arrowz = doc.get(arrows)
  //   //   const arrows = arrowz.map(arrow => arrow);
  //   //   console.log("Current data: ", arrows);
  //   //   // const arrowz = temparr.map(arrow => arrow);
  //   //   // setCurrentArrows(arrowz);
  //   // });
  //   return () => {
  //     unsub();
  //   };
  // }, []);

  // .onSnapshot((querySnapshot) => {
  //   const array = querySnapshot.docs.map((msg) => {
  //     return {
  //       id: msg.id,
  //       created: msg.get("created"),
  //       from: msg.get("from"),
  //       fromspaceId: msg.get("fromspaceId"),
  //       message: msg.get("message"),
  //       reaction: msg.get("reaction"),
  //       to: msg.get("to"),
  //     };
  //   });
  //   setMsgs([...array]);



  function safeGameMutate(modify) {
    setGame((g) => {
      const update = { ...g };
      modify(update);
      return update;
    });
  }


  function onDrop(sourceSquare, targetSquare) {
    document.getElementById("chess_slide").play();
    const gameCopy = { ...game };
    const move = gameCopy.move({
      from: sourceSquare,
      to: targetSquare,
      promotion: 'q' // always promote to a queen for example simplicity
    });
    setGame(gameCopy);
    db.collection("space")
      .doc(currentSpaceId)
      .collection("stations")
      .doc(currentStationId)
      .collection("chess")
      .doc("Currentgame")
      .set({
        fen: gameCopy.fen(),
        history: gameCopy.history(),
        move: { move },
      }, { merge: false });
    // setOnlineFen(gameCopy.fen());
    return move;
  }
  // function onDrop(sourceSquare, targetSquare) {
  //   document.getElementById("chess_slide").play();
  //   const gameCopy = { ...game };
  //   const move = gameCopy.move({
  //     from: sourceSquare,
  //     to: targetSquare,
  //     promotion: 'q' // always promote to a queen for example simplicity
  //   });
  //   setGame(gameCopy);
  //   db.collection("space")
  //     .doc(currentSpaceId)
  //     .collection("stations")
  //     .doc(currentStationId)
  //     .collection("chess")
  //     .doc("Currentgame")
  //     .set({
  //       fen: gameCopy.fen(),
  //       history: gameCopy.history(),
  //       move: { move },
  //     }, { merge: false });
  //   return move;
  // }

  // function onArrowsChange(squares) {
  //   console.log(squares);
  //   // setArrows(squares);
  //   // console.log("squares are here", squares);

  //   const abc = squares.map(square => square);
  //   db.collection("space")
  //     .doc(currentSpaceId)
  //     .collection("stations")
  //     .doc(currentStationId)
  //     .collection("chess")
  //     .doc("Arrows")
  //     .set({
  //       arrows: abc.toString(),
  //     }, { merge: true });
  // }

  // const handleUpdateChess = (game) => {
  //   db.collection("space")
  //     .doc(currentSpaceId)
  //     .collection("stations")
  //     .doc(currentStationId)
  //     .collection("chess")
  //     .doc("Currentgame")
  //     .update({
  //       currentgame: { ...game },
  //     });
  // };
  // const handleUpdateChessUndo = (game) => {
  //   db.collection("space")
  //     .doc(currentSpaceId)
  //     .collection("stations")
  //     .doc(currentStationId)
  //     .collection("chess")
  //     .doc("Currentgame")
  //     .update({
  //       undo: game.undo(),
  //     });
  // };
  const handleUpdateChessReset = (game) => {
    db.collection("space")
      .doc(currentSpaceId)
      .collection("stations")
      .doc(currentStationId)
      .collection("chess")
      .doc("Currentgame")
      .set({
        reset: game.reset(),
        fen: game.fen(),
      });
  };

  function onSquareRightClick(square) {
    const colour = 'rgba(153, 0, 0, 0.4)';
    setRightClickedSquares({
      ...rightClickedSquares,
      [square]:
        rightClickedSquares[square] && rightClickedSquares[square].backgroundColor === colour
          ? undefined
          : { backgroundColor: colour }
    });
  }

  return (
    <div className="chess-container" >
      <div className="chess__buttongroup">
        <button
          className="chess__button"
          onClick={() => {
            safeGameMutate((game) => {
              game.undo();
            });
            chessboardRef.current.clearPremoves();
            // handleUpdateChessUndo(game);
          }}
        >
          <BsArrowCounterclockwise />
          Undo
        </button>
        <button
          className="chess__button"
          onClick={() => {
            safeGameMutate((game) => {
              game.reset();
            });
            chessboardRef.current.clearPremoves();
            handleUpdateChessReset(game);
          }}
        >
          <BsCup />
          Reset
        </button>
        <button
          className="chess__button"
          onClick={() => {
            setBoardOrientation((currentOrientation) => (currentOrientation === 'white' ? 'black' : 'white'));
          }}
        >
          <BsArrowDownUp />
          Flip
        </button>
        {
          game && game.game_over() ? <div className="chess__gameover-message"><h4>Game Over! <br /> ☑</h4> <button className="chess__button"
            onClick={() => {
              safeGameMutate((game) => {
                game.reset();
              });
            }}><BsCup />Play Again ?</button></div> : <span></span>
        }
      </div>
      <audio
        id="chess_slide"
        src="/media/mixkit-slide-click-1130.wav"
      ></audio>
      <Chessboard
        id="StyledBoard"
        animationDuration={200}
        boardOrientation={boardOrientation}
        boardWidth={boardWidth}
        position={onlinefen}
        onPieceDrop={onDrop}
        snapToCursor={false}
        customBoardStyle={{
          borderRadius: '4px',
          boxShadow: '0 7px 17px rgba(0, 0, 0, 0.5)',
          outline: '2px solid',
          outlineColor: themedarkcolor,

        }}
        customDarkSquareStyle={{ backgroundColor: themedarkcolor }}
        customLightSquareStyle={{ backgroundColor: themelightcolor }}
        onSquareRightClick={onSquareRightClick}
        customSquareStyles={{
          ...rightClickedSquares
        }}
        customPieces={piecesPreference}
        ref={chessboardRef}
      // onArrowsChange={onArrowsChange}
      // customArrows={currentarrows}
      />
      <div className="chess__theme-settings">
        <div className="chess__buttongroup">
          <h5>Pieces Set</h5>
          <button className="chess__button" onClick={() => setPiecesPreference("customPieces")}>Classic</button>
          <button className="chess__button" onClick={() => setPiecesPreference(customPieces)}>Smooth</button>
        </div>
        <div className="chess__buttongroup" onChange={onChangeTheme}>
          <h5>Chessboard</h5>
          <div className="chess__themebutton chess__forest"><input type="radio" value="Green" name="theme" /> <label>Forest</label></div>
          <div className="chess__themebutton chess__sea"><input type="radio" value="Blue" name="theme" /> <label>Sea</label></div>
          <div className="chess__themebutton chess__mono"><input type="radio" value="Mono" name="theme" /> <label>Mono</label></div>
          <div className="chess__themebutton chess__coffee"><input type="radio" value="Brown" name="theme" /> <label>Coffee</label></div>
        </div>
      </div>
    </div>
  );
}