import SettingsType from "./settings.type";

export const showCalendarSettings = () => ({
  type: SettingsType.SHOW_CALENDAR_SETTINGS,
});

export const showGanttSettings = () => ({
  type: SettingsType.SHOW_GANTT_SETTINGS,
});

export const showFilesSettings = () => ({
  type: SettingsType.SHOW_FILES_SETTINGS,
});

export const showConceptSettings = () => ({
  type: SettingsType.SHOW_CONCEPT_SETTINGS,
});

export const showNotesSettings = () => ({
  type: SettingsType.SHOW_NOTES_SETTINGS,
});
