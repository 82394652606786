import React from "react";

import "./module-list.styles.scss";

import {
  FaUser,
  FaUserCheck,
  FaExclamationCircle,
  FaInfoCircle,
  FaCalendarCheck,
  FaCalendarAlt,
  FaHourglassHalf,
} from "react-icons/fa";

import { GiStopwatch } from "react-icons/gi";

// import { BsPersonFill, BsPersonCheckFill, BsInfoSquareFill } from "react-icons/bs";

import { updateModulesDb } from "../../../firebase/firebase.utils";

const ModuleList = ({
  module,
  setModules,
  modules,
  setForce,
  force,
  type,
  currentStationId,
  currentSpaceId,
}) => {
  // => dok se raid createStation 1. put ide lokalno, dalje sve prek db
  // => ako je local, obavezno naglasiti type="local"

  const toggleStateLocal = () => {
    console.log("local");
    let array = modules;
    let index = array.findIndex((item) => item.name === module.name);
    array[index].active = !module.active;
    console.log(array);
    setModules(array);
    setForce(force + 1);
  };

  const toggleStateDb = () => {
    console.log(module);
    updateModulesDb(currentSpaceId, currentStationId, module, modules);
  };

  // AKO JE DB
  return (
    <div
      className="moduleList"
      style={{
        border:
          module.active === true ? "1px solid black" : "1px solid lightgray",
        background: "white",
      }}
      mode="flat"
      onClick={() => (type === "local" ? toggleStateLocal() : toggleStateDb())}
    >
      <p
        style={{
          color: module.active === true ? "black" : "lightgray",
        }}
      >
        {module.icon === "faUser" && <FaUser />}
        {module.icon === "faCalendarCheck" && <FaCalendarCheck />}
        {module.icon === "faInfoCircle" && <FaInfoCircle />}
        {module.icon === "faExclamationCircle" && <FaExclamationCircle />}
        {module.icon === "faUserCheck" && <FaUserCheck />}
        {module.icon === "faCalendarAlt" && <FaCalendarAlt />}
        {module.icon === "faHourglassHalf" && <FaHourglassHalf />}
        {module.icon === "GiStopwatch" && <GiStopwatch />}
        {module.name}
      </p>
    </div>
  );
};

export default ModuleList;
