import * as React from "react"
const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={280} height={230} {...props}>
    <title>{"facial-hair/Full 3"}</title>
    <path
      fill="#000"
      fillRule="evenodd"
      d="M227.994 104.31c-9.313-8.296-19.752-24.976-33.13-11.778-3.31 3.729-4.65 12.089-9.71 13.729-6.72 2.18-13.38-4.93-15.67-10.39-9.37-30.337 29.364-38.658 51.43-33.757 10.78 4.427 18.72 22.247 19.2 31.547.31 6.25-4.98 15.44-12.12 10.65m70.26-46.76a5.485 5.485 0 0 0-.37-1.098c-4.12-1.557-7.379.03-9.393 2.997.066.737.286 1.484.425 2.215-1.554 3.274-2.956 7.325-5.062 10.154a3.568 3.568 0 0 1-1.26-.132c-34.914 3.646-17.869-32.883-53.28-41.956-27.373-4.516-76.847.657-89.76 29.148-10.184 45.508-61.967 13.798-78.969-9.87-18.14-21.538-29.87-47.858-41.18-73.398-2.39-5.405-9.85-1.54-9.039 3.646C-.088.227.26 25.417-2.329 48.392c-4.404 45.835-8.695 110.113 33.892 138.1 55.487 62.717 194.045 84.849 258.043 27.223 22.154-47.63 19.423-105.737 8.648-156.164"
    />
  </svg>
)
export default SvgComponent
