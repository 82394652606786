export const productivityHabits = [
  {
    name: "Set goals/priorities each day.",
    icon: "🎯",
    rrule: "RRULE:FREQ=DAILY;INTERVAL=1",
    step: 1,
    steps: 1,
    unit: "time(s)",
  },
  {
    name: "Review the week and plan ahead.",
    icon: "📈",
    rrule: "RRULE:FREQ=WEEKLY;INTERVAL=1;BYDAY=MO",
    step: 1,
    steps: 1,
    unit: "time(s)",
  },
  {
    name: "Declutter your home/office",
    icon: "🏠",
    rrule: "RRULE:FREQ=DAILY;INTERVAL=1",
    step: 1,
    steps: 1,
    unit: "time(s)",
  },
  {
    name: "Declutter emails",
    icon: "📧",
    rrule: "RRULE:FREQ=DAILY;INTERVAL=2",
    step: 1,
    steps: 1,
    unit: "time(s)",
  },
];

export const growthHabits = [
  {
    name: "Learn something new",
    icon: "💡",
    rrule: "RRULE:FREQ=DAILY;INTERVAL=1",
    step: 20,
    steps: 20,
    unit: "minute(s)",
  },
  {
    name: "Learn a language",
    icon: "🌍",
    rrule: "RRULE:FREQ=DAILY;INTERVAL=1",
    step: 30,
    steps: 30,
    unit: "minute(s)",
  },
  {
    name: "Learn an instrument",
    icon: "🎻",
    rrule: "RRULE:FREQ=DAILY;INTERVAL=3",
    step: 1,
    steps: 1,
    unit: "hour(s)",
  },
  {
    name: "Read a Book",
    icon: "📚",
    rrule: "RRULE:FREQ=DAILY;INTERVAL=1",
    step: 45,
    steps: 45,
    unit: "minute(s)",
  },
];

export const healthHabits = [
  {
    name: "Drink Water",
    icon: "💧",
    rrule: "RRULE:FREQ=DAILY;INTERVAL=1",
    step: 1,
    steps: 10,
    unit: "cup(s)",
  },
  {
    name: "Eat a healthy meal",
    icon: "🥗",
    rrule: "RRULE:FREQ=DAILY;INTERVAL=1",
    step: 1,
    steps: 3,
    unit: "time(s)",
  },
  {
    name: "Take vitamin",
    icon: "💊",
    rrule: "RRULE:FREQ=DAILY;INTERVAL=1",
    step: 1,
    steps: 1,
    unit: "count(s)",
  },
  {
    name: "Take a walk",
    icon: "👟",
    rrule: "RRULE:FREQ=DAILY;INTERVAL=1",
    step: 1,
    steps: 2,
    unit: "mile(s)",
  },
  {
    name: "Run",
    icon: "👟",
    rrule: "RRULE:FREQ=DAILY;INTERVAL=1",
    step: 1,
    steps: 1,
    unit: "mile(s)",
  },
  {
    name: "Workout",
    icon: "🔩",
    rrule: "RRULE:FREQ=DAILY;INTERVAL=2",
    step: 1,
    steps: 1,
    unit: "time(s)",
  },
  {
    name: "Regular Sleep",
    icon: "💤",
    rrule: "RRULE:FREQ=DAILY;INTERVAL=1",
    step: 7,
    steps: 7,
    unit: "hour(s)",
  },
];

export const homeHabits = [
  {
    name: "Cook",
    icon: "🍲",
    rrule: "RRULE:FREQ=DAILY;INTERVAL=1",
    step: 1,
    steps: 1,
    unit: "time(s)",
  },
  {
    name: "Buy groceries",
    icon: "🛒",
    rrule: "RRULE:FREQ=WEEKLY;INTERVAL=1;BYDAY=TU,SA",
    step: 1,
    steps: 1,
    unit: "time(s)",
  },
  {
    name: "Clean",
    icon: "🧹",
    rrule: "RRULE:FREQ=WEEKLY;INTERVAL=1;BYDAY=TH",
    step: 1,
    steps: 1,
    unit: "time(s)",
  },
  {
    name: "Laundry",
    icon: "🧺",
    rrule: "RRULE:FREQ=WEEKLY;INTERVAL=1;BYDAY=FR",
    step: 1,
    steps: 1,
    unit: "time(s)",
  },
];

export const financialHabits = [
  {
    name: "Save money",
    icon: "🏦",
    rrule: "RRULE:FREQ=MONTHLY;INTERVAL=1;BYMONTHDAY=1",
    step: 1,
    steps: 1,
    unit: "time(s)",
  },
  {
    name: "Pay rent",
    icon: "💰",
    rrule: "RRULE:FREQ=MONTHLY;INTERVAL=1;BYMONTHDAY=3",
    step: 1,
    steps: 1,
    unit: "time(s)",
  },
  {
    name: "Pay bills",
    icon: "💰",
    rrule: "RRULE:FREQ=MONTHLY;INTERVAL=1;BYMONTHDAY=4",
    step: 1,
    steps: 1,
    unit: "time(s)",
  },
  {
    name: "Invest",
    icon: "💸",
    rrule: "RRULE:FREQ=WEEKLY;INTERVAL=1;BYDAY=TH",
    step: 1,
    steps: 1,
    unit: "time(s)",
  },
];

export const socialHabits = [
  {
    name: "Call an old friend to catch up",
    icon: "📞",
    rrule: "RRULE:FREQ=WEEKLY;INTERVAL=1;BYDAY=TH,SA",
    step: 1,
    steps: 1,
    unit: "time(s)",
  },
  {
    name: "Check in with one friend a day",
    icon: "🫂",
    rrule: "RRULE:FREQ=DAILY;INTERVAL=1",
    step: 1,
    steps: 1,
    unit: "time(s)",
  },
  {
    name: "Expand your network",
    icon: "🌐",
    rrule: "RRULE:FREQ=DAILY;INTERVAL=2",
    step: 1,
    steps: 1,
    unit: "time(s)",
  },
];

export const selfcareHabits = [
  {
    name: "Journal",
    icon: "📒",
    rrule: "RRULE:FREQ=DAILY;INTERVAL=1",
    step: 1,
    steps: 1,
    unit: "time(s)",
  },
  {
    name: "Brush teeth",
    icon: "🪥",
    rrule: "RRULE:FREQ=DAILY;INTERVAL=1",
    step: 1,
    steps: 2,
    unit: "time(s)",
  },
  {
    name: "Floss teeth",
    icon: "🦷",
    rrule: "RRULE:FREQ=DAILY;INTERVAL=1",
    step: 1,
    steps: 1,
    unit: "time(s)",
  },
  {
    name: "Skincare",
    icon: "🧴",
    rrule: "RRULE:FREQ=DAILY;INTERVAL=1",
    step: 1,
    steps: 2,
    unit: "time(s)",
  },
  {
    name: "Bathe",
    icon: "🛁",
    rrule: "RRULE:FREQ=DAILY;INTERVAL=2",
    step: 1,
    steps: 1,
    unit: "time(s)",
  },
  {
    name: "Meditate ",
    icon: "🪷",
    rrule: "RRULE:FREQ=DAILY;INTERVAL=1",
    step: 10,
    steps: 10,
    unit: "minute(s)",
  },
  {
    name: "Yoga ",
    icon: "🧘🏽‍♀️",
    rrule: "RRULE:FREQ=DAILY;INTERVAL=3",
    step: 30,
    steps: 30,
    unit: "minute(s)",
  },
  {
    name: "Get fresh air",
    icon: "🌳",
    rrule: "RRULE:FREQ=DAILY;INTERVAL=1",
    step: 1,
    steps: 1,
    unit: "time(s)",
  },
];
