import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";

import { setDeadlineDate } from "../../../firebase/firebase.utils";

import Tooltip from "../../retro/tooltip/tooltip.component";

import "./deadline.styles.scss";

import { FaCalendarAlt } from "react-icons/fa";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Deadline = ({ task }) => {
  const spaceId = useSelector((state) => state.history.spaceId);
  const stationId = useSelector((state) => state.history.stationId);
  const [date, setDate] = useState();
  const [time, setTime] = useState();

  useMemo(() => {
    if (!task) return;
    if (task.enddate === null) return;
    let d = task.enddate.toDate();
    let day = d.getDate();
    let month = d.getMonth();
    let hour = d.getHours();
    let minute = d.getMinutes();
    setDate(`${day}.${month + 1}`);
    setTime(`End time: ${hour}:${minute}`);
  }, [task]);

  const handleDate = (e) => {
    console.log(e.target.value);
    let dd = new Date(e.target.value);
    setDeadlineDate(spaceId, stationId, dd, task.id);
  };

  return (
    <div className="deadline">
      {/* <input type="datetime-local" onChange={(e) => handleDate(e)} /> */}
      <Tooltip text={date ? time : "No end date"} />
      {!date && <FaCalendarAlt />}
        <p>{date}</p>
        {/* <p>{time}</p> */}
      {/* <DatePicker
        // popperProps={{ strategy: "fixed" }}
        wrapperClassName="datepicker"
        isClearable
        placeholderText="Select a start time"
        // value={edate}
        showTimeSelect
        // startDate="start"
        timeFormat="p"
        timeIntervals={15}
        timeCaption="time"
        dateFormat="MMM d, yy | hh:mm aa"
        // selected={task.enddate}
        // onChange={(date) => setStartDate(date)}
        // note: this onChange is not needed, because it saves to db when it is not active as well
        // minDate={moment().toDate()}
        todayButton="today"
      /> */}
    </div>
  );
};

export default Deadline;
