import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { db } from "../../firebase/firebase.utils";

import RetroButton from "../retro/button/retro-button.component";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { BsListCheck } from "react-icons/bs";

import EmojiPick from "../emoji/emoji.component";

import "./station-item-infolder.styles.scss";

function StationIteminFolder({ data }) {
  const activeSpaceId = useSelector((state) => state.history.spaceId);

  const [infolderproject, setInfolderproject] = useState(false);

  useEffect(() => {
    db.collection("space")
      .doc(activeSpaceId)
      .collection("stations")
      .doc(data)
      .onSnapshot((doc) => {
        if (!doc.empty) {
          ///check here later
          let currentone = doc.data();
          setInfolderproject(currentone);
        }
      });
  }, []);

  return (
    <div className="stationIteminFolder">
      <Link to={`/s/${activeSpaceId}/e/${data}/b`}>
        <RetroButton>
          {/* <BsListCheck size="1.2em" /> */}
          <div className="EmojiIcon">{infolderproject?.emojicon}</div>
          {/* {data} */}
          <a>{infolderproject?.name}</a>
        </RetroButton>
      </Link>
    </div>
  );
}

export default StationIteminFolder;
