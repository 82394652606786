import React from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import "./views.styles.scss";
import "../../";

import Modules from "../modules/modules.component";

import {
  BsClipboardCheck,
  BsListCheck,
  BsCalendar2Check,
  BsListNested,
  BsFiles,
  BsSticky,
} from "react-icons/bs";

import { TfiBlackboard } from "react-icons/tfi";

import { FaChess } from "react-icons/fa";

const Views = ({ view, currentStationId, currentSpaceId }) => {
  const spaceId = useSelector((state) => state.history.spaceId);
  const { wspaceid, projectid } = useParams();
  // const stationId = id;
  // const stationId = useSelector((state) => state.history.stationId);
  return (
    <div className="views-container">
      <div className="views">
        <Link to={`/s/${spaceId}/e/${projectid}/b`}>
          <div className={view === "b" ? "views__view-active" : "views__view"}>
            <BsClipboardCheck />
            <p>Board</p>
          </div>
        </Link>
        <Link to={`/s/${spaceId}/e/${projectid}/l`}>
          <div className={view === "l" ? "views__view-active" : "views__view"}>
            <BsListCheck />
            <p>List</p>
          </div>
        </Link>
        <Link to={`/s/${spaceId}/e/${projectid}/c`}>
          <div className={view === "c" ? "views__view-active" : "views__view"}>
            <BsCalendar2Check />
            <p>Calendar</p>
          </div>
        </Link>
        <Link to={`/s/${spaceId}/e/${projectid}/g`}>
          <div className={view === "g" ? "views__view-active" : "views__view"}>
            <BsListNested />
            <p>Gantt</p>
          </div>
        </Link>
        <Link to={`/s/${spaceId}/e/${projectid}/f`}>
          <div className={view === "f" ? "views__view-active" : "views__view"}>
            <BsFiles />
            <p>Files</p>
          </div>
        </Link>
        <Link to={`/s/${spaceId}/e/${projectid}/m`}>
          <div className={view === "m" ? "views__view-active" : "views__view"}>
            <TfiBlackboard />
            <p>Concept</p>
          </div>
        </Link>
        <Link to={`/s/${spaceId}/e/${projectid}/n`}>
          <div className={view === "n" ? "views__view-active" : "views__view"}>
            <BsSticky />
            <p>Notes</p>
          </div>
        </Link>
      </div>
      <div className="views__chess-and-modules">
        <Link to={`/s/${spaceId}/e/${projectid}/chesstime`}>
          <div
            className={
              view === "chesstime"
                ? "views__view-active chess_view-active no-mobile"
                : "views__view chess_view no-mobile"
            }
          >
            <FaChess />
            <p>Chess</p>
          </div>
        </Link>
            <Modules
              currentStationId={currentStationId}
              currentSpaceId={currentSpaceId}
              view={view}
            />
      </div>
    </div>
  );
};

export default Views;
