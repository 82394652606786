import React, { useState, useEffect } from "react";

import "./create-folder.styles.scss";

import { createNewFolder } from "../../firebase/firebase.utils";

import BoxLayer from "../retro/box-layer/box-layer.component";
import RetroButton from "../retro/button/retro-button.component";
import Loading from "../retro/loading/loading.component";
import FStep1 from "./step1/fstep1.component";
import FStep2 from "./step2/fstep2.component";
import FStep3 from "./step3/fstep3.component";

const CreateFolder = ({ setCreateFolder, activeSpaceId }) => {
  // const history = useNavigate();
  const [folderName, setFolderName] = useState("Enter Folder name");
  const [folderId, setFolderId] = useState("");
  const [force, setForce] = useState(0);
  const [loading, setLoading] = useState(false);
  const [steps, setSteps] = useState({
    step1: true,
    step2: false,
    step3: false,
  });
  const [folderContent, setFolderContent] = useState([""]);

  const [folderOrder, setFolderOrder] = useState([""]);

  useEffect(() => {
    let keys = Object.keys(folderContent);
    setFolderOrder(keys);
  }, [folderContent, setFolderContent, force, setForce]);

  const handleCreate = async () => {
    setLoading(true);
    const folderId = await createNewFolder(
      activeSpaceId,
      folderName,
      folderContent,
      folderOrder
    );
    setTimeout(() => {
      setLoading(false);
      setCreateFolder(false);
      if (!folderId) return;
      // history.push(`/s/${activeSpaceId}/e/${folderId}/b`);
    }, 300);
  };

  return (
    <div className="createStation">
      <BoxLayer setLayer={setCreateFolder}>
        {loading && <Loading />}
        <div className="cs__createPop">
          {steps.step1 && (
            <>
              <FStep1
                folderName={folderName}
                setFolderName={setFolderName}
                // setSteps={setSteps}
              />

              <div className="cs__create-btn">
                {/* <RetroButton
                  mode="gray"
                  onClick={() =>
                    setSteps({
                      step3: false,
                      step2: false,
                    })
                  }
                >
                  back
                </RetroButton> */}
                <RetroButton onClick={() => handleCreate()}>create</RetroButton>
              </div>
            </>
          )}
          {/* {steps.step2 && (
            <FStep2
              setSteps={setSteps}
              folderContent={folderContent}
              setFolderContent={setFolderContent}
            />
          )}
          {steps.step3 && (
            <>
              <FStep3 setSteps={setSteps} setForce={setForce} force={force} />
            </>
          )} */}
        </div>
      </BoxLayer>
    </div>
  );
};

export default CreateFolder;
