import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, Outlet } from "react-router-dom";
import {
  db,
  updateDrag,
  updateColumnDrag,
  updateUser,
} from "../../firebase/firebase.utils";

import { setStatusType } from "../../redux/space/space.actions";

import StatusTypeList from "../../components/dnd/list/status-type-list/status-type-list.component";
import BoardNewStatus from "../../components/board-new-status/board-new-status.component";

import TaskAddButton from "../../components/task-add-btn/task-add-btn.component";

import "./list.styles.scss";

import { FaTrashAlt } from "react-icons/fa";

const List = ({ station, stationTasksFL }) => {
  const users = useSelector((state) => state.user.users);
  const dispatch = useDispatch();
  // const history = useNavigate();
  const location = useLocation();
  const currentSpaceId = location.pathname.split("/")[2]; //.filter(crumb => crumb !== "")
  const currentStationId = location.pathname.split("/")[4];
  const [state, setState] = useState([]);
  const [bOpacity, setBOpacity] = useState(0);

  const [phaseOrder, setPhaseOrder] = useState([]);

  useEffect(() => {
    setPhaseOrder(station.phaseOrder);
    // dispatch(setStatusType(station.statusType));
    // console.log("Phase Order of the project is:", phaseOrder);
  }, [station]);

  const [phases, setPhases] = useState([]);
  useEffect(() => {
    //mounts
    const unsub = db
      .collection("space")
      .doc(currentSpaceId)
      .collection("stations")
      .doc(currentStationId)
      .collection("phases")
      .onSnapshot((querySnapshot) => {
        const array = querySnapshot.docs.map((doc) => {
          return {
            id: doc.id,
            name: doc.get("name"),
            fontColor: doc.get("fontColor"),
            open: doc.get("open"),
            taskIds: doc.get("taskIds"),
          };
        });
        // console.log("Current data in array: ", array);
        setPhases([...array]);
      });

    return () => {
      unsub();
    };
  }, []);

  // useEffect(() => {
  //   setState(station);
  //   dispatch(setStatusType(station.statusType));
  // }, [station]);

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    setBOpacity(0);

    if (!destination) return;

    if (result.type === "DEFAULT") {
      if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      ) {
        return;
      }
      const start = source.droppableId;
      const finish = destination.droppableId;
      // console.log(start);
      // console.log(finish);
      if (start === finish) {
        const index = phases.findIndex(
          (item) => item.id === source.droppableId
        );

        const phaseId = source.droppableId;

        const phase = phases[index];
        const copiedItems = [...phase?.taskIds];
        const [removed] = copiedItems.splice(source.index, 1);
        copiedItems.splice(destination.index, 0, removed);

        const dummyarray = ["CQXNo7inoboFX3ieJIE2", "4KjYt1AlPbE0mMqiAFeB"];
        updateDrag(currentSpaceId, currentStationId, phaseId, copiedItems);
        return;
      }

      // on drag delete
      if (destination.droppableId === "delete") {
        console.log("we are at", "if delete");
        const index = phases.findIndex(
          (item) => item.id === source.droppableId
        );

        const phaseId = source.droppableId;

        const phase = phases[index];
        const copiedItems = [...phase.taskIds];
        copiedItems.splice(source.index, 1);

        updateDrag(currentSpaceId, currentStationId, phaseId, copiedItems);

        db.collection("space")
          .doc(currentSpaceId)
          .collection("stations")
          .doc(currentStationId)
          .collection("tasks")
          .doc(draggableId)
          .delete();

        return;
      }

      // moving from one list to another

      console.log("we are at", "moving from one list to another");
      const sourceindex = phases.findIndex(
        (item) => item.id === source.droppableId
      );

      const sourcephaseId = source.droppableId;

      const sourcephase = phases[sourceindex];
      const sourcecopiedItems = [...sourcephase.taskIds];
      const [removed] = sourcecopiedItems.splice(source.index, 1);

      const destinationindex = phases.findIndex(
        (item) => item.id === destination.droppableId
      );

      const destinationphaseId = destination.droppableId;

      const destinationphase = phases[destinationindex];
      const destinationcopiedItems = [...destinationphase.taskIds];

      destinationcopiedItems.splice(destination.index, 0, removed);

      updateDrag(
        currentSpaceId,
        currentStationId,
        sourcephaseId,
        sourcecopiedItems
      );
      updateDrag(
        currentSpaceId,
        currentStationId,
        destinationphaseId,
        destinationcopiedItems
      );
      db.collection("space")
        .doc(currentSpaceId)
        .collection("stations")
        .doc(currentStationId)
        .collection("tasks")
        .doc(draggableId)
        .update({
          phase: destinationphaseId,
        });
      return;
    } else {
      const newStatusOrder = Array.from(phaseOrder);
      newStatusOrder.splice(source.index, 1);
      newStatusOrder.splice(destination.index, 0, draggableId);

      console.log("we are at", "Phases reorder");

      // const newState = {
      //   ...state,
      //   statusOrder: newStatusOrder,
      // };
      // setState(newState);
      setPhaseOrder(newStatusOrder);
      updateColumnDrag(currentSpaceId, currentStationId, newStatusOrder);
    }
  };

  const onDragStart = (result) => {
    if (result.type === "DEFAULT") {
      setBOpacity(1);
    }
  };

  return (
    <div className="listView">
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <div className="listContainer">
          <Droppable
            droppableId={"allStatusTypes"}
            type="column"
            direction="vertical"
          >
            {(provided) => {
              return (
                <div
                  className="list"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  <>
                    {phases
                      ?.sort(
                        (a, b) =>
                          phaseOrder?.indexOf(a.id) - phaseOrder?.indexOf(b.id)
                      )
                      .map((phase, index) => {
                        // const status = state.statusType[statusId];
                        // const tasks = status.taskIds.map(
                        //   (taskId) => state.tasks[taskId]
                        // );

                        return (
                          // <div>
                          //   {phase.name}
                          // </div>
                          <StatusTypeList
                            currentSpaceId={currentSpaceId}
                            currentStationId={currentStationId}
                            key={phase.id}
                            status={phase}
                            tasks={phase.taskIds}
                            index={index}
                            stationTasksFL={stationTasksFL}
                          />
                        );
                      })}
                    {provided.placeholder}
                  </>

                  <div className="statusType list__newStatus">
                    <BoardNewStatus />
                  </div>
                </div>
              );
            }}
          </Droppable>
          <div
            className="list__dragDelete"
            style={{
              opacity: bOpacity,
            }}
          >
            <Droppable droppableId="delete">
              {(provided, snapshot) => {
                const style = {
                  backgroundColor: snapshot.isDraggingOver
                    ? "rgba(0,0,0,0.05)"
                    : "",
                  borderRadius: "8px",
                  position: "absolute",
                  // bottom: "0px",
                  // height: "10vh",
                };
                return (
                  <>
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      style={style}
                    >
                      {/* <FaTrashAlt size="1em" /> */}
                      <section>
                        <span class="trash">
                          <span></span>
                          <i></i>
                        </span>
                      </section>
                    </div>
                    <div className="list__placeholder" style={style}>
                      {provided.placeholder}
                    </div>
                  </>
                );
              }}
            </Droppable>
          </div>
        </div>
      </DragDropContext>
      <Outlet />
      <TaskAddButton />
    </div>
  );
};

export default List;
