import * as React from "react"
const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={392} height={138} {...props}>
    <title>{"accessories/Glasses 2"}</title>
    <path
      fill="#000"
      fillRule="evenodd"
      d="M372.311 95.458c-12.14 12.545-32.409 16.145-48.562 9.417-41.476-17.33-21.175-71.38 10.812-85.894 3.996-.996 9.333-3.36 14.2-3.895.44-.216 1.398.409 2.56-.182 35.27 3.938 43.302 58.69 20.99 80.554m-133.778 3.21c-13.617 18.062-38.933 23.634-59.617 15.238-40.862-16.092-26.369-64.021 1.588-82.811 59.057-22.191 83.444 34.273 58.03 67.574m-88.58-40.018c-.039-.018-.077-.027-.116-.045.216-.095.432-.19.647-.287-.07.183-.146.364-.214.547-.106-.073-.199-.158-.318-.215m250.47-19.523c-3.334.007-8.15.555-10.91 2.868-6.806-22.953-31.928-44.196-53.776-28.706-17.205 5.533-29.972 17.804-37.228 34.118-13.083-7.782-33.655-4.46-45 5.832-7.43-24.551-25.716-33.148-47.48-34.417-23.757-3.891-47.435 19.18-54.823 37.688-6.143-.7-15.322-2.654-21.1 1.137-37.508-9.836-103.083-15.26-110.708-9.794-5.434 2.085-8.416 14.91-.826 15.603 6.614-.94-.772-9.398 5.895-10.321 35.523-2.202 70.008 4.952 105.155 7.954 3.138 4.22 13.277 5.633 18.686 3.937-8.519 31.396 9.871 52.756 37.426 59.154 42.667 9.8 79.788-28.367 68.631-68.067 12.925-6.43 27.792-6.154 41.872-2.767-23.528 86.59 115.93 82.705 94.148-8.384 14.495 7.21 17.643-4.38 10.038-5.835"
    />
  </svg>
)
export default SvgComponent
