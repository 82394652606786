import { MdDelete, MdFileCopy } from "react-icons/md";
import {
  BsTrash3Fill,
  BsFillCheckCircleFill,
  BsFillClipboardFill,
} from "react-icons/bs";
import { IoIosCopy, IoIosCheckmarkCircleOutline } from "react-icons/io";
import { TbArrowsExchange2 } from "react-icons/tb";
export const contextMenuItems = [
  {
    label: "Multiselect",
    icon: <BsFillCheckCircleFill />,
  },
  {
    label: "Move (soon...)",
    icon: <TbArrowsExchange2 />,
  },
  //   {
  //     label: "Copy text",
  //     icon: <BsFillClipboardFill />,
  //   },
  {
    label: "Delete",
    icon: <BsTrash3Fill />,
  },
];
