import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useLocation, useNavigate, Outlet } from "react-router-dom";

import { db, fieldValue } from "../../firebase/firebase.utils";

import { Excalidraw } from "@excalidraw/excalidraw";

import { motion } from "framer-motion";

import InvitetoPremium from "../../components/marketing/invite-to-premium.component";
import TaskAddButton from "../../components/task-add-btn/task-add-btn.component";

import BoxLayer from "../../components/retro/box-layer/box-layer.component";
import { MdAddToDrive } from "react-icons/md";
import { BiSolidLayerPlus } from "react-icons/bi";

import {
  BsCheckLg,
  BsXCircle,
  BsPencilSquare,
  BsTrash,
  BsFolderFill,
  BsPlusLg,
} from "react-icons/bs";
import { IoSyncCircleOutline, IoSyncCircleSharp } from "react-icons/io5";
import { TfiBlackboard } from "react-icons/tfi";
import { FaGoogleDrive } from "react-icons/fa";

import FilesVisual from "../../assets/illustration/undraw_folder_re_apfp.svg";

import "./concept.styles.scss";

import moment from "moment";

const variants = {
  open: {
    opacity: 0,
    x: "-100%",
    width: "594px",
    height: "calc(100% - 90px)",
    borderRadius: "6px",
    right: "0px",
    translateX: "36%",
    translateY: "-2%",
  },
  closed: { opacity: 1, x: 0 },
};

const ConceptView = ({ station }) => {
  const users = useSelector((state) => state.user.users);
  const theme = useSelector((state) => state.theme.theme);
  const dispatch = useDispatch();
  // const history = useNavigate();
  const location = useLocation();
  const currentSpaceId = location.pathname.split("/")[2];
  const currentStationId = location.pathname.split("/")[4];
  const currentUser = useSelector((state) => state.user.currentUser);
  const [state, setState] = useState([]);
  const [deleteOpacity, setDeleteOpacity] = useState(0);

  const [gDriveIds, setGDriveIds] = useState([]);
  const [tabs, setTabs] = useState([]);

  const [gDriveName, setGDriveName] = useState("");
  const [gDriveId, setGDriveId] = useState("");

  const [newTabName, setNewTabName] = useState("");
  const [newTabType, setNewTabType] = useState("whiteboard");
  const [newTabLink, setNewTabLink] = useState("");

  const [activeTab, setActiveTab] = useState({
    id: "whiteboard",
    title: "Whiteboard",
  });
  const [liveMode, setLiveMode] = useState(false);
  const [zenMode, setZenMode] = useState(false);

  const [viewMode, setViewMode] = useState("#grid");

  const [showConceptModal, setShowConceptModal] = useState(false);

  // const [excalElements, setExcalElements] = useState([]);
  // const [excalState, setExcalState] = useState({});
  const [isCollaborating, setIsCollaborating] = useState(false);
  const [thereIsWhiteboard, setThereIsWhiteboard] = useState(false);
  const [addInputMode, setAddInputMode] = useState(false);

  const [showInvitation, setShowInvitation] = useState(false);
  const [excaliTheme, setExcaliTheme] = useState("light");

  const excalidrawRef = useRef(null);

  // function convertedThemeName() {
  //   if (selectedTheme === "dark-theme") {
  //     return "dark";
  //   } else {
  //     return "light";
  //   }
  // }

  // const selectedTheme = localStorage.getItem("selectedTheme");
  // const excaliTheme = theme === "dark-theme" ? "dark" : "light";

  useEffect(() => {
    if (theme === "dark-theme") {
      setExcaliTheme("dark");
    } else {
      setExcaliTheme("light");
    }
  }, [theme]);

  const convertedThemeName = () => {
    // const selectedTheme = localStorage.getItem("selectedTheme");
    // const selectedTheme = document.body.getAttribute("data-theme");
    // if (selectedTheme === "dark-theme") {
    //   return "dark";
    // }
    return "dark";
  };

  const updateScene = () => {
    const sceneData = {
      elements: [
        {
          type: "rectangle",
          version: 141,
          versionNonce: 361174001,
          isDeleted: false,
          id: "oDVXy8D6rom3H1-LLH2-f",
          fillStyle: "hachure",
          strokeWidth: 1,
          strokeStyle: "solid",
          roughness: 1,
          opacity: 100,
          angle: 0,
          x: 100.50390625,
          y: 93.67578125,
          strokeColor: "#c92a2a",
          backgroundColor: "transparent",
          width: 186.47265625,
          height: 141.9765625,
          seed: 1968410350,
          groupIds: [],
        },
      ],
      appState: {
        viewBackgroundColor: "#edf2ff",
      },
    };
    excalidrawRef.current.updateScene(sceneData);
  };

  useEffect(() => {
    //mounts
    const unsub = db
      .collection("space")
      .doc(currentSpaceId)
      .collection("stations")
      .doc(currentStationId)
      .collection("concepts")
      .orderBy("createdAt")
      .onSnapshot((querySnapshot) => {
        // let arrayofType = querySnapshot.map(a => a.type);
        // const index = querySnapshot.findIndex(
        //   (item) => item.type === "whiteboard"
        // );
        // if (index > -1) {
        //   setThereIsWhiteboard(true);
        // } else {
        //   setThereIsWhiteboard(false);
        // }
        let list = [];
        querySnapshot.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
          if (doc.data().type === "whiteboard") {
            setThereIsWhiteboard(true);
          }
        });
        if (list.length > 0) {
          setActiveTab(list[0]);
        }

        // let whiboardexists = list.indexOf((c) => (c.type = "whiteboard"));
        // if (list.indexOf((c) => (c.type = "whiteboard"))) {
        //   setThereIsWhiteboard(true);
        // } else {
        //   setThereIsWhiteboard(false);
        // }
        setTabs(list);
        // const sceneData = {
        //   elements: list[0].data().elements,
        //   appState: excalidrawRef.current.getAppState(),
        // };
        // excalidrawRef.current.updateScene(sceneData);
      });
    return () => {
      unsub();
    };
  }, [currentSpaceId, currentStationId]);

  useEffect(() => {
    //mounts
    if (liveMode) {
      db.collection("space")
        .doc(currentSpaceId)
        .collection("stations")
        .doc(currentStationId)
        .onSnapshot((doc) => {
          let refTabId = doc.get("activeConceptTab");
          let refTab = tabs.filter((tab) => tab.id === refTabId);
          setActiveTab(refTab[0]);
        });
      //find live mode tab from the server
      //set the activetab
    }
  }, [liveMode, activeTab]);

  // useEffect(() => {
  //   if (liveMode & (activeTab.type === "whiteboard")) {
  //     //mounts
  //     const unsub = db
  //       .collection("space")
  //       .doc(currentSpaceId)
  //       .collection("stations")
  //       .doc(currentStationId)
  //       .collection("concepts")
  //       .doc(activeTab.id)
  //       .onSnapshot((doc) => {
  //         const sceneDbData = {
  //           elements: JSON.parse(doc.get("elements")),
  //           appState: {
  //             viewBackgroundColor: "#FFFFFF",
  //           },
  //         };
  //         excalidrawRef.current.updateScene(sceneDbData);
  //       });
  //     return () => {
  //       unsub();
  //     };
  //   }
  // }, [liveMode, activeTab]);

  // useEffect(() => {
  //   //mounts
  //   if (liveMode) {
  //     if (activeTab.type === "whiteboard") {
  //       const unsub = db
  //         .collection("space")
  //         .doc(currentSpaceId)
  //         .collection("stations")
  //         .doc(currentStationId)
  //         .collection("concepts")
  //         .doc(activeTab.id)
  //         .onSnapshot((doc) => {
  //           let refElementsString = doc.get("elements");
  //           let refElementsArray = JSON.parse(refElementsString);
  //           const sceneData = {
  //             elements: refElementsArray,
  //             appState: {
  //               viewBackgroundColor: "#edf2ff",
  //             },
  //           };
  //           excalidrawRef.current.updateScene(sceneData);
  //         });
  //       return () => {
  //         unsub();
  //       };
  //     }
  //     //find live mode tab from the server
  //     //set the activetab
  //   }
  // }, [liveMode, activeTab]);

  const handleTabRemove = (id) => {
    db.collection("space")
      .doc(currentSpaceId)
      .collection("stations")
      .doc(currentStationId)
      .collection("concepts")
      .doc(id)
      .delete();
  };

  const onChange = (elements, state) => {
    if (liveMode) {
      db.collection("space")
        .doc(currentSpaceId)
        .collection("stations")
        .doc(currentStationId)
        .collection("concepts")
        .doc(activeTab.id)
        .update({
          elements: JSON.stringify(elements),
        });
    }
    // const sceneData = {
    //   elements: elements,
    //   appState: state,
    // };
    // console.log("Elements :", elements, "State : ", state);
    // console.log("Elements :", elements, "State : ", state);
    // setExcalElements(elements);
    // setExcalState(state);
  };

  const onSaveWhiteBoard = () => {
    // const sceneData = {
    //   elements: excalidrawRef.current.getSceneElements(),
    //   // appState: excalidrawRef.current.getAppState(),
    // };
    const refelements = JSON.stringify(
      excalidrawRef.current.getSceneElements()
    );

    if (thereIsWhiteboard) {
      db.collection("space")
        .doc(currentSpaceId)
        .collection("stations")
        .doc(currentStationId)
        .collection("concepts")
        .doc(activeTab.id)
        .update({
          elements: refelements,
        });
    } else {
      db.collection("space")
        .doc(currentSpaceId)
        .collection("stations")
        .doc(currentStationId)
        .collection("concepts")
        .add({
          title: "Whiteboard",
          type: "whiteboard",
          elements: refelements,
          createdAt: fieldValue.serverTimestamp(),
        });
    }
  };

  const handleSubmitTab = (e) => {
    e.preventDefault();
    if (newTabType === "whiteboard") {
      db.collection("space")
        .doc(currentSpaceId)
        .collection("stations")
        .doc(currentStationId)
        .collection("concepts")
        .add({
          title: newTabName,
          type: newTabType,
          elements: JSON.stringify([]),
          createdAt: fieldValue.serverTimestamp(),
        });
    }
    if (newTabType === "figma") {
      db.collection("space")
        .doc(currentSpaceId)
        .collection("stations")
        .doc(currentStationId)
        .collection("concepts")
        .add({
          title: newTabName,
          type: newTabType,
          link: newTabLink,
          createdAt: fieldValue.serverTimestamp(),
        });
    }
    if (newTabType === "canva") {
      db.collection("space")
        .doc(currentSpaceId)
        .collection("stations")
        .doc(currentStationId)
        .collection("concepts")
        .add({
          title: newTabName,
          type: newTabType,
          link: newTabLink,
          createdAt: fieldValue.serverTimestamp(),
        });
    }
    if (newTabType === "embed") {
      db.collection("space")
        .doc(currentSpaceId)
        .collection("stations")
        .doc(currentStationId)
        .collection("concepts")
        .add({
          title: newTabName,
          type: newTabType,
          link: newTabLink,
          createdAt: fieldValue.serverTimestamp(),
        });
    }
  };

  const handleActiveTabChange = (tab) => {
    if (liveMode) {
      setActiveTab(tab);
      console.log("TAB ID IS", tab.id);
      db.collection("space")
        .doc(currentSpaceId)
        .collection("stations")
        .doc(currentStationId)
        .update({ activeConceptTab: tab.id });
    } else {
      setActiveTab(tab);
    }
  };

  const handleSwitchtoLiveMode = () => {
    if (currentUser.plan === "Free") {
    }
    if (liveMode) {
      setLiveMode(false);
      setZenMode(false);
      db.collection("space")
        .doc(currentSpaceId)
        .collection("stations")
        .doc(currentStationId)
        .update({ liveMode: false });
    } else {
      setLiveMode(true);
      setZenMode(true);
      db.collection("space")
        .doc(currentSpaceId)
        .collection("stations")
        .doc(currentStationId)
        .update({ liveMode: true, activeConceptTab: activeTab.id });
    }
  };

  function onChangeType(event) {
    if (event.target.value === "Whiteboard") {
      setNewTabType("whiteboard");
    }
    if (event.target.value === "Figma") {
      setNewTabType("figma");
    }
    if (event.target.value === "Canva") {
      setNewTabType("canva");
    }
    if (event.target.value === "Embed") {
      setNewTabType("embed");
    }
  }

  // todo: write a function to sort tabs by name instead of date: .sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))

  return (
    <div className="conceptView">
      <div className="conceptContainer">
        {activeTab.id === "whiteboard" && (
            <Excalidraw
              ref={excalidrawRef}
              theme={excaliTheme}
              onChange={onChange}
              isCollaborating={isCollaborating}
              name="Custom name of drawing"
              zenModeEnabled={zenMode}
              renderTopRightUI={() => {
                return (
                  <button
                    style={{
                      background: "white",
                      border: "1px solid #cccccc",
                      borderRadius: "0.375rem",
                      color: "#3d3d3d",
                      padding: "0px 10px",
                      width: "max-content",
                      fontWeight: "bold",
                    }}
                    onClick={() => onSaveWhiteBoard()}
                  >
                    Save
                  </button>
                );
              }}
            />
        )}
        {tabs?.map((tab, index) => (
          <div
            className={
              activeTab.id === tab.id
                ? "concept_container-active"
                : "concept_container"
            }
          >
            {tab.type === "figma" && (
              <iframe
                src={`https://www.figma.com/embed?embed_host=antlogy&url=${tab.link}`}
                width="100%"
                height="100%"
                style={{ zIndex: activeTab.id === tab.id ? "5" : "0" }}
              />
            )}
            {tab.type === "canva" && (
              <iframe
                src={`${tab.link}?embed`}
                width="100%"
                height="100%"
                style={{
                  zIndex: activeTab.id === tab.id ? "5" : "0",
                  maxWidth: "716px",
                  maxHeight: "600px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  alignSelf: "center",
                  margin: "40px",
                }}
              />
            )}
            {tab.type === "embed" && (
              <iframe
                src={`${tab.link}`}
                width="100%"
                height="100%"
                style={{ zIndex: activeTab.id === tab.id ? "5" : "0" }}
              />
            )}
            {tab.type === "whiteboard" && (
              <div
                className={
                  activeTab.id === tab.id
                    ? "concept_container-active"
                    : "concept_container"
                }
              >
                <Excalidraw
                  ref={excalidrawRef}
                  theme={excaliTheme}
                  onChange={onChange}
                  isCollaborating={isCollaborating}
                  initialData={{
                    elements: JSON.parse(tab.elements),
                    appState: {
                      viewBackgroundColor: "#ffffff",
                    },
                    scrollToContent: true,
                  }}
                  name="Custom name of drawing"
                  zenModeEnabled={zenMode}
                  tab={tab}
                  renderTopRightUI={() => {
                    return (
                      <button
                        style={{
                          background: "white",
                          border: "1px solid #cccccc",
                          borderRadius: "0.375rem",
                          color: "#3d3d3d",
                          padding: "0px 10px",
                          width: "max-content",
                          fontWeight: "bold",
                        }}
                        onClick={() => onSaveWhiteBoard()}
                      >
                        Save
                      </button>
                    );
                  }}
                />
              </div>
            )}
          </div>
        ))}
        <div className="concept__header-tabs">
          {!thereIsWhiteboard && (
            <button className="concept_tab-header">
              <TfiBlackboard />
              Whiteboard
            </button>
          )}
          {tabs?.map((tab, index) => (
            <ul
              className={
                tab.id === activeTab.id
                  ? "concept_tab-header"
                  : "concept_tab-header-nonactive"
              }
              key={tab.id}
            >
              <div
                className="files__list-title"
                onClick={() => handleActiveTabChange(tab)}
              >
                {tab.type === "whiteboard" && <TfiBlackboard />}
                <h5>{tab.title}</h5>
              </div>
              <div className="files__list-btngroup">
                {/* <button>
                    <BsPencilSquare />
                  </button> */}
                <button onClick={() => handleTabRemove(tab.id)}>
                  <BsTrash />
                </button>
              </div>
            </ul>
          ))}
          {/* <button className="addconcept_tab">
            <div
              style={{
                transform: addInputMode ? "rotate(45deg)" : "rotate(0deg)",
                transition: "0.2s all ease-in",
              }}
            >
              <BsPlusLg
                size="1.2em"
                onClick={() => setAddInputMode(!addInputMode)}
              />
            </div>
            {addInputMode && (
              <form
                onSubmit={(e) => handleSubmitTab(e) & setAddInputMode(false)}
                id="newConceptTabForm"
              >
                <input
                  onChange={(e) => setNewTabName(e.target.value)}
                  type="text"
                  placeholder="Title"
                  // value={newTabName}
                  autoFocus
                ></input>
                <div className="conceptTab__Inputs" onChange={onChangeType}>
                  <div className="conceptTab__Input-radio">
                    <input
                      type="radio"
                      value="Whiteboard"
                      name="type"
                      checked={newTabType === "whiteboard"}
                    />
                    <label>Whiteboard</label>
                  </div>
                  <div className="conceptTab__Input-radio">
                    <input
                      type="radio"
                      value="Figma"
                      name="type"
                      checked={newTabType === "figma"}
                    />
                    <label>Figma</label>
                  </div>
                  <div className="conceptTab__Input-radio">
                    <input
                      type="radio"
                      value="Canva"
                      name="type"
                      checked={newTabType === "canva"}
                    />
                    <label>Canva</label>
                  </div>
                  <div className="conceptTab__Input-radio">
                    <input
                      type="radio"
                      value="Embed"
                      name="type"
                      checked={newTabType === "embed"}
                    />
                    <label>Presentation</label>
                  </div>
                </div>
                {newTabType !== "whiteboard" && (
                  <input
                    onChange={(e) => setNewTabLink(e.target.value)}
                    type="text"
                    placeholder="Link"
                    // value={newTabName}
                    style={{ height: "20px" }}
                    autoFocus
                  ></input>
                )}
                <button>
                  <BsCheckLg size="1.5em" />
                </button>
              </form>
            )}
          </button> */}
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {currentUser.plan === "Free" ? (
              <button
                className="concept_tab-header-nonactive"
                onClick={() => setShowInvitation(!showInvitation)}
              >
                {liveMode ? (
                  <IoSyncCircleSharp color="#1a82c4" />
                ) : (
                  <IoSyncCircleOutline />
                )}
                Live Mode
              </button>
            ) : (
              <button
                className="concept_tab-header-nonactive"
                onClick={() => handleSwitchtoLiveMode()}
              >
                {liveMode ? (
                  <IoSyncCircleSharp color="#1a82c4" />
                ) : (
                  <IoSyncCircleOutline />
                )}
                Live Mode
              </button>
            )}
          </div>
          {showInvitation && (
            <InvitetoPremium
              setShowInvitation={setShowInvitation}
              featureName={"Live Presentation Mode"}
              featureDesc={
                "combine various tabs like whiteboards, figma, canva, embed websites and present them to your clients or to your team in a synced way"
              }
              featureImg={
                "https://images.pexels.com/photos/7441094/pexels-photo-7441094.jpeg"
              }
              currentUser={currentUser}
            />
          )}
          {/* <button className="widgetheader" onClick={updateScene}>
          Update Scene
        </button>
        <button className="widgetheader" onClick={onSaveWhiteBoard}>
          Save Scene
        </button> */}
        </div>
      </div>
      <motion.button
        className="addnote__btn"
        animate={showConceptModal ? "open" : "closed"}
        variants={variants}
        transition={{ type: "ease-in-out", duration: 0.4 }}
        onClick={() => setShowConceptModal(!showConceptModal)}
      >
        <BiSolidLayerPlus size="1.8em" />
      </motion.button>
      {showConceptModal && (
        <BoxLayer setLayer={() => setShowConceptModal(!showConceptModal)}>
          <form
                onSubmit={(e) => handleSubmitTab(e) & setAddInputMode(false) & setShowConceptModal(false)}
                id="newConceptTabForm"
                className="newConceptModal"
              >
                <div className="conceptTab__InputRow">
                <input
                  onChange={(e) => setNewTabName(e.target.value)}
                  type="text"
                  placeholder="Title"
                  // value={newTabName}
                  autoFocus
                ></input>
                                {newTabType !== "whiteboard" && (
                  <input
                    onChange={(e) => setNewTabLink(e.target.value)}
                    type="text"
                    placeholder="Link"
                    // value={newTabName}
                    autoFocus
                  ></input>
                )}
                </div>
                <div className="conceptTab__Inputs" onChange={onChangeType}>
                  <div className="conceptTab__Input-radio">
                    <input
                      type="radio"
                      value="Whiteboard"
                      name="type"
                      checked={newTabType === "whiteboard"}
                    />
                    <label>Whiteboard</label>
                  </div>
                  <div className="conceptTab__Input-radio">
                    <input
                      type="radio"
                      value="Figma"
                      name="type"
                      checked={newTabType === "figma"}
                    />
                    <label>Figma</label>
                  </div>
                  <div className="conceptTab__Input-radio">
                    <input
                      type="radio"
                      value="Canva"
                      name="type"
                      checked={newTabType === "canva"}
                    />
                    <label>Canva</label>
                  </div>
                  <div className="conceptTab__Input-radio">
                    <input
                      type="radio"
                      value="Embed"
                      name="type"
                      checked={newTabType === "embed"}
                    />
                    <label>Presentation</label>
                  </div>
                </div>
                <button className="files__savebtn">
                  <BsCheckLg size="1.5em" />
                  Add
                </button>
              </form>
        </BoxLayer>
      )}
      <Outlet />
      <TaskAddButton />
    </div>
  );
};

export default ConceptView;
