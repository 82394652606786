import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import styled from "styled-components";

import SignIn from "../../sign-in/sign-in.component.class";
import SignUp from "../../sign-up/sign-up.component.class";
import RetroButton from "../../../components/retro/button/retro-button.component";
import BoxLayer from "../../../components/retro/box-layer/box-layer.component";

import { Link as ScrollLink } from "react-scroll";
import { HashLink, NavHashLink } from "react-router-hash-link";

const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;

  // column-gap: 20px;

  li {
    padding: 32px 20px;
    // :last-child {
    //   padding: 32px 0px;
    // }
  }

  @media (max-width: 768px) {
    flex-flow: column nowrap;
    background-color: white;
    position: fixed;
    // transform: ${({ open }) =>
      open ? "translateX(0)" : "translateX(100%)"};
    opacity: ${({ open }) => (open ? "1" : "0")};
    transform: ${({ open }) => (open ? "translateY(0)" : "translateY(-100%)")};
    margin-top: 90px;
    z-index: -1;
    top: 0;
    right: 0;
    // height: 100%;
    width: 185px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    transition: all 0.3s ease-in-out;
    box-shadow: rgb(0 0 0 / 20%) 0px 9px 30px -10px;
    li {
      color: #fff;
      //   padding: 30px, 20px;
    }
  }
`;

const RightNav = ({ open }) => {
  const currentUser = useSelector((state) => state.user.currentUser);
  const [showLogin, setShowLogin] = useState(false);
  const [showRegister, setShowRegister] = useState(false);

  let location = useLocation();

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  return (
    <Ul open={open}>
      {/* <li className="nav-item">
        <Link to="product" spy={true} smooth={true} offset={-80} duration={500}>
          Product
        </Link>
      </li> */}
      <li className="nav-item">
        <HashLink
          to="https://www.antlogy.com/#features"
          smooth
          scroll={scrollWithOffset}
          className={
            `${location.pathname}${location.hash}` === "/#features"
              ? "active"
              : ""
          }
        >
          Features
        </HashLink>
      </li>
      <li className="nav-item">
        <HashLink
          to="https://www.antlogy.com/#collaboration"
          smooth
          scroll={scrollWithOffset}
          className={
            `${location.pathname}${location.hash}` === "/#collaboration"
              ? "active"
              : ""
          }
        >
          for Teams
        </HashLink>
      </li>
      <li className="nav-item">
        <HashLink
          to="https://www.antlogy.com/#pricing"
          smooth
          scroll={scrollWithOffset}
          className={
            `${location.pathname}${location.hash}` === "/#pricing"
              ? "active"
              : ""
          }
        >
          Pricing
        </HashLink>
      </li>
      <li className="nav-item">
        <HashLink
          to="https://www.antlogy.com/#consultancy"
          smooth
          scroll={scrollWithOffset}
          className={
            `${location.pathname}${location.hash}` === "/#consultancy"
              ? "active"
              : ""
          }
        >
          Consult
        </HashLink>
      </li>
      {/* <li className="nav-item">
        <Link
          to="signinup"
          spy={true}
          smooth={true}
          offset={-80}
          duration={500}
        >
          Sign In | Sign Up
        </Link>
      </li> */}
      {/* {currentUser ? (
        <div className="nav-item-btns">
          <Link to="/app">
            <RetroButton
              style={{ background: "#1d1d1d", color: "white" }}
              // onClick={() => setShowRegister(!showRegister)}
            >
              Go to App
            </RetroButton>
          </Link>
        </div>
      ) : (
        <div className="nav-item-btns">
          <RetroButton onClick={() => setShowLogin(!showLogin)}>
            Sign In
          </RetroButton>
          <RetroButton
            style={{ background: "#1d1d1d", color: "white" }}
            onClick={() => setShowRegister(!showRegister)}
          >
            Get Started
          </RetroButton>
        </div>
      )}
      {showLogin && (
        <BoxLayer setLayer={setShowLogin}>
          <SignIn />
        </BoxLayer>
      )}
      {showRegister && (
        <BoxLayer setLayer={setShowRegister}>
          <SignUp />
        </BoxLayer>
      )} */}
    </Ul>
  );
};

export default RightNav;
