import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { db, fieldValue } from "../../firebase/firebase.utils";

import BoxLayer from "../retro/box-layer/box-layer.component";
import RetroButton from "../retro/button/retro-button.component";
import { Tooltip } from "react-tooltip";

import {
  BsGoogle,
  BsApple,
  BsCheckLg,
  BsXCircle,
  BsQuestionCircleFill,
} from "react-icons/bs";

// import "./mini-calendar.styles.scss";

export default function IntegrateCal({
  calType,
  setShowGCalModal,
  setShowICalModal,
  handleCloseSettings,
  personalGcals,
  projectGcals,
  personalIcals,
  projectIcals,
}) {
  const location = useLocation();
  const currentSpaceId = location.pathname.split("/")[2];
  const currentStationId = location.pathname.split("/")[4];
  const currentUser = useSelector((state) => state.user.currentUser);

  const [iCalUrl, setICalUrl] = useState("");
  const [gCalId, setGCalId] = useState("");
  const [gCalName, setGCalName] = useState("");
  const [gCalColor, setGCalColor] = useState("#f6b73c");
  const [gCalType, setGcalType] = useState("project");

  const handleGCalSave = () => {
    const gcalObject = { name: gCalName, googleCalendarId: gCalId, color: gCalColor };
    if (gCalType === "personal" || "both") {
      db.collection("users")
        .doc(currentUser.uid)
        .update({
          googleCals: fieldValue.arrayUnion(gcalObject),
        });
    }
    if (gCalType === "project" || "both") {
      db.collection("space")
        .doc(currentSpaceId)
        .collection("stations")
        .doc(currentStationId)
        .update({
          googleCals: fieldValue.arrayUnion(gcalObject),
        });
    }
    handleCloseSettings();
    // setShowGCal(true);
  };
  const handleGCalRemove = (gCal, type) => {
    if (type === "personal" || "both") {
      const gcalObject = personalGcals.find((g) => g.googleCalendarId === gCal.googleCalendarId);
      db.collection("users")
        .doc(currentUser.uid)
        .update({
          googleCals: fieldValue.arrayRemove(gcalObject),
        });
    }
    if (type === "project" || "both") {
      const gcalObject = projectGcals.find((g) => g.googleCalendarId === gCal.googleCalendarId);
      db.collection("space")
        .doc(currentSpaceId)
        .collection("stations")
        .doc(currentStationId)
        .update({
          googleCals: fieldValue.arrayRemove(gcalObject),
        });
    }
  };
  const handleICalSave = () => {
    const icalObject = { name: gCalName, id: iCalUrl, color: gCalColor };
    if (gCalType === "personal" || "both") {
      db.collection("users")
        .doc(currentUser.uid)
        .update({
          iCals: fieldValue.arrayUnion(icalObject),
        });
    }
    if (gCalType === "project" || "both") {
      db.collection("space")
        .doc(currentSpaceId)
        .collection("stations")
        .doc(currentStationId)
        .update({
          iCals: fieldValue.arrayUnion(icalObject),
        });
    }
    handleCloseSettings();
    // setShowGCal(true);
  };
  const handleICalRemove = (iCal, type) => {
    if (type === "personal" || "both") {
      const icalObject = personalIcals.find((c) => c.id === iCal.id);
      db.collection("users")
        .doc(currentUser.uid)
        .update({
          iCals: fieldValue.arrayRemove(icalObject),
        });
    }
    if (type === "project" || "both") {
      const icalObject = projectIcals.find((c) => c.id === iCal.id);
      db.collection("space")
        .doc(currentSpaceId)
        .collection("stations")
        .doc(currentStationId)
        .update({
          iCals: fieldValue.arrayRemove(icalObject),
        });
    }
  };

  return (
    <>
      {calType === "google" && (
        <BoxLayer setLayer={setShowGCalModal}>
          <div className="cal__gCal-header">
            <h3>
              <BsGoogle /> Google Calendar
            </h3>
            <div>
              <BsQuestionCircleFill data-tooltip-id="googleCal-how" />
            </div>
            <Tooltip id="googleCal-how">
              <div className="cal__gCal-tip">
                How to integrate a google calendar
                <ul style={{ marginTop: "10px" }}>
                  <li>
                    Find your calendar in google calendar, Click on the ⋮
                    button,
                  </li>
                  <li>Go in "Settings and Sharing", </li>
                  <li>Check " Make available to public", </li>
                  <li>
                    Scroll down and find the "Calendar ID" ( below Integrate
                    calendar ),{" "}
                  </li>
                  <li>Select, Copy, and Paste below.</li>
                </ul>
              </div>
            </Tooltip>
          </div>
          <div className="cal__gCal-form">
            <div className="cal__gCal-line">
              <input
                type="color"
                // id="gCalColor"
                name="body"
                value={gCalColor}
                onChange={(e) => setGCalColor(e.target.value)}
              />
              {/* <label for="gCalColor">Color</label> */}
              <input
                onChange={(e) => setGCalName(e.target.value)}
                type="text"
                placeholder="Calendar Name"
                value={gCalName}
              />
            </div>
            <div className="cal__gCal-line">
              <input
                onChange={(e) => setGCalId(e.target.value)}
                type="text"
                placeholder="Google Calendar ID"
                value={gCalId}
              />
              {gCalId !== "" && (
                <div>
                  <button
                    className="cal__gCal-clearBtn"
                    onClick={() => setGCalId("") & handleGCalRemove("")}
                  >
                    <BsXCircle size="1.4em" />
                  </button>
                </div>
              )}
            </div>
            <div className="cal__gCal-line">
              <div className="cal__gCal-radiobtn">
                <input
                  name="gcaltype"
                  id="personal"
                  value="personal"
                  type="radio"
                  checked={gCalType === "personal"}
                  onChange={(e) => setGcalType(e.target.value)}
                />
                <label for="personal">Personal</label>
              </div>
              <div className="cal__gCal-radiobtn">
                <input
                  name="gcaltype"
                  id="project"
                  value="project"
                  type="radio"
                  checked={gCalType === "project"}
                  onChange={(e) => setGcalType(e.target.value)}
                />
                <label for="project">Project</label>
              </div>
              <div className="cal__gCal-radiobtn">
                <input
                  name="gcaltype"
                  id="both"
                  value="both"
                  type="radio"
                  checked={gCalType === "both"}
                  onChange={(e) => setGcalType(e.target.value)}
                />
                <label for="both">Both</label>
              </div>
            </div>

            <div className="cal__gCal-line">
              <RetroButton
                onClick={() => handleGCalSave(gCalId)}
                disabled={gCalId === ""}
              >
                <BsCheckLg size="1.4em" />
                Add Calendar
              </RetroButton>
            </div>
          </div>
        </BoxLayer>
      )}
      {calType === "apple" && (
        <BoxLayer setLayer={setShowICalModal}>
          <div className="cal__gCal-header">
            <h3>
              <BsApple /> Apple Calendar
            </h3>
            <div>
              <BsQuestionCircleFill data-tooltip-id="appleCal-how" />
            </div>
            <Tooltip id="appleCal-how">
              <div className="cal__gCal-tip">
                How to integrate an apple (iCloud) calendar
                <ul style={{ marginTop: "10px" }}>
                  <li>Go to your Calendars app</li>
                  <li>
                    Tap the Info button next to the iCloud calendar you want to
                    share.
                  </li>
                  <li>
                    Turn on Public Calendar, then it will give you a URL link.
                  </li>
                  <li>Select, Copy, and Paste below.</li>
                </ul>
              </div>
            </Tooltip>
          </div>
          <div className="cal__gCal-form">
            <div className="cal__gCal-line">
              <input
                type="color"
                // id="gCalColor"
                name="body"
                value={gCalColor}
                onChange={(e) => setGCalColor(e.target.value)}
              />
              {/* <label for="gCalColor">Color</label> */}
              <input
                onChange={(e) => setGCalName(e.target.value)}
                type="text"
                placeholder="Calendar Name"
                value={gCalName}
              />
            </div>
            <div className="cal__gCal-line">
              <input
                onChange={(e) => setICalUrl(e.target.value)}
                type="text"
                placeholder="Apple Calendar URL"
                value={iCalUrl}
              />
              {iCalUrl !== "" && (
                <div>
                  <button
                    className="cal__gCal-clearBtn"
                    onClick={() => setICalUrl("")}
                  >
                    <BsXCircle size="1.4em" />
                  </button>
                </div>
              )}
            </div>
            <div className="cal__gCal-line">
              <div className="cal__gCal-radiobtn">
                <input
                  name="gcaltype"
                  id="personal"
                  value="personal"
                  type="radio"
                  checked={gCalType === "personal"}
                  onChange={(e) => setGcalType(e.target.value)}
                />
                <label for="personal">Personal</label>
              </div>
              <div className="cal__gCal-radiobtn">
                <input
                  name="gcaltype"
                  id="project"
                  value="project"
                  type="radio"
                  checked={gCalType === "project"}
                  onChange={(e) => setGcalType(e.target.value)}
                />
                <label for="project">Project</label>
              </div>
              <div className="cal__gCal-radiobtn">
                <input
                  name="gcaltype"
                  id="both"
                  value="both"
                  type="radio"
                  checked={gCalType === "both"}
                  onChange={(e) => setGcalType(e.target.value)}
                />
                <label for="both">Both</label>
              </div>
            </div>

            <div className="cal__gCal-line">
              <RetroButton
                onClick={() => handleGCalSave(gCalId)}
                disabled={gCalId === ""}
              >
                <BsCheckLg size="1.4em" />
                Add Calendar
              </RetroButton>
            </div>
          </div>
        </BoxLayer>
      )}
    </>
  );
}
