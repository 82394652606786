import { combineReducers } from "redux";

import spaceReducer from "./space/space.reducer";
import userReducer from "./user/user.reducer";
import historyReducer from "./history/history.reducer";
import taskReducer from "./tasks/tasks.reducer";
import prjphaseReducer from "./prjphases/prjphases.reducer";
import prjtaskReducer from "./prjtasks/prjtasks.reducer";
import prjnoteReducer from "./prjnotes/prjnotes.reducer";
import filterReducer from "./filter/filter.reducer"
import meetsReducer from "./meeting/meeting.reducer";
import themeReducer from "./theme/theme.reducer";
import settingsReducer from "./settings/settings.reducer";

const rootReducer = combineReducers({
  space: spaceReducer,
  user: userReducer,
  history: historyReducer,
  tasks: taskReducer,
  prjphases: prjphaseReducer,
  prjtasks: prjtaskReducer,
  prjnotes: prjnoteReducer,
  filter: filterReducer,
  meets: meetsReducer,
  theme: themeReducer,
  settings: settingsReducer,
});

export default rootReducer;
