import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// import { NotesContext } from "./NotesContext";
// import { FormContext } from "../form/FormContext";

import sanitizeHtml from "sanitize-html";

import {
  deleteNote,
  pinNote,
  archiveNote,
  addNoteLabel,
  deleteNoteLabel,
} from "../../../firebase/firebase.utils";

import Reader from "../../../components/editor/reader";
import Editor from "../../../components/editor/editor";

import Labels from "../../../components/note-detail/labels/labels.component";

import "./note-card.styles.scss";

import { BsPinAngle, BsPinAngleFill } from "react-icons/bs";
import { IoTrashOutline, IoArchiveOutline, IoArchive } from "react-icons/io5";

export default function NoteCard({ note }) {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();
  const currentSpaceId = location.pathname.split("/")[2];
  const currentStationId = location.pathname.split("/")[4];

  const [labels, setLabels] = useState([]);
  const [label, setLabel] = useState("");

  useEffect(() => {
    if (note) {
      setLabels(note.labels);
    }
  }, [note]);

  function handleMouseEnter() {
    setIsMouseOver(true);
  }
  function handleMouseLeave() {
    setIsMouseOver(false);
  }

  function handleNoteClick() {
    navigate(`note/${note.id}`);
  }

  const handlePin = (note) => {
    if (note.isPinned) {
      pinNote(currentSpaceId, currentStationId, note.id, false);
    } else {
      pinNote(currentSpaceId, currentStationId, note.id, true);
    }
  };
  const handleArchive = (note) => {
    if (note.isArchived) {
      archiveNote(currentSpaceId, currentStationId, note.id, false);
    } else {
      archiveNote(currentSpaceId, currentStationId, note.id, true);
    }
  };

  const handleLabelSubmit = (e) => {
    e.preventDefault();
    addNoteLabel(currentSpaceId, currentStationId, note.id, label);
    setLabel("");
  };

  const handleRemoveLabel = (lbl) => {
    deleteNoteLabel(currentSpaceId, currentStationId, note.id, lbl);
  };

  // const cleanNoteText = sanitizeHtml(note.text, {
  //   allowedTags: [
  //     "h2",
  //     "h3",
  //     "h4",
  //     "h5",
  //     "p",
  //     "b",
  //     "i",
  //     "blockquote",
  //     "em",
  //     "strong",
  //     "a",
  //     "img",
  //     "details",
  //     "summary",
  //     "table",
  //     "tr",
  //     "td",
  //     "tbody",
  //     "iframe",
  //   ],
  //   allowedAttributes: {
  //     a: ["href", "name", "target"],
  //     // We don't currently allow img itself by default, but
  //     // these attributes would make sense if we did.
  //     p: ["color"],
  //     span: ["color"],
  //     img: ["src", "srcset", "alt", "title", "width", "height", "loading"],
  //     iframe: ["src", "width", "height", "frameborder", "allowfullscreen"],
  //     table: ["border", "width"],
  //     nonBooleanAttributes: ["style"]
  //   },
  //   // allowedIframeHostnames: ["www.youtube.com"],
  // });

  const cleanNoteText = note.text

  return (
    <div
      className="notecard"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div>
        <div onClick={handleNoteClick}>
          {note.url && <img src={note.url} alt="card" />}
        </div>
        <h3 onClick={handleNoteClick}> {note.title}</h3>
        {/* <Text>{note.text}</Text> */}
        <div
          className="notecard_reader-container"
          dangerouslySetInnerHTML={{ __html: cleanNoteText }}
          onClick={handleNoteClick}
          // style={{ textAlign: "left" }}
        />
        {/* <div className="notecard_reader-container" onClick={handleNoteClick}>
          {note.text}

          <Reader
            id="editor"
            note={note}
            // parentStateChanger={setNote}
            initialEditorState={note.text}
            cutomEditorState={note.text}
          />
        </div> */}
        {/* <Reader onChange ={onChange} readerState={demoHTMLText} /> */}
      </div>
      <div className="notecard_header">
        <p className="notecard_pin-btn" onClick={() => handlePin(note)}>
          <span className="material-icons">
            {note.isPinned ? (
              <BsPinAngleFill size="1.5em" />
            ) : (
              <BsPinAngle size="1.5em" />
            )}
          </span>
        </p>
      </div>
      <div className="notecard_footer">
        <Labels
          labels={labels}
          handleLabelSubmit={handleLabelSubmit}
          handleRemoveLabel={handleRemoveLabel}
          setLabel={setLabel}
          label={label}
        />
        <div className="notecard_footer-buttons">
          <button
            // className="notecard_archive-btn"
            onClick={() => handleArchive(note)}
          >
            {note.isArchived ? (
              <IoArchive size="1.5em" />
            ) : (
              <IoArchiveOutline size="1.5em" />
            )}
          </button>
          <button
            // className="notecard_delete-btn"
            onClick={() => deleteNote(currentSpaceId, currentStationId, note)}
          >
            <IoTrashOutline size="1.5em" />
          </button>
        </div>
      </div>
    </div>
  );
}
