import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useLocation, useNavigate } from "react-router-dom";

import { useActiveSpaceData } from "../../hooks/useActiveSpaceData.hook";

import DockHeader from "../../components/dock-header/dock-header.component";
import Box from "../../components/retro/box/box.component";
import RecentStations from "../../components/recent-stations/recent-stations.component";
import Members from "../../components/members/members.component";

import EditorJournal from "../../components/editor/editorjournal";
import EditorJournalNew from "../../components/editor/editorJournalNew";
import EditorBlog from "../../components/editor/editorblog";

import AntEditor from "../../components/editor/anteditor/anteditor";
import GoBackButton from "../../components/ui-elements/gobackbutton";

import LeadsClients from "../../components/leads-clients/leads-clients.component";

import RetroButton from "../../components/retro/button/retro-button.component";

import { BsThreeDots, BsPeopleFill } from "react-icons/bs";

import "./dock-station.styles.scss";
// import "../gantt/gantt.styles.scss";

import {
  db,
  storage,
  fieldValue,
  updateWorkspaceBrief,
} from "../../firebase/firebase.utils";

import DockChart from "../../components/charts/DockChart.component";

// import { Task, ViewMode, Gantt } from "gantt-task-react";
// import "gantt-task-react/dist/index.css";

import Gantt from "../../components/gantt/frappe-gantt";

import TaskAddButton from "../../components/task-add-btn/task-add-btn.component";

import {
  changeDescriptionOfSpace,
  renameSpace,
  updateColorOfSpace,
} from "../../firebase/firebase.utils";

const DockStation = () => {
  const activeSpaceData = useActiveSpaceData();
  const users = useSelector((state) => state.user.users);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();
  const currentSpaceId = location.pathname.split("/")[2];
  const currentStationId = location.pathname.split("/")[4];

  const currentUser = useSelector((state) => state.user.currentUser);

  const [state, setState] = useState([]);
  const [deleteOpacity, setDeleteOpacity] = useState(0);

  const inputRefDesc = useRef();
  const [inputDesc, setInputDesc] = useState("");

  const _svgRoadmap = useRef();
  const _chartRoadmap = useRef();
  const noteEditorRef = useRef(null);

  const [docImgFile, setDocImgFile] = useState(null);
  const [docImgFileAltText, setDocImgFileAltText] = useState("");

  const [htmlfromserializer, setHtmlfromSerializer] = useState("");

  const [viewMode, setViewMode] = useState("Month");

  const [showBrief, setShowBrief] = useState(true);
  const [showMainToolbar, setShowMainToolbar] = useState(false);
  const [showAnalytics, setShowAnaltytics] = useState(false);

  const [editorMainToolbar, setEditorMainToolbar] = useState(false);

  const [initialValue, setInitialValue] = useState(
    '<ul class="editor-list-ul list-disc"><li value="1" class="editor-listitem"><span>Initial something 1</span></li><li value="2" class="editor-listitem"><span>something 2</span></li><li value="3" class="editor-listitem"><span>something 3</span></li></ul>'
  );

  // const initialValue =
  //   '<ul class="editor-list-ul list-disc"><li value="1" class="editor-listitem"><span>Initial something 1</span></li><li value="2" class="editor-listitem"><span>something 2</span></li><li value="3" class="editor-listitem"><span>something 3</span></li></ul>';
  const [workspaceBrief, setWorkspaceBrief] = useState(initialValue);

  const handleSubmitDesc = (e) => {
    e.preventDefault();
    console.log(activeSpaceData.uid, inputDesc);
    changeDescriptionOfSpace(activeSpaceData.spaceId, inputDesc);
    inputRefDesc.current.blur();
  };

  useEffect(() => {
    const editor = noteEditorRef.current;
    db.collection("space")
      .doc(currentSpaceId)
      .onSnapshot((doc) => {
        if (doc.exists) {
          let workspacejournal = doc.data();
          // setCustomEditorState(workspacejournal.description);
          setInitialValue(workspacejournal.description);
          setWorkspaceBrief(workspacejournal.description);
        } else {
          // setCustomEditorState(emptyEditorState);
          setWorkspaceBrief(initialValue);
        }
      });
  }, [currentSpaceId]);

  // useEffect(() => {
  //   const editor = noteEditorRef.current;
  //   // console.log("THE DIFFERENCE IS", note.updatedAt?.seconds);
  //   if (workspaceBrief !== null) {
  //     // if (editor) {
  //     //   editor.update(() => {
  //     //     let nodes = [];

  //     //     // Parse html
  //     //     const parser = new DOMParser();
  //     //     const dom = parser.parseFromString(workspaceBrief, "text/html");
  //     //     nodes = $generateNodesFromDOM(editor, dom);

  //     //     // Set content
  //     //     const root = $getRoot();
  //     //     root.clear();
  //     //     root.append(...nodes);
  //     //   });
  //     // } else {
  //     //   const waitforeditor = setTimeout(() => {
  //     //     const editor = noteEditorRef.current;
  //     //     editor.update(() => {
  //     //       let nodes = [];

  //     //       // Parse html
  //     //       const parser = new DOMParser();
  //     //       const dom = parser.parseFromString(workspaceBrief, "text/html");
  //     //       nodes = $generateNodesFromDOM(editor, dom);

  //     //       // Set content
  //     //       const root = $getRoot();
  //     //       root.clear();
  //     //       root.append(...nodes);
  //     //     });
  //     //   }, 50);
  //     //   console.log("EDITORREF CURRENT IS", noteEditorRef.current);
  //     //   return () => clearTimeout(waitforeditor);
  //     // }
  //     setInitialValue(workspaceBrief);
  //   }
  // }, [workspaceBrief, currentSpaceId]);

  // const handleSaveForm = () => {
  //   db.collection("space").doc(currentSpaceId).update({
  //     description: note.text,
  //   });
  // };

  // const [viewMode, setViewMode] = useState(ViewMode.Day);
  // let columnWidth = 65;
  // if (viewMode === ViewMode.Year) {
  //   columnWidth = 350;
  // } else if (viewMode === ViewMode.Month) {
  //   columnWidth = 300;
  // } else if (viewMode === ViewMode.Week) {
  //   columnWidth = 250;
  // }
  const [isChecked, setIsChecked] = useState(false);

  // useEffect(() => {
  //   setState(currentSpaceId);
  // }, [currentSpaceId]);

  const tasks = [
    {
      id: "Task 1",
      name: "Redesign website",
      start: "2022-12-23",
      end: "2023-01-03",
      progress: 20,
      dependencies: "",
      custom_class: "bar-milestone", // optional
    },
    {
      id: "Task 2",
      name: "Review design decisions",
      start: "2023-01-01",
      end: "2023-01-03",
      progress: 20,
      dependencies: "Task 1",
      custom_class: "bar-pink bar-milestone", // optional
    },
    {
      id: "Task 3",
      name: "Get Approval",
      start: "2023-01-04",
      end: "2023-01-10",
      progress: 20,
      dependencies: "Task 2",
      custom_class: "bar-orange bar-milestone", // optional
    },
    {
      id: "Task 4",
      name: "Launch the website",
      start: "2023-01-11",
      end: "2023-01-12",
      progress: 20,
      dependencies: "Task 3",
      custom_class: "bar-milestone", // optional
    },
    {
      id: "Task 5",
      name: "Plan the Updates",
      start: "2023-01-14",
      end: "2023-01-20",
      progress: 20,
      dependencies: "Task 3, Task 4",
      custom_class: "bar-milestone", // optional
    },
  ];

  const [tasklist, setTasklist] = useState([]);
  useEffect(() => {
    //mounts
    const unsub = db
      .collection("space")
      .doc(currentSpaceId)
      .collection("stations")
      .orderBy("startdate", "asc")
      .where("startdate", "!=", null)
      .onSnapshot((querySnapshot) => {
        if (!querySnapshot.empty) {
          const array = querySnapshot.docs.map((doc) => {
            return {
              start: doc.get("startdate")?.toDate(),
              end: doc.get("enddate")?.toDate(),
              name: doc.get("name"),
              id: doc.id,
              progress: (4 / 8) * 100,
              swimlane: doc.get("phase"),
              // sub_swimlane: doc.get("id"),
              editable: true,
              start_drag: true,
              end_drag: true,
              // // type: doc.get("type"),
              // project: doc.get("project"),
              // // hideChildren: doc.get("hidechildren"),
              custom_class: "task",
              dependencies: doc.get("dependencies"),
              fromSpaceId: doc.get("fromSpaceId"),
            };
          });
          setTasklist([...array]);
          _chartRoadmap.current.refresh([...array]);
        } else {
          // setTasklist(tasks);
          _chartRoadmap.current.refresh([]);
        }
      });

    return () => {
      unsub();
    };
  }, [currentSpaceId]);

  const handleTaskChange = (project, start, end) => {
    db.collection("space")
      .doc(currentSpaceId)
      .collection("stations")
      .doc(project.id)
      .update({
        startdate: start,
        enddate: end,
      });
  };

  const handleDependencyAdd = (connection) => {
    db.collection("space")
      .doc(currentSpaceId)
      .collection("stations")
      .doc(connection.to)
      .update({
        dependencies: fieldValue.arrayUnion(connection.from),
      });
  };

  const handleDependencyRemove = (connection) => {
    db.collection("space")
      .doc(currentSpaceId)
      .collection("stations")
      .doc(connection.to)
      .update({
        dependencies: fieldValue.arrayRemove(connection.from),
      });
  };

  const goToProject = (task) => {
    navigate(`/s/${task.fromSpaceId}/e/${task.id}/b`);
  };

  const handleViewModeChange = (e) => {
    setViewMode(e.target.value);
    _chartRoadmap.current.change_view_mode(e.target.value);
  };

  // var emptyEditorState = `{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}`;
  // const [customEditorState, setCustomEditorState] = useState(emptyEditorState);

  // let initialState = {
  //   text: "",
  // };

  // const [note, setNote] = useState(initialState);
  // const [note, setNote] = useState(initialState);

  useEffect(() => {
    // if (chart.current) return;

    _chartRoadmap.current = new Gantt(_svgRoadmap.current, tasklist, {
      on_click: function (task) {
        console.log(task);
        goToProject(task);
      },
      on_dependency_added: function (connection) {
        console.log(connection);
        handleDependencyAdd(connection);
      },
      on_dependency_deleted: function (connection) {
        console.log(connection);
        handleDependencyRemove(connection);
      },
      on_date_change: function (task, start, end) {
        console.log(task, start, end);
        handleTaskChange(task, start, end);
      },
      on_progress_change: function (task, progress) {
        console.log(task, progress);
        // handleProgressChange(task);
      },
      on_view_change: function (mode) {
        console.log(mode);
      },
      view_mode: viewMode,
    });
  }, []);

  // // AUTOSAVE of TEXT;
  // useEffect(() => {
  //   const contenttimer = setTimeout(() => {
  //     if (workspaceBrief !== htmlfromserializer) {
  //       // updateNoteText(
  //       //   currentSpaceId,
  //       //   currentStationId,
  //       //   noteId,
  //       //   htmlfromserializer,
  //       //   currentUser.userName
  //       // );
  //       // changeDescriptionOfSpace(currentSpaceId, htmlfromserializer);
  //       db.collection("space").doc(currentSpaceId).update({
  //         description: htmlfromserializer,
  //       });
  //       console.log("trigged content save");
  //     }
  //   }, 3000);
  //   return () => clearTimeout(contenttimer);
  // }, [htmlfromserializer]);

  // AUTOSAVE of WORKSPACE BRIEF;
  useEffect(() => {
    if (initialValue !== workspaceBrief) {
      console.log("saving...");
      const timer = setTimeout(() => {
        updateWorkspaceBrief(
          currentSpaceId,
          workspaceBrief,
          currentUser.userName
        );
        console.log("brief is saved");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [workspaceBrief]);

  const handleUploadDocImage = async (e) => {
    // const file = e.target.files[0];
    const storageRef = storage.ref(`space/${currentSpaceId}`);
    const fileRef = storageRef.child(docImgFile.name);
    // setShowLoading(true);
    await fileRef.put(docImgFile);
    const fileUrl = await fileRef.getDownloadURL();
    // setFeaturedImage(fileUrl);

    // setDocumentImgFile(null);
    // setDocImgFileAltText("");
    return fileUrl;
  };

  return (
    <div className="dockStation">
      <div className="dockStation-content">
        {activeSpaceData ? <DockHeader activeSpaceData={activeSpaceData} /> : <div className="dockHeader"><div className="dH__upperRow"><GoBackButton /><div className="dh__header">          <RetroButton style={{ background: "white" }}>
                          <BsPeopleFill /> 
                  </RetroButton><h2 style={{fontWeight: "500", fontSize: "1.2em"}}>Shared With  Me</h2></div></div><div className="dH__bottomRow"></div></div>}
        <div className="dockStation-content-inner">
          <div className="roadmap__content">
            <div className="gantt__header-buttons">
              <div className="widgetheader">
                <h3>Roadmap</h3>
              </div>
              <div className="gantt__buttons-dock">
                <button
                  style={{
                    textDecoration:
                      viewMode === "Day" &&
                      `underline 2px ${activeSpaceData?.color}`,
                  }}
                  className={
                    viewMode === "Day"
                      ? "gantt__button-active"
                      : "gantt__button"
                  }
                  value="Day"
                  onClick={handleViewModeChange}
                >
                  Day
                </button>
                <button
                  style={{
                    textDecoration:
                      viewMode === "Week" &&
                      `underline 2px ${activeSpaceData?.color}`,
                  }}
                  className={
                    viewMode === "Week"
                      ? "gantt__button-active"
                      : "gantt__button"
                  }
                  value="Week"
                  onClick={handleViewModeChange}
                >
                  Week
                </button>
                <button
                  style={{
                    textDecoration:
                      viewMode === "Month" &&
                      `underline 2px ${activeSpaceData?.color}`,
                  }}
                  className={
                    viewMode === "Month"
                      ? "gantt__button-active"
                      : "gantt__button"
                  }
                  value="Month"
                  onClick={handleViewModeChange}
                >
                  Month
                </button>
              </div>
            </div>
            <div>
              {/* <Gantt
                  tasks={tasks}
                  viewMode={viewMode}
                  // ganttHeight={150}
                  rowHeight={30}
                  listCellWidth={isChecked ? "155px" : ""}
                  onDateChange
                  columnWidth={columnWidth}
                  barProgressColor="#1d1d1d"
                  barBackgroundColor="gray"
                  // barProgressSelectedColor="red"
                  // preStepsCount="2"
                  // columnWidth={columnWidth}
                /> */}
              <svg
                ref={_svgRoadmap}
                style={{ maxHeight: "100%" }}
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
              ></svg>
            </div>
          </div>
          {activeSpaceData && (
            <div className="ds__content">
              <div className="dh__description ds__item">
                <div>
                  <div className="client__header-tabs">
                    <div
                      className={
                        !showAnalytics
                          ? "widgetheader"
                          : "widgetheader-nonactive"
                      }
                    >
                      <h3 onClick={() => setShowAnaltytics(false)}>
                        Workspace Brief
                      </h3>
                    </div>
                    <div
                      className={
                        showAnalytics
                          ? "widgetheader"
                          : "widgetheader-nonactive"
                      }
                    >
                      <h3 onClick={() => setShowAnaltytics(true)}>Analytics</h3>
                    </div>
                  </div>
                  {/* <Box>
                  <form onSubmit={(e) => handleSubmitDesc(e)}>
                    <textarea
                      cols="38"
                      rows="5"
                      ref={inputRefDesc}
                      placeholder={activeSpaceData.description}
                      onChange={(e) => setInputDesc(e.target.value)}
                    />
                    <div className="ch__writeUpdate-btn">
                      <RetroButton type="submit">Save Changes</RetroButton>
                    </div>
                  </form>
                </Box> */}
                  {!showAnalytics && (
                    <div className="workspace__journal">
                      {" "}
                      <AntEditor
                        mainToolbarOn={editorMainToolbar}
                        floatingToolbarOn={true}
                        placeholder={
                          "You can use this text area for onboarding information, for team guidelines, workflow explanations, etc..."
                        }
                        initialValue={initialValue}
                        setDocContent={setWorkspaceBrief}
                        setDocImgFile={setDocImgFile}
                        setDocImgFileAltText={setDocImgFileAltText}
                        docImgFileAltText={docImgFileAltText}
                        handleUploadDocImage={handleUploadDocImage}
                      />
                      {/* <EditorJournalNew
                      id="editorNote"
                      onChange={(state, editor) => {
                        noteEditorRef.current = editor;
                        // console.log("STATE IS", state);
                        // console.log("EDITOR IS", editor);
                      }}
                      initialValue={initialValue}
                      dbValue={workspaceBrief}
                      setDocumentImgFile={setDocumentImgFile}
                      setDocImgFileAltText={setDocImgFileAltText}
                      docImgFileAltText={docImgFileAltText}
                      handleUploadDocImage={handleUploadDocImage}
                      setHtmlfromSerializer={setHtmlfromSerializer}
                      // fullToolbar={true}
                      showMainToolbar={showMainToolbar}
                    /> */}
                      {/* <EditorJournal
                      id="editor"
                      note={note}
                      parentStateChanger={setNote}
                      initialEditorState={customEditorState}
                      cutomEditorState={customEditorState}
                    /> */}
                      {/* <div
                      className="journal__form-button"
                      onClick={handleSaveForm}
                    >
                      Save
                    </div> */}
                      {/* <button
                      onClick={() => setShowMainToolbar(!showMainToolbar)}
                    >
                      <BsThreeDots />
                    </button> */}
                    </div>
                  )}
                  {showAnalytics && (
                    <DockChart activeSpaceData={activeSpaceData} />
                  )}
                </div>
              </div>
              <div className="ds__recentStations ds__item-secondary">
                {/* <div className="widgetheader">
                  <h3>Leads & Clients</h3>
                </div> */}
                <LeadsClients currentUser={currentUser} />
                {/* <RecentStations activeSpaceData={activeSpaceData} /> */}
              </div>
              <div className="ds__members ds__item-secondary">
                <Members activeSpaceData={activeSpaceData} />
              </div>
            </div>
          )}
        </div>
      </div>
      <TaskAddButton />
    </div>
  );
};

export default DockStation;
