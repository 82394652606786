import React, { useState } from "react";
import ExampleTheme from "./themes/ExampleTheme";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import TreeViewPlugin from "./plugins/TreeViewPlugin";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { CheckListPlugin } from "@lexical/react/LexicalCheckListPlugin";
import { ListItemNode, ListNode } from "@lexical/list";

import ImagePlugin from "./plugins/ImagePlugin";
// import ImgToolbarPlugin from "./plugins/ImageToolbar";
import { ImageNode } from "./nodes/ImageNode";


import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from "@lexical/markdown";

import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";

import { $createParagraphNode, $createTextNode, $getRoot, $getSelection, SerializedEditorState } from 'lexical';

import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';

import FigmaPlugin from "./plugins/FigmaPlugin";
import { FigmaNode } from "./nodes/FigmaNode";

import YouTubePlugin from "./plugins/YouTubePlugin";
import { YouTubeNode } from "./nodes/YouTubeNode";

import CollapsiblePlugin from "./plugins/CollapsiblePlugin";
import { CollapsibleContainerNode } from "./nodes/CollapsibleContainerNode";
import { CollapsibleContentNode } from "./nodes/CollapsibleContentNode";
import { CollapsibleTitleNode } from "./nodes/CollapsibleTitleNode";

import ExcalidrawPlugin from "./plugins/ExcalidrawPlugin";
import { ExcalidrawNode } from "./nodes/ExcalidrawNode";

import { HorizontalRuleNode } from "@lexical/react/LexicalHorizontalRuleNode";
import HorizontalRulePlugin from "./plugins/HorizontalRulePlugin";

import HtmlSerializerPlugin from "./plugins/HtmlSerializerPlugin";
import { $generateNodesFromDOM } from "@lexical/html";

import { TablePlugin } from "@lexical/react/LexicalTablePlugin";
import { TableContext } from "./plugins/TablePlugin";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import TableCellActionMenuPlugin from "./plugins/TableActionMenuPlugin";
import TableCellResizer from "./plugins/TableCellResizer";
import ActionsPlugin from "./plugins/ActionsPlugin";
import SpeechToTextPlugin from "./plugins/SpeechToTextPlugin";

import "./styles.css";
import { useEffect } from "react";


// function UpdatePlugin() {
//     const [editor] = useLexicalComposerContext();

//     function update() {
//         editor.update(() => {
//             const root = $getRoot();
//             const p = $createParagraphNode();
//             p.append($createTextNode("new node"));
//             root.append(p);
//         });
//     }
//     return <button onClick={update}>Add a node</button>;
// }

function Placeholder() {
    return <div className="editor-placeholder">Enter some rich text...</div>;
}


const getInitialState = (editor, initialValue) => {
    editor.update(() => {
        // In the browser you can use the native DOMParser API to parse the HTML string.
        const parser = new DOMParser();
        const dom = parser.parseFromString(initialValue, "text/html");

        // Once you have the DOM instance it's easy to generate LexicalNodes.
        const nodes = $generateNodesFromDOM(editor, dom);

        const root = $getRoot();

        nodes.map((node) => root.append(node));
    });
};

const getEditorConfig = (initialValue) => ({
    // The editor theme
    theme: ExampleTheme,
    // Handling of errors during update
    onError(error) {
        throw error;
    },
    editorState: (editor) => getInitialState(editor, initialValue),
    // Any custom nodes go here
    nodes: [
        HeadingNode,
        ListNode,
        ListItemNode,
        QuoteNode,
        CodeNode,
        CodeHighlightNode,
        TableNode,
        TableCellNode,
        TableRowNode,
        AutoLinkNode,
        LinkNode,
        FigmaNode,
        YouTubeNode,
        CollapsibleContainerNode,
        CollapsibleContentNode,
        CollapsibleTitleNode,
        HorizontalRuleNode,
        ExcalidrawNode,
        ImageNode,
    ]
});


export default function EditorBlog({ onChange, initialValue, setDocumentImgFile, setDocImgFileAltText, docImgFileAltText, handleUploadDocImage, setHtmlfromSerializer }) {

    // const onChange = (editorState) => {
    //     // console.log(JSON.stringify(editorState));
    //     // parentStateChanger(JSON.stringify(editorState.toJSON()));
    //     parentStateChanger({ ...note, text: JSON.stringify(editorState) });
    // };

    const [floatingAnchorElem, setFloatingAnchorElem] = useState(null);

    const onRef = (_floatingAnchorElem) => {
        if (_floatingAnchorElem !== null) {
            setFloatingAnchorElem(_floatingAnchorElem);
        }
    };

    return (
        <LexicalComposer initialConfig={getEditorConfig(initialValue)}>
            <TableContext>
                <div className="editor-container">
                    <ToolbarPlugin setDocumentImgFile={setDocumentImgFile} setDocImgFileAltText={setDocImgFileAltText} docImgFileAltText={docImgFileAltText} handleUploadDocImage={handleUploadDocImage} />
                    <div className="editor-inner">
                        <RichTextPlugin
                            contentEditable={<ContentEditable className="editor-input" />}
                            placeholder={<Placeholder />}
                            ErrorBoundary={LexicalErrorBoundary}
                        />
                        <ListPlugin />
                        <CheckListPlugin />
                        <HistoryPlugin />
                        {/* <TreeViewPlugin /> */}
                        <AutoFocusPlugin />
                        <CodeHighlightPlugin />
                        <LinkPlugin />
                        <AutoLinkPlugin />
                        <ListMaxIndentLevelPlugin maxDepth={7} />
                        <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
                        <OnChangePlugin onChange={onChange} />
                        {/* <UpdatePlugin /> */}
                        {/* <CustomContentPlugin customEditorState={cutomEditorState} /> */}
                        <FigmaPlugin />
                        <YouTubePlugin />
                        <CollapsiblePlugin />
                        <HorizontalRulePlugin />
                        <ActionsPlugin />
                        <SpeechToTextPlugin />
                        <ExcalidrawPlugin />
                        <TablePlugin />
                        <TableCellResizer />
                        <ImagePlugin />
                        <HtmlSerializerPlugin setHtmlfromSerializer={setHtmlfromSerializer} />
                        {/* <TableCellActionMenuPlugin anchorElem={floatingAnchorElem} /> */}
                    </div>
                    {/* <div>{note.text}</div> */}
                    {/* <div>{initialEditorState}</div> */}
                    {/* <div>{serializedEditorState}</div> */}
                </div>
            </TableContext>
        </LexicalComposer>
    );
}




// function CustomContentPlugin({ initEditorState }) {
//     const [editor] = useLexicalComposerContext();

//     useEffect(() => {

//         const editorState = editor.parseEditorState(initEditorState);
//         editor.setEditorState(editorState);
//         // Focus the editor when the effect fires!
//         editor.focus();
//     }, [editor, initEditorState]);

//     return null;
// }



// When the editor changes, you can get notified via the
// LexicalOnChangePlugin!
// function onChange(editorState) {
//     editorState.read(() => {
//       // Read the contents of the EditorState here.
//       const root = $getRoot();
//       const selection = $getSelection();

//       console.log(root, selection);
//     });
//   }

{/* <OnChangePlugin onChange={onChange} /> */ }

function CustomContentPlugin({ customEditorState }) {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {

        const editorState = editor.parseEditorState(customEditorState);
        editor.setEditorState(editorState);
        // Focus the editor when the effect fires!
        //   editor.focus();
    }, [editor, customEditorState]);

    return null;
}




export const Reader = ({ readerState }) => {
    const initialConfig2 = {
        namespace: "MyEditor",
        editable: false,
        //   theme,
        //   onError
        editorState: readerState,
    }


    return (
        <LexicalComposer initialConfig={initialConfig2}>
            <RichTextPlugin
                contentEditable={<ContentEditable />}
                placeholder={""}
                ErrorBoundary={LexicalErrorBoundary}
            />
            <UpdatePlugin readerState={readerState} />
        </LexicalComposer>
    )
}

const UpdatePlugin = ({ readerState }) => {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        if (readerState) {
            const state = editor.parseEditorState(readerState)
            editor.setEditorState(state)
        }
    }, [readerState, editor]);
    return null
}