import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import RetroButton from "../retro/button/retro-button.component";
import CreateSpace from "../create-space/create-space.component";

import "./space-data.styles.scss";

import { FaPlusSquare } from "react-icons/fa";

function SpaceData() {
  const spaceData = useSelector((state) => state.space.spaceData);
  const [create, setCreate] = useState(false);

  return (
    <div className="spaceData">
      <div className="sd__data">
        {spaceData.map((data) => {
          const { spaceId, color, name } = data;
          function changeBackground(e) {
            e.target.style.background = color;
          }
          function removeBackground(e) {
            e.target.style.background = "none";
          }
          return (
            <Link to={`/s/${data.spaceId}`}>
              <div key={spaceId} className="sd__btn">
                <div
                  className="sd__btnClick"
                  onMouseOver={changeBackground}
                  onMouseOut={removeBackground}
                >
                  {/* <RetroButton style={{ background: color }}>
                    {name.charAt(0)}
                  </RetroButton> */}
                  <h4 style={{ textDecoration: `underline ${color} 2px` }}>
                    {name}
                  </h4>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
      <div className="sd__createNewSpace">
        <RetroButton mode="create" onClick={() => setCreate(!create)}>
          Create new Workpace
        </RetroButton>
      </div>
      {create && <CreateSpace setLayer={setCreate} />}
    </div>
  );
}

export default SpaceData;
