import React from "react";
import { useDispatch } from "react-redux";

import { toggleTime } from "../../../redux/filter/filter.actions";

import "./time-filter.styles.scss";

const TimeFilter = ({ time }) => {
  const dispatch = useDispatch();
  return (
    <div className="timeFilter">
      <div
        className={`timeFilter-item ${
          time === -2 && "timeFilter__active timeFilter-past"
        }`}
        onClick={() => dispatch(toggleTime(-2))}
      >
        <p>{time === -2 ? "past due" : "past"}</p>
      </div>
      <div
        className={`timeFilter-item ${
          time === 0 && "timeFilter__active timeFilter-today"
        }`}
        onClick={() => dispatch(toggleTime(0))}
      >
        <p>today</p>
      </div>
      <div
        className={`timeFilter-item ${
          time === 1 && "timeFilter__active timeFilter-tomorrow"
        }`}
        onClick={() => dispatch(toggleTime(1))}
      >
        <p>{time === 1 ? "tomorrow" : "tmrw"}</p>
      </div>
      <div
        className={`timeFilter-item ${
          time === 7 && "timeFilter__active timeFilter-week"
        }`}
        onClick={() => dispatch(toggleTime(7))}
      >
        <p>{time === 7 ? "this week" : "week"}</p>
      </div>
      <div
        className={`timeFilter-item ${
          time === 30 && "timeFilter__active timeFilter-month"
        }`}
        onClick={() => dispatch(toggleTime(30))}
      >
        <p>{time === 30 ? "this month" : "month"}</p>
      </div>
    </div>
  );
};

export default TimeFilter;
