import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import { setIdsNull } from "../../redux/history/history.actions";

import RetroButton from "../retro/button/retro-button.component";
import CreateSpace from "../create-space/create-space.component";
import Icon from "./icon/icon.component";

import "./home-fly.styles.scss";

import { GoHome } from "react-icons/go";
import { MdEmojiPeople } from "react-icons/md";
import { GiTreehouse } from "react-icons/gi";
import { IoLeafSharp } from "react-icons/io5";
import { HiOutlineHomeModern, HiBuildingLibrary } from "react-icons/hi2";

import { setOpen } from "../../redux/user/user.actions";

const HomeFly = () => {
  const spaceData = useSelector((state) => state.space.spaceData);
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname;
  const [createNewSpace, setCreateNewSpace] = useState(false);

  return (
    <>
      <div className="homeFly">
        <div className="hsf__controls">
          <Link
            to="/"
            onClick={() => {
              dispatch(setIdsNull());
              dispatch(setOpen(true));
            }}
          >
            <div className="hsf__controls-home">
              {/* <RetroButton> */}
                <IoLeafSharp strokeWidth="0.1" />
              {/* </RetroButton> */}
            </div>
          </Link>
        </div>
      </div>
      <AnimatePresence>
        {createNewSpace && <CreateSpace setLayer={setCreateNewSpace} />}
      </AnimatePresence>
    </>
  );
};

export default HomeFly;
