import React from 'react'
import { useNavigate } from 'react-router-dom'
import { BsChevronLeft } from 'react-icons/bs'

const GoBackButton = () => {
    const navigate = useNavigate();

    return (
        <button style={{display: "flex", width: "40px", height:"40px", alignItems: "center", justifyContent: "center", marginRight: "-5px"}} onClick={() => navigate(-1)}>
            <BsChevronLeft size="1.8em" strokeWidth="0.5" />
        </button>
    )
}

export default GoBackButton