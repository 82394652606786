import SettingsType from "./settings.type";

const INITIAL_STATE = {
  showCalendarSettings: false,
  showGanttSettings: false,
  showFilesSettings: false,
  showConceptSettings: false,
  showNotesSettings: false,
};

const settingsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SettingsType.SHOW_CALENDAR_SETTINGS:
      return {
        showCalendarSettings: (!state.showCalendarSettings),
        showGanttSettings: false,
        showFilesSettings: false,
        showConceptSettings: false,
        showNotesSettings: false,
      };
    case SettingsType.SHOW_GANTT_SETTINGS:
      return {
        showCalendarSettings: false,
        showGanttSettings: (!state.showGanttSettings),
        showFilesSettings: false,
        showConceptSettings: false,
        showNotesSettings: false,
      };
    case SettingsType.SHOW_FILES_SETTINGS:
      return {
        showCalendarSettings: false,
        showGanttSettings: false,
        showFilesSettings: (!state.showFilesSettings),
        showConceptSettings: false,
        showNotesSettings: false,
      };
    case SettingsType.SHOW_CONCEPT_SETTINGS:
      return {
        showCalendarSettings: false,
        showGanttSettings: false,
        showFilesSettings: false,
        showConceptSettings: (!state.showConceptSettings),
        showNotesSettings: false,
      };
    case SettingsType.SHOW_NOTES_SETTINGS:
      return {
        showCalendarSettings: false,
        showGanttSettings: false,
        showFilesSettings: false,
        showConceptSettings: false,
        showNotesSettings: (!state.showNotesSettings),
      };
    default:
      return state;
  }
};

export default settingsReducer;
