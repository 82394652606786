import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  showCalendarSettings,
  showGanttSettings,
  showFilesSettings,
  showConceptSettings,
  showNotesSettings,
} from "../../redux/settings/settings.actions";

import "./modules.styles.scss";
import "../../theme.styles.scss";

import { BsGear } from "react-icons/bs";

import RetroButton from "../retro/button/retro-button.component";
import BoxLayer from "../retro/box-layer/box-layer.component";
import ModuleList from "./module-list/module-list.component";

const Modules = ({ view, currentStationId, currentSpaceId }) => {
  const moduleData = useSelector((state) => state.space.moduleData);
  const [moduleWindow, setModuleWindow] = useState(false);

  const dispatch = useDispatch();

  const handleSettingsWindow = () => {
    if (view === "b" || view === "l") {
      setModuleWindow(!moduleWindow);
    } else if (view === "c") {
      dispatch(showCalendarSettings());
    } else if (view === "g") {
      dispatch(showGanttSettings());
    } else if (view === "f") {
      dispatch(showFilesSettings());
    } else if (view === "m") {
      dispatch(showConceptSettings());
    } else if (view === "n") {
      dispatch(showNotesSettings());
    }
  };

  return (
    <div className="modules">
      <div className="modules__button">
        <RetroButton
          mode="flat2"
          color="$body"
          onClick={() => handleSettingsWindow()}
        >
          <BsGear />
        </RetroButton>
      </div>
      {moduleWindow && (
        <div className="modules__win">
          <BoxLayer setLayer={setModuleWindow}>
            {moduleData?.map((module) => {
              return (
                <ModuleList
                  key={module.name}
                  module={module}
                  modules={moduleData}
                  currentStationId={currentStationId}
                  currentSpaceId={currentSpaceId}
                />
              );
            })}
          </BoxLayer>
        </div>
      )}
    </div>
  );
};

export default Modules;
