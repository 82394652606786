export function getStartOfDay(givendate) {
    let daystartref = new Date(givendate);
    daystartref.setHours(0, 0, 0, 0);
    return daystartref;
}
export function getEndOfDay(givendate) {
    let dayendref = new Date(givendate);
    dayendref.setHours(23, 59, 59, 59);
    return dayendref;
}

export function getStartOfWeek(givendate) {
    let sundayofweek = new Date(
        givendate.setDate(givendate.getDate() - givendate.getDay())
    );
    sundayofweek.setHours(0, 0, 0, 0);
    return sundayofweek;
}

export function getEndOfWeek(givendate) {
    let saturdayofweek = new Date(
        givendate.setDate(givendate.getDate() - givendate.getDay() + 6)
    );
    saturdayofweek.setHours(23, 59, 59, 59);
    return saturdayofweek;
}

export function getStartOfMonth(givendate) {
    const firstdayofmonth = new Date(
        givendate.getFullYear(),
        givendate.getMonth(),
        1
    );
    firstdayofmonth.setHours(0, 0, 0, 0);
    return firstdayofmonth;
}

export function getEndOfMonth(givendate) {
    let lastdayofmonth = new Date(
        givendate.getFullYear(),
        givendate.getMonth() + 1,
        0
    );
    lastdayofmonth.setHours(23, 59, 59, 59);
    return lastdayofmonth;
}

export function secondsToHM(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " min, " : " mins ") : "";
    return hDisplay + mDisplay; 
}