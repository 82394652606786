import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import RetroButton from "../retro/button/retro-button.component";

import { BsListCheck } from "react-icons/bs";

import EmojiPick from "../emoji/emoji.component";

import "./station-item.styles.scss";

function StationItem({ data }) {
  const activeSpaceId = useSelector((state) => state.history.spaceId);
  return (
    <div className="stationItem">
      <Link to={`/s/${activeSpaceId}/e/${data.stationId}/b`}>
        <RetroButton>
          {/* <BsListCheck size="1.2em" /> */}
          <div className="EmojiIcon">{data.emojicon}</div>
          <a>{data.name}</a>
        </RetroButton>
      </Link>
    </div>
  );
}

export default StationItem;
