import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import * as React from "react";
import {
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  REDO_COMMAND,
  UNDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  FORMAT_TEXT_COMMAND,
  FORMAT_ELEMENT_COMMAND,
  $getSelection,
  $isRangeSelection,
  $createParagraphNode,
  $getNodeByKey,
  INDENT_CONTENT_COMMAND,
  OUTDENT_CONTENT_COMMAND,
} from "lexical";
import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import {
  $isParentElementRTL,
  $wrapNodes,
  $isAtNodeEnd,
} from "@lexical/selection";
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  INSERT_CHECK_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
  $isListNode,
  ListNode,
} from "@lexical/list";
import { createPortal } from "react-dom";
import {
  $createHeadingNode,
  $createQuoteNode,
  $isHeadingNode,
} from "@lexical/rich-text";
import {
  $createCodeNode,
  $isCodeNode,
  getDefaultCodeLanguage,
  getCodeLanguages,
} from "@lexical/code";
import { INSERT_IMAGE_COMMAND } from "./ImagePlugin";

import DropDown, { DropDownItem } from "../ui/DropDown";

// import {INSERT_EMBED_COMMAND} from '@lexical/react/LexicalAutoEmbedPlugin';

// import {EmbedConfigs} from './AutoEmbedPlugin';

import { INSERT_FIGMA_COMMAND } from "./FigmaPlugin";
import { INSERT_YOUTUBE_COMMAND } from "./YouTubePlugin";

import { INSERT_COLLAPSIBLE_COMMAND } from "./CollapsiblePlugin";

import { INSERT_HORIZONTAL_RULE_COMMAND } from "@lexical/react/LexicalHorizontalRuleNode";

import { INSERT_TABLE_COMMAND } from "@lexical/table";
import { InsertTableCommandPayload } from "@lexical/table";

import { INSERT_EXCALIDRAW_COMMAND } from "../plugins/ExcalidrawPlugin";

const LowPriority = 1;

const supportedBlockTypes = new Set([
  "paragraph",
  "quote",
  "code",
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "ul",
  "ol",
  "check",
]);

const blockTypeToBlockName = {
  code: "Code Block",
  h1: "Heading 1",
  h2: "Heading 2",
  h3: "Heading 3",
  h4: "Heading 4",
  h5: "Heading 5",
  ol: "Numbered List",
  paragraph: "Normal",
  quote: "Quote",
  ul: "Bulleted List",
  check: "Check List",
};

function Divider() {
  return <div className="divider" />;
}

function positionEditorElement(editor, rect) {
  if (rect === null) {
    editor.style.opacity = "0";
    editor.style.top = "-1000px";
    editor.style.left = "-1000px";
  } else {
    editor.style.opacity = "1";
    editor.style.top = `${rect.top + rect.height + window.pageYOffset + 10}px`;
    editor.style.left = `${
      rect.left + window.pageXOffset - editor.offsetWidth / 2 + rect.width / 2
    }px`;
  }
}

function FloatingLinkEditor({ editor }) {
  const editorRef = useRef(null);
  const inputRef = useRef(null);
  const mouseDownRef = useRef(false);
  const [linkUrl, setLinkUrl] = useState("");
  const [isEditMode, setEditMode] = useState(false);
  const [lastSelection, setLastSelection] = useState(null);

  const updateLinkEditor = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent)) {
        setLinkUrl(parent.getURL());
      } else if ($isLinkNode(node)) {
        setLinkUrl(node.getURL());
      } else {
        setLinkUrl("");
      }
    }
    const editorElem = editorRef.current;
    const nativeSelection = window.getSelection();
    const activeElement = document.activeElement;

    if (editorElem === null) {
      return;
    }

    const rootElement = editor.getRootElement();
    if (
      selection !== null &&
      !nativeSelection.isCollapsed &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const domRange = nativeSelection.getRangeAt(0);
      let rect;
      if (nativeSelection.anchorNode === rootElement) {
        let inner = rootElement;
        while (inner.firstElementChild != null) {
          inner = inner.firstElementChild;
        }
        rect = inner.getBoundingClientRect();
      } else {
        rect = domRange.getBoundingClientRect();
      }

      if (!mouseDownRef.current) {
        positionEditorElement(editorElem, rect);
      }
      setLastSelection(selection);
    } else if (!activeElement || activeElement.className !== "link-input") {
      positionEditorElement(editorElem, null);
      setLastSelection(null);
      setEditMode(false);
      setLinkUrl("");
    }

    return true;
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateLinkEditor();
        });
      }),

      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateLinkEditor();
          return true;
        },
        LowPriority
      )
    );
  }, [editor, updateLinkEditor]);

  useEffect(() => {
    editor.getEditorState().read(() => {
      updateLinkEditor();
    });
  }, [editor, updateLinkEditor]);

  useEffect(() => {
    if (isEditMode && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditMode]);

  return (
    <div ref={editorRef} className="link-editor">
      {isEditMode ? (
        <input
          ref={inputRef}
          className="link-input"
          value={linkUrl}
          onChange={(event) => {
            setLinkUrl(event.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              if (lastSelection !== null) {
                if (linkUrl !== "") {
                  editor.dispatchCommand(TOGGLE_LINK_COMMAND, linkUrl);
                }
                setEditMode(false);
              }
            } else if (event.key === "Escape") {
              event.preventDefault();
              setEditMode(false);
            }
          }}
        />
      ) : (
        <>
          <div className="link-input">
            <a href={linkUrl} target="_blank" rel="noopener noreferrer">
              {linkUrl}
            </a>
            <div
              className="link-edit"
              role="button"
              tabIndex={0}
              onMouseDown={(event) => event.preventDefault()}
              onClick={() => {
                setEditMode(true);
              }}
            />
          </div>
        </>
      )}
    </div>
  );
}

function Select({ onChange, className, options, value }) {
  return (
    <select className={className} onChange={onChange} value={value}>
      <option hidden={true} value="" />
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

function getSelectedNode(selection) {
  const anchor = selection.anchor;
  const focus = selection.focus;
  const anchorNode = selection.anchor.getNode();
  const focusNode = selection.focus.getNode();
  if (anchorNode === focusNode) {
    return anchorNode;
  }
  const isBackward = selection.isBackward();
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode;
  } else {
    return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
  }
}

function BlockOptionsDropdownList({
  editor,
  blockType,
  toolbarRef,
  setShowBlockOptionsDropDown,
}) {
  const dropDownRef = useRef(null);

  useEffect(() => {
    const toolbar = toolbarRef.current;
    const dropDown = dropDownRef.current;

    if (toolbar !== null && dropDown !== null) {
      const { top, left } = toolbar.getBoundingClientRect();
      dropDown.style.top = `${top + 40}px`;
      dropDown.style.left = `${left}px`;
    }
  }, [dropDownRef, toolbarRef]);

  useEffect(() => {
    const dropDown = dropDownRef.current;
    const toolbar = toolbarRef.current;

    if (dropDown !== null && toolbar !== null) {
      const handle = (event) => {
        const target = event.target;

        if (!dropDown.contains(target) && !toolbar.contains(target)) {
          setShowBlockOptionsDropDown(false);
        }
      };
      document.addEventListener("click", handle);

      return () => {
        document.removeEventListener("click", handle);
      };
    }
  }, [dropDownRef, setShowBlockOptionsDropDown, toolbarRef]);

  const formatParagraph = () => {
    if (blockType !== "paragraph") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createParagraphNode());
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatHeading1 = () => {
    if (blockType !== "h1") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode("h1"));
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatHeading2 = () => {
    if (blockType !== "h2") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode("h2"));
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatHeading3 = () => {
    if (blockType !== "h3") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode("h3"));
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatHeading4 = () => {
    if (blockType !== "h4") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode("h4"));
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatHeading5 = () => {
    if (blockType !== "h5") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode("h5"));
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatBulletList = () => {
    if (blockType !== "ul") {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND);
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatNumberedList = () => {
    if (blockType !== "ol") {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND);
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatCheckList = () => {
    if (blockType !== "check") {
      editor.dispatchCommand(INSERT_CHECK_LIST_COMMAND, undefined);
      // setBlockType("paragraph");
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND);
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatQuote = () => {
    if (blockType !== "quote") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createQuoteNode());
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatCode = () => {
    if (blockType !== "code") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createCodeNode());
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  return (
    <div className="dropdown" ref={dropDownRef}>
      <button className="item" onClick={formatParagraph}>
        <span className="icon paragraph" />
        <span className="text">Normal</span>
        {blockType === "paragraph" && <span className="active" />}
      </button>
      <div class="divider"></div>
      <button className="item" onClick={formatHeading1}>
        <span className="icon heading-1" />
        <span className="text">Heading 1</span>
        {blockType === "h1" && <span className="active" />}
      </button>
      <button className="item" onClick={formatHeading2}>
        <span className="icon heading-2" />
        <span className="text">Heading 2</span>
        {blockType === "h2" && <span className="active" />}
      </button>
      <button className="item" onClick={formatHeading3}>
        <span className="icon heading-3" />
        <span className="text">Heading 3</span>
        {blockType === "h3" && <span className="active" />}
      </button>
      <button className="item" onClick={formatHeading4}>
        <span className="icon heading-4" />
        <span className="text">Heading 4</span>
        {blockType === "h4" && <span className="active" />}
      </button>
      <button className="item" onClick={formatHeading5}>
        <span className="icon heading-5" />
        <span className="text">Heading 5</span>
        {blockType === "h5" && <span className="active" />}
      </button>
      <div class="divider"></div>
      <button className="item" onClick={formatBulletList}>
        <span className="icon bullet-list" />
        <span className="text">Bullet List</span>
        {blockType === "ul" && <span className="active" />}
      </button>
      <button className="item" onClick={formatNumberedList}>
        <span className="icon numbered-list" />
        <span className="text">Numbered List</span>
        {blockType === "ol" && <span className="active" />}
      </button>
      <button className="item" onClick={formatCheckList}>
        <span className="icon check-list" />
        <span className="text">Check List</span>
        {blockType === "check" && <span className="active" />}
      </button>
      <div class="divider"></div>
      <button className="item" onClick={formatQuote}>
        <span className="icon quote" />
        <span className="text">Quote</span>
        {blockType === "quote" && <span className="active" />}
      </button>
      <button className="item" onClick={formatCode}>
        <span className="icon code" />
        <span className="text">Code Block</span>
        {blockType === "code" && <span className="active" />}
      </button>
    </div>
  );
}

function AlignOptionsDropdownList({
  editor,
  alignType,
  // toolbarRef,
  setShowAlignOptionsDropDown,
}) {
  const aligndropDownRef = useRef(null);

  // useEffect(() => {
  //     const toolbar = toolbarRef.current;
  //     const aligndropDown = aligndropDownRef.current;

  //     if (toolbar !== null && aligndropDown !== null) {
  //         const { top, left } = toolbar.getBoundingClientRect();
  //         aligndropDown.style.top = `${top + 40}px`;
  //         aligndropDown.style.left = `${left}px`;
  //     }
  // }, [aligndropDownRef, toolbarRef]);

  // useEffect(() => {
  //     const aligndropDown = aligndropDownRef.current;
  //     const toolbar = toolbarRef.current;

  //     if (aligndropDown !== null && toolbar !== null) {
  //         const handle = (event) => {
  //             const target = event.target;

  //             if (!aligndropDown.contains(target) && !toolbar.contains(target)) {
  //                 setShowAlignOptionsDropDown(false);
  //             }
  //         };
  //         document.addEventListener("click", handle);

  //         return () => {
  //             document.removeEventListener("click", handle);
  //         };
  //     }
  // }, [aligndropDownRef, setShowAlignOptionsDropDown, toolbarRef]);

  const alignLeft = () => {
    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left");
    setShowAlignOptionsDropDown(false);
  };
  const alignCenter = () => {
    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "center");
    setShowAlignOptionsDropDown(false);
  };
  const alignRight = () => {
    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "right");
    setShowAlignOptionsDropDown(false);
  };
  const alignJustify = () => {
    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "justify");
    setShowAlignOptionsDropDown(false);
  };

  return (
    <div className="dropdown" ref={aligndropDownRef}>
      <button
        onClick={alignLeft}
        className="toolbar-item spaced"
        aria-label="Left Align"
      >
        {alignType === "left" && <span className="left" />}
        <i className="format left-align" />
      </button>
      <button
        onClick={alignCenter}
        className="toolbar-item spaced"
        aria-label="Center Align"
      >
        {alignType === "center" && <span className="center" />}
        <i className="format center-align" />
      </button>
      <button
        onClick={alignRight}
        className="toolbar-item spaced"
        aria-label="Right Align"
      >
        {alignType === "right" && <span className="right" />}
        <i className="format right-align" />
      </button>
      <button
        onClick={alignJustify}
        className="toolbar-item"
        aria-label="Justify Align"
      >
        {alignType === "justify" && <span className="active" />}
        <i className="format justify-align" />
      </button>
    </div>
  );
}

export default function ToolbarPlugin({
  setDocumentImgFile,
  setDocImgFileAltText,
  docImgFileAltText,
  handleUploadDocImage,
}) {
  const [editor] = useLexicalComposerContext();
  const toolbarRef = useRef(null);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [blockType, setBlockType] = useState("paragraph");
  const [selectedElementKey, setSelectedElementKey] = useState(null);
  const [showBlockOptionsDropDown, setShowBlockOptionsDropDown] =
    useState(false);
  const [showAlignOptionsDropDown, setShowAlignOptionsDropDown] =
    useState(false);
  const [showInsertDropDown, setShowInsertDropDown] = useState(false);
  const [codeLanguage, setCodeLanguage] = useState("");
  const [isRTL, setIsRTL] = useState(false);
  const [isLink, setIsLink] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);
  const [isCode, setIsCode] = useState(false);
  const [showImageOptions, setShowImageOptions] = useState(false);
  const [showFileImgModal, setShowFileImgModal] = useState(false);
  const [showLinkImgModal, setShowLinkImgModal] = useState(false);

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === "root"
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        setSelectedElementKey(elementKey);
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          setBlockType(type);
          if ($isCodeNode(element)) {
            setCodeLanguage(element.getLanguage() || getDefaultCodeLanguage());
          }
        }
      }
      // Update text format
      setIsBold(selection.hasFormat("bold"));
      setIsItalic(selection.hasFormat("italic"));
      setIsUnderline(selection.hasFormat("underline"));
      setIsStrikethrough(selection.hasFormat("strikethrough"));
      setIsCode(selection.hasFormat("code"));
      setIsRTL($isParentElementRTL(selection));

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }
    }
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, newEditor) => {
          updateToolbar();
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload);
          return false;
        },
        LowPriority
      )
    );
  }, [editor, updateToolbar]);

  const codeLanguges = useMemo(() => getCodeLanguages(), []);
  const onCodeLanguageSelect = useCallback(
    (e) => {
      editor.update(() => {
        if (selectedElementKey !== null) {
          const node = $getNodeByKey(selectedElementKey);
          if ($isCodeNode(node)) {
            node.setLanguage(e.target.value);
          }
        }
      });
    },
    [editor, selectedElementKey]
  );

  const insertLink = useCallback(() => {
    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, "https://");
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [editor, isLink]);

  function FillURLFigma() {
    const text = prompt("Enter the URL of the Figma video:", "");
    const match =
      /https:\/\/([\w.-]+\.)?figma.com\/(file|proto)\/([0-9a-zA-Z]{22,128})(?:\/.*)?$/.exec(
        text
      );
    if (match != null) {
      return match[3];
    }
    return null;
  }

  function FillURLYoutube() {
    const url = prompt("Enter the URL of the YouTube video:", "");

    const match =
      /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/.exec(url);

    const id = match ? (match?.[2].length === 11 ? match[2] : null) : null;

    if (id != null) {
      return id;
    }

    return null;
  }

  function FillColumns() {
    const columns = prompt("Enter the number of columns:", "");

    if (columns != null) {
      return columns;
    } else {
      return String(0);
    }
  }

  const onClick = (payload) => {
    editor.dispatchCommand(INSERT_TABLE_COMMAND, payload);
  };

  const Fill = () => {
    const rows = prompt("Enter the number of rows:", "");
    const columns = prompt("Enter the number of columns:", "");

    if (
      isNaN(Number(columns)) ||
      columns == null ||
      rows == null ||
      columns === "" ||
      rows === "" ||
      isNaN(Number(rows))
    ) {
      return;
    }

    onClick({ columns: columns, rows: rows });
  };

  const alignLeft = () => {
    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left");
    setShowAlignOptionsDropDown(false);
  };
  const alignCenter = () => {
    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "center");
    setShowAlignOptionsDropDown(false);
  };
  const alignRight = () => {
    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "right");
    setShowAlignOptionsDropDown(false);
  };
  const alignJustify = () => {
    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "justify");
    setShowAlignOptionsDropDown(false);
  };
  const alignIndent = () => {
    editor.dispatchCommand(INDENT_CONTENT_COMMAND, "indent");
    // setShowAlignOptionsDropDown(false);
  };
  const alignOutdent = () => {
    editor.dispatchCommand(OUTDENT_CONTENT_COMMAND, "indent");
    // setShowAlignOptionsDropDown(false);
  };

  const onImageConfirmClick = async () => {
    const url = await handleUploadDocImage();
    onImageConfirmDispatch({
      altText: docImgFileAltText,
      src: url,
    });
    setShowFileImgModal(false);
  };

  const onImageConfirmDispatch = (payload) => {
    editor.dispatchCommand(INSERT_IMAGE_COMMAND, payload);
  };

  return (
    <div className="toolbar" ref={toolbarRef}>
      <button
        disabled={!canUndo}
        onClick={() => {
          editor.dispatchCommand(UNDO_COMMAND);
        }}
        className="toolbar-item spaced"
        aria-label="Undo"
      >
        <i className="format undo" />
      </button>
      <button
        disabled={!canRedo}
        onClick={() => {
          editor.dispatchCommand(REDO_COMMAND);
        }}
        className="toolbar-item"
        aria-label="Redo"
      >
        <i className="format redo" />
      </button>
      <Divider />
      {supportedBlockTypes.has(blockType) && (
        <>
          <button
            className="toolbar-item block-controls"
            onClick={() =>
              setShowBlockOptionsDropDown(!showBlockOptionsDropDown)
            }
            aria-label="Formatting Options"
          >
            <span className={"icon block-type " + blockType} />
            <span className="text">{blockTypeToBlockName[blockType]}</span>
            <i className="chevron-down" />
          </button>
          {showBlockOptionsDropDown &&
            createPortal(
              <BlockOptionsDropdownList
                editor={editor}
                blockType={blockType}
                toolbarRef={toolbarRef}
                setShowBlockOptionsDropDown={setShowBlockOptionsDropDown}
              />,
              document.body
            )}
          <Divider />
        </>
      )}
      {blockType === "code" ? (
        <>
          <Select
            className="toolbar-item code-language"
            onChange={onCodeLanguageSelect}
            options={codeLanguges}
            value={codeLanguage}
          />
          <i className="chevron-down inside" />
        </>
      ) : (
        <>
          <button
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
            }}
            className={"toolbar-item spaced " + (isBold ? "active" : "")}
            aria-label="Format Bold"
          >
            <i className="format bold" />
          </button>
          <button
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
            }}
            className={"toolbar-item spaced " + (isItalic ? "active" : "")}
            aria-label="Format Italics"
          >
            <i className="format italic" />
          </button>
          <button
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
            }}
            className={"toolbar-item spaced " + (isUnderline ? "active" : "")}
            aria-label="Format Underline"
          >
            <i className="format underline" />
          </button>
          <button
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough");
            }}
            className={
              "toolbar-item spaced " + (isStrikethrough ? "active" : "")
            }
            aria-label="Format Strikethrough"
          >
            <i className="format strikethrough" />
          </button>
          <button
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, "code");
            }}
            className={"toolbar-item spaced " + (isCode ? "active" : "")}
            aria-label="Insert Code"
          >
            <i className="format code" />
          </button>
          <button
            onClick={insertLink}
            className={"toolbar-item spaced " + (isLink ? "active" : "")}
            aria-label="Insert Link"
          >
            <i className="format link" />
          </button>
          {isLink &&
            createPortal(<FloatingLinkEditor editor={editor} />, document.body)}
          <Divider />
          {/* <button
                        onClick={() => {
                            editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left");
                        }}
                        className="toolbar-item spaced"
                        aria-label="Left Align"
                    >
                        <i className="format left-align" />
                    </button>
                    <button
                        onClick={() => {
                            editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "center");
                        }}
                        className="toolbar-item spaced"
                        aria-label="Center Align"
                    >
                        <i className="format center-align" />
                    </button>
                    <button
                        onClick={() => {
                            editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "right");
                        }}
                        className="toolbar-item spaced"
                        aria-label="Right Align"
                    >
                        <i className="format right-align" />
                    </button>
                    <button
                        onClick={() => {
                            editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "justify");
                        }}
                        className="toolbar-item"
                        aria-label="Justify Align"
                    >
                        <i className="format justify-align" />
                    </button>{" "} */}
          <>
            <button
              className="toolbar-item align-controls"
              onClick={() =>
                setShowAlignOptionsDropDown(!showAlignOptionsDropDown)
              }
              aria-label="Align Options"
            >
              {/* <span className={"icon block-type " + blockType} /> */}
              {/* <span className="text">{blockTypeToBlockName[blockType]}</span> */}
              <i className="format left-align" />
              <i className="chevron-down" />
            </button>
            {showAlignOptionsDropDown && (
              <div className="dropdown-align">
                <button
                  onClick={alignLeft}
                  className="toolbar-item spaced"
                  aria-label="Left Align"
                >
                  <i className="format left-align" />
                </button>
                <button
                  onClick={alignCenter}
                  className="toolbar-item spaced"
                  aria-label="Center Align"
                >
                  <i className="format center-align" />
                </button>
                <button
                  onClick={alignRight}
                  className="toolbar-item spaced"
                  aria-label="Right Align"
                >
                  <i className="format right-align" />
                </button>
                <button
                  onClick={alignJustify}
                  className="toolbar-item"
                  aria-label="Justify Align"
                >
                  <i className="format justify-align" />
                </button>
                <div class="divider"></div>
                <button
                  onClick={alignIndent}
                  className="toolbar-item"
                  aria-label="Indent"
                >
                  <i className="format indent-align" />
                </button>
                <button
                  onClick={alignOutdent}
                  className="toolbar-item"
                  aria-label="Outdent"
                >
                  <i className="format outdent-align" />
                </button>
              </div>
            )}
            <Divider />
          </>
          <button
            className="toolbar-item insert-controls"
            onClick={() => setShowInsertDropDown(!showInsertDropDown)}
            aria-label="Align Options"
          >
            {/* <span className={"icon block-type " + blockType} /> */}
            {/* <span className="text">{blockTypeToBlockName[blockType]}</span> */}
            <i className="plus" />
            <i className="chevron-down" />
          </button>
          {showInsertDropDown && (
            <div className="dropdown-insert">
              <button
                onClick={() => setShowImageOptions(!showImageOptions)}
                className={"toolbar-item spaced "}
              >
                <i className="insert image" />
                <span className="text">Image</span>
                {/* <i className="chevron-down" /> */}
              </button>
              {showImageOptions && (
                <div className="toolbar-image-options">
                  <button
                    className="toolbar-item spaced "
                    onClick={() =>
                      setShowFileImgModal(!showFileImgModal) &
                      setShowInsertDropDown(false) &
                      setShowLinkImgModal(false)
                    }
                  >
                    Upload File
                  </button>
                  <button
                    className="toolbar-item spaced "
                    onClick={() =>
                      setShowLinkImgModal(!showLinkImgModal) &
                      setShowInsertDropDown(false) &
                      setShowFileImgModal(false)
                    }
                  >
                    From URL
                  </button>
                </div>
              )}
              <button
                onClick={() => {
                  editor.dispatchCommand(
                    INSERT_YOUTUBE_COMMAND,
                    FillURLYoutube()
                  );
                  setShowInsertDropDown(false);
                }}
                className={"toolbar-item spaced "}
              >
                <i className="insert youtube" />
                <span className="text">YouTube Video</span>
              </button>
              <button
                onClick={() => {
                  editor.dispatchCommand(INSERT_FIGMA_COMMAND, FillURLFigma());
                  setShowInsertDropDown(false);
                }}
                className={"toolbar-item spaced "}
              >
                <i className="figma" />
                <span className="text">Figma File</span>
              </button>
              <button
                onClick={() => {
                  editor.dispatchCommand(INSERT_COLLAPSIBLE_COMMAND, undefined);
                }}
                className={"toolbar-item spaced "}
              >
                <i className="insert collapsible" />
                <span className="text">Collapsible</span>
              </button>
              <button
                onClick={() => {
                  editor.dispatchCommand(
                    INSERT_HORIZONTAL_RULE_COMMAND,
                    undefined
                  );
                }}
                className={"toolbar-item spaced "}
              >
                <i className="insert horizontalrule" />
                <span className="text">Horizontal Line</span>
              </button>
              <button
                onClick={() => {
                  editor.dispatchCommand(INSERT_EXCALIDRAW_COMMAND, undefined);
                }}
                className={"toolbar-item spaced "}
              >
                <i className="insert excalidraw" />
                <span className="text">Excalidraw</span>
              </button>
              <button onClick={() => Fill()} className={"toolbar-item spaced "}>
                <i className="insert table" />
                <span className="text">Table</span>
              </button>
            </div>
          )}

          {showFileImgModal && (
            <div className="editor_modal">
              {/* <label for="image-upload" className="admin_image-upload">
                Upload
              </label> */}
              <div
                className="editor_modal-overlay"
                onClick={() => setShowFileImgModal(false)}
              ></div>
              <div
                className="editor_imagelink"
                onClick={(e) => e.stopPropagation()}
              >
                <input
                  type="file"
                  id="image-upload"
                  name="image-upload"
                  // className="userProfile__info-input"
                  // onChange={(e) => handleUploadImage(e)}
                  accept="image/*"
                  onChange={(e) => setDocumentImgFile(e.target.files[0])}
                />
                <input
                  type="text"
                  name="alttext"
                  placeholder="Alt Text"
                  onChange={(e) => setDocImgFileAltText(e.target.value)}
                />
                <button onClick={onImageConfirmClick}>Confirm</button>
              </div>
            </div>
          )}
          {showLinkImgModal && (
            <div className="editor_modal">
              <div
                className="editor_modal-overlay"
                onClick={() => setShowLinkImgModal(false)}
              ></div>
              <div
                className="editor_imagelink"
                onClick={(e) => e.stopPropagation()}
              >
                <input
                  type="url"
                  name="imagelink"
                  placeholder="Image Link"
                  autoFocus
                />
                <input type="text" name="alttext" placeholder="Alt Text" />
                <button
                  onClick={() =>
                    onImageConfirmClick({
                      altText: docImgFileAltText,
                      src: handleUploadDocImage(),
                    })
                  }
                >
                  Confirm
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
