import React, { useState } from "react";

import "./color-palette.styles.scss";

function ColorPalette({ returnColor, defColor }) {
  const [customColor, setCustomColor] = useState("");
  const [showButton, setShowButton] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    returnColor(e.target.value);
    setShowButton(false);
  };
  return (
    <div className="color-palette">
      <div
        className="palette_color color1"
        onMouseDown={() => returnColor("#3b3b3b")}
      ></div>
      <div
        className="palette_color color2"
        onMouseDown={() => returnColor("#89afb4")}
      ></div>
      <div
        className="palette_color color3"
        onMouseDown={() => returnColor("#d4745e")}
      ></div>
      <div
        className="palette_color color4"
        onMouseDown={() => returnColor("#eecd81")}
      ></div>
      <div
        className="palette_color color5"
        onMouseDown={() => returnColor("#bf94b2")}
      ></div>
      <div
        className="palette_color color6"
        onMouseDown={() => returnColor("#826860")}
      ></div>
      <div
        className="palette_color color7"
        onMouseDown={() => returnColor("#b887ac")}
      ></div>
      <div
        className="palette_color color8"
        onMouseDown={() => returnColor("#d1624f")}
      ></div>
      <div
        className="palette_color color9"
        onMouseDown={() => returnColor("#d6d85a")}
      ></div>
      <div
        className="palette_color color10"
        onMouseDown={() => returnColor("#39a7a4")}
      ></div>
      <div
        className="palette_color color11"
        onMouseDown={() => returnColor("#6c77a7")}
      ></div>
      <div
        className="palette_color color12"
        onMouseDown={() => returnColor("#f8df51")}
      ></div>
      <div
        className="palette_color color13"
        onMouseDown={() => returnColor("#e4a380")}
      ></div>
      <div
        className="palette_color color14"
        onMouseDown={() => returnColor("#dc8e8d")}
      ></div>
      <div
        className="palette_color color15"
        onMouseDown={() => returnColor("#6daed1")}
      ></div>
      <div
        className="palette_color color16"
        onMouseDown={() => returnColor("#727272")}
      ></div>
      <div
        className="palette_color color17"
        onMouseDown={() => returnColor("#daa4bb")}
      ></div>
      <div
        className="palette_color color18"
        onMouseDown={() => returnColor("#ebc194")}
      ></div>
      <div
        className="palette_color color19"
        onMouseDown={() => returnColor("#efe47f")}
      ></div>
      <div
        className="palette_color color20"
        onMouseDown={() => returnColor("#a4d1e4")}
      ></div>
      <div
        className="palette_color color21"
        onMouseDown={() => returnColor("#8dc2b0")}
      ></div>
      <div
        className="palette_color color22"
        onMouseDown={() => returnColor("#aec977")}
      ></div>
      <div
        className="palette_color color23"
        onMouseDown={() => returnColor("#7398c0")}
      ></div>
      <div
        className="palette_color color24"
        onMouseDown={() => returnColor("#b2b2b2")}
      ></div>
      <div
        className="palette_color color25"
        onMouseDown={() => returnColor("#aca1be")}
      ></div>
      <div
        className="palette_color color26"
        onMouseDown={() => returnColor("#c76573")}
      ></div>
      <div onClick={() => setShowButton(true)}>
        <input
          className="colorpicker_rounded"
          type="color"
          value={customColor}
          onChange={(e) => setCustomColor(e.target.value)}
        />
        {showButton && (
          <button className="colorpicker_btn" onMouseDown={handleSubmit}>
            ✓ Confirm Selection
          </button>
        )}
      </div>
      <button onClick={handleSubmit}>Custom</button>
      <button onClick={handleSubmit}>Clean</button>
    </div>
  );
}

export default ColorPalette;
