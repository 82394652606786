import * as React from "react"
const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={280} height={230} {...props}>
    <title>{"facial-hair/Full 2"}</title>
    <path
      fill="#000"
      fillRule="evenodd"
      d="M248.132 111.724c-3.49-1.142-6.918-2.472-10.362-3.752-17.61-8.846-38.88-10.45-57.347-3-9.064 3.656-17.516 10.225-26.611 14.53a80.856 80.856 0 0 1-6.54 2.151c-1.35-.47-2.7-.97-4.04-1.47-9.647-3.636-18.721-8.12-27.531-13.055a56.408 56.408 0 0 0-2.963-3.844c6.375-.905 12.617-2.409 18.264-3.96 17.34-4.75 33.68-12.49 50.96-17.45 16.09-4.63 33.55-7.16 49.71-1.41 7.67 2.73 14.42 7.51 21.88 10.73 3.86 1.66 8.49 3.29 13 3.53-2.91 6.97-6.59 13.48-11.62 18.9-2.3-.49-4.58-1.18-6.8-1.9Zm37.52-54.593c-1.543 1.283-7.915 5.423-9.982 7.891a78.52 78.52 0 0 1-1.593-.625c-.91-1.116-2.248-1.606-3.613-1.576-9.53-4.431-18.012-10.694-25.582-18.547-5.38-5.57-10.89-11.4-18.08-14.63-8.47-3.82-16.8-2.06-25.7-1.48-9.47.63-18.82-2.06-28.28-.77-6.92.95-13.4 3.88-19 8.01-11.43 8.43-19.31 20.27-28.97 30.48-10.96 11.58-24.44 18.98-39.43 22.76-.18-.12-.37-.24-.56-.35-11.05-10-21.03-21.29-29.86-33.32-8.62-11.75-31.35-64.64-36.84-69.28-2.28-1.93-4.78-2.76-7.17-2.69-4.33.1-8.33 3.11-10.12 7.85-3.04 8.06-1.52 18.48-1.28 26.91.3 10.32.98 20.63 2.06 30.9 1.07 10.15 2.49 20.27 4.26 30.32.89 5.08 1.88 10.15 2.97 15.2.34 1.59.77 3.01 1.29 4.33.53 2.49 1.1 4.97 1.71 7.43 3.98 16.11 10.26 32.24 22.25 44.13 12.48 12.39 29.44 20.62 45.19 27.98 15 7.01 30.72 13.24 46.85 18.12 14.01 6.35 29.01 11.37 44.07 14.01 16.58 2.91 34.01 3.09 50.02-2.67a88.1 88.1 0 0 0 22.06-11.7c9.57-4.97 18.18-11.8 25.23-20.84 12.627-16.202 18.654-35.863 21.291-56.136.675-5.189 3.213-25.076 3.412-28.172l1.934-29.998c.304-4.72-5.488-6.066-8.536-3.536Z"
    />
  </svg>
)
export default SvgComponent
