import { BlockWithAlignableContents } from "@lexical/react/LexicalBlockWithAlignableContents";
import { DecoratorBlockNode } from "@lexical/react/LexicalDecoratorBlockNode";
import * as React from "react";
function YouTubeComponent({ className, format, nodeKey, videoID }) {
  return React.createElement(
    BlockWithAlignableContents,
    { className: className, format: format, nodeKey: nodeKey },
    React.createElement("iframe", {
      width: "100%",
      height: "100%",
      src: `https://www.youtube.com/embed/${videoID}`,
      frameBorder: "0",
      allow:
        "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
      allowFullScreen: true,
      title: "YouTube video",
    })
  );
}
export class YouTubeNode extends DecoratorBlockNode {
  static getType() {
    return "youtube";
  }
  static clone(node) {
    return new YouTubeNode(node.__id, node.__format, node.__key);
  }
  static importJSON(serializedNode) {
    const node = $createYouTubeNode(serializedNode.videoID);
    node.setFormat(serializedNode.format);
    return node;
  }
  exportJSON() {
    return Object.assign(Object.assign({}, super.exportJSON()), {
      type: "youtube",
      version: 1,
      videoID: this.__id,
    });
  }
  constructor(id, format, key) {
    super(format, key);
    this.__id = id;
  }
  updateDOM() {
    return false;
  }
  getId() {
    return this.__id;
  }
  getTextContent(_includeInert, _includeDirectionless) {
    return `https://www.youtube.com/watch?v=${this.__id}`;
  }
  decorate(_editor, config) {
    const embedBlockTheme = config.theme.embedBlock || {};
    const className = {
      base: embedBlockTheme.base || "",
      focus: embedBlockTheme.focus || "",
    };
    return React.createElement(YouTubeComponent, {
      className: className,
      format: this.__format,
      nodeKey: this.getKey(),
      videoID: this.__id,
    });
  }
  isInline() {
    return false;
  }
}
export function $createYouTubeNode(videoID) {
  return new YouTubeNode(videoID);
}
export function $isYouTubeNode(node) {
  return node instanceof YouTubeNode;
}
