import React, { useState, useEffect } from "react";

import "../habits.styles.scss";

import Lottie from "lottie-react";
import Confetti from "../lottie/15108-confetti-banner.json";

export default function HabitAnimation({
  habit,
  animRefHabit,
  setanimRefHabit,
}) {
  const [showLineConfetti, setShowLineConfetti] = useState(false);

  useEffect(() => {
    if (habit.id == animRefHabit) {
      document.getElementById("habit-done_tone").play();
      setShowLineConfetti(true);
      setTimeout(() => {
        setShowLineConfetti(false);
      }, 1500);
    }
    setanimRefHabit("");
  }, [habit, animRefHabit]);

  // mixkit-fairy-arcade-sparkle-866.wav

  return (
    <div className="habitline-confetti">
      <audio
        id="habit-done_tone"
        src="/media/mixkit-fairy-arcade-sparkle-866.wav"
      ></audio>
      {showLineConfetti && <Lottie animationData={Confetti} />}
    </div>
  );
}
