import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import "./loading-page.styles.scss";

const LoadingPage = () => {
  return (
    <AnimatePresence>
      <motion.div
        className="loadingPage"
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
      >
        <div className="loadingPage__item">
          <div class="clockloader"></div>
        </div>
        <div className="loadingPage__logo loadingPage__item">
          <img
            classname="antLogy__logo"
            src="/antlogy_logo.png"
            height="40px"
            alt="ANTLOGY"
            style={{ objectFit: "cover" }}
          />
          {/* <h4 className="homeIcon">Everything</h4> */}
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default LoadingPage;
