import * as React from "react"
const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={280} height={230} {...props}>
    <title>{"facial-hair/Chin"}</title>
    <path
      fill="#000"
      fillRule="evenodd"
      d="M209.56 138.064c-29.593 4.348-51.013 3.45-64.26-2.696-19.81-9.19-39.08-18.081-57.05-30.254-18.41-12.472-34.03-27.281-45.18-46.238C32.7 41.237 24.08 22.52 15.4 3.948c-.507-1.085-5.49-11.874-7.25-15.492-2.71-5.61-10.633-3.85-10.633 4.86 0 8.708-.387 74.66 14.833 100.603 13.73 23.431 37 39.803 60.12 54.093 25.58 15.801 59.56 32.882 90.08 37.395 27.62 4.08 59.14-7.38 79.14-26.27 20.91-19.756 26.83-46.595 37.62-72.298 2.41-5.745-6.134-9.21-8.64-3.56-3.146 7.093-23.516 25.355-61.11 54.785Z"
    />
  </svg>
)
export default SvgComponent
