export const SET_MAIN_STREAM = "SET_MAIN_STREAM";
export const ADD_PARTICIPANT = "ADD_PARTICIPANT";
export const REMOVE_PARTICIPANT = "REMOVE_PARTICIPANT";
export const SET_USER = "SET_USER";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_PARTICIPANT = "UPDATE_PARTICIPANT";

// const meetActionTypes = {
//     SET_MAIN_STREAM = "SET_MAIN_STREAM";
//     ADD_PARTICIPANT = "ADD_PARTICIPANT";
//     REMOVE_PARTICIPANT = "REMOVE_PARTICIPANT";
//     SET_USER = "SET_USER";
//     UPDATE_USER = "UPDATE_USER";
//     UPDATE_PARTICIPANT = "UPDATE_PARTICIPANT";
//   };
  
//   export default meetActionTypes;
