import "./focus.component.scss";
import React, { useState, useEffect, useRef } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

import { db, fieldValue, setTimerOpen, timestamp } from "../../firebase/firebase.utils";

import { BsArrowClockwise, BsPause } from "react-icons/bs";

import FocusTask from "./focus-task.component";

import { useSelector } from "react-redux";

import moment from "moment";
import { current } from "@reduxjs/toolkit";

export default function FocusApp({ countdownRef, setCountdownVal, isOpen, handleTimerOpen }) {

  const assignedTasks = useSelector((state) => state.tasks.tasks);
  const spaceData = useSelector((state) => state.space.spaceData);
  const [taskName, setTaskName] = useState();
  //Variables
  const [timerType, setTimerType] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [sessionTime, setSessionTime] = useState(1500);
  const [breakTime, setBreakTime] = useState(300);
  const [restart, setRestart] = useState(0);

  const [assignedColTasks, setAssignedColTasks] = useState([]);
  const [assignedColSpaces, setAssignedColSpaces] = useState([]);

  const [currentTask, setCurrentTask] = useState(null);

  const [showWorkspaces, setShowWorkspaces] = useState(false);
  const [showWorkspaceTasks, setShowWorkspaceTasks] = useState(false);

  const [circleSize, setCircleSize] = useState(160);

  const currentUser = useSelector((state) => state.user.currentUser);

  const clockedTaskTime = useRef(0)

  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  )

  useEffect(() => {
    window
      .matchMedia("(min-width: 768px)")
      .addEventListener('change', e => setMatches(e.matches));
  }, []);


  // useEffect(() => {
  //   if (!currentUser.uid) return;
  //   var newtasks = db
  //     .collectionGroup("tasks")
  //     .where("assign", "==", currentUser.uid);
  //   newtasks.get().then((querySnapshot) => {
  //     let list = [];
  //     let listspace = [];
  //     querySnapshot.forEach((doc) => {
  //       // const now = new Date();
  //       // const nowtimestamp = Date.now();
  //       list.push(doc.data());
  //       listspace.push(doc.data().fromSpaceId);
  //     });
  //     setAssignedColTasks(list);
  //     setAssignedColSpaces(listspace);
  //   });

  // }, []);

  useEffect(() => {

    const interval = setInterval(() => {
      // if (moment(doc.data().startdate.toDate()).minute() === moment(new Date()).minute()) {
      assignedTasks.forEach((task) => {
        // if (task.startdate !== null & moment(task.startdate?.toDate()).format('LLL') === moment(new Date()).format('LLL')) {
        if (task.startdate !== null & moment.duration(moment(new Date()).diff(moment(task.startdate?.toDate()))).humanize() === "a minute") {
          // moment.duration(m2.diff(m1)).humanize(true)
          document.getElementById("person_notification").play();
          // if (!isOpen) { SetIsOpen(true) };
          if (!isOpen) { handleTimerOpen() };
          // setTaskName(task.title);
          setSessionTime(moment.duration(`00:${task.duration}`).asSeconds() * 60);
          setCurrentTask(task);
        }
      })

    }, 59000);

    return () => clearInterval(interval);

  }, [assignedTasks])


  // querySnapshot?.filter((t) => t.startdate === newDate())


  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      return <div className="clock">Time's Up!</div>;
    }
    const minutes = Math.floor(remainingTime / 60)
      .toString()
      .padStart(2, 0);
    const seconds = (remainingTime % 60).toString().padStart(2, 0);
    setCountdownVal(`${minutes}:${seconds}`);
    const clockedTimeRef = sessionTime - remainingTime;
    clockedTaskTime.current = clockedTimeRef
    return (
      <div className="clock__wrapper">
        <button onClick={() => setShowWorkspaces(!showWorkspaces)}>
          Focus on
        </button>
        {showWorkspaces && <div className="focus__taskselectmenu">{spaceData.map((space) => { const { spaceId, color, name } = space; return (<details key={spaceId}><summary>{name}</summary>{assignedTasks.filter((t) => t.fromSpaceId === spaceId).map((task) => { return <div key={task.id} onClick={() => setCurrentTask(task) & setShowWorkspaces(false)}>{task.title}</div> })}</details>) })}</div>}
        {currentTask?.title && <div className="timer__taskdetails"><h5>{currentTask?.title}</h5></div>}
        <span className="clock__title" id="timer-label">
          {!timerType ? "Time to work" : "Walk around/stretch"}
          {isPlaying ? " like an ant..." : ". Right?"}
        </span>
        <div className="clock__value" id="time-left">
          {minutes}:{seconds}
        </div>
      </div>
    );
  };

  const StartStop = () => {
    if (isPlaying) {
      return (
        <span className="btn__icon btn__stop material-icons" id="start_stop">
          stop
        </span>
      );
    } else {
      return (
        <span className="btn__icon btn__start material-icons" id="start_stop">
          start
        </span>
      );
    }
  };

  // const getCurrentDate = (separator = '') => {

  //   let newDate = new Date()
  //   let date = newDate.getDate();
  //   let month = newDate.getMonth() + 1;
  //   let year = newDate.getFullYear();

  //   return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`
  // }

  function startOfWeek(date) {
    var diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);

    return new Date(date.setDate(diff));

  }

  // console.log(startOfWeek(new Date()).toString());


  // function getMonday(d) {
  //   d = new Date(d);
  //   var day = d.getDay(),
  //     diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
  //   return new Date(d.setDate(diff));
  // }

  // getMonday(new Date());

  const handleSaveTracked = () => {
    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    //
    let weekstartday = startOfWeek(newDate).getDate();
    let weekstartmonth = startOfWeek(newDate).getMonth() + 1;
    let weekstartyear = startOfWeek(newDate).getFullYear();
    // Save the session time to TaskId;
    // db.collection("space")
    // .doc(spaceId)
    // .collection("stations")
    // .doc(stationId)
    // .update({
    //   description: newDesc,
    // });
    // var startOfWeek = moment().startOf('week').toDate();
    // var endOfWeek = moment().endOf('week').toDate();

    // Increment the total time in Space

    db.collection("space")
      .doc(currentTask.fromSpaceId)
      .collection("tracking")
      .doc(`${weekstartyear}-${weekstartmonth}`)
      .set({
        [weekstartday]: fieldValue.increment(sessionTime),
      },
        { merge: true });
  };

  // trackedBy: "oAh3WA2RC0hdSy1mQmgYthDTaPj1",

  const timelogsRef = db
    .collection("users")
    .doc(currentUser.uid)
    .collection("timelogs");

  //Functions

  const initiateClock = (e) => {
    const btn = e.target.className.includes("btn__start");
    if (btn) {
      setIsPlaying(true);
    } else {
      setIsPlaying(false);
      const btnStop = e.target.className.includes("btn__stop");
      if (btnStop & (currentTask !== null)) {
        timelogsRef.add({
          billable: false,
          duration: clockedTaskTime.current,
          fromSpaceId: currentTask.fromSpaceId,
          fromStationId: currentTask.fromStationId,
          logTime: fieldValue.serverTimestamp(),
          loggedBy: currentUser.uid,
          taskId: currentTask.id,
        })
      }
    }
  };

  const changeType = (e) => {
    const btn = e.target;
    if (btn.className.includes("btn__break")) {
      setTimerType(true);
      document
        .getElementById("btn__session")
        .classList.remove("btn__type--active");
      // document.body.style.transition = "none";
      document.getElementById('break_tone').play();
      document.body.style.background = "url('https://images.unsplash.com/photo-1505118380757-91f5f5632de0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2126&q=80') no-repeat 50% 50% / cover";
    } else {
      setTimerType(false);

      document
        .getElementById("btn__break")
        .classList.remove("btn__type--active");
      document.getElementById('focus_tone').play();
      document.body.style.background = "#fafafa";
    }
    setIsPlaying(false);
    setRestart((pre) => pre + 1);
    btn.classList.add("btn__type--active");
  };

  const changeTime = (e) => {
    const btnClass = e.target.className;
    const btnText = e.target.innerHTML;
    if (btnClass.includes("btn__sessionlength")) {
      if (btnText === "+" && sessionTime < 3541) {
        setSessionTime((pre) => pre + 60);
      } else if (btnText === "-" && sessionTime > 60) {
        setSessionTime((pre) => pre - 60);
      }
    } else if (btnClass.includes("btn__breaklength")) {
      if (btnText === "+" && breakTime < 3541) {
        setBreakTime((pre) => pre + 60);
      } else if (btnText === "-" && breakTime > 60) {
        setBreakTime((pre) => pre - 60);
      }
    } else {
      return;
    }
  };

  const complete = () => {
    if (timerType) {
      document
        .getElementById("btn__break")
        .classList.remove("btn__type--active");
      document
        .getElementById("btn__session")
        .classList.add("btn__type--active");
      document.getElementById('focus_tone').play();
      document.body.style.background = "#fafafa";
    } else {
      document
        .getElementById("btn__session")
        .classList.remove("btn__type--active");
      document.getElementById("btn__break").classList.add("btn__type--active");
      document.getElementById('break_tone').play();
      document.body.style.background = "url('https://images.unsplash.com/photo-1505118380757-91f5f5632de0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2126&q=80') no-repeat 50% 50% / cover";
    }
    setTimerType((pre) => !pre);
    setRestart((pre) => pre + 1);
  };

  const reset = () => {
    setRestart((pre) => pre + 1);
    setIsPlaying(false);
  };

  return (

    <div className="focus_container">
      <div className="focus_wrapper">
        <div className="focus_clock">
          <CountdownCircleTimer
            key={restart}
            isPlaying={isPlaying}
            duration={timerType ? breakTime : sessionTime}
            colors={["#ebebeb"]}
            onComplete={() => {
              complete();
              return { shouldRepeat: true, delay: 1.5 };
            }}
            size={matches ? 160 : 240}
            strokeWidth={5}
            trailStrokeWidth={4}
            strokeLinecap={"square"}
            rotation={"clockwise"}
            trailColor={"black"}
          >
            {renderTime}
          </CountdownCircleTimer>
        </div>
        <div className="focus_control">
          <button
            className="btn btn__control btn__startstop"
            onClick={initiateClock}
          >
            <StartStop />
          </button>
          {/* {currentTask && <button onClick={handleSaveTracked}>
            Save
          </button>} */}
          <button className="btn btn__control btn__reset" onClick={reset}>
            <span className="btn__icon material-icons" id="reset">
              <BsArrowClockwise size="1.2em" />
            </span>
          </button>
        </div>
        <div className="focus_setup">
          <div className="setup__type">
            <button
              className="btn btn__type btn__session btn__type--active"
              id="btn__session"
              onClick={changeType}
            >
              Focus
            </button>
            <button
              className="btn btn__type btn__break"
              id="btn__break"
              onClick={changeType}
            >
              Break
            </button>
          </div>
          <div className="setup__length">
            <p className="length__title" id="session-label">
              Focus Time
            </p>
            <div className="length__config length__session">
              <button
                className="btn btn__sessionlength btn__action btn__subtract"
                id="break-decrement"
                onClick={changeTime}
              >
                -
              </button>
              <span className="length__time" id="session-length">
                {sessionTime / 60}
              </span>
              <button
                className="btn btn__sessionlength btn__action btn__add"
                id="break-increment"
                onClick={changeTime}
              >
                +
              </button>
            </div>
          </div>
          <div className="setup__length">
            <p className="length__title" id="break-label">
              Break Time
            </p>
            <div className="length__config length__break">
              <button
                className="btn btn__breaklength btn__action btn__subtract"
                id="session-decrement"
                onClick={changeTime}
              >
                -
              </button>
              <span className="length__time" id="break-length">
                {breakTime / 60}
              </span>
              <button
                className="btn btn__breaklength btn__action btn__add"
                id="break-increment"
                onClick={changeTime}
              >
                +
              </button>
            </div>
          </div>
          {/* <div>
            <FocusTask setIsPlaying={setIsPlaying} isPlaying={isPlaying} setTaskName={setTaskName} setSessionTime={setSessionTime} />
          </div> */}

          {/* <button onClick={() => countdownRef(renderTime)}>ref</button> */}
          <audio
            id="focus_tone"
            src="/media/mixkit-magic-astral-sweep-effect-2629.wav"
          ></audio>
          <audio
            id="break_tone"
            src="/media/mixkit-intro-transition-1146.wav"
          ></audio>
          <audio
            id="person_notification"
            src="/media/mixkit-guitar-notification-alert-2320.wav"
          ></audio>
        </div>
      </div>
    </div>
  );
}
