import * as React from "react"
const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={392} height={138} {...props}>
    <title>{"accessories/Glasses 5"}</title>
    <path
      fill="#000"
      fillRule="evenodd"
      d="M367.09 74.432c-2.828 7.696-7.132 15.708-12.395 22.185-21.294 18.032-41.986 8.048-52.491-16.39-3.993-17.272 6.606-35.876 17.837-48.58 16.22-16.258 42.692-7.726 50.15 11.6.453 2.504.727 5.002.858 7.492-.155 8.091-1.13 15.006-3.96 22.693m-204.68-14.52c28.053-33.368 80.532-35.152 79.194 16.203-6.026 65.004-107.498 49.05-79.194-16.204Zm213.504-27.836a46.738 46.738 0 0 0-3.266-4.817c-.44-.262-.692-2.586-2.31-2.758-21.627-23.859-59.62-10.322-73.349 13.452-2.507 3.006-4.551 6.525-6.06 10.243-13.526-2.48-29.294-1.999-41.874 3.11-12.475-32.572-50.24-35.48-75.126-21.563-15.48 7.613-25.517 23.556-40.57 32.314-35.39 2.547-73.37-5.382-108.12 3.495-27.407 5.698-4.497 19.575.1 10.314 32.762-9.68 71.578-1.5 105.594-3.68 3.538 5.982 10.32 10.878 17.08 10.67 7.302 64.72 101.685 53.988 104.941-3.545 3.694-5.839 8.287-11.427 14.86-15.065 2.517-.52 4.949-1.198 7.17-2.246 3.798-.536 9.65 1.074 13.144 4.236 11.97 71.816 80.924 63.452 93.248.538.245-.25.93-1.188 1.455-2.693 4.219-5.577 14.545-7.65 12.894-17.065-1.469-9.417-13.36-9.305-19.811-14.94Z"
    />
  </svg>
)
export default SvgComponent
