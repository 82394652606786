import React from "react";
import Navbar from "../Nav/Navbar";
import Footer from "../Nav/Footer";
import { Outlet } from "react-router-dom";

const LayoutFront = () => {
  return (
    <div className="app_frontend">
      {/* <Navbar /> */}
      <Outlet />
      <Footer />
    </div>
  );
};

export default LayoutFront;
