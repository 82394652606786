import React from "react";
import { useSelector } from "react-redux";
import Card from "../../Shared/Card/Card.component";
// import { faMicrophoneSlash } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FaMicrophoneSlash,
} from "react-icons/fa";
import "./Participant.css";

export const Participant = (props) => {
  const currentUserRef = useSelector((state) => state.user.currentUser);
  // const { userName } = currentUser;
  const { imageUrl } = currentUserRef;
  const userAvatarRef = imageUrl;

  const {
    curentIndex,
    currentParticipant,
    hideVideo,
    videoRef,
    showAvatar,
    currentUser,
  } = props;
  if (!currentParticipant) return <></>;
  return (
    <div className={`meet_participant ${hideVideo ? "meet_hide" : ""}`}>
      <Card>
        <video
          ref={videoRef}
          className="meet_video"
          id={`participantVideo${curentIndex}`}
          autoPlay
          playsInline
        ></video>
        {!currentParticipant.audio && (
          // <FontAwesomeIcon
          //   className="muted"
          //   icon={faMicrophoneSlash}
          //   title="Muted"
          // />
          <FaMicrophoneSlash className="meet_muted" title="Muted" />
        )}
        {showAvatar && (<>
          {userAvatarRef ? <div
            style={{ background: currentParticipant.avatarColor }}
            className="meet_avatar"
          >
            <img style={{ height: "80px", width: "80px", borderRadius: "50%" }} alt="" src={userAvatarRef} />
            {/* {currentParticipant.name[0]} */}
          </div> : <div
            style={{ background: currentParticipant.avatarColor }}
            className="meet_avatar"
          >
            {currentParticipant.name[0]}
          </div>}
        </>

        )}
        <div className="meet_name">
          {currentParticipant.name}
          {currentUser ? "(You)" : ""}
        </div>
      </Card>
    </div>
  );
};
