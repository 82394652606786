import React from "react";
import { useRef } from "react";

import { useInView } from "framer-motion";

import Lottie from "lottie-react";

import WorkingTogether from "./lottie/WorkingTogether.json";
import Calendar from "./lottie/Calendar.json";
import PieChartViz from "./lottie/PieChartViz.json";
import Folder from "./lottie/Folder.json";
import Chess from "./lottie/Chess.json";
import ChatFriends from "./lottie/ChatFriends.json";

import BoardVisual from "../../../assets/illustration/undraw_scrum_board_re_wk7v.svg";
import CalendarVisual from "../../../assets/illustration/undraw_calendar_re_ki49.svg";
import GanttVisual from "../../../assets/illustration/undraw_performance_overview_re_mqrq.svg";
import NotesVisual from "../../../assets/illustration/undraw_notebook_re_id0r.svg";
import FilesVisual from "../../../assets/illustration/undraw_folder_re_apfp.svg";
import ChatVisual from "../../../assets/illustration/undraw_development_re_g5hq.svg";
import BreakVisual from "../../../assets/illustration/undraw_conversation_re_c26v.svg";

import { motion } from "framer-motion";

import "./collaboration.scss";

import images from "../images";

const CollabFeatures = [
  {
    Header: "Board & List Views",
    Content:
      "View your entire project on a team board. Tasks can be organized in column dragged between columns that represent different phases. Agile Management, Kanban, techniques can be applied..",
    Visual: BoardVisual,
  },
  {
    Header: "Project Calendar",
    Content:
      "Manage all the tasks of your team from one calendar. You can also view your google calendar events. Daily, 3-Days, Weekly and Monthly views are supported.",
    Visual: CalendarVisual,
  },
  {
    Header: "Gantt View & Roadmap",
    Content:
      "See the tasks of a project on a timeline and update the relational tasks automatically. Also, see the big picture on timeline by adding start & end dates to projects with ease.",
    Visual: GanttVisual,
  },
  {
    Header: "Project Files",
    Content:
      "We currently support Google Drive integration. You can set a list/grid view of a drive folder. Then you can reach your files with the convenience of a single click from inside the project.",
    Visual: FilesVisual,
  },
  {
    Header: "Project Notes",
    Content:
      "With Antlogy, all your project notes and related information can live in the same place. So team members can reach them with ease. In a way it's like simplified google docs, but also with additional features.",
    Visual: NotesVisual,
  },
  {
    Header: "Chat & Meetings",
    Content:
      "Either chat with your project team, with your entire workspace members or with a singular person from the same workspace. You can also setup video meetings. It comes with integrated communication tools and features optimized for teams and individuals who’d like to use them for customer management.",
    Visual: ChatVisual,
  },
  {
    Header: "Need a break?",
    Content:
      "If you use the focus timer, it'll remind you to give a break and to continue working. Inside the app, you can even play some chess with a colleague or with the computer (easy-mode)",
    Visual: BreakVisual,
  },
];

function LefttoRightFadeIn({ children }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <section ref={ref}>
      <span
        style={{
          transform: isInView ? "none" : "translateX(-500px)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
        }}
      >
        {children}
      </span>
    </section>
  );
}

function FadeInToLeft({ children }) {
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 1 }}
      variants={{
        visible: { opacity: 1, translateX: "0px" },
        hidden: { opacity: 0, translateX: "200px" },
      }}
      // className="collab__visual"
    >
      {children}
    </motion.div>
  );
}

function FadeInToRight({ children }) {
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 1 }}
      variants={{
        visible: { opacity: 1, translateX: "0px" },
        hidden: { opacity: 0, translateX: "-200px" },
      }}
      className="collab__visual"
    >
      {children}
    </motion.div>
  );
}

function FadeInWhenVisible({ children }) {
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 1 }}
      variants={{
        visible: { opacity: 1, scale: 1 },
        hidden: { opacity: 0, scale: 0 },
      }}
    >
      {children}
    </motion.div>
  );
}

const LINE_VARIANTS = {
  visible: { height: "50vh", transition: { duration: 3 } },
  hidden: { height: "0vh" },
};

const LineElement = (
  <div
    style={{
      position: "absolute",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "2px",
      // zIndex: 20,
      pointerEvents: "none",
    }}
  >
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      // animate="visible"
      variants={LINE_VARIANTS}
      style={{ backgroundColor: "#C4C6C9", width: 2, height: "0.25vh" }}
    />
  </div>
);

export default function SectionCollaboration() {
  const interactivity = {
    mode: "scroll",
    actions: [
      // {
      //   visibility: [0, 0.2],
      //   type: "stop",
      //   frames: [0],
      // },
      {
        visibility: [0, 1],
        type: "seek",
        frames: [0, 150],
      },
      // {
      //   visibility: [0.45, 1.0],
      //   type: "loop",
      //   frames: [120, 150],
      // },
    ],
  };

  return (
    <section id="collaboration" className="sec__collaboration">
      <div className="sec__collaboration-header">
        <h2>Team Features</h2>
        <h3>Collaboration made easy</h3>
      </div>
      <div className="sec__collaboration-features">
        {CollabFeatures.map((collabfeature) => {
          const { Header, Content, Visual } = collabfeature;
          return (
            <div className="sec_collab" key={Header}>
              <div className="sec_collab-card">
                <div className="sec_collab-cardHeader">
                  <h4>{Header}</h4>
                </div>
                <div>
                  <p>{Content}</p>
                </div>
              </div>
              <div
                style={{
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "2px",
                  // zIndex: 20,
                  pointerEvents: "none",
                }}
                className="sec_collab-middleline"
              >
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  // animate="visible"
                  variants={LINE_VARIANTS}
                  style={{
                    backgroundColor: "#D2D2D2",
                    // backgroundColor: "#C4C6C9",
                    width: "2px",
                    height: "0.3vh",
                  }}
                />
              </div>
              <FadeInToRight className="sec_collab-visual">
                <img src={Visual} width="350" height="200" />
              </FadeInToRight>
            </div>
          );
        })}
      </div>
      <div className="sec_collab-yayy">
        <FadeInWhenVisible>
          <Lottie
            interactivity={interactivity}
            animationData={WorkingTogether}
          />
        </FadeInWhenVisible>
      </div>
    </section>
  );
}
