import React, { useState, useEffect } from "react";
import {
  db,
  updateFolderDrag,
  fieldValue,
} from "../../firebase/firebase.utils";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate, Link } from "react-router-dom";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import {
  setFolderData,
  setStationData,
  setModules,
  setActiveModules,
} from "../../redux/space/space.actions";
import { setSpaceId, setStationId } from "../../redux/history/history.actions";

import { useActiveSpaceData } from "../../hooks/useActiveSpaceData.hook";

import RetroButton from "../retro/button/retro-button.component";
import StationItem from "../station-item/station-item.component.class";
import FolderItem from "../folder-item/folder-item.component.class";
import CreateStation from "../create-station/create-station.component";
import CreateFolder from "../create-folder/create-folder.component";

import SharedStationItem from "../station-item/shared-station-item";

import { ContextMenu } from "../context-menu/ContextMenu";
import { contextMenuItemsFolder } from "../context-menu/menus/contextMenuItemsFolder";

import { BsGripVertical } from "react-icons/bs";

import InvitetoPremium from "../marketing/invite-to-premium.component";
import useEntitlement from "../../hooks/useMembership";

import "./stations.styles.scss";

const Stations = () => {
  const activeSpaceData = useActiveSpaceData();
  const stationData = useSelector((state) => state.space.stationData);
  const folderData = useSelector((state) => state.space.folderData);
  const [createStation, setCreateStation] = useState(false);

  const [createFolder, setCreateFolder] = useState(false);

  const history = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const activeSpaceId = location.pathname.split("/")[2];
  const activeStationId = location.pathname.split("/")[4];

  const currentUser = useSelector((state) => state.user.currentUser);
  const { entitlement, loading } = useEntitlement();
  console.log("ENTITLEMENT is,", entitlement);

  const [state, setState] = useState([]);

  const [folders, setFolders] = useState();

  const [fdopen, setFdOpen] = useState(false);

  const [showInvitation, setShowInvitation] = useState(false);

  const [isOpen, SetIsOpen] = useState(true);
  const [x, setX] = useState(0);

  const [sharedPrSpaces, setSharedPrSpaces] = useState([]);

  const [activeItem, setActiveItem] = useState("");
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({
    top: 0,
    left: 0,
  });

  const onRightClick = (event, folder) => {
    event.preventDefault();
    const top = event.clientY;
    const left = event.clientX;

    setActiveItem(folder);
    setShowContextMenu(true);
    setContextMenuPosition({ top, left });
  };

  const onContextMenuItemClick = (menuItemLabel) => {
    switch (menuItemLabel) {
      case "Delete":
        console.log("clicled Delete");
        console.log(activeItem.id);
        // setListData(listData.filter((item) => item.id !== activeItem));
        // activeItem.id
        const inFolderItems = activeItem.items;

        if (inFolderItems.length > 0) {
        inFolderItems.forEach((projectId) => {
          db.collection("space")
          .doc(activeSpaceId)
          .collection("stations")
          .doc(projectId)
          .update({folder: null})
        });
      }
        db.collection("space")
        .doc(activeSpaceId)
        .collection("folders")
        .doc(activeItem.id)
        .delete();
        break;
      case "Copy":
        console.log("Copy done");
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const closeContextMenu = () => setShowContextMenu(false);
    window.addEventListener("click", closeContextMenu);
    return () => window.removeEventListener("click", closeContextMenu);
  }, [activeItem]);

  // useEffect(() => {
  //   if (isOpen) {
  //     setX(0);
  //   } else {
  //     setX("-220px");
  //   }
  // }, [isOpen]);

  useEffect(() => {
    const spaceValues = [];

    currentUser?.sharedWith?.forEach((space) => {
      spaceValues.push(space.fromSpaceId);
    });
    setSharedPrSpaces(spaceValues);

    const getSharedProjectSpaces = async () => {
      let listDocs = [];
      const ref = db.collection("space").where("spaceId", "in", spaceValues);
      const docs = await ref.get();
      docs.forEach((doc) => {
        listDocs.push(doc.data());
      });
      setSharedPrSpaces(listDocs);
    };
    // call the function
    getSharedProjectSpaces()
      // make sure to catch any error
      .catch(console.error);
  }, [activeSpaceId]);

  useEffect(() => {
    if (isOpen) {
      setX(0);
    } else {
      setX("-220px");
    }
  }, [isOpen]);

  useEffect(() => {
    db.collection("space")
      .doc(activeSpaceId)
      .collection("stations")
      .orderBy("name", "asc")
      // .where("folder", "==", null)
      .onSnapshot((querySnapshot) => {
        let docs = [];
        querySnapshot.forEach((doc) => {
          docs.push({ id: doc.id, ...doc.data() });
        });
        dispatch(setStationData(docs));
      });
    if (activeSpaceId) {
      dispatch(setSpaceId(activeSpaceId));
    }
  }, [activeSpaceId, dispatch]);

  useEffect(() => {
    db.collection("space")
      .doc(activeSpaceId)
      .collection("folders")
      .orderBy("name", "asc")
      .onSnapshot((querySnapshot) => {
        let docs = [];
        querySnapshot.forEach((doc) => {
          docs.push({
            id: doc.id,
            folderitems: doc.data().items,
            ...doc.data(),
          });
        });
        dispatch(setFolderData(docs));
      });
    if (activeSpaceId) {
      dispatch(setSpaceId(activeSpaceId));
    }
  }, [activeSpaceId, dispatch]);

  useEffect(() => {
    if (activeStationId) {
      db.collection("space")
        .doc(activeSpaceId)
        .collection("stations")
        .doc(activeStationId)
        .collection("modules")
        .doc("modules")
        .onSnapshot((doc) => {
          if (doc.exists) {
            const modules = doc.data().modules;
            const filterData = modules.filter((item) => item.active === true);
            dispatch(setModules(modules));
            dispatch(setActiveModules(filterData));
          }
        });
      dispatch(setStationId(activeStationId));
    }
  }, [activeStationId, activeSpaceId, dispatch]);

  // UPDATE DATABASE FOLDERS ON DRAG

  // UPDATE STATE AND TRIGGER DB UPDATE ON DRAG

  const onDragEnd = (result, folders, setFolders) => {
    if (!result.destination) return;
    const { source, destination, draggableId } = result;

    if (source.droppableId !== destination.droppableId) {
      // const sourceFolder = folders[source.droppableId];
      // const destFolder = folders[destination.droppableId];
      // const sourceItems = [...sourceFolder.items];
      // const destItems = [...destFolder.items];
      // const [removed] = sourceItems.splice(source.index, 1);
      // destItems.splice(destination.index, 0, removed);
      // setFromFolder(sourceItems);
      // setIntoFolder(destItems);
      // setFolders({
      //   ...folders,
      //   [source.droppableId]: {
      //     ...sourceFolder,
      //     items: sourceItems,
      //   },
      //   [destination.droppableId]: {
      //     ...destFolder,
      //     items: destItems,
      //   },
      // });
      db.collection("space")
        .doc(activeSpaceId)
        .collection("folders")
        .doc(source.droppableId)
        .update({ items: fieldValue.arrayRemove(draggableId) });
      db.collection("space")
        .doc(activeSpaceId)
        .collection("folders")
        .doc(destination.droppableId)
        .update({ items: fieldValue.arrayUnion(draggableId) });
    }
    if (
      source.droppableId !== destination.droppableId &&
      source.droppableId === activeSpaceId
    ) {
      db.collection("space")
        .doc(activeSpaceId)
        .collection("stations")
        .doc(draggableId)
        .update({ folder: destination.droppableId });
      db.collection("space")
        .doc(activeSpaceId)
        .collection("folders")
        .doc(destination.droppableId)
        .update({ items: fieldValue.arrayUnion(draggableId) });
    }
    if (
      source.droppableId !== destination.droppableId &&
      destination.droppableId === activeSpaceId
    ) {
      db.collection("space")
        .doc(activeSpaceId)
        .collection("stations")
        .doc(draggableId)
        .update({ folder: null });
      db.collection("space")
        .doc(activeSpaceId)
        .collection("folders")
        .doc(destination.droppableId)
        .update({ items: fieldValue.arrayRemove(draggableId) });
    } else {
      const folder = folders[source.droppableId];
      const copiedItems = [...folder.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setFolders({
        ...folders,
        [source.droppableId]: {
          ...folder,
          items: copiedItems,
        },
      });
      // updateFolderDrag(folders);
    }
  };

  const handleFdClick = () => {
    setFdOpen(!fdopen);
  };

  return (
    <div className="stations">
      <h2
        className="stations__spacename"
        style={{ textDecoration: `underline 2px ${activeSpaceData?.color}` }}
      >
        {activeSpaceData?.name}
      </h2>
      <DragDropContext
        onDragEnd={(result) => onDragEnd(result, folders, setFolders)}
      >
        {folderData?.map((folder, index) => {
          return (
            <div
              key={folder?.id}
              onClick={handleFdClick}
              onContextMenu={(event) => onRightClick(event, folder)}
              className="st_singularFolder"
            >
              <FolderItem data={folder} />
            </div>
          );
        })}
        {showContextMenu && (
          <ContextMenu
            top={contextMenuPosition.top}
            left={contextMenuPosition.left}
            menuItems={contextMenuItemsFolder}
            onMenuItemClick={onContextMenuItemClick}
          />
        )}
        <div>
          <Droppable droppableId={activeSpaceId} key={activeSpaceId}>
            {(provided, snapshot) => {
              return (
                <div
                  className="mainProjectsFolder"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {stationData?.map((station) => {
                    return (
                      <Draggable
                        key={station.id}
                        draggableId={station.id}
                        // index={station.index}
                      >
                        {(provided, snapshot) => {
                          return (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <div
                                // className="stations__item"
                                className={
                                  station.stationId === activeStationId
                                    ? "stations__item-active"
                                    : "stations__item"
                                }
                                key={station.stationId}
                                style={{
                                  display:
                                    station.folder == null ? "flex" : "none",
                                }}
                              >
                                <div
                                  className="project__drag"
                                  {...provided.dragHandleProps}
                                >
                                  <BsGripVertical />
                                </div>
                                <StationItem data={station} />
                              </div>
                            </div>
                          );
                        }}
                      </Draggable>
                    );
                  })}
                </div>
              );
            }}
          </Droppable>
        </div>
      </DragDropContext>
      <div>
        {currentUser?.sharedWith
          ?.filter((prj) => prj.shortcutSpace === activeSpaceId)
          .map((project) => {
            return (
              // <PrMember
              //   key={member.uid}
              //   member={member}
              //   activeSpaceData={activeSpaceData}
              //   activeSpaceId={activeSpaceId}
              // />
              <div key={project.stationId}>
                <div className="stationItem">
                  <SharedStationItem project={project} shortcut={true} />
                </div>
              </div>
            );
          })}
      </div>
      <div className="stations__createStation">
        {currentUser.plan === "Free" ? (
          <RetroButton
            mode="flat"
            color="info"
            onClick={() => setShowInvitation(!showInvitation)}
          >
            {createFolder ? "Cancel" : "Create new folder"}
          </RetroButton>
        ) : (
          <RetroButton
            mode="flat"
            color="info"
            onClick={() => setCreateFolder(!createFolder)}
          >
            {createFolder ? "Cancel" : "Create new folder"}
          </RetroButton>
        )}
        {showInvitation && (
          <InvitetoPremium
            setShowInvitation={setShowInvitation}
            featureName={"Creating Folders"}
            featureDesc={"organize your projects and ideas efficiently"}
            featureImg={
              "https://images.pexels.com/photos/4792282/pexels-photo-4792282.jpeg"
            }
            // featureImg={
            //   "https://images.unsplash.com/photo-1557487307-8dc8ec048eb8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
            // }
            currentUser={currentUser}
          />
        )}
      </div>
      {createFolder && (
        <CreateFolder
          setCreateFolder={setCreateFolder}
          activeSpaceId={activeSpaceId}
        />
      )}
      <div className="stations__createStation">
        <RetroButton
          mode="flat"
          color="info"
          onClick={() => setCreateStation(!createStation)}
        >
          {createStation ? "Cancel" : "Create new project"}
        </RetroButton>
      </div>
      {createStation && (
        <CreateStation
          setCreateStation={setCreateStation}
          activeSpaceId={activeSpaceId}
        />
      )}
      {activeSpaceId === "sharedwithme" && (
        <div>
          {sharedPrSpaces.map((space) => {
            return (
              <>
                <details key={space.spaceId}>
                  <summary>{space.name}</summary>
                  {currentUser?.sharedWith?.map((project) => {
                    return (
                      // <PrMember
                      //   key={member.uid}
                      //   member={member}
                      //   activeSpaceData={activeSpaceData}
                      //   activeSpaceId={activeSpaceId}
                      // />
                      <div key={project.stationId}>
                        <div className="stationItem">
                          <Link
                            to={`/s/${project.fromSpaceId}/e/${project.stationId}/b`}
                          >
                            <RetroButton>
                              {/* <BsListCheck size="1.2em" /> */}
                              {/* <div className="EmojiIcon">{data.emojicon}</div> */}
                              <a>{project.stationId}</a>
                            </RetroButton>
                          </Link>
                        </div>
                      </div>
                    );
                  })}
                </details>
              </>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Stations;
