import React, { useState } from "react";
import "../webadmin.styles.scss";

const EmailEditor = () => {
  const [sendto, setSendto] = useState(null);
  const [from, setFrom] = useState(null);
  return (
    <div className="admin_emailform">
      <h3>Email Form</h3>
      <select onChange={(e) => setFrom(e.target.value)}>
        <option value="hello@antlogy.com">hello@antlogy.com</option>
        <option value="support@antlogy.com">support@antlogy.com</option>
        <option value="notifications@antlogy.com">
          notifications@antlogy.com
        </option>
      </select>
      sending from: {from}
      <form>
        <input placeholder="to"></input>
        <textarea></textarea>
        <button>Send</button>
      </form>
    </div>
  );
};

export default EmailEditor;
