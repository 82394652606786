import React, { useState, useEffect } from "react";

import { Picker } from "emoji-mart";
import RetroButton from "../../retro/button/retro-button.component";

import { db, fieldValue } from "../../../firebase/firebase.utils";

import { datetime, RRule, RRuleSet, rrulestr } from "rrule";

import moment from "moment";

import Recurring from "../../recurring/recurring.component";

import ActivityCalendar, { ThemeInput } from "react-activity-calendar";

import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

import { BsPlusLg, BsLink } from "react-icons/bs";
import { TfiLink, TfiUnlink } from "react-icons/tfi";

import {
  productivityHabits,
  growthHabits,
  healthHabits,
  homeHabits,
  financialHabits,
  socialHabits,
  selfcareHabits,
} from "./habit.templates";

import "./habit.styles.scss";

export default function Habit({
  currentUser,
  habit,
  habitIcon,
  setHabitIcon,
  habitName,
  setHabitName,
  setRelatedSpaceId,
  setRelatedStationId,
  setRefRRule,
  step,
  setStep,
  steps,
  setSteps,
  unit,
  setUnit,
  handleCloseHabit,
  handleSaveHabit,
  handleDeleteHabit,
  habitmodalMode,
  setSelectedDate,
}) {
  //   const [habitName, setHabitName] = useState("");

  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  // const [selectedDate, setSelectedDate] = useState(`${year}-${month}-${date}`);

  const [isOpen, setIsOpen] = useState(false);
  const [refrruleText, setRefRruleText] = useState("");

  const [habitHistory, setHabitHistory] = useState([]);

  const [isLinked, setIsLinked] = useState("");

  const [initialRRule, setInitialRRule] = useState(
    new RRule({ freq: RRule.DAILY, interval: 1 })
  );
  const [modifiedRRule, setModifiedRRule] = useState(initialRRule);

  const [rruleString, setRruleString] = useState("");

  useEffect(() => {
    if (!habit) return;
    if (habitmodalMode === "add") return;
    if (habit.refrrule) {
      const currentRrule = rrulestr(habit.refrrule);
      setRefRruleText(currentRrule.toText());
      setInitialRRule(RRule.fromString(habit.refrrule));
      setModifiedRRule(RRule.fromString(habit.refrrule));
      // const allrecur = currentRrule.between(datetime(2023, 5, 1), datetime(2023, 5, 2));
      // console.log("All recurrences:", allrecur);
    }
  }, [habit]);

  useEffect(() => {
    if (!habit) return;
    if (habitmodalMode === "add") return;
    const unsub = db
      .collection("users")
      .doc(currentUser.uid)
      .collection("habits")
      .doc(habit.id)
      .onSnapshot((doc) => {
        const refarray = doc.data().history;
        const array = [...refarray]
          .sort((a, b) => (a.date > b.date ? 1 : -1))
          .map((field) => {
            return {
              count: field.checked,
              date: field.date,
              level: Math.round(field.checked * 4),
            };
          });
        setHabitHistory([...array]);
      });
    return () => {
      unsub();
    };
  }, []);

  useEffect(() => {
    setRruleString(modifiedRRule.toString());
  }, [modifiedRRule]);

  const onSelectEmoji = (emojiObject) => {
    setHabitIcon(emojiObject.native);
    setIsOpen(!isOpen);
  };

  const handlesetEmojiOpen = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleTemplateSelect = (habittemplate) => {
    setHabitName(habittemplate.name);
    setHabitIcon(habittemplate.icon);
    setRefRRule(habittemplate.rrule);
    setStep(habittemplate.step);
    setSteps(habittemplate.steps);
    setUnit(habittemplate.unit);
  };

  // var todayutc = moment().utc().startOf('day'); // today in UTC
  // const printrule = rrulestr(habit.refrrule)

  const minimalTheme = {
    light: ["hsl(0, 0%, 92%)", "rebeccapurple"],
    // dark: the default theme will be used as fallback
  };

  const explicitTheme = {
    light: ["#EBEBEB", "#96BBD2", "#6FA2C3", "#4889B1", "#2270A1"],
    dark: ["#383838", "#4D455D", "#7DB9B6", "#F5E9CF", "#E96479"],
  };

  // const now = new Date();
  // const length = 90;

  // const days = Array.from({ length }, (_, days) => {
  //   let day = new Date(now); // clone "now"
  //   day.setDate(now.getDate() - days); // change the date
  //   return day;
  // });

  // console.log(days);

  // let now = new Date();
  // console.log("Today: " + now.toUTCString());
  // let last30days = new Date(now.setDate(now.getDate() - 30));
  // console.log("Last 30th day: " + last30days.toUTCString());

  return (
    <div className="habit">
      <div className="habit-backdrop" onClick={() => handleCloseHabit()}></div>
      <div className="habit-content">
        <button
          className="habit__exit__button"
          onClick={() => handleCloseHabit()}
        >
          <BsPlusLg size="1.7em" />
        </button>
        {habitmodalMode === "add" && (
          <>
            <h3>Habit Templates</h3>
            <div className="habit-templates">
              <details>
                <summary>Productivity</summary>
                {productivityHabits.map((habittemplate) => {
                  const { name, icon, id } = habittemplate;
                  return (
                    <div key={id} className="habitline">
                      <div
                        className="habitinfo"
                        onClick={() => handleTemplateSelect(habittemplate)}
                      >
                        <span>{icon}</span>
                        <h5>{name}</h5>
                      </div>
                    </div>
                  );
                })}
              </details>
              <details>
                <summary>Growth</summary>
                {growthHabits.map((habittemplate) => {
                  const { name, icon, id } = habittemplate;
                  return (
                    <div key={id} className="habitline">
                      <div
                        className="habitinfo"
                        onClick={() => handleTemplateSelect(habittemplate)}
                      >
                        <span>{icon}</span>
                        <h5>{name}</h5>
                      </div>
                    </div>
                  );
                })}
              </details>
              <details>
                <summary>Health</summary>
                {healthHabits.map((habittemplate) => {
                  const { name, icon, id } = habittemplate;
                  return (
                    <div key={id} className="habitline">
                      <div
                        className="habitinfo"
                        onClick={() => handleTemplateSelect(habittemplate)}
                      >
                        <span>{icon}</span>
                        <h5>{name}</h5>
                      </div>
                    </div>
                  );
                })}
              </details>
              <details>
                <summary>Home</summary>
                {homeHabits.map((habittemplate) => {
                  const { name, icon, id } = habittemplate;
                  return (
                    <div key={id} className="habitline">
                      <div
                        className="habitinfo"
                        onClick={() => handleTemplateSelect(habittemplate)}
                      >
                        <span>{icon}</span>
                        <h5>{name}</h5>
                      </div>
                    </div>
                  );
                })}
              </details>
              <details>
                <summary>Financial</summary>
                {financialHabits.map((habittemplate) => {
                  const { name, icon, id } = habittemplate;
                  return (
                    <div key={id} className="habitline">
                      <div
                        className="habitinfo"
                        onClick={() => handleTemplateSelect(habittemplate)}
                      >
                        <span>{icon}</span>
                        <h5>{name}</h5>
                      </div>
                    </div>
                  );
                })}
              </details>
              <details>
                <summary>Social</summary>
                {socialHabits.map((habittemplate) => {
                  const { name, icon, id } = habittemplate;
                  return (
                    <div key={id} className="habitline">
                      <div
                        className="habitinfo"
                        onClick={() => handleTemplateSelect(habittemplate)}
                      >
                        <span>{icon}</span>
                        <h5>{name}</h5>
                      </div>
                    </div>
                  );
                })}
              </details>
              <details>
                <summary>Selfcare</summary>
                {selfcareHabits.map((habittemplate) => {
                  const { name, icon, id } = habittemplate;
                  return (
                    <div key={id} className="habitline">
                      <div
                        className="habitinfo"
                        onClick={() => handleTemplateSelect(habittemplate)}
                      >
                        <span>{icon}</span>
                        <h5>{name}</h5>
                      </div>
                    </div>
                  );
                })}
              </details>
            </div>{" "}
          </>
        )}
        {habitmodalMode === "edit" && (
          <div className="habit-history">
            <details open>
              <summary>
                <h3>History</h3>
              </summary>
              <div className="activitycal-container">
                <ActivityCalendar
                  data={habitHistory}
                  eventHandler={{
                    onClick: (event) => (activity) => {
                      console.log({ event, activity });
                      alert(JSON.stringify(activity, null, 4));
                      console.log("clicked somewhere");
                    },
                    // onMouseEnter: (event) => (activity) =>
                    //   console.log("mouseEnter"),
                  }}
                  renderBlock={(block, activity) =>
                    React.cloneElement(block, {
                      "data-tooltip-id": "react-tooltip-habit",
                      "data-tooltip-html":
                        activity.count >= 1
                          ? `👏 Reached the goal on ${activity.date} 🎉`
                          : activity.count > 0
                          ? `${activity.count * 100}% is reached on ${
                              activity.date
                            } 👍`
                          : `No activity on ${activity.date}`,
                    })
                  }
                  theme={explicitTheme}
                  // hideColorLegend={true}
                  // hideTotalCount={true}
                  hideMonthLabels={false}
                  showWeekdayLabels={true}
                  labels={{
                    legend: {
                      less: "Less",
                      more: "More",
                    },
                    months: [
                      "Jan",
                      "Feb",
                      "Mar",
                      "Apr",
                      "May",
                      "Jun",
                      "Jul",
                      "Aug",
                      "Sep",
                      "Oct",
                      "Nov",
                      "Dec",
                    ],
                    totalCount: "{{count}} checks in {{year}}",
                    totalCount: " ",
                    weekdays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
                  }}
                />
                <Tooltip id="react-tooltip-habit" />
              </div>
            </details>
          </div>
        )}
        <div className="habit-form-header">
          <h3>Habit</h3>
          {/* <p>Related Workspace</p> */}
          {isLinked === "" ? (
            <button onClick={() => setIsLinked("beingset")}>
              <TfiLink />
            </button>
          ) : (
            <div className="habit-form-header-link">
              <p>Coming feature 😊</p>
              <select>
                <option value="" disabled selected>
                  Link a Project
                </option>
                <option>Project 1</option>
                <option>Project 2</option>
              </select>
              <button onClick={() => setIsLinked("")}>
                <TfiUnlink />
              </button>
            </div>
          )}
        </div>
        <form className="habit-form">
          <div className="habit__EmojiIcon">
            <button
              className="HabitEmojiPick"
              onClick={(e) => handlesetEmojiOpen(e)}
            >
              <span> {habitIcon ? habitIcon : habit.icon}</span>
              {/* <MdOutlineEmojiFoodBeverage /> */}
            </button>
            <div className="PickerOn">
              {isOpen && (
                <Picker
                  native={true}
                  showPreview={false}
                  showSkinTones={true}
                  // skinTonePosition="search"
                  emojiTooltip={true}
                  autoFocus={true}
                  emojiSize={20}
                  perLine={6}
                  color="black"
                  sheetSize={15}
                  style={{
                    position: "absolute",
                    // maxWidth: "250px",
                    // with: "100%",
                    outline: "none",
                    zIndex: "150",
                    // fontSize:"16px"
                  }}
                  onSelect={onSelectEmoji}
                />
              )}
            </div>
          </div>
          <div className="habitsettings">
            {habitName === "" ? (
              <input
                value={habitName}
                placeholder={"Enter habit name"}
                onChange={(e) => setHabitName(e.target.value)}
                className="habitnameinput"
              ></input>
            ) : (
              <input
                value={habitName}
                onChange={(e) => setHabitName(e.target.value)}
                className="habitnameinput"
              ></input>
            )}
            <div className="habitoptions">
              <Recurring
                initialRRule={initialRRule}
                modifiedRRule={modifiedRRule}
                setModifiedRRule={setModifiedRRule}
                rruleString={rruleString}
                refrrule={habit.refrrule}
                setRefRRule={setRefRRule}
              />
            </div>
            <div className="habitoptions">
              <label>Goal</label>
              <input
                type="number"
                min={1}
                // max="10"
                className="inverval_number"
                // placeholder="2"
                defaultValue={5}
                value={steps}
                onChange={(e) => setSteps(e.target.value)}
              ></input>
              <select value={unit} onChange={(e) => setUnit(e.target.value)}>
                <optgroup label="Quantity">
                  <option value="time(s)">time(s)</option>
                  <option value="count(s)">count(s)</option>
                  <option value="page(s)">page(s)</option>
                </optgroup>
                <optgroup label="Measurement">
                  <option value="cup(s)">cup(s)</option>
                  <option value="milliliter(s)">milliliter(s)</option>
                </optgroup>
                <optgroup label="Duration">
                  <option value="minute(s)">minute(s)</option>
                  <option value="pomodoro(s)">pomodoro(s)</option>
                  <option value="hour(s)">hour(s)</option>
                </optgroup>
                <optgroup label="Distance">
                  <option value="meter(s)">meter(s)</option>
                  <option value="kilometer(s)">kilometer(s)</option>
                  <option value="feet">feet</option>
                  <option value="step(s)">step(s)</option>
                  <option value="mile(s)">miles(s)</option>
                </optgroup>
                <optgroup label="Currency">
                  <option value="dollar(s)">dollar(s)</option>
                  <option value="euro(s)">euro(s)</option>
                  <option value="peso(s)">peso(s)</option>
                </optgroup>
              </select>
              <p>at habit day</p>
              {/* <select>
                <option>a day</option>
                <option>a week</option>
                <option>a month</option>
              </select> */}
            </div>
            <div className="habitoptions">
              <label>Check</label>
              <input
                type="number"
                min={1}
                max={habit?.steps}
                className="inverval_number"
                // placeholder="2"
                defaultValue={1}
                value={step}
                onChange={(e) => setStep(e.target.value)}
              ></input>
              <p>{unit} at a time</p>
            </div>
            <div className="habitbtns">
              {habitmodalMode === "edit" && (
                <RetroButton onClick={handleDeleteHabit}>Delete</RetroButton>
              )}
              <RetroButton onClick={handleSaveHabit}>
                {habitmodalMode === "edit" ? "Update" : "Save"}
              </RetroButton>
            </div>
          </div>
        </form>
        {/* {rrulestr(habit.refrrule).after(new Date(), true)} */}
      </div>
    </div>
  );
}
