import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import RetroButton from "../retro/button/retro-button.component";
import CreateStation from "../create-station/create-station.component";

import { FaTasks, FaPlusSquare } from "react-icons/fa";

import "./recent-stations.styles.scss";

function RecentStations({ activeSpaceData }) {
  const spaceId = useSelector((state) => state.history.spaceId);
  const stationData = useSelector((state) => state.space.stationData);
  const [recentStation, setRecentStation] = useState(null);
  const [createStation, setCreateStation] = useState(false);

  useEffect(() => {
    if (stationData) {
      setRecentStation(stationData.slice(-10));
    }
  }, [stationData]);

  return (
    <div className="recentStations">
      {stationData?.length < 1 ? (
        <div className="recentStations__noStations">
          <div className="recentStations__msg">
            <p>No Stations!</p>
          </div>
        </div>
      ) : (
        recentStation?.map((data) => {
          return (
            <div className="rs__item" key={data.stationId}>
              <Link to={`/s/${activeSpaceData.spaceId}/e/${data.stationId}/b`}>
                <RetroButton mode="flat">
                  {/* <FaTasks size="2em" /> */}
                  <div className="EmojiIcon">{data.emojicon}</div>
                  {data.name}
                </RetroButton>
              </Link>
            </div>
          );
        })
      )}
      <div className="recentStations__create">
        <RetroButton mode="create" onClick={() => setCreateStation(true)}>
          <FaPlusSquare />
          Create new Project
        </RetroButton>
      </div>
      {createStation && (
        <CreateStation
          setCreateStation={setCreateStation}
          activeSpaceId={spaceId}
        />
      )}
    </div>
  );
}

export default RecentStations;
