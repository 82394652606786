import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "./create-station.styles.scss";

import { createNewStation2 } from "../../firebase/firebase.utils";

import BoxLayer from "../retro/box-layer/box-layer.component";
import RetroButton from "../retro/button/retro-button.component";
import Loading from "../retro/loading/loading.component";
import Step1 from "./step1/step1.component";
import Step2 from "./step2/step2.component";
import Step3 from "./step3/step3.component";

const CreateStation = ({ setCreateStation, activeSpaceId }) => {
  const navigate = useNavigate();
  const [stationName, setStationName] = useState("Enter Project name");
  const [stationId, setStationId] = useState("");
  const [force, setForce] = useState(0);
  const [loading, setLoading] = useState(false);
  const [steps, setSteps] = useState({
    step1: true,
    step2: false,
    step3: false,
  });

  const [statusType, setStatusType] = useState({
    "to do": {
      id: "to do",
      name: "to do",
      taskIds: [],
      color: "rgb(234 236 239)",
      fontColor: "#34b5e4",
      open: true,
    },
    "on it": {
      id: "on it",
      name: "on it",
      taskIds: [],
      color: "rgb(234 236 239)",
      fontColor: "#FDAB3D",
      open: true,
    },
    stuck: {
      id: "stuck",
      name: "stuck",
      taskIds: [],
      color: "rgb(234 236 239)",
      fontColor: "#e2445c",
      open: true,
    },
    done: {
      id: "done",
      name: "done",
      taskIds: [],
      color: "rgb(234 236 239)",
      fontColor: "#05ce91",
      open: true,
    },
  });

  const [statusOrder, setStatusOrder] = useState([
    "to do",
    "on it",
    "stuck",
    "done",
  ]);

  useEffect(() => {
    let keys = Object.keys(statusType);
    setStatusOrder(keys);
  }, [statusType, setStatusType, force, setForce]);

  const [modules, setModules] = useState([
    { name: "CreatedBy", active: false, icon: "faUser" },
    { name: "Assign", active: true, icon: "faUserCheck" },
    // { name: "Status", active: false, icon: "faInfoCircle" },
    { name: "CreatedDate", active: false, icon: "faCalendarCheck" },
    { name: "EstDuration", active: false, icon: "GiStopwatch" },
    { name: "StartDate", active: false, icon: "faCalendarCheck" },
    { name: "Deadline", active: false, icon: "faCalendarAlt" },
    { name: "DaysLeft", active: true, icon: "faHourglassHalf" },
    { name: "Priority", active: true, icon: "faExclamationCircle" },
  ]);

  const handleCreate = async () => {
    setLoading(true);
    const stationId = await createNewStation2(
      activeSpaceId,
      stationName,
      statusType,
      statusOrder,
      modules,
      startDate,
      endDate,
      emoji.native
    );
    setTimeout(() => {
      setLoading(false);
      setCreateStation(false);
      if (!stationId) return;
      navigate(`/s/${activeSpaceId}/e/${stationId}/b`);
    }, 300);
  };

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState();

  const defaultEmoji = {
    id: "heart_eyes",
    name: "Smiling Face with Heart-Shaped Eyes",
    short_names: ["black_circle"],
    colons: ":black_circle:",
    emoticons: [],
    unified: "1f60d",
    skin: null,
    native: "◾",
  };

  const [emoji, setEmoji] = useState(defaultEmoji);

  return (
    <div className="createStation">
      <BoxLayer setLayer={setCreateStation}>
        {loading && <Loading />}
        <div className="cs__createPop">
          {steps.step1 && (
            <Step1
              stationName={stationName}
              setStationName={setStationName}
              setSteps={setSteps}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              startDate={startDate}
              endDate={endDate}
              setEmoji={setEmoji}
              emoji={emoji}
            />
          )}
          {steps.step2 && (
            <Step2
              setSteps={setSteps}
              statusType={statusType}
              setStatusType={setStatusType}
            />
          )}
          {steps.step3 && (
            <>
              <Step3
                setSteps={setSteps}
                modules={modules}
                setModules={setModules}
                setForce={setForce}
                force={force}
              />

              <div className="cs__create-btn">
                <RetroButton
                  mode="gray"
                  onClick={() =>
                    setSteps({
                      step3: false,
                      step2: true,
                    })
                  }
                >
                  back
                </RetroButton>
                <RetroButton onClick={() => handleCreate()}>create</RetroButton>
              </div>
            </>
          )}
        </div>
      </BoxLayer>
    </div>
  );
};

export default CreateStation;
