import React from "react";

import { BsPersonCheck } from "react-icons/bs";
import { IoPersonOutline } from "react-icons/io5";

import "./avatar.styles.scss";

const Avatar = ({ src }) => (
  <div className="avatar">
    {src ? <img alt="" src={src} /> : <BsPersonCheck />}
  </div>
);

export default Avatar;
