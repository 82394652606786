import prjnoteActionTypes from "./prjnotes.types";

const INITIAL_STATE = {
  prjnotes: [],
};

const prjnoteReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case prjnoteActionTypes.SET_PRJNOTES:
      return {
        ...state,
        prjnotes: action.payload,
      };
    default:
      return state;
  }
};

export default prjnoteReducer;
