import prjphaseActionTypes from "./prjphases.types";

const INITIAL_STATE = {
  prjphases: [],
};

const prjphaseReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case prjphaseActionTypes.SET_PRJPHASES:
      return {
        ...state,
        prjphases: action.payload,
      };
    default:
      return state;
  }
};

export default prjphaseReducer;
