const spaceActionTypes = {
  SET_SPACE_DATA: "SET_SPACE_DATA",
  REMOVE_SPACE_DATA: "REMOVE_SPACE_DATA",
  SET_STATION_DATA: "SET_STATION_DATA",
  SET_FOLDER_DATA: "SET_FOLDER_DATA",
  REMOVE_ONE_SPACE: "REMOVE_ONE_SPACE",
  SET_MODULES: "SET_MODULES",
  SET_ACTIVE_MODULES: "SET_ACTIVE_MODULES",
  SET_STATUS_TYPE: "SET_STATUS_TYPE",
  LOG_OUT: "LOG_OUT",
  SET_OPEN: "SET_OPEN",
};

export default spaceActionTypes;
