import * as React from "react"
const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={392} height={138} {...props}>
    <title>{"accessories/Glasses 4"}</title>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#FFF"
        d="M370.267 94.081c-9.18 11.47-45.116 24.2-64.243 10.38-19.126-13.82-26.685-23.45-29.241-40.72-.273-1.84-3.602-3.065-5.01-1.758-7.88 7.32-12.982 16.644-18.036 25.991-5.7 10.542-11.315 21.15-21.675 28.286-18.348 9.835-51.542 11.236-61.349 6.897-18.786-8.31-33.646-26.084-37.026-50.544-2.291-7.233-4.44-11.611-6.448-13.133-2.854-2.164-18.607-4.092-20.333-4.412-31.099-5.77-70.431-9.449-98.13 10.852-3.749 2.266-16.581 10.779-16.774 8.685-.131-8.199 9.16-26.756 30.292-35.586 26.132-10.92 64.977-11.559 87.48-9.149 1.132.121 4.99-.827 5.263-.945 34.094-14.76 73.322-10.84 109.802-4.636 5.228 1.014 52.025 12.602 70.639 5.581 30.776-12.564 63.59-21.666 96.824-18.085.278.03 4.473 25.496 1.205 27.234-2.6 1.382-7.027 4.925-8.599 6.864-1.406 1.733-2.291 3.888-2.213 6.873.632 17.27-4.295 31.164-12.428 41.325ZM243.728 78.672c3.903-5.645 5.134-18.326 3.5-27.577-.827-4.684-2.433-8.413-4.449-10.269-1.34-1.233-2.869-1.814-4.536-1.728-.914.047-1.905.307-2.952.873-14.194-7.678-31.388-10.442-47.687-9.647-12.975-.003-22.232 3.06-28.51 7.827-7.553 5.736-10.956 13.982-11.153 22.925-.234 10.612 4.125 22.221 11.276 31.407 6.844 8.792 16.207 15.31 26.364 16.814 11.53 2.289 23.281.075 33.498-5.342 10.478-5.556 19.329-14.478 24.65-25.283Zm113.78 5.987c6.576-7.772 10.949-17.882 11.615-27.9.605-9.116-1.836-18.147-8.585-25.61-6.12-5.251-16.932-5.339-28.307-2.414-14.708 3.782-29.924 12.234-34.996 17.693-1.195.626-2.304 1.717-3.27 4.057l-.007.834c-1.536 7.087-.624 14.278 2.034 20.912 2.887 7.206 7.839 13.748 13.876 18.74 8.022 6.607 16.396 8.63 24.234 7.563 8.877-1.208 17.133-6.463 23.405-13.875Z"
      />
      <path
        fill="#000"
        d="M294.42 27.063c31.165-12.724 64.478-21.895 98.204-18.26 2.789-1.062 6.25-.168 7.487 3.776 12.013 36.595-14.776 26.418-14.416 40.098 2.808 76.703-100.287 89.802-111.88 11.504l-.212.126c-16.678 15.563-20.191 40.888-39.838 54.423-39.633 28.985-95.815 6.02-103.031-45.58l-.158-.698c-2.422-10.53-5.318-13.141-17.264-13.09l-1.888-.375c-31.039-6.115-72.372-11.61-100.96 9.42C4.246 72.023 3.954 81.32-1.3 85.358c-5.756 1.118-9.302-5.805-9.692-10.596-.912-47.763 84.558-51.199 122.836-47.1.73-.757 2.292-.663 2.818.28 34.618-14.988 73.619-12.914 110.685-6.61 22.96 4.444 45.639 14.57 69.073 5.731Zm95.1-9.443c-18.7-.935-35.998.088-54.354 4.583-9.674 2.334-17.098 5.285-26.358 8.834-57.758 25.59-64.42-19.896-193.888-2.066 1.675 7.174 1.983 14.277 2.073 21.017 26.435-1.065 20.21 24.177 30.114 41.906l.306.535c14.434 25.868 48.494 37.934 75.969 20.776 22.922-13.36 25.158-47.245 47.98-60.693 19.783-.778 11.88 25.922 22.647 36.896 15.154 20.221 47.365 23.048 66.367 4.028 8.915-9.152 13.653-20.772 13.915-33.256.497-7.976-.871-16.08 5.159-21.69 4.227-4.295 11.737-2.862 12.599-9.353.548-3.838-1.048-8-2.528-11.517ZM186.28 28.075c18.577-.915 38.233 2.31 54.184 11.422 11.807-9.396 14.7 27.946 6.863 38.747-11.291 23.102-37.578 38.29-62.889 33.247-39.486-5.722-66.998-83.485 1.842-83.416ZM239.33 42l-1.01-.148c-17.113-2.611-63.708-14.708-81.679 4.385C139.2 77.907 183.447 131.1 229.492 88.73c12.815-13.047 17.782-28.868 9.838-46.73Zm54.23 4.047c8.861-10.493 54.026-31.747 69.77-18.108 28.733 32.201-16.834 97.334-55 65.601-12.712-10.607-21.075-27.507-17.416-43.872l.006-.686c.008-.248.054-.192.32-.502.471-1.237 1.32-2.027 2.32-2.433Zm69.368 19.517c9.69-52.557-30.808-32.577-64.068-18.549 1.784 1.631 1.776 3.858 1.263 5.688-3.507 38.996 43.89 61.094 62.805 12.861ZM111.172 30.216C97.299 34.914 5.538 26.512-2.94 69.526 6.009 55.03 24.54 49.276 41.2 46.154c22.66-4.247 45.198-1.788 67.54 2.681l.462-4.624c.496-4.904 1.018-9.493 1.969-13.995Z"
      />
    </g>
  </svg>
)
export default SvgComponent
