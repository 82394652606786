import React,{useState} from "react";
import { Link, useLocation } from "react-router-dom";
import { motion } from "framer-motion";

import "./task-add-btn.styles.scss";

import { BsPlusLg } from "react-icons/bs";
import { HiOutlineDocumentCheck, HiPlus, HiOutlineDocumentPlus } from "react-icons/hi2";

const variants = {
  open: { opacity: 0, x: "-100%", width: "360px", height: "500px", borderRadius: "6px", right: "0px", translateX: "100%", translateY: "-50%" },
  closed: { opacity: 1, x: 0 },
}

const TaskAddButton = () => {
  // const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const isOpen = location.pathname.includes("addtask")
  return (
    <Link to="addtask">
      <motion.button
        className="addtask__btn"
        animate={isOpen ? "open" : "closed"}
        variants={variants}
        transition={{ type: "ease-in-out", duration: 0.4 }}
      >
        {/* <BsPlusLg size="1.8em" /> */}
        <HiPlus size="2.3em" />
      </motion.button>
    </Link>
  );
};

export default TaskAddButton;
