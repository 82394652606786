import React, { useEffect, useState } from "react";

import RetroButton from "../../retro/button/retro-button.component";
import RetroInput from "../../retro/input/input.component";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Picker } from "emoji-mart";
import EmojiPicker from "../../emoji/emojiPicker";

import { FaTasks } from "react-icons/fa";

import "./step1.styles.scss";

const Step1 = ({
  stationName,
  setStationName,
  setSteps,
  setStartDate,
  setEndDate,
  startDate,
  endDate,
  setEmoji,
  emoji,
}) => {
  const [isNameOK, setIsNameOK] = useState(false);
  const unClick = {
    background: "gray",
    PointerEvent: "none",
  };
  const handleNext = () => {
    if (!isNameOK) {
      alert("Project name is required");
      return;
    }
    setSteps({
      step1: false,
      step2: true,
    });
  };

  const [duration, setDuration] = useState(30);

  useEffect(() => {
    var someDate = new Date();
    var result = someDate.setDate(startDate.getDate() + duration);
    // console.log(new Date(result))
    setEndDate(new Date(result));
  }, [startDate, duration]);

  useEffect(() => {
    if (stationName === "" || stationName === "Enter Station name") {
      setIsNameOK(false);
    } else {
      setIsNameOK(true);
    }
  }, [stationName]);

  // const [inputEmojicon, setInputEmojicon] = useState("");
  const [emisOpen, setEmIsOpen] = useState(false);

  const onSelectEmoji = (emoji) => {
    setEmoji(emoji);
    setEmIsOpen(!emisOpen);
  };

  return (
    <section className="sectionName">
      <h2>Create Project</h2>
      {/* <div className="sn__icon">
        <FaTasks size="3em" />
      </div> */}
      <div className="st__emojicon-name-row">
        <div className="st__emojiconwithlabel">
          <div className="EmojiIcon">
            <button
              className="EmojiPick"
              onClick={() => {
                setEmIsOpen(!emisOpen);
              }}
            >
              <button>{emoji.native}</button>
            </button>
            <div className="PickerOn">
              {emisOpen && <EmojiPicker onEmojiSelect={onSelectEmoji} />}
            </div>
          </div>
          <label>Icon</label>
        </div>
        <div className="sn__name">
          {/* <p>Station name</p> */}
          <div onChange={(e) => setStationName(e.target.value)}>
            <RetroInput placeholder={stationName} autoFocus={true} />
          </div>
        </div>
      </div>
      <div className="cr__duration-label">
        <label>duration</label>
      </div>
      <div className="cr__duration-buttons">
        <button onClick={() => setDuration(3)}>3 days</button>
        <button onClick={() => setDuration(5)}>5 days</button>
        <button onClick={() => setDuration(7)}>1 week</button>
        <button onClick={() => setDuration(14)}>2 weeks</button>
        <button onClick={() => setDuration(30)}>1 month</button>
        <button onClick={() => setDuration(90)}>3 months</button>
        <button onClick={() => setDuration(180)}>6 months</button>
      </div>
      <div className="cr__twocolumnsnarrow">
        <div>
          <label>start date</label>
          <DatePicker
            popperProps={{ strategy: "fixed" }}
            wrapperClassName="datepicker"
            // isClearable
            placeholderText="Select a start date"
            value={startDate}
            // showTimeSelect
            // startDate="start"
            timeFormat="p"
            timeIntervals={15}
            timeCaption="time"
            dateFormat="MMMM d, yyyy"
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            // note: this onChange is not needed, because it saves to db when it is not active as well
            // minDate={moment().toDate()}
            todayButton="today"
          />
        </div>
        <div>
          <label>end date</label>
          <DatePicker
            popperProps={{ strategy: "fixed" }}
            wrapperClassName="datepicker"
            // isClearable
            placeholderText="Select end or deadline"
            // value={event.endDate}
            // showTimeSelect
            timeFormat="p"
            timeIntervals={15}
            dateFormat="MMMM d, yyyy"
            selected={endDate}
            onChange={(date) => setEndDate(date)}
          />
        </div>
      </div>
      <div className="sn__next">
        <div>
          <RetroButton
            // mode="flat2"
            onClick={() => {
              handleNext();
            }}
            style={isNameOK ? null : unClick}
          >
            Next
          </RetroButton>
        </div>
      </div>
    </section>
  );
};

export default Step1;
