export const tasksSample = [
  {
    start: new Date([2018, 10, 1]),
    end: "2018-10-08",
    name: "Some text",
    id: "Task 0",
    swimlane: "First steps",
    sub_swimlane: "1. item",
    primary_color: "rgb(255, 51, 204)",
    // start_drag: false,
    // end_drag: false,
    editable: true,
    start_drag: true,
    end_drag: true,
    progress: 120,
    dependencies: "Task 1",
    //custom class:custom text:name of task property to show
    //to_show_in_popup: "new-progress:My Custom Progress Text:progress, new-dependencies::dependencies, new-drag:Is it allowed to change start date?:start_drag"
  },
  {
    start: "2018-10-03",
    end: "2018-10-06",
    name: "Write new content",
    id: "Task 1",
    progress: 5,
    swimlane: "Second step",
    sub_swimlane: "3. item",
    editable: true,
    start_drag: true,
    end_drag: true,
  },
  {
    start: "2018-10-04",
    end: "2018-10-08",
    name: "Apply new styles",
    id: "Task 2",
    text_color: "black",
    swimlane: "First steps",
    sub_swimlane: "3. item",
    secondary_color: "green",
    progress: 10,
    editable: true,
    start_drag: true,
    end_drag: true,
    dependencies: "Task 1",
  },
  {
    start: "2018-10-08",
    end: "2018-10-09",
    name: "Review",
    id: "Task 3",
    text_color: "#3399ff",
    swimlane: "Next steps",
    sub_swimlane: "1. item",
    progress: 5,
    editable: true,
    start_drag: true,
    end_drag: true,
    dependencies: "Task 2",
  },
  {
    start: new Date([2019, 1, 8]),
    end: new Date([2019, 1, 10]),
    name: "Deploy",
    id: "Task 4",
    progress: 0,
    editable: true,
    start_drag: true,
    end_drag: true,
    dependencies: "Task 2",
    swimlane: "Other steps",
  },
  {
    start: "2019-01-11",
    end: "2019-01-11",
    name: "Go Live!",
    id: "Task 5",
    progress: 0,
    dependencies: "Task 4",
    editable: true,
    custom_class: "bar-milestone",
    swimlane: "Other steps",
  } /*,
  {
    start: '2014-01-05',
    end: '2019-10-12',
    name: 'Long term task',
    editable: false,
    id: "Task 6",
    progress: 0
  }*/,
];
