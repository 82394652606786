import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DaysLeft from "../modules/days-left/days-left.component";
import Priority from "../modules/priority/priority.component";
import CreatedBy from "../modules/created-by/created-by.component";
import RetroButton from "../retro/button/retro-button.component";

import "./assigned-tasks.styles.scss";

import {
  db,
  // createNewInboxTask,
  updateTaskAsDone,
  // updateInboxTaskAsDone,
  // fieldValue,
} from "../../firebase/firebase.utils";

import { IoCheckmarkDone } from "react-icons/io5";
import { BsChevronRight, BsChevronDown, BsList } from "react-icons/bs";
import { FiSquare, FiCheckSquare } from "react-icons/fi";

const AssignedTaskItem = ({ spaceId, color, name, assignedColTasks }) => {
  const [showList, setShowList] = useState(true);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMode, setModalMode] = useState("");
  const [activeEvent, setActiveEvent] = useState({});
  const [events, setEvents] = useState([]);

  const navigate = useNavigate();

  const handleCheck = (task, done) => {
    document.getElementById("done_tone").play();
    // const IdRef = currentUser.uid;
    if (task.done) {
      updateTaskAsDone(task.fromSpaceId, task.fromStationId, task.id, false);
    } else {
      updateTaskAsDone(task.fromSpaceId, task.fromStationId, task.id, true);
      console.log("it says it is not done!");
    }
  };

  const handleOpenModal = (mode, event) => {
    // setModalMode(mode);
    // setActiveEvent(event);
    // setModalIsOpen(true);
    navigate(`task/${event.id}`)
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
    setModalMode("");
    setActiveEvent({});
  };

  const handleSaveEvent = (mode, event) => {
    if (mode === "add") {
      setEvents([...events, { id: events.length + 1, ...event }]);
    } else {
      setEvents(events.map((e) => (e.id === event.id ? event : e)));
    }
    handleCloseModal();
  };

  const handleDeleteEvent = (event) => {
    setEvents(events.filter((e) => e.id !== event.id));
    handleCloseModal();
  };

  // const handleEventClick = (task) => {
  //   console.log("selected task is:", task);
  //   // console.log("id of the selected task:", task);
  //   handleOpenModal(
  //     "edit",
  //     {
  //       id: task.id,
  //       title: task.title,
  //       description: task.description,
  //       done: task.done,
  //       startdate: task.startdate,
  //       enddate: task.enddate,
  //       allDay: task.allDay,
  //       duration: task.duration,
  //       done: task.done,
  //       createdBy: task.createdBy,
  //       createdAt: task.createdAt,
  //       assign: task.assign,
  //       phase: task.phase,
  //       priority: task.priority,
  //       progress: task.progress,
  //       type: task.type,
  //       fromSpaceId: task.fromSpaceId,
  //       fromStationId: task.fromStationId,
  //       subtasks: task.subtasks,
  //     }
  //     // events.find((e) => e.id.toString() === id.toString())
  //   );
  // };

  const handleEventClick = (taskref) => {
    // console.log("selected task is:", task);
    if (taskref.startdate === null)
      handleOpenModal(
        "edit",
        {
          id: taskref.id,
          title: taskref.title,
          description: taskref.description,
          done: taskref.done,
          startdate: taskref.startdate,
          enddate: taskref.enddate,
          allDay: taskref.allDay,
          duration: taskref.duration,
          done: taskref.done,
          createdBy: taskref.createdBy,
          createdAt: taskref.createdAt,
          assign: taskref.assign,
          phase: taskref.phase,
          priority: taskref.priority,
          progress: taskref.progress,
          type: taskref.type,
          fromSpaceId: taskref.fromSpaceId,
          fromStationId: taskref.fromStationId,
          subtasks: taskref.subtasks,
        }
        // events.find((e) => e.id.toString() === id.toString())
      );
    else
      handleOpenModal(
        "edit",
        {
          id: taskref.id,
          title: taskref.title,
          description: taskref.description,
          done: taskref.done,
          startdate: taskref.startdate.toDate(),
          enddate: taskref.enddate.toDate(),
          allDay: taskref.allDay,
          duration: taskref.duration,
          done: taskref.done,
          createdBy: taskref.createdBy,
          createdAt: taskref.createdAt,
          assign: taskref.assign,
          phase: taskref.phase,
          priority: taskref.priority,
          progress: taskref.progress,
          type: taskref.type,
          fromSpaceId: taskref.fromSpaceId,
          fromStationId: taskref.fromStationId,
          subtasks: taskref.subtasks,
        }
        // events.find((e) => e.id.toString() === id.toString())
      );
  };

  return (
    <>
      <div
        className="at__fromspace-list-btn"
        onClick={() => setShowList(!showList)}
      >
        <RetroButton style={{ background: color }}>
          {name.charAt(0)}
        </RetroButton>
        <h4>{name}</h4>
        {showList ? <BsChevronDown /> : <BsChevronRight />}
      </div>
      <div className={showList ? "at__showlist" : "at__hidelist"}>
        {assignedColTasks
          .filter((task) => task.fromSpaceId === spaceId)
          // .filter((task) => task.done === false)
          .map((task) => {
            const { fromSpaceId, fromStationId } = task;
            return (
              <div
                key={task.id}
                // className="at__item"
                className="fc-event"
                id={task.id}
                title={task.title}
                start={task.startdate}
                end={task.enddate}
                duration={task.duration}
                fromSpaceId={task.fromSpaceId}
                fromStationId={task.fromStationId}
              >
                <div>
                  {/* <Link to={`/s/${fromSpaceId}/e/${fromStationId}/b`}> */}
                  <RetroButton mode="flat">
                    <div className="at__check-and-name">
                      <div
                        onClick={(id, done) => handleCheck(task, done)}
                        className="at__checkBox"
                      >
                        {task.done ? <FiCheckSquare /> : <FiSquare />}
                      </div>
                      <audio
                        id="done_tone"
                        src="/media/mixkit-cool-interface-click-tone-2568.wav"
                      ></audio>
                      <div
                        className="at__content"
                        onClick={() => handleEventClick(task)}
                      >
                        <p>{task.title}</p>
                      </div>
                    </div>
                    <div className="at__modules">
                      <div className="at__daysLeft">
                        <DaysLeft task={task} />
                      </div>
                      <div className="at__priority">
                        <Priority task={task} />
                      </div>
                      {/* <div className="at__by">
                          <CreatedBy task={task} />
                        </div> */}
                    </div>
                  </RetroButton>
                  {/* </Link> */}
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default AssignedTaskItem;
