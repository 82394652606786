import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";

import GoBackButton from "../ui-elements/gobackbutton";

import MiniMenu from "../retro/mini-menu/mini-menu.component";
import DeleteStation from "./delete-space/delete-space.component";
import Colors from "../colors/colors.component";

import RetroButton from "../retro/button/retro-button.component";
import "./dock-header-styles.scss";

import {
  changeDescriptionOfSpace,
  renameSpace,
  updateColorOfSpace,
} from "../../firebase/firebase.utils";

import { FaFillDrip, FaICursor, FaTools } from "react-icons/fa";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";

const DockHeader = ({ activeSpaceData }) => {
  const spaceId = useSelector((state) => state.history.spaceId);
  const [inputDesc, setInputDesc] = useState("");
  const [inputName, setInputName] = useState("");
  const [showMiniMenu, setShowMiniMenu] = useState(false);
  const [showColors, setShowColors] = useState(false);
  const [color, setColor] = useState("");
  const [defColor, setDefColor] = useState("");

  const inputRefDesc = useRef();
  const inputRefName = useRef();

  useEffect(() => {
    if (!activeSpaceData) return;
    setDefColor(activeSpaceData.color);
  }, [color, spaceId]);

  useEffect(() => {
    if (!color) return;
    updateColorOfSpace(spaceId, color);
    setShowColors(false);
    setShowMiniMenu(false);
  }, [color, spaceId]);

  const handleSubmitDesc = (e) => {
    e.preventDefault();
    console.log(activeSpaceData.uid, inputDesc);
    changeDescriptionOfSpace(activeSpaceData.spaceId, inputDesc);
    inputRefDesc.current.blur();
  };

  const handleSubmitName = (e) => {
    e.preventDefault();
    console.log(activeSpaceData.uid, inputDesc);
    renameSpace(activeSpaceData.spaceId, inputName);
    inputRefName.current.blur();
  };

  return (
    <div className="dockHeader">
      <div className="dH__upperRow">
        {/* <BsChevronLeft
          size="1.8em"
          strokeWidth="0.5"
          className="si__breadcrumb_arrowleft"
        /> */}
        <GoBackButton />
        <div className="dh__header">
          <RetroButton style={{ background: activeSpaceData.color }}>
                          {activeSpaceData.name.charAt(0)}
                  </RetroButton>
            <div className="dh__name">
              <form
                onSubmit={(e) => handleSubmitName(e)}
                style={{ width: `${activeSpaceData?.name?.length + 2}ch` }}
              >
                <input
                  ref={inputRefName}
                  placeholder={activeSpaceData.name}
                  onChange={(e) => setInputName(e.target.value)}
                  // style={{ width: `${activeSpaceData?.name?.length}ch` }}
                />
              </form>
            </div>
            {/* <div className="dh__description">
            <form onSubmit={(e) => handleSubmitDesc(e)}>
              <input
                ref={inputRefDesc}
                placeholder={activeSpaceData.description}
                onChange={(e) => setInputDesc(e.target.value)}
              />
            </form>
          </div> */}
        </div>
        <BsChevronRight
          size="1.2em"
          strokeWidth="0.5"
          className="si__breadcrumb_arrowright"
        />
        <div className="dh__settings">
          <div className="dh__settings-tool">
            <div className="dh__settings-icon">
              <FaTools onClick={() => setShowMiniMenu(!showMiniMenu)} />
            </div>
            {showMiniMenu && (
              <MiniMenu setLayer={setShowMiniMenu}>
                <ul>
                  <li
                    onClick={() => {
                      inputRefName.current.focus();
                      setShowMiniMenu(false);
                    }}
                  >
                    <div className="tooltip">Rename</div>
                    <FaICursor fontSize="1x" />
                  </li>
                  <DeleteStation data={activeSpaceData} />
                  <li onClick={() => setShowColors(!showColors)}>
                    <div className="tooltip">colors</div>
                    <FaFillDrip fontSize="1x" />
                  </li>
                  {showColors && (
                    <Colors returnColor={setColor} defColor={defColor} />
                  )}
                </ul>
              </MiniMenu>
            )}
          </div>
        </div>
      </div>
      <div className="dH__bottomRow"></div>
    </div>
  );
};

export default DockHeader;
