import React from "react";
import { useDispatch } from "react-redux";

import { toggleStatus } from "../../../redux/filter/filter.actions";

import { FaSeedling } from "react-icons/fa";

import Tooltip from "../../retro/tooltip/tooltip.component";

import "./fire-priority.styles.scss";

const FirePriority = ({ priority }) => {
  const { name, status } = priority;
  const dispatch = useDispatch();
  return (
    <div
      onClick={() => dispatch(toggleStatus(name))}
      className={`firePriority ${status && "firePriority__active"}`}
    >
      <div className={`firePriority-item filter__${name}`}>
        <Tooltip text={name} />
        <FaSeedling
          style={{
            opacity: status ? 1 : 0.8,
            transform: status ? "scale(1.15)" : "scale(1)",
          }}
        />
        {status && <div className="firePriority__active-name"><p>{name}</p></div>}
      </div>
    </div>
  );
};

export default FirePriority;
