import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { db, fieldValue } from "../../firebase/firebase.utils";
import { useSelector } from "react-redux";

import useCurrentMembers from "../../hooks/useCurrentMembers.hook";

import RetroButton from "../retro/button/retro-button.component";
import RetroInput from "../retro/input/input.component";
import Member from "./member/member.component";
import PrMembers from "./prmembers.component";

import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

import "./members.styles.scss";

const Members = ({ activeSpaceData }) => {
  const history = useNavigate();
  const activeSpaceId = useSelector((state) => state.history.spaceId);
  const [email, setEmail] = useState("");
  const [invite, setInvite] = useState(false);
  const [projectInvite, setProjectInvite] = useState(false);
  const [invitetoProject, setInvitetoProject] = useState(false);
  const [memberData, setMemberData] = useState([]);
  const inputRef = useRef();

  const stationData = useSelector((state) => state.space.stationData);

  const memberPromise = useCurrentMembers();

  const [showProjectMembers, setShowProjectMembers] = useState(false);

  useEffect(() => {
    memberPromise.then((data) => {
      setMemberData(data);
    });
  }, [activeSpaceId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    db.collection("users")
      .where("email", "==", email)
      .get()
      .then((doc) => {
        if (doc.empty) {
          alert("No user in database. User must be registered");
        } else {
          doc.forEach((data) => {
            db.collection("space")
              .doc(activeSpaceId)
              .update({
                members: fieldValue.arrayUnion(data.data().uid),
              })
              .then(() => {
                alert("user added");
                inputRef.current.value = "";
                setInvite(false);
                history.go(0);
              });
          });
        }
      });
  };

  const handlePrMembSubmit = (e) => {
    e.preventDefault();
    db.collection("users")
      .where("email", "==", email)
      .get()
      .then((doc) => {
        if (doc.empty) {
          alert("No user in database. User must be registered");
        } else {
          doc.forEach((data) => {
            db.collection("space")
              .doc(activeSpaceId)
              .update({
                members: fieldValue.arrayUnion(data.data().uid),
              })
              .then(() => {
                alert("user added");
                inputRef.current.value = "";
                setInvite(false);
                history.go(0);
              });
          });
        }
      });
  };

  // useEffect(() => {
  //   projectmemberPromise.then((data) => {
  //     setProjectMemberData(data);
  //   });
  // }, [activeSpaceId]);

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   db.collection("users")
  //     .where("email", "==", email)
  //     .get()
  //     .then((doc) => {
  //       if (doc.empty) {
  //         alert("No user in database. User must be registered");
  //       } else {
  //         doc.forEach((data) => {
  //           db.collection("space")
  //             .doc(activeSpaceId)
  //             .doc(activeStationId) ??
  //             .update({
  //               members: fieldValue.arrayUnion(data.data().uid),
  //             })
  //             .then(() => {
  //               alert("user added");
  //               inputRef.current.value = "";
  //               setInvite(false);
  //               history.go(0);
  //             });
  //         });
  //       }
  //     });
  // };

  return (
    <div className="members">
      <div className="client__header-tabs">
        <div
          className={
            !showProjectMembers ? "widgetheader" : "widgetheader-nonactive"
          }
          onClick={() => setShowProjectMembers(false)}
        >
          <h3
            data-tooltip-id="workspace-members"
            data-tooltip-content="ℹ️ Workspace members (such as teammates) can access to this dashboard and all the projects of the workspace."
          >
            Team Members
          </h3>{" "}
          <Tooltip id="workspace-members" style={{ width: "400px" }} />
        </div>
        <div
          className={
            showProjectMembers ? "widgetheader" : "widgetheader-nonactive"
          }
          onClick={() => setShowProjectMembers(true)}
        >
          <h3
            data-tooltip-id="project-members"
            data-tooltip-content="ℹ️ Project members (such as clients, contractors, etc.) can only access to the invited project. This keeps the rest of your workspace information private."
          >
            Project Members
          </h3>{" "}
          <Tooltip id="project-members" style={{ width: "400px" }} />
        </div>
      </div>
      <div className="members__contentBox">
        {!showProjectMembers && (
          <div className="members__workspacelevel">
            <div className="members__list">
              {memberData?.map((member) => {
                return (
                  <Member
                    key={member.uid}
                    member={member}
                    activeSpaceData={activeSpaceData}
                    activeSpaceId={activeSpaceId}
                  />
                );
              })}
            </div>
            {invite && (
              <form onSubmit={handleSubmit}>
                <div
                  className="members__invite"
                  onChange={(e) => setEmail(e.target.value)}
                >
                  <RetroInput ref={inputRef} placeholder="email" type="email" />
                </div>
              </form>
            )}
            <div className="members__button">
              {invite && (
                <>
                  <RetroButton mode="gray" onClick={() => setInvite(false)}>
                    cancel
                  </RetroButton>
                  <RetroButton color="info" onClick={(e) => handleSubmit(e)}>
                    Submit
                  </RetroButton>
                </>
              )}
              {!invite && (
                <RetroButton
                  color="info"
                  onClick={() => setInvite(!invite)}
                  type="submit"
                >
                  Invite to Workspace
                </RetroButton>
              )}
            </div>
          </div>
        )}

        {showProjectMembers && (
          <div className="members__list">
            <div className="members__projectlevel">
              {stationData?.map((station) => {
                return (
                  <div className="project_members" key={station.id}>
                      <PrMembers station={station} setShowProjectMembers={setShowProjectMembers} />
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Members;
