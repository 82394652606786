import React, { useState, useEffect } from "react";

import EditorJournal from "../editor/editorjournal";
import AntEditor from "../editor/anteditor/anteditor";

import {
  db,
  fieldValue,
  updateJournalDay,
  storage,
} from "../../firebase/firebase.utils";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { IoChevronBackOutline } from "react-icons/io5";

import "./journal.styles.scss";

export default function Journal({ currentUser }) {
  var emptyEditorState = `{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}`;
  const [customEditorState, setCustomEditorState] = useState(emptyEditorState);

  let initialState = {
    text: "",
  };

  // const [note, setNote] = useState(initialState);
  const [note, setNote] = useState(initialState);

  const [initialValue, setInitialValue] = useState("");
  const [journalContent, setJournalContent] = useState("");

  const [docImgFile, setDocImgFile] = useState(null);
  const [docImgFileAltText, setDocImgFileAltText] = useState("");

  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  let yesterday = new Date(newDate - 86400000);

  const [selectedDate, setSelectedDate] = useState(`${year}-${month}-${date}`);
  const [pickerDate, setPickerDate] = useState(new Date());

  let defaultyesterday = pickerDate.getDate();

  useEffect(() => {
    db.collection("users")
      .doc(currentUser.uid)
      .collection("journals")
      .doc(selectedDate)
      .onSnapshot((doc) => {
        if (doc.exists) {
          let currentjournal = doc.data();
          setInitialValue(currentjournal.journal);
        }
      });
  }, [selectedDate]);

  // useEffect(() => {
  //   db.collection("users")
  //     .doc(currentUser.uid)
  //     .collection("journals")
  //     .doc(selectedDate)
  //     .onSnapshot((doc) => {
  //       if (doc.exists) {
  //         let currentjournal = doc.data();
  //         setCustomEditorState(currentjournal.journal.text);
  //       } else {
  //         setCustomEditorState(emptyEditorState);
  //       }
  //     });
  // }, [selectedDate]);

  const handleSaveForm = () => {
    updateJournalDay(currentUser.uid, selectedDate, journalContent);
  };

  // AUTOSAVE of WORKSPACE BRIEF;
  // useEffect(() => {
  //   if (initialValue !== workspaceBrief) {
  //     console.log("saving...");
  //     const timer = setTimeout(() => {
  //       updateWorkspaceBrief(
  //         currentSpaceId,
  //         workspaceBrief,
  //         currentUser.userName
  //       );
  //       console.log("brief is saved");
  //     }, 3000);
  //     return () => clearTimeout(timer);
  //   }
  // }, [workspaceBrief]);

  const handleUploadDocImage = async (e) => {
    // const file = e.target.files[0];
    const storageRef = storage.ref(`users/${currentUser.uid}/journal`);
    const fileRef = storageRef.child(docImgFile.name);
    // setShowLoading(true);
    await fileRef.put(docImgFile);
    const fileUrl = await fileRef.getDownloadURL();
    // setFeaturedImage(fileUrl);

    // setDocumentImgFile(null);
    // setDocImgFileAltText("");
    return fileUrl;
  };


  return (
    <div className="journal" id="journal">
      <div className="journal__btn-group">
        {/* <button>{date - 1}</button> */}
        {/* <button onClick={() => setPickerDate(new Date(pickerDate - 86400000)) & setSelectedDate(`${year}-${month}-${date}`)}> */}
        <button
          onClick={() =>
            setPickerDate(new Date(pickerDate - 86400000)) &
            setSelectedDate(
              `${new Date(pickerDate - 86400000).getFullYear()}-${
                new Date(pickerDate - 86400000).getMonth() + 1
              }-${new Date(pickerDate - 86400000).getDate()}`
            )
          }
        >
          <IoChevronBackOutline />
        </button>
        <button className="journal__datepicker">
          <DatePicker
            popperProps={{ strategy: "fixed" }}
            wrapperClassName="datepicker"
            // isClearable
            placeholderText="..."
            value={pickerDate}
            // showTimeSelect
            timeFormat="p"
            dateFormat="dd"
            selected={pickerDate}
            maxDate={new Date()}
            onChange={(date) =>
              setPickerDate(date) &
              setSelectedDate(
                `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
              )
            }
          />
        </button>
        <button
          onClick={() =>
            setPickerDate(new Date()) &
            setSelectedDate(`${year}-${month}-${date}`)
          }
          className={
            pickerDate.toDateString() === new Date().toDateString()
              ? "journal__today-btn"
              : "journal__today-btn-passive"
          }
        >
          Today
        </button>
      </div>
      <AntEditor
        mainToolbarOn={false}
        floatingToolbarOn={true}
        placeholder="Write down some ideas, memories, feelings, quick notes, etc..."
        initialValue={initialValue}
        setDocContent={setJournalContent}
        setDocImgFile={setDocImgFile}
        setDocImgFileAltText={setDocImgFileAltText}
        docImgFileAltText={docImgFileAltText}
        handleUploadDocImage={handleUploadDocImage}
      />
      {/* <EditorJournal
        id="editor"
        note={note}
        parentStateChanger={setNote}
        initialEditorState={customEditorState}
        cutomEditorState={customEditorState}
      /> */}
      <div className="journal__form-button" onClick={handleSaveForm}>
        Save
      </div>
    </div>
  );
}
