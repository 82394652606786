import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import { AnimatePresence, motion } from "framer-motion";

import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker-cssmodules.css";
import "react-datepicker/dist/react-datepicker.css";
// import Flatpickr from "react-flatpickr";
// import "flatpickr/dist/flatpickr.css";

import { RRule } from "rrule";

import { Picker } from "emoji-mart";

import Tooltip from "../retro/tooltip/tooltip.component";

import RetroButton from "../retro/button/retro-button.component";
import Avatar from "../retro/avatar/avatar.component";
import Message from "../message/message.component";
import Subtasks from "../dnd/subtasks/subtasks.component";
import Priority from "../modules/priority/priority.component";
import Assign from "../modules/assign/assign-component";
import CreatedBy from "../modules/created-by/created-by.component";
import CreatedDate from "../modules/created-date/created-date.component";
import DaysLeft from "../modules/days-left/days-left.component";

import Duration from "../duration/duration.component";
import Recurring from "../recurring/recurring.component";

import FocusTask from "../focus/focus-task.component";

import {
  BsPlusLg,
  BsArrowsAngleExpand,
  BsArrowRepeat,
  BsReverseLayoutSidebarReverse,
  BsCardText,
  BsCalendar2,
  BsCalendar2CheckFill,
  BsStopwatch,
  BsArrowBarDown,
  BsArrowBarUp,
  BsArrowsExpand,
  BsArrowsCollapse,
  BsEmojiSmileUpsideDown,
  BsTrash,
} from "react-icons/bs";
import { FiSquare, FiCheckSquare } from "react-icons/fi";
import { FaCheckDouble } from "react-icons/fa";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { IoRepeat } from "react-icons/io5";

import {
  db,
  fieldValue,
  createNewTask,
  createNewInboxTask,
  createNewInboxTaskPlus,
  createMessageToTask,
  createCommentToTask,
  // personCommentNotify,
} from "../../firebase/firebase.utils";

import InvitetoPremium from "../marketing/invite-to-premium.component";

// import ControlDaysOfWeek from "./ControlDaysOfWeek";
// import getDiffInDays from "./getDiffInDays";

import moment from "moment";

import "./task-detail.styles.scss";
import ProgressBar from "../progress-bar/progress-bar.component";

// const DEFAULT_EVENT = {
//   title: "",
//   color: "#ff0000",
//   startTime: "09:00",
//   endTime: "18:00",
//   allDay: false,
//   startRecur: undefined,
//   endRecur: undefined,
//   daysOfWeek: undefined
// };

const TaskAdd = ({
  mode,
  task,
  event: eventInitialData,
  onSaveClick,
  onDeleteClick,
  onCancelClick,
  station,
}) => {
  const [event, setEvent] = useState({
    // ...DEFAULT_EVENT,
    ...eventInitialData,
  });

  const users = useSelector((state) => state.user.users);
  const currentUser = useSelector((state) => state.user.currentUser);
  const spaceData = useSelector((state) => state.space.spaceData);
  const stationData = useSelector((state) => state.space.stationData);
  const dispatch = useDispatch();
  const history = useNavigate();
  const location = useLocation();
  const currentSpaceId = location.pathname.split("/")[2];
  const currentStationId = location.pathname.split("/")[4];

  const { wspaceid, projectid } = useParams();

  const navigate = useNavigate();

  const [state, setState] = useState([]);
  const [deleteOpacity, setDeleteOpacity] = useState(0);

  const [showInvitation, setShowInvitation] = useState(false);

  const [isPlaying, setIsPlaying] = useState(false);

  const commenttextareaRef = useRef();

  const [emojiOpen, setEmojiOpen] = useState(false);
  const [emoji, setEmoji] = useState();

  const [reaction, setReaction] = useState();

  const [refrrule, setRefRRule] = useState("");

  const [initialRRule, setInitialRRule] = useState(
    new RRule({ freq: RRule.DAILY, interval: 1 })
  );
  const [modifiedRRule, setModifiedRRule] = useState(null);

  const [rruleString, setRruleString] = useState(null);

  useEffect(() => {
    setState(station);
  }, [station]);

  useEffect(() => {
    if (projectid) {
      setSelectedWorkspaceId(wspaceid);
      setSelectedProjectId(projectid);
      setProjectOptions(stationData);
      //setPhaseOptions
      const fetchPhases = () => {
        db.collection("space")
          .doc(wspaceid)
          .collection("stations")
          .doc(projectid)
          .collection("phases")
          .onSnapshot((querySnapshot) => {
            const array = querySnapshot.docs.map((doc) => {
              return {
                id: doc.get("id"),
                name: doc.get("name"),
              };
            });
            setPhaseOptions([...array]);
            const arrayRef = [...array];
            // const projectIndex = stationData?.findIndex((station) => station?.id === projectid)
            // const stationPhasesArr = Array.from(stationData[projectIndex].phaseOrder)
            // arrayRef.sort((a, b) => (stationPhasesArr.indexOf(a.id) - stationPhasesArr.indexOf(b.id)))
            setSelectedPhaseId(arrayRef[0].id);
          });
      };
      fetchPhases();
    } else if (wspaceid) {
      setSelectedWorkspaceId(wspaceid);
      setProjectOptions(stationData);
      setSelectedProjectId(stationData[0].id);
      //setPhaseOptions
      const fetchPhases = () => {
        db.collection("space")
          .doc(wspaceid)
          .collection("stations")
          .doc(stationData[0].id)
          .collection("phases")
          .onSnapshot((querySnapshot) => {
            const array = querySnapshot.docs.map((doc) => {
              return {
                id: doc.get("id"),
                name: doc.get("name"),
              };
            });
            setPhaseOptions([...array]);
            const arrayRef = [...array];
            // const projectIndex = stationData?.findIndex((station) => station?.id === projectid)
            // const stationPhasesArr = Array.from(stationData[projectIndex].phaseOrder)
            // arrayRef.sort((a, b) => (stationPhasesArr.indexOf(a.id) - stationPhasesArr.indexOf(b.id)))
            setSelectedPhaseId(arrayRef[0].id);
          });
      };
      fetchPhases();
    }
  }, [wspaceid, projectid]);

  // const initialTitle = event.title
  // const initialStartDate = event.startDate
  // const initialEndDate = event.endDate

  const [id, setID] = useState();
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState(null);
  // const [starthour, setStarthour] = useState("");
  const [endDate, setEndDate] = useState(null);
  // const [endhour, setEndhour] = useState("");
  const [duration, setDuration] = useState("00:30");
  const [showduration, setShowDuration] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [allday, setAllday] = useState(false);
  const [alldayinitial, setAlldayInitial] = useState(false);
  const [showtime, setShowTime] = useState(false);
  const [taskdone, setTaskDone] = useState(false);
  const [phase, setPhase] = useState();
  const [progress, setProgress] = useState(0);
  const [type, setType] = useState("task");

  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState("");

  const [reactionpickerOpen, setReactionPickerOpen] = useState(false);
  const [reactionmessageref, setReactionMessageRef] = useState();

  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState(
    `${currentUser.uid}-Inbox`
  );
  const [projectOptions, setProjectOptions] = useState(null);

  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [phaseOptions, setPhaseOptions] = useState(null);

  const [selectedPhaseId, setSelectedPhaseId] = useState(null);

  // useEffect(() => {
  //   if (event) {
  //     setID(event.id);
  //     setTitle(event.title);
  //     setDescription(event.description);
  //     setStartDate(event.startdate);
  //     setEndDate(event.enddate);
  //     setDuration(event.duration);
  //     setTaskDone(event.done);
  //     setAllday(event.allDay);
  //     setPhase(event.phase);
  //     setProgress(event.progress);
  //     setType(event.type);
  //     setRefRRule(event.rrule);
  //   }
  // }, [event]);

  function toHoursAndMinutes(totalMinutes) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return `${padToTwoDigits(hours)}:${padToTwoDigits(minutes)}`;
  }

  function padToTwoDigits(num) {
    return num.toString().padStart(2, "0");
  }

  useEffect(() => {
    if (location.state) {
      setStartDate(location.state.startdate);
      setEndDate(location.state.enddate);
      setDuration(
        toHoursAndMinutes(
          (location.state.enddate - location.state.startdate) / 1000 / 60
        )
      );
      function checkIfitsAllday() {
        const dateobjstart = location.state.startdate;
        const dateobjend = location.state.enddate;
        const startTime = `${dateobjstart.getHours()}:${dateobjstart.getMinutes()}`;
        const endTime = `${dateobjend.getHours()}:${dateobjend.getMinutes()}`;
        if (startTime === endTime) {
          setAllday(true);
        }
      }
      checkIfitsAllday();
    }
  }, [location.state]);

  const onCheckBoxChange = (e) => {
    if (e.target.checked === true) {
      setShowTime(true);
      setAllday(true);
    } else {
      setShowTime(false);
      setAllday(false);
    }
  };

  const onMilestoneChange = (e) => {
    if (e.target.checked === true) {
      setType("milestone");
    } else {
      setType("task");
    }
  };

  const handleUpdateEvent = (prop, value) => {
    setEvent((event) => ({ ...event, [prop]: value }));
  };

  const [more, setMore] = useState(false);
  const [modalsidemode, setModalSideMode] = useState(false);
  const [repeat, setRepeat] = useState(false);

  const handleSetRepeat = (e) => {
    e.preventDefault();
    setRepeat(!repeat);
  };
  const handleSetMore = (e) => {
    e.preventDefault();
    setMore(!more);
  };

  function showMoreOptions() {
    var x = document.getElementById("myDIV");
    if (x.style.display === "none") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  }

  const handleSaveTask = (e) => {
    e.preventDefault();
    if (title === null) {
      alert("Please give the task a title first...");
      return;
    }
    if (selectedWorkspaceId === `${currentUser.uid}-Inbox`) {
      createNewInboxTaskPlus(
        currentUser.uid,
        title,
        description,
        startDate,
        endDate,
        type,
        allday,
        duration,
        progress,
        modifiedRRule
      );
      navigate(-1);
    } else {
      createNewTask(
        selectedWorkspaceId,
        selectedProjectId,
        selectedPhaseId,
        title,
        description,
        currentUser.uid,
        startDate,
        endDate,
        type,
        allday,
        duration,
        progress,
        modifiedRRule
      );
      navigate(-1);
    }
  };

  // useEffect(() => {
  //   const listener = event => {
  //     if (event.code === "Enter" || event.code === "NumpadEnter") {
  //       console.log("Enter key was pressed. Run your function.");
  //       event.preventDefault();
  //       // callMyFunction();
  //       handleSaveTask();
  //     }
  //   };
  //   document.addEventListener("keydown", listener);
  //   return () => {
  //     document.removeEventListener("keydown", listener);
  //   };
  // }, []);

  const handleUpdateTask = (e) => {
    e.preventDefault();
    // console.log("e.fromSpaceId is:", event.fromSpaceId);
    // console.log("userId is:", `${currentUser.uid}-Inbox`);
    if (event.fromSpaceId === `${currentUser.uid}-Inbox`) {
      db.collection("users")
        .doc(currentUser.uid)
        .collection("tasks")
        .doc(event.id)
        .update({
          title: title,
          description: description,
          // done: taskdone,
          startdate: startDate,
          enddate: endDate,
          // duration: duration,
          allDay: allday,
          type: type,
          // progress: progress,
          // rrule: refrrule,
        });
    } else {
      db.collection("space")
        .doc(event.fromSpaceId)
        .collection("stations")
        .doc(event.fromStationId)
        .collection("tasks")
        .doc(id)
        .update({
          title: title,
          description: description,
          done: taskdone,
          startdate: startDate,
          enddate: endDate,
          duration: duration,
          allDay: allday,
          type: type,
          progress: progress,
        });
      if (refrrule) {
        db.collection("space")
          .doc(event.fromSpaceId)
          .collection("stations")
          .doc(event.fromStationId)
          .collection("tasks")
          .doc(id)
          .update({
            rrule: refrrule,
          });
      }
    }
    onCancelClick();
  };

  const handleDeleteTask = (e) => {
    if (event.fromSpaceId === `${currentUser.uid}-Inbox`) {
      db.collection("users")
        .doc(currentUser.uid)
        .collection("tasks")
        .doc(event.id)
        .delete();
    } else {
      db.collection("space")
        .doc(event.fromSpaceId)
        .collection("stations")
        .doc(event.fromStationId)
        .collection("tasks")
        .doc(id)
        .delete();
      db.collection("space")
        .doc(event.fromSpaceId)
        .collection("stations")
        .doc(event.fromStationId)
        .collection("phases")
        .doc(phase)
        .update({
          taskIds: fieldValue.arrayRemove(id),
        });

      //Delete Comments
      db.collection("space")
        .doc(event.fromSpaceId)
        .collection("stations")
        .doc(event.fromStationId)
        .collection("tasks")
        .doc(id)
        .collection("comments")
        .get()
        .then(function (querySnapshot) {
          // Once we get the results, begin a batch
          var batch = db.batch();

          querySnapshot.forEach(function (doc) {
            // For each doc, add a delete operation to the batch
            batch.delete(doc.ref);
          });

          // Commit the batch
          return batch.commit();
        })
        .then(function () {
          // Delete completed!
          // ...
        });
    }
    if (event.assign) {
      const assignedUserRef = db.collection("users").doc(event.assign);
      assignedUserRef.get().then((userRefData) => {
        let assignedTasks = userRefData.data().assignedTasks;
        const findIndex = assignedTasks.findIndex((item) => item.id === id);
        assignedUserRef.update({
          assignedTasks: fieldValue.arrayRemove(assignedTasks[findIndex]),
        });
      });
    }
    onCancelClick();
  };

  useEffect(() => {
    //mounts
    const unsub = db
      .collection("space")
      .doc(currentSpaceId)
      .collection("stations")
      .doc(currentStationId)
      .collection("tasks")
      .doc(id)
      .collection("comments")
      .orderBy("created", "desc")
      .limit(15)
      .onSnapshot((querySnapshot) => {
        // if (querySnapshot.exists) {
        const array = querySnapshot.docs.map((msg) => {
          return {
            id: msg.id,
            created: msg.get("created"),
            from: msg.get("from"),
            fromTaskId: msg.get("fromTaskId"),
            message: msg.get("message"),
            reaction: msg.get("reaction"),
          };
        });
        setComments([...array]);
        // }
      });
    return () => {
      unsub();
    };
  }, [id]);

  const handleCheck = (e) => {
    setTaskDone(!taskdone);
    document.getElementById("done_tone").play();
    db.collection("space")
      .doc(currentSpaceId)
      .collection("stations")
      .doc(currentStationId)
      .collection("tasks")
      .doc(id)
      .update({
        done: taskdone,
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // if (!comment) {
    //   alert("no message");
    //   return;
    // }
    if (comment) {
      createCommentToTask(
        event.fromSpaceId,
        event.fromStationId,
        comment,
        currentUser.uid,
        id,
        currentUser.userName
      );
      commenttextareaRef.current.value = "";
      setComment("");
      // personCommentNotify(
      //   event.fromSpaceId,
      //   event.fromStationId,
      //   id,
      //   event.assign,
      //   currentUser.userName
      // );
    }
  };

  const handlesetEmojiOpen = (e) => {
    e.preventDefault();
    setEmojiOpen(!emojiOpen);
  };

  const handlesetReactionPickerOpen = (e) => {
    e.preventDefault();
    setReactionPickerOpen(!reactionpickerOpen);
  };

  const onSelectEmoji = (emojiObject) => {
    commenttextareaRef.current.focus();
    setEmoji(emojiObject);
    const emoji = emojiObject.native;
    setComment(comment ? comment + emoji : emoji);
    setEmojiOpen(!emojiOpen);
  };

  const handleDeleteComment = (e, msg) => {
    e.preventDefault();
    db.collection("space")
      .doc(currentSpaceId)
      .collection("stations")
      .doc(currentStationId)
      .collection("tasks")
      .doc(id)
      .collection("comments")
      .doc(msg.id)
      .delete();
  };

  const onSelectReaction = (emoji) => {
    setReaction(emoji);
    setReactionPickerOpen(!reactionpickerOpen);
    db.collection("space")
      .doc(currentSpaceId)
      .collection("stations")
      .doc(currentStationId)
      .collection("tasks")
      .doc(id)
      .collection("comments")
      .doc(reactionmessageref)
      .update({ reaction: emoji.native });
  };

  const handleUpdateTaskDuration = (duration) => {
    setDuration(duration);
  };

  const handleWorkspaceSelect = (e) => {
    setSelectedWorkspaceId(e.target.value);
    db.collection("space")
      .doc(e.target.value)
      .collection("stations")
      // .doc(currentStationId)
      // .collection("tasks")
      .onSnapshot((querySnapshot) => {
        const array = querySnapshot.docs.map((doc) => {
          return {
            id: doc.id,
            name: doc.get("name"),
            emojicon: doc.get("emojicon"),
          };
        });
        setProjectOptions([...array]);
        // handleProjectSelect([...array][0].id);
      });
  };

  const handleProjectSelect = (e) => {
    setSelectedProjectId(e.target.value);
    db.collection("space")
      .doc(selectedWorkspaceId)
      .collection("stations")
      .doc(e.target.value)
      .collection("phases")
      .onSnapshot((querySnapshot) => {
        const array = querySnapshot.docs.map((doc) => {
          return {
            id: doc.get("id"),
            name: doc.get("name"),
          };
        });
        setPhaseOptions([...array]);
      });
  };

  const handleExitBtnClicked = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  return (
    <motion.form
      initial={{
        x: "40%",
        y: "35%",
        opacity: 0.5,
        scale: 0.1,
        borderRadius: "50%",
      }}
      animate={{ x: 0, y: 0, opacity: 1, scale: 1, borderRadius: 0 }}
      transition={{ type: "ease-in-out", duration: 0.4 }}
      exit={{ x: "60%", opacity: 0.5, scale: 0.1 }}
      key="addtask"
      className="TaskModal"
      onSubmit={handleSaveTask}
    >
      <div onClick={handleExitBtnClicked} className="overlay"></div>
      <div class={!more ? "modal-content" : "modal-content-full"}>
        <div className="TaskModal__Main">
          <div className="TaskModal__Row1">
            <h4>{mode === "add" ? "Add Task to" : "Edit"}</h4>
            <select className="tm_select" onChange={handleWorkspaceSelect}>
              <option
                // value={station.id}
                // onChange={handleStationChange}
                value={`${currentUser.uid}-Inbox`}
                selected={selectedWorkspaceId === `${currentUser.uid}-Inbox`}
                className="tm_option"
              >
                Inbox
              </option>
              {spaceData?.map((space) => {
                return (
                  <option
                    key={space.spaceId}
                    value={space.spaceId}
                    selected={selectedWorkspaceId === space.spaceId}
                    className="tm_option"
                  >
                    {space.name}
                  </option>
                );
              })}
            </select>
            <button className="exit__button" onClick={handleExitBtnClicked}>
              <BsPlusLg size="1.7em" />
            </button>
          </div>
          {/* <button
          className="sidemode__button"
          onClick={() => setModalSideMode(!modalsidemode)}
        >
          <BsReverseLayoutSidebarReverse size="1.2em" />
        </button> */}

          <div className="TaskModal__Row">
            <div className="tm_breadcrumb">
              {projectOptions && (
                <select className="tm_select" onChange={handleProjectSelect}>
                  {projectOptions.map((project) => (
                    <option
                      key={project.id}
                      value={project.id}
                      selected={selectedProjectId === project.id}
                      className="tm_option"
                    >
                      {project.emojicon}{" "}
                      {project.name}
                    </option>
                  ))}
                </select>
              )}
              {phaseOptions && (
                <select
                  className="tm_select"
                  onChange={(e) => setSelectedPhaseId(e.target.value)}
                  // onChange={handlePhaseChange}
                  // defaultValue={[1]}
                  // defaultValue={phase}
                >
                  {phaseOptions.map((phase) => (
                    <option
                      key={phase.id}
                      value={phase.id}
                      selected={selectedPhaseId === phase.id}
                      className="tm_option"
                    >
                      {phase.name}
                    </option>
                  ))}
                </select>
              )}
            </div>
            {/* <div className="TaskModal__module">
              <FocusTask
                setIsPlaying={setIsPlaying}
                isPlaying={isPlaying}
                task={event}
                title={title}
              />
            </div> */}
            <div className="TaskModal__module">
              {/* <label>Assigned to</label> */}
              <Assign task={event} />
            </div>
          </div>

          <div className="TaskModal__Row2">
            {mode === "edit" && (
              <div
                className={
                  taskdone ? "task__modal-done" : "task__modal-notdone"
                }
              >
                {/* <input type="checkbox"></input> */}
                <i onClick={handleCheck}>
                  {taskdone ? <FiCheckSquare /> : <FiSquare />}
                </i>
                <audio
                  id="done_tone"
                  src="/media/mixkit-cool-interface-click-tone-2568.wav"
                ></audio>
              </div>
            )}
            <div className="modal_info-block">
              <div className="modal_task_name">
                {mode === "add" ? (
                  <textarea
                    autoFocus
                    className="modal_task_name"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder="Task / Event Name..."
                  ></textarea>
                ) : (
                  <textarea
                    className="modal_task_name"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder="Task / Event Name..."
                  ></textarea>
                )}
              </div>
              <div className="tm__description-priority">
                <div className="tm__description-box">
                  <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="Add description"
                  />
                </div>
                <div className="TaskModal__module-priority">
                  {/* <label>Priority</label> */}
                  {/* <BsArrowRepeat /> */}
                  {/* <Tooltip text="Priority" /> */}
                  <Priority task={event} />
                </div>
              </div>
            </div>
            {/* <h3>{title}</h3> */}
            {/* <input
            type="checkbox"
            onChange={(e) => setCompleted(e.target.checked)}
          /> */}
          </div>

          {/* <p>{id}</p> */}
          <div className="TaskModal__Row3">
            <div className="tm__allday">
              <input
                type="checkbox"
                value={allday}
                checked={allday}
                onChange={onCheckBoxChange}
              />
              {/* <BsCalendar2 />
          <BsCalendar2CheckFill /> */}
              <label className={!allday && "tm__alldaytext"}>All day</label>
            </div>
            {showInvitation && (
              <InvitetoPremium
                setShowInvitation={setShowInvitation}
                featureName={"Duration Estimates"}
                featureDesc={
                  "set duration estimates, automatically generate start/end time upon dragging a task on a time-block of the calendar"
                }
                featureImg={
                  "https://images.unsplash.com/photo-1555255675-1d2d8db2d191?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=686&q=80"
                }
                currentUser={currentUser}
              />
            )}
            <Duration
              event={event}
              open={showduration}
              duration={duration}
              setDuration={setDuration}
              setShowInvitation={setShowInvitation}
              showInvitation={showInvitation}
              currentUser={currentUser}
              handleUpdateTaskDuration={handleUpdateTaskDuration}
              // onClick={currentUser.plan == "Free" ? setShowInvitation(true) : setShowInvitation(false)}
            />
            <button className="repeat_icon" onClick={handleSetRepeat}>
              <BsArrowRepeat size="1.5em" />
              <Tooltip text="Repeat" />
              {/* Does not repeat */}
            </button>
          </div>

          {repeat && (
            <div className="TaskModal__Row">
              <Recurring
                initialRRule={initialRRule}
                modifiedRRule={modifiedRRule}
                setModifiedRRule={setModifiedRRule}
                rruleString={rruleString}
                startDateRef={startDate}
              />
            </div>
          )}

          {!repeat && (
            <div className="TaskModal__Row">
              {!showtime ? (
                <div className="twocolumns">
                  <div>
                    <label>start</label>
                    <DatePicker
                      popperProps={{ strategy: "fixed" }}
                      wrapperClassName="datepicker"
                      isClearable
                      placeholderText="Select a start time"
                      value={event.startDate}
                      showTimeSelect
                      // startDate="start"
                      timeFormat="p"
                      timeIntervals={15}
                      timeCaption="time"
                      dateFormat="MMM d, yy | hh:mm aa"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      // note: this onChange is not needed, because it saves to db when it is not active as well
                      // minDate={moment().toDate()}
                      todayButton="today"
                    />
                  </div>
                  <div>
                    <label>end</label>
                    <DatePicker
                      popperProps={{ strategy: "fixed" }}
                      popperPlacement="left-end"
                      popperClassName="date-picker-reports"
                      wrapperClassName="datepicker"
                      isClearable
                      placeholderText="Select end or deadline"
                      // value={event.endDate}
                      showTimeSelect
                      timeFormat="p"
                      timeIntervals={15}
                      dateFormat="MMM d, yy | hh:mm aa"
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      todayButton="today"
                    />
                  </div>
                </div>
              ) : (
                <div className="twocolumnsnarrow">
                  <div>
                    <label>start</label>
                    <DatePicker
                      popperProps={{ strategy: "fixed" }}
                      wrapperClassName="datepicker"
                      isClearable
                      placeholderText="Select a start date"
                      value={event.startDate}
                      // showTimeSelect
                      // startDate="start"
                      timeFormat="p"
                      timeIntervals={15}
                      timeCaption="time"
                      dateFormat="MMMM d, yyyy"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      // note: this onChange is not needed, because it saves to db when it is not active as well
                      // minDate={moment().toDate()}
                      todayButton="today"
                    />
                  </div>
                  <div>
                    <label>end</label>
                    <DatePicker
                      popperProps={{ strategy: "fixed" }}
                      wrapperClassName="datepicker"
                      isClearable
                      placeholderText="Select end or deadline"
                      // value={event.endDate}
                      // showTimeSelect
                      timeFormat="p"
                      timeIntervals={15}
                      dateFormat="MMMM d, yyyy"
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
          {!repeat && (
            <div className="tm__Description-Subtask">
              <div className="lt__subtasks">
                <Subtasks task={event} open opacity="opacityTrue" />
              </div>
            </div>
          )}
          <div className="Buttons__Row">
            <div className="save__button">
              {mode === "add" && (
                <div className="tm__button">
                  <RetroButton
                    type="submit"
                    onClick={handleSaveTask}
                    disabled={repeat ? true : false}
                  >
                    Save
                  </RetroButton>
                </div>
              )}
              {mode === "edit" && (
                <div className="tm__button">
                  <RetroButton
                    type="submit"
                    onClick={handleUpdateTask}
                    disabled={repeat ? true : false}
                  >
                    Update
                  </RetroButton>
                </div>
              )}
              {mode === "edit" && (
                <div className="tm__delete">
                  <RetroButton
                    onClick={handleDeleteTask}
                    disabled={repeat ? true : false}
                  >
                    Delete
                  </RetroButton>
                </div>
              )}
            </div>
            {more ? (
              <button className="more_icon" onClick={handleSetMore}>
                <p>less...</p>
                <BsArrowsCollapse size="2em" />
                <Tooltip text="show less..." />
              </button>
            ) : (
              <button className="more_icon" onClick={handleSetMore}>
                <p>more...</p>
                <BsArrowsExpand size="2em" />
                <Tooltip text="show more..." />
              </button>
            )}
          </div>
        </div>
        {more ? (
          <div
            className={more ? "tm__extra-column" : null}
            style={{ transition: "all 1s ease" }}
          ></div>
        ) : null}
      </div>
    </motion.form>
  );
};

export default TaskAdd;
