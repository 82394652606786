import * as React from "react"
const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={280} height={230} {...props}>
    <title>{"facial-hair/Full"}</title>
    <path
      fill="#000"
      fillRule="evenodd"
      d="M117.725 95.193c8.096-18.967 25.767-32.907 46.111-38.004 14.94-3.733 30.62-3.613 45.85-2.132 7.979.771 16.538 1.403 24.058 4.366 6.421 2.532 11.654 7.317 15.589 12.934 4.684 6.656 7.87 14.274 10.106 22.082.318 1.11.617 2.229.895 3.353-.972 5.891-3.038 10.905-8.494 14.61-5.325 3.616-11.679 5.096-18.013 5.621a28.327 28.327 0 0 1-1.771-.919c-9.87-5.65-16.817-19.002-29.274-17.195-11.117 1.614-18.436 15.602-25.047 23.804-21.222 2.473-42.428-3.904-59.695-16.039-.877-4.153-.927-8.342-.315-12.481m157.033-14.388a81.81 81.81 0 0 0-6.37-13.725c-8.38-14.374-21.242-25.828-36.512-32.375-36.122-15.466-82.86-4.563-112.031 20.803-9.965 8.67-20.04 21.414-23.087 35.12-.91.18-1.769.654-2.476 1.32C73.206 78.68 51.502 65.914 34.518 47.52 22.185 34.155 13.606 18.34 8.353.96c-.43-1.43-2.736-1.22-2.666.362l.06 1.11c-.22-.68-1.259-.33-1.339.33-2.826 21.113-6.361 43.497-3.106 64.72 1.948 12.663 7.7 21.934 15.39 31.945 8.239 10.73 17.567 20.71 27.104 30.282 6.052 6.075 12.374 13.083 19.784 17.538 8.618 5.175 18.395 9.11 27.593 13.104 9.358 4.074 18.855 7.838 28.532 11.101 19.344 6.547 39.338 11.072 59.68 13.054 16.29 1.582 34.904 3.305 50.514-2.823 11.964-4.695 21.621-13.183 29.28-23.395 8.34-11.121 14.932-23.79 18.576-37.15 3.787-13.876-2.199-38.095-2.997-40.332"
    />
  </svg>
)
export default SvgComponent
