import * as React from "react"
const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={392} height={138} {...props}>
    <title>{"accessories/Glasses 3"}</title>
    <path
      fill="#000"
      fillRule="evenodd"
      d="M367.134 98.318c-6.733 9.045-19.215 11.926-30.728 10.931-24.831-2.39-32.646-30.59-33.46-52.51 16.65 4.684-1.408-28.49 55.51-32.138 20.066-1.672 14.534 10.446 23.399 19.3-.182 18.582-3.275 38.773-14.721 54.417M248.863 76.966c-14.66 68.653-94.938 52.323-103.371-9.563 7.787-5.496 5.736-19.067 15.41-23.984 15.332-9.796 34.498-9.377 51.909-9.147 22.255 1.53 28.597 10.748 40.029 20.806-.55 6.718-2.136 14.68-3.977 21.888m150.072-63.735c-.823-.137-1.647-.266-2.471-.387a7.419 7.419 0 0 0-2.276-.815 38.921 38.921 0 0 0-5.984-1.037c-28.532-6.42-94.54 2.696-97.263 27.044-9.086 3.033-16.948.317-27.48.587-8.015-33.505-146.598-28.776-153.263-8.576l-.076-.02c-6.404-1.983-15.845-1.505-23.175-1.819-15.651-.328-31.32 1.067-46.633 4.368-28.675 4.557-67.81 9.892-47.867 45.7l.012.02.003-.004c3.437 3.523 9.27.686 9.487-4.083-6.705-16.964 8.439-19 20.09-23.876 28.994-10.416 58.512-11.86 88.498-8.638 2.256 2.656 5.679 4.972 9.297 6.184 20.049 3.328 9.483 18.2 22.156 20.363-2.328 51.842 60.613 80.14 97.082 46.061 14.56-14.236 18.032-35.325 21.408-54.334 1.785-.4 2.92-1.377 3.583-2.669 4.293-4.286 6.99-8.244 15.479-9.42 8.489-1.177 15.024 1.184 15.908 1.907.223.45.462.9.74 1.35a5.124 5.124 0 0 0 1.516 1.626c-8.383 51.624 31.694 84.272 72.153 57.497 17.58-16.537 21.295-41.855 22.029-64.453 2.383-1.438 3.892-4.225 3.067-7.729-1.137-4.83 1.529-9.012 5.775-11.112 5.517-2.727 4.54-12.686-1.795-13.735"
    />
  </svg>
)
export default SvgComponent
