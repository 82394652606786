import React, { useState } from "react";

import CreateSpace from "../create-space/create-space.component";

import "./no-space-data.styles.scss";

import { GiTeapotLeaves } from "react-icons/gi";

function NoSpaceData() {
  const [create, setCreate] = useState(false);
  return (
    <>
      <div className="noSpaceData">
        <GiTeapotLeaves size="4em" />
        <p>
          No workspaces yet, {/* */} <br/>
          <span className="nsd__span" onClick={() => setCreate(!create)}>
            <b>Create a new Workspace!</b>
          </span>
        </p>
        <div className="nsd__createBtn"></div>
      </div>
      {create && <CreateSpace setLayer={setCreate} />}
    </>
  );
}

export default NoSpaceData;
