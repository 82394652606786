import React, { useEffect, useState } from "react";

import RetroButton from "../../retro/button/retro-button.component";
import RetroInput from "../../retro/input/input.component";

import { BsFolderPlus } from "react-icons/bs";

import "./fstep1.styles.scss";

const FStep1 = ({ folderName, setFolderName, setSteps }) => {
  const [isNameOK, setIsNameOK] = useState(false);
  const unClick = {
    background: "gray",
    PointerEvent: "none",
  };
  const handleNext = () => {
    if (!isNameOK) {
      alert("Folder name is required");
      return;
    }
    setSteps({
      step1: false,
      // step2: true,
    });
  };

  useEffect(() => {
    if (folderName === "" || folderName === "Enter Folder name") {
      setIsNameOK(false);
    } else {
      setIsNameOK(true);
    }
  }, [folderName]);

  return (
    <section className="sectionName">
      <h2>Create Folder</h2>
      <div className="sn__icon">
        <BsFolderPlus size="2.4em" />
      </div>
      <div className="sn__name">
        {/* <p>Folder name</p> */}
        <div onChange={(e) => setFolderName(e.target.value)}>
          <RetroInput placeholder={folderName} />
        </div>
      </div>
      {/* <div className="sn__next">
        <RetroButton
          mode="flat2"
          onClick={() => {
            handleNext();
          }}
          style={isNameOK ? null : unClick}
        >
          Next
        </RetroButton>
      </div> */}
    </section>
  );
};

export default FStep1;



