export const UserData = [
  {
    id: 1,
    workspace: ["Antlogy"],
    hours: 80000,
    userLost: 823,
    color: "#6daed1"
  },
  {
    id: 2,
    workspace: "Andlogy",
    hours: 45677,
    userLost: 345,
    color: "#f8df51"
  },
  {
    id: 3,
    workspace: "Langlogy",
    hours: 78888,
    userLost: 555,
    color: "#e4a380"
  },
  {
    id: 4,
    workspace: "Girlfriend Goal Academy",
    hours: 90000,
    userLost: 4555,
    color: "#c76573"
  },
  {
    id: 5,
    workspace: "Logos the Living & Amplify",
    hours: 4300,
    userLost: 234,
    color: "#a4d1e4"
  },
  {
    id: 6,
    workspace: "Lingua",
    hours: 12300,
    userLost: 234,
    color: "#b2b2b2"
  },
];



// {
//   fromSpaceID: "Lingua",
//   fromStationID: ,
//   taskID: ,
//   timestamp: ,
//   tracked: ,
//   user: ,
// },