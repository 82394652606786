import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import DaysLeft from "../modules/days-left/days-left.component";
import Priority from "../modules/priority/priority.component";
import CreatedBy from "../modules/created-by/created-by.component";
import RetroButton from "../retro/button/retro-button.component";

import RetroInput from "../retro/input/input.component";
import CheckBox from "../ui-elements/checkbox";

import {
  db,
  createNewInboxTask,
  updateTaskAsDone,
  updateInboxTaskAsDone,
  fieldValue,
} from "../../firebase/firebase.utils";

import "./assigned-tasks.styles.scss";

import { IoLeafSharp } from "react-icons/io5";
import { BsChevronRight, BsChevronDown, BsList } from "react-icons/bs";
import { FiSquare, FiCheckSquare } from "react-icons/fi";

const AssignedTaskItem = ({ color, name, inboxTasks }) => {
  const currentUser = useSelector((state) => state.user.currentUser);
  const [showList, setShowList] = useState(true);

  const [newTaskName, setNewTaskName] = useState("");
  const [inputName, setInputName] = useState("");
  const inputRef = useRef();
  const inputNameRef = useRef();

  const [currentTask, setCurrentTask] = useState("");

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMode, setModalMode] = useState("");
  const [activeEvent, setActiveEvent] = useState({});
  const [events, setEvents] = useState([]);

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    createNewInboxTask(currentUser.uid, newTaskName, null);
    inputRef.current.value = "";
    inputRef.current.blur();
  };

  const handleCheck = (task, done) => {
    document.getElementById("done_tone").play();
    const IdRef = currentUser.uid;
    if (task.done) {
      updateInboxTaskAsDone(IdRef, task.id, false);
    } else {
      updateInboxTaskAsDone(IdRef, task.id, true);
      console.log("it says it is not done!");
    }
    // if (task.assign) {
    //   const assignedUserRef = db.collection("users").doc(task.assign);
    //   assignedUserRef.get().then((userRefData) => {
    //     let assignedTasks = userRefData.data().assignedTasks;
    //     const findIndex = assignedTasks.findIndex((item) => item.id === task);
    //     assignedUserRef.update({
    //       assignedTasks: fieldValue.arrayRemove(assignedTasks[findIndex]),
    //     });
    //     assignedUserRef.update({
    //       assignedTasks: fieldValue.arrayUnion(taskrefinfo),
    //     });
    //   });
    // }
  };

  const handleOpenModal = (mode, event) => {
    // setModalMode(mode);
    // setActiveEvent(event);
    // setModalIsOpen(true);
    navigate(`task/${event.id}`);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
    setModalMode("");
    setActiveEvent({});
  };

  const handleSaveEvent = (mode, event) => {
    if (mode === "add") {
      setEvents([...events, { id: events.length + 1, ...event }]);
    } else {
      setEvents(events.map((e) => (e.id === event.id ? event : e)));
    }
    handleCloseModal();
  };

  const handleDeleteEvent = (event) => {
    setEvents(events.filter((e) => e.id !== event.id));
    handleCloseModal();
  };

  const handleEventClick = (task) => {
    console.log("selected task is:", task);
    // console.log("id of the selected task:", task);
    handleOpenModal(
      "edit",
      {
        id: task.id,
        title: task.title,
        description: task.description,
        done: task.done,
        startdate: task.startdate,
        enddate: task.enddate,
        allDay: task.allDay,
        duration: task.duration,
        done: task.done,
        createdBy: task.createdBy,
        createdAt: task.createdAt,
        assign: task.assign,
        phase: task.phase,
        priority: task.priority,
        progress: task.progress,
        type: task.type,
        fromSpaceId: task.fromSpaceId,
        fromStationId: task.fromStationId,
        subtasks: task.subtasks,
      }
      // events.find((e) => e.id.toString() === id.toString())
    );
  };

  return (
    <>
      <div
        className="at__fromspace-list-btn"
        onClick={() => setShowList(!showList)}
      >
        <RetroButton style={{ background: "#1A82C4", color: "white" }}>
          <IoLeafSharp strokeWidth="0.1" />
        </RetroButton>
        {/* <RetroButton style={{ background: color }}>
          {name.charAt(0)}
        </RetroButton> */}
        <h4>{name}</h4>
        {showList ? <BsChevronDown /> : <BsChevronRight />}
      </div>
      <div className={showList ? "at__showlist" : "at__hidelist"}>
        {inboxTasks
          // .filter((task) => task.fromSpaceId === spaceId)
          // .filter((task) => task.done === false)
          .map((task) => {
            // const { fromSpaceId, fromStationId } = task;
            return (
              <div
                key={task.id}
                // className="at__item"
                className="fc-event"
                id={task.id}
                done={task.done}
                title={task.title}
                start={task.startdate}
                end={task.enddate}
                duration={task.duration}
                fromSpaceId={task.fromSpaceId}
                fromStationId={task.fromStationId}
              >
                <div>
                  <RetroButton mode="flat">
                    <div className="at__check-and-name">
                      <div
                        className="at__checkBox"
                        onClick={(id, done) => handleCheck(task, done)}
                      >
                        {task.done ? <FiCheckSquare /> : <FiSquare />}
                        {/* <input type="checkbox" checked={task.done}></input> */}
                        {/* <CheckBox checked={task.done}/> */}
                      </div>
                      <audio
                        id="done_tone"
                        src="/media/mixkit-cool-interface-click-tone-2568.wav"
                      ></audio>
                      <div
                        className="at__content"
                        onClick={() => handleEventClick(task)}
                      >
                        <p>{task.title}</p>
                      </div>
                    </div>
                    <div className="at__modules">
                      <div className="at__daysLeft">
                        <DaysLeft task={task} />
                      </div>
                      <div className="at__priority">
                        <Priority task={task} />
                      </div>
                      {/* <div className="at__by">
                        <CreatedBy task={task} />
                      </div> */}
                    </div>
                  </RetroButton>
                </div>
              </div>
            );
          })}
        <div className="st__newTask st__newTask-inside">
          {showList && (
            <form
              onSubmit={(e) => {
                handleSubmit(e);
              }}
            >
              <div onChange={(e) => setNewTaskName(e.target.value)}>
                <RetroInput ref={inputRef} placeholder="Add Task" />
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  );
};

export default AssignedTaskItem;
