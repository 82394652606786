import React, { useContext, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { showNotesSettings } from "../../redux/settings/settings.actions";

import { Outlet } from "react-router-dom";

import { motion } from "framer-motion";

import NoteCard from "./note-card/note-card";

import NoteAddButton from "../../components/task-add-btn/note-add-btn.component";
import TaskAddButton from "../../components/task-add-btn/task-add-btn.component";

import { HiOutlineTag } from "react-icons/hi2";

import "./notes.styles.scss";

export default function Notes() {
  // const { notes } = useContext(NotesContext);
  const prjNotes = useSelector((state) => state.prjnotes.prjnotes);
  const settingsOn = useSelector((state) => state.settings.showNotesSettings);
  const currentUser = useSelector((state) => state.user.currentUser);
  const dispatch = useDispatch();
  const [labelFilters, setLabelFilters] = useState([]);
  const [filteredNotes, setFilteredNotes] = useState(prjNotes);
  const [isOpen, setIsOpen] = useState(true);

  // console.log("PROJECT NOTES ARE,", prjNotes);
  // console.log("NOTES ARE,", notes);

  // useEffect(() => {
  //   if (isOpen) {
  //     setX(0);
  //   } else {
  //     setX("-200px");
  //   }
  // }, [isOpen]);

  const [showSettings, setShowSettings] = useState(false);
  useEffect(() => {
    if (settingsOn) {
      setShowSettings(true);
    } else {
      setShowSettings(false);
    }
  }, [settingsOn]);

  const handleCloseSettings = () => {
    dispatch(showNotesSettings());
  };

  useEffect(() => {
    setFilteredNotes(prjNotes);
  }, [prjNotes]);

  // useEffect(() => {
  //   setFilteredNotes(notes);
  // }, [notes]);

  useEffect(() => {
    if (labelFilters.length === 0) {
      setFilteredNotes(prjNotes);
    }
  }, [labelFilters]);

  const handleLabelFilterNew = (label) => {
    // const newArr = [];
    // setFilteredNotes(newArr);
    if (labelFilters.includes(label)) {
      const findIndex = labelFilters?.findIndex((item) => item === label);
      const labelFiltersRef = labelFilters;
      labelFiltersRef?.splice(findIndex, 1);
      setLabelFilters(labelFiltersRef);
      console.log("FIRST IF RESULT", labelFiltersRef);
      const filteredNotesRef = Object.values(prjNotes).filter((n) =>
        n.labels.some((r) => labelFilters.includes(r))
      );
      setFilteredNotes(filteredNotesRef);
      if (labelFilters.length === 0) {
        setLabelFilters([]);
      }
    } else {
      const refLabelArray = labelFilters;
      refLabelArray.push(label);
      setLabelFilters(refLabelArray);
      console.log("SECOND IF RESULT", refLabelArray);
      const filteredNotesRef = Object.values(prjNotes).filter((n) =>
        n.labels.some((r) => labelFilters.includes(r))
      );
      setFilteredNotes(filteredNotesRef);
      // let filteredArray = [];
      // const copiedfilteredNotes = [...filteredNotes];
      // notes.forEach((note) => {
      //   let labelArray = note.labels;
      //   if (labelArray.includes(label)) {
      //     copiedfilteredNotes.push(note);
      //   }
      // });
      // setFilteredNotes(copiedfilteredNotes);
    }
  };

  // const contains = arr1.some(element => {
  //   return arr2.indexOf(element) !== -1;
  // });

  const allLabelsRef1 = prjNotes.map((note) => note.labels);
  const allLabels2 = allLabelsRef1.filter((arr) => arr.length > 0);
  const allLabels = allLabels2.flat(1);

  function removeDuplicates(arr) {
    return [...new Set(arr)];
  }

  // const filteredNotes =
  //   labelFilters.length > 0
  //     ? notes.filter((n) => n.labels.some((r) => labelFilters.indexOf(r) >= 0))
  //     : notes;

  return (
    <div className="note-groups">
      <div className="notes__content-container">
        <div className="notes__content">
          <h4>Pinned</h4>
          <div className="notes__container">
            {filteredNotes
              ?.filter((n) => n.isPinned === true)
              .filter((n) => n.isArchived === false)
              .filter((n) => n.private !== true)
              .map((note) => {
                return <NoteCard key={note.id} note={note} />;
              })}
            {prjNotes.filter((n) => n.isPinned === true).length < 1 && (
              <h5>No pinned notes yet.</h5>
            )}
          </div>
          <h4>Notes</h4>
          <div className="notes__container">
            {filteredNotes.filter((n) => n.isPinned === false).length < 1 && (
              <h5>No created notes yet.</h5>
            )}
            {filteredNotes
              ?.filter((n) => n.isPinned === false)
              .filter((n) => n.isArchived === false)
              .filter((n) => n.private !== true)
              .map((note) => {
                return (
                  <NoteCard
                    key={note.id}
                    note={note}
                    // handleLabelFilter={handleLabelFilterNew}
                  />
                );
              })}
          </div>
          <h4>Private Notes</h4>
          <div className="notes__container">
            {filteredNotes.filter((n) => n.isPinned === false).length < 1 && (
              <h5>No created notes yet.</h5>
            )}
            {filteredNotes
              ?.filter((n) => n.private === true)
              .filter((n) => n.lastUpdater === currentUser?.userName)
              .map((note) => {
                return (
                  <NoteCard
                    key={note.id}
                    note={note}
                    // handleLabelFilter={handleLabelFilterNew}
                  />
                );
              })}
          </div>
          <h4>Archive</h4>
          <div className="notes__container">
            {filteredNotes
              ?.filter((n) => n.isPinned === false)
              .filter((n) => n.isArchived === true)
              .filter((n) => n.private !== true)
              .map((note) => {
                return (
                  <NoteCard
                    key={note.id}
                    note={note}
                    // handleLabelFilter={handleLabelFilterNew}
                  />
                );
              })}
            {filteredNotes.filter((n) => n.isArchived === true).length ===
              0 && (
              <h5>No archived notes yet.</h5>
            )}
          </div>
        </div>
      </div>
      <motion.div
        className="notes-labels__container"
        animate={{ marginRight: isOpen ? "0px" : "-100px"}}
        transition={{ ease: "easeOut" }}
      >
        <motion.div
          className="labelcolumn__hide"
          animate={{ marginRight: isOpen ? "0px" : "-100px" }}
          transition={{ ease: "easeOut" }}
          onClick={() => setIsOpen(!isOpen)}
        >
          <span>Labels</span>
          <HiOutlineTag size="1em" />
        </motion.div>
        <button
          className={
            labelFilters.length === 0
              ? "label_filter_btn-active"
              : "label_filter_btn"
          }
          onClick={() => setLabelFilters([])}
        >
          All
        </button>
        <div className="notes-innerlabels">
          {removeDuplicates(allLabels).map((label) => {
            const labelisactive = labelFilters.includes(label);
            return (
              <button
                className={
                  labelisactive ? "label_filter_btn-active" : "label_filter_btn"
                }
                key={label}
                onClick={() => handleLabelFilterNew(label)}
              >
                {label}
              </button>
            );
          })}
        </div>
      </motion.div>
      <Outlet />
      <NoteAddButton />
      <TaskAddButton />
    </div>
  );
}

// style={{
//   background: labelisactive ? "#1A82C4" : "white",
//   color: labelisactive ? "white" : "black",
// }}
