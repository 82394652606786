import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Routes, Route, useLocation } from "react-router-dom";
import { db } from "../../firebase/firebase.utils";

import { setPrjPhases } from "../../redux/prjphases/prjphases.actions";
import { setPrjTasks } from "../../redux/prjtasks/prjtasks.actions";
import { setPrjNotes } from "../../redux/prjnotes/prjnotes.actions";

import ProtectedRoutes from "../../components/protectedRoutes/protectedRoutes.component";

import {
  statusFilterFunction,
  timeFilterFunction,
  personFilterFunction,
} from "../../utils/taskFilters";

import Board from "../board/board.component";
import List from "../list/list.component";
import Calendar from "../calendar/calendar.component";
import GanttView from "../gantt/gantt.component";
import ConceptView from "../concept/concept.component";
import Files from "../files/files.component";
// import Notes from "../notes/notes.component";
import Notes from "../notes/notes";
import ChessMain from "../chess/chess.main.component";
import StationMenu from "../../components/create-station/station-menu/station-menu.component";

import TaskDetail from "../../components/task-detail/task-detail.component";
import TaskAdd from "../../components/task-detail/task-add.component";

import NoteDetail from "../../components/note-detail/note-detail";

import { BsPlusLg } from "react-icons/bs";

import "./enter-station.stayles.scss";

const EnterStation = (isAuth) => {
  // const history = useNavigate();
  const filter = useSelector((state) => state.filter);
  const location = useLocation();
  const currentSpaceId = location.pathname.split("/")[2];
  const currentStationId = location.pathname.split("/")[4];
  const [station, setStation] = useState([]);
  const [stationTasks, setStationTasks] = useState([]);
  const [stationNotes, setStationNotes] = useState([]);
  // const [stationTasksFL, setstationTasksFL] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!currentSpaceId) return;
    if (!currentStationId) return;
    db.collection("space")
      .doc(currentSpaceId)
      .collection("stations")
      .doc(currentStationId)
      .onSnapshot((stationData) => {
        setStation(stationData.data());
      });
  }, [currentSpaceId, currentStationId]);

  useEffect(() => {
    if (!currentSpaceId) return;
    if (!currentStationId) return;
    db.collection("space")
      .doc(currentSpaceId)
      .collection("stations")
      .doc(currentStationId)
      .collection("phases")
      .onSnapshot((querySnapshot) => {
        dispatch(setPrjPhases(querySnapshot.docs.map((doc) => doc.data())));
      });
  }, [currentSpaceId, currentStationId]);

  useEffect(() => {
    if (!currentSpaceId) return;
    if (!currentStationId) return;
    db.collection("space")
      .doc(currentSpaceId)
      .collection("stations")
      .doc(currentStationId)
      .collection("tasks")
      .onSnapshot((querySnapshot) => {
        setStationTasks(querySnapshot.docs.map((doc) => doc.data()));
        dispatch(setPrjTasks(querySnapshot.docs.map((doc) => doc.data())));
      });
  }, [currentSpaceId, currentStationId]);

  useEffect(() => {
    if (!currentSpaceId) return;
    if (!currentStationId) return;
    db.collection("space")
      .doc(currentSpaceId)
      .collection("stations")
      .doc(currentStationId)
      .collection("notes")
      .orderBy("createdAt", "desc")
      .onSnapshot((querySnapshot) => {
        setStationNotes(
          querySnapshot.docs.map((doc) => {
            return { ...doc.data(), id: doc.id };
          })
        );
        dispatch(
          setPrjNotes(
            querySnapshot.docs.map((doc) => {
              return { ...doc.data(), id: doc.id };
            })
          )
        );
      });
  }, [currentSpaceId, currentStationId]);

  // useEffect(() => {
  //   //FILTERS should be here and modify stationTasks...
  //   const stationTasksFLRef = stationTasks.filter((task) => {
  //     if (
  //       statusFilterFunction(task, filter) === false ||
  //       timeFilterFunction(task, filter) === false ||
  //       personFilterFunction(task, filter) === false
  //     ) {
  //       return false;
  //     } else {
  //       return true;
  //     }
  //   });
  //   setstationTasksFL(stationTasksFLRef)
  // }, [currentSpaceId, currentStationId, stationTasks, filter]);

  const stationTasksFL = stationTasks.filter((task) => {
    if (
      statusFilterFunction(task, filter) === false ||
      timeFilterFunction(task, filter) === false ||
      personFilterFunction(task, filter) === false
    ) {
      return false;
    } else {
      return true;
    }
  });

  return (
    <div className="enterStation">
      <StationMenu />
      <Routes>
        {/* <Route
          element={
            <ProtectedRoutes
              isAuth={isAuth}
              station={station}
              stationTasks={stationTasks}
              stationTasksFL={stationTasksFL}
              stationNotes={stationNotes}
            />
          }
        > */}
        <Route
          path="b"
          element={
            <Board
              station={station}
              stationTasks={stationTasks}
              stationTasksFL={stationTasksFL}
              stationNotes={stationNotes}
            />
          }
        >
          <Route
            path="task/:taskId"
            element={
              <TaskDetail
                station={station}
                stationTasks={stationTasks}
                stationTasksFL={stationTasksFL}
                stationNotes={stationNotes}
                mode="edit"
              />
            }
          />
          <Route
            path="addtask"
            element={
              <TaskAdd
                station={station}
                stationTasks={stationTasks}
                stationTasksFL={stationTasksFL}
                stationNotes={stationNotes}
                mode="add"
              />
            }
          />
        </Route>
        <Route
          path="l"
          element={
            <List
              station={station}
              stationTasks={stationTasks}
              stationTasksFL={stationTasksFL}
              stationNotes={stationNotes}
            />
          }
        >
          <Route
            path="task/:taskId"
            element={
              <TaskDetail
                station={station}
                stationTasks={stationTasks}
                stationTasksFL={stationTasksFL}
                stationNotes={stationNotes}
                mode="edit"
              />
            }
          />
          <Route
            path="addtask"
            element={
              <TaskAdd
                station={station}
                stationTasks={stationTasks}
                stationTasksFL={stationTasksFL}
                stationNotes={stationNotes}
                mode="add"
              />
            }
          />
        </Route>
        <Route
          path="c"
          element={
            <Calendar
              station={station}
              stationTasks={stationTasks}
              stationTasksFL={stationTasksFL}
              stationNotes={stationNotes}
            />
          }
        >
          <Route
            path="task/:taskId"
            element={
              <TaskDetail
                station={station}
                stationTasks={stationTasks}
                stationTasksFL={stationTasksFL}
                stationNotes={stationNotes}
                mode="edit"
              />
            }
          />
          <Route
            path="addtask"
            element={
              <TaskAdd
                station={station}
                stationTasks={stationTasks}
                stationTasksFL={stationTasksFL}
                stationNotes={stationNotes}
                mode="add"
              />
            }
          />
        </Route>
        <Route
          path="g"
          element={
            <GanttView
              station={station}
              stationTasks={stationTasks}
              stationTasksFL={stationTasksFL}
              stationNotes={stationNotes}
            />
          }
        >
          <Route
            path="task/:taskId"
            element={
              <TaskDetail
                station={station}
                stationTasks={stationTasks}
                stationTasksFL={stationTasksFL}
                stationNotes={stationNotes}
                mode="edit"
              />
            }
          />
          <Route
            path="addtask"
            element={
              <TaskAdd
                station={station}
                stationTasks={stationTasks}
                stationTasksFL={stationTasksFL}
                stationNotes={stationNotes}
                mode="add"
              />
            }
          />
        </Route>
        <Route
          path="m"
          element={
            <ConceptView
              station={station}
              stationTasks={stationTasks}
              stationTasksFL={stationTasksFL}
              stationNotes={stationNotes}
            />
          }
        >
          <Route
            path="task/:taskId"
            element={
              <TaskDetail
                station={station}
                stationTasks={stationTasks}
                stationTasksFL={stationTasksFL}
                stationNotes={stationNotes}
                mode="edit"
              />
            }
          />
          <Route
            path="addtask"
            element={
              <TaskAdd
                station={station}
                stationTasks={stationTasks}
                stationTasksFL={stationTasksFL}
                stationNotes={stationNotes}
                mode="add"
              />
            }
          />
        </Route>
        <Route
          path="f"
          element={
            <Files
              station={station}
              stationTasks={stationTasks}
              stationTasksFL={stationTasksFL}
              stationNotes={stationNotes}
            />
          }
        >
          <Route
            path="addtask"
            element={
              <TaskAdd
                station={station}
                stationTasks={stationTasks}
                stationTasksFL={stationTasksFL}
                stationNotes={stationNotes}
                mode="add"
              />
            }
          />
          </Route>
        <Route
          path="n"
          element={
            <Notes
              station={station}
              stationTasks={stationTasks}
              stationTasksFL={stationTasksFL}
              stationNotes={stationNotes}
            />
          }
        >
          <Route
            path="task/:taskId"
            element={
              <TaskDetail
                station={station}
                stationTasks={stationTasks}
                stationTasksFL={stationTasksFL}
                stationNotes={stationNotes}
                mode="edit"
              />
            }
          />
          <Route
            path="addtask"
            element={
              <TaskAdd
                station={station}
                stationTasks={stationTasks}
                stationTasksFL={stationTasksFL}
                stationNotes={stationNotes}
                mode="add"
              />
            }
          />
          <Route
            path="note/:noteId"
            element={
              <NoteDetail
                station={station}
                stationTasks={stationTasks}
                stationTasksFL={stationTasksFL}
                stationNotes={stationNotes}
                mode="edit"
              />
            }
          />
          <Route
            path="addnote"
            element={
              <NoteDetail
                station={station}
                stationTasks={stationTasks}
                stationTasksFL={stationTasksFL}
                stationNotes={stationNotes}
                mode="add"
              />
            }
          />
        </Route>
        <Route path="/chesstime" element={<ChessMain station={station} />} />
        {/* </Route> */}
      </Routes>
    </div>
  );
};

export default EnterStation;
