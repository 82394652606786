import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";

import { setStartDate } from "../../../firebase/firebase.utils";

import Tooltip from "../../retro/tooltip/tooltip.component";

import "./start-date.styles.scss";

import { FaCalendarAlt } from "react-icons/fa";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const StartDate = ({ task }) => {
  const spaceId = useSelector((state) => state.history.spaceId);
  const stationId = useSelector((state) => state.history.stationId);
  const [date, setDate] = useState();
  const [time, setTime] = useState();

  useMemo(() => {
    if (!task) return;
    if (task.startdate === null) return;
    let d = task.startdate.toDate();
    let day = d.getDate();
    let month = d.getMonth();
    let hour = d.getHours();
    let minute = d.getMinutes();
    setDate(`${day}.${month + 1}`);
    setTime(`Start time: ${hour}:${minute}`);
  }, [task]);

  // const handleDate = (e) => {
  //   console.log(e.target.value);
  //   let dd = new Date(e.target.value);
  //   setStartDate(spaceId, stationId, dd, task.id);
  // };

  return (
    <div className="startdate">
      {/* <input type="datetime-local" onChange={(e) => handleDate(e)} /> */}
      <Tooltip text={date ? time : "No start date"}/>
      {!date && <FaCalendarAlt />}
      <p>{date}</p>
    </div>
  );
};

export default StartDate;
