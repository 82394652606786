import React, { useState } from "react";

import { BsPlusLg, BsX } from "react-icons/bs";

import "./labels.component.styles.scss";

export default function Labels({
  labels,
  handleLabelSubmit,
  handleRemoveLabel,
  setLabel,
  label,
}) {
  const [inputOpen, setInputOpen] = useState(false);

  return (
    <div className="notes__form-actions-sub-labels">
      {labels?.map((lbl) => {
        return (
          <div key={lbl} className="label_form_btn">
            {lbl}
            <button className="label_form_btn-delete" onClick={() => handleRemoveLabel(lbl)}>
              <BsX size="1.4em" />
            </button>
          </div>
        );
      })}
      <div className="label_form_btn-plus">
        <button
          style={{
            transform: inputOpen ? "rotate(45deg)" : "rotate(0deg)",
            transition: "0.2s all ease-in",
          }}
          onClick={() => setInputOpen(!inputOpen) & setLabel("")}
        >
          <BsPlusLg size="1.4em" />
        </button>
        {inputOpen && (
          <form onSubmit={handleLabelSubmit}>
            <input
              type="text"
              placeholder="Add label"
              value={label}
              onChange={(event) => setLabel(event.target.value)}
              autoFocus={inputOpen}
            />
          </form>
        )}
      </div>
    </div>
  );
}
