import React from "react";

import { FaCaretDown } from "react-icons/fa";

import "./tooltip.styles.scss";

const Tooltip = ({ text }) => {
  return (
    <div className="tooltip">
      <div className="tooltip__text">{text}</div>
      <div className="tooltip__arrow">
        <FaCaretDown />
      </div>
    </div>
  );
};

export default Tooltip;
