import React, { useMemo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { setTimeZone } from "../../../redux/filter/filter.actions";

import "./duration.styles.scss";

import { FaCalendarTimes } from "react-icons/fa";
import { GiStopwatch } from "react-icons/gi";

import { BsStopwatch } from "react-icons/bs";

import Tooltip from "../../retro/tooltip/tooltip.component";

const EstDuration = ({ task }) => {
  const [daysLeft, setDaysLeft] = useState(null);
  const [daysLeftText, setDaysLeftText] = useState("");
  const [daysColor, setDaysColor] = useState("gray");
  const [mins, setMins] = useState(null);
  const dispatch = useDispatch();
  const { created, startdate, deadline } = task;

  const [showduration, setShowDuration] = useState(false);
  const [duration, setDuration] = useState(false);
  const [showinvitation, setShowInvitation] = useState(false);

  const currentUser = useSelector((state) => state.user.currentUser);

  const handlesetShowDuration = (e) => {
    e.preventDefault();
    setShowDuration(!showduration);
  };


  useMemo(() => { 
    if (!task) return;
    if (!task.duration) return;
    setDuration(task.duration)
  },[duration, task]);

  useMemo(() => {
    if (!created) return;
    if (!startdate) return;
    if (!deadline) return;

    if (deadline.seconds === undefined) return;
    let today = new Date();
    let sd = startdate.toDate();
    let dd = deadline.toDate();

    let tdt = today.getTime();
    let sdt = sd.getTime();
    let ddt = dd.getTime();

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = ddt - sdt;

    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);

    setDaysLeft(diffInDays);

    // if (daysLeft < 0) setDaysColor("rgb(226, 68, 92)");
    // if (daysLeft >= 0) setDaysColor("rgb(52, 181, 228)");
    // if (daysLeft > 5) setDaysColor("rgb(65, 163, 0)");

    // if (daysLeft === 0) {
    //   setDaysLeftText("today");
    // }
    if (daysLeft === 0) {
      setDaysLeftText("30m");
    }
    if (daysLeft < 0) {
      setDaysLeftText(`${daysLeft} days`);
    }
    // if (daysLeft === 1) {
    //   setDaysLeftText(`tomorrow`);
    // }
    if (daysLeft > 1 && daysLeft < 8) {
      setDaysLeftText(`${daysLeft}d`);
    }
    if (daysLeft > 7) {
      setDaysLeftText(`${daysLeft}d`);
    }
  }, [daysLeft, task]);

  // useEffect(() => {
  //   if (daysLeft === 0) {
  //     const state = { taskId: task.id, zone: 0 };
  //     dispatch(setTimeZone(state));
  //     return;
  //   }
  //   if (daysLeft < 0) {
  //     const state = { taskId: task.id, zone: null };
  //     dispatch(setTimeZone(state));
  //     return;
  //   }
  //   if (daysLeft === 1) {
  //     const state = { taskId: task.id, zone: 1 };
  //     dispatch(setTimeZone(state));
  //     return;
  //   }
  //   if (daysLeft > 1 && daysLeft < 8) {
  //     const state = { taskId: task.id, zone: 7 };
  //     dispatch(setTimeZone(state));
  //     return;
  //   }
  //   if (daysLeft > 7) {
  //     const state = { taskId: task.id, zone: 30 };
  //     dispatch(setTimeZone(state));
  //     return;
  //   }
  // }, [daysLeft]);

  return (
    // <div className="daysLeft">
    //   {daysLeft === null && (
    //     <>
    //       <Tooltip text="Duration" /> <GiStopwatch />
    //     </>
    //   )}
    //   <p style={{ color: daysColor }}>{daysLeftText}</p>
    // </div>
    <div className="tm__durationest">
    {/* <BsStopwatch onClick={() => setShowDuration(!showduration)} /> */}
    {/* <input
      value={duration}
      onChange={(e) => setDuration(e.target.value)}
      placeholder="Duration est."
    ></input> */}
    <p>{duration}</p>
    {/* {currentUser.plan == "Free" ? (
      <button onClick={() => setShowInvitation(!false)}>
        <BsStopwatch size="1.3em" />
      </button>
    ) : (
      <button onClick={handlesetShowDuration}>
        <BsStopwatch size="1.3em" />
      </button>
    )} */}

    <Tooltip text="Duration Estimate" />

    {showduration && (
      <div className="duration__container">
        <div
          className="duration__tag"
          // onClick={handlesetDuration(false)}
          onClick={() => setDuration("00:05") & setShowDuration(false)}
        >
          5m
        </div>
        <div
          className="duration__tag"
          onClick={() => setDuration("00:10") & setShowDuration(false)}
        >
          10m
        </div>
        <div
          className="duration__tag"
          onClick={() => setDuration("00:15") & setShowDuration(false)}
        >
          15m
        </div>
        <div
          className="duration__tag"
          onClick={() => setDuration("00:20") & setShowDuration(false)}
        >
          20m
        </div>
        <div
          className="duration__tag"
          onClick={() => setDuration("00:25") & setShowDuration(false)}
        >
          25m
        </div>
        <div
          className="duration__tag"
          onClick={() => setDuration("00:30") & setShowDuration(false)}
        >
          30m
        </div>
        <div
          className="duration__tag"
          onClick={() => setDuration("00:45") & setShowDuration(false)}
        >
          45m
        </div>
        <div
          className="duration__tag"
          onClick={() => setDuration("01:00") & setShowDuration(false)}
        >
          1h
        </div>
        <div
          className="duration__tag"
          onClick={() => setDuration("01:15") & setShowDuration(false)}
        >
          1h 15m
        </div>
        <div
          className="duration__tag"
          onClick={() => setDuration("01:30") & setShowDuration(false)}
        >
          1h 30m
        </div>
        <div
          className="duration__tag"
          onClick={() => setDuration("02:00") & setShowDuration(false)}
        >
          2h
        </div>
        <div
          className="duration__tag"
          onClick={() => setDuration("03:00") & setShowDuration(false)}
        >
          3h
        </div>
        <div
          className="duration__tag"
          onClick={() => setDuration("04:00") & setShowDuration(false)}
        >
          4h
        </div>
      </div>
    )}
  </div>
  );
};

export default EstDuration;
