import React, { useState } from "react";

import "./colors.styles.scss";

function Colors({ returnColor, defColor }) {
  const [customColor, setCustomColor] = useState("");
  const [showButton, setShowButton] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    returnColor(e.target.value);
    setShowButton(false);
  };
  return (
    <div className="colors">
      <div
        className="colors__color color1"
        onClick={() => returnColor("#3b3b3b")}
      ></div>
      <div
        className="colors__color color2"
        onClick={() => returnColor("#89afb4")}
      ></div>
      <div
        className="colors__color color3"
        onClick={() => returnColor("#d4745e")}
      ></div>
      <div
        className="colors__color color4"
        onClick={() => returnColor("#eecd81")}
      ></div>
      <div
        className="colors__color color5"
        onClick={() => returnColor("#bf94b2")}
      ></div>
      <div
        className="colors__color color6"
        onClick={() => returnColor("#826860")}
      ></div>
      <div
        className="colors__color color7"
        onClick={() => returnColor("#b887ac")}
      ></div>
      <div
        className="colors__color color8"
        onClick={() => returnColor("#d1624f")}
      ></div>
      <div
        className="colors__color color9"
        onClick={() => returnColor("#d6d85a")}
      ></div>
      <div
        className="colors__color color10"
        onClick={() => returnColor("#39a7a4")}
      ></div>
      <div
        className="colors__color color11"
        onClick={() => returnColor("#6c77a7")}
      ></div>
      <div
        className="colors__color color12"
        onClick={() => returnColor("#f8df51")}
      ></div>
      <div
        className="colors__color color13"
        onClick={() => returnColor("#e4a380")}
      ></div>
      <div
        className="colors__color color14"
        onClick={() => returnColor("#dc8e8d")}
      ></div>
      <div
        className="colors__color color15"
        onClick={() => returnColor("#6daed1")}
      ></div>
      <div
        className="colors__color color16"
        onClick={() => returnColor("#727272")}
      ></div>
      <div
        className="colors__color color17"
        onClick={() => returnColor("#daa4bb")}
      ></div>
      <div
        className="colors__color color18"
        onClick={() => returnColor("#ebc194")}
      ></div>
      <div
        className="colors__color color19"
        onClick={() => returnColor("#efe47f")}
      ></div>
      <div
        className="colors__color color20"
        onClick={() => returnColor("#a4d1e4")}
      ></div>
      <div
        className="colors__color color21"
        onClick={() => returnColor("#8dc2b0")}
      ></div>
      <div
        className="colors__color color22"
        onClick={() => returnColor("#aec977")}
      ></div>
      <div
        className="colors__color color23"
        onClick={() => returnColor("#7398c0")}
      ></div>
      <div
        className="colors__color color24"
        onClick={() => returnColor("#b2b2b2")}
      ></div>
      <div
        className="colors__color color25"
        onClick={() => returnColor("#aca1be")}
      ></div>
      <div
        className="colors__color color26"
        onClick={() => returnColor("#c76573")}
      ></div>
      <div onClick={() => setShowButton(true)}>
        <input
          className="colorpicker_rounded"
          type="color"
          value={customColor}
          onChange={(e) => setCustomColor(e.target.value)}
        />
        {showButton && (
          <button className="colorpicker_btn" onClick={handleSubmit}>
            ✓ Confirm Selection
          </button>
        )}
      </div>
    </div>
  );
}

export default Colors;
