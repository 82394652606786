import React from "react";
import "./ContextMenu.scss";

export const ContextMenu = ({ top, left, menuItems, onMenuItemClick }) => {
  return (
    <div style={{ top, left }} className="context-menu-container">
      {menuItems.map((menuItem, index) => {
        return (
          <div
            key={index}
            className="context-menu-item-container"
            onClick={() => onMenuItemClick(menuItem.label)}
          >
            <div>{menuItem.icon}</div>
            <div>{menuItem.label}</div>
          </div>
        );
      })}
    </div>
  );
};
