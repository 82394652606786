import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useLocation, useNavigate } from "react-router-dom";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import RetroButton from "../../../components/retro/button/retro-button.component";

import { BsPlusLg, BsArrowsAngleExpand, BsReverseLayoutSidebarReverse } from "react-icons/bs";
import { IoRepeat } from "react-icons/io5";

import { db } from "../../../firebase/firebase.utils";

import ControlDaysOfWeek from "./ControlDaysOfWeek";
import getDiffInDays from "./getDiffInDays";

import moment from 'moment';

import "./EventModal.scss";

// const DEFAULT_EVENT = {
//   title: "",
//   color: "#ff0000",
//   startTime: "09:00",
//   endTime: "18:00",
//   allDay: false,
//   startRecur: undefined,
//   endRecur: undefined,
//   daysOfWeek: undefined
// };

const EventModal = ({ mode,
  event: eventInitialData,
  onSaveClick,
  onDeleteClick,
  onCancelClick,
  station }) => {
  const [event, setEvent] = useState({
    // ...DEFAULT_EVENT,
    ...eventInitialData
  });



  const users = useSelector((state) => state.user.users);
  const dispatch = useDispatch();
  // const history = useNavigate();
  const location = useLocation();
  const currentSpaceId = location.pathname.split("/")[2];
  const currentStationId = location.pathname.split("/")[4];
  const [state, setState] = useState([]);
  const [deleteOpacity, setDeleteOpacity] = useState(0);

  useEffect(() => {
    setState(station);
  }, [station]);

  // const initialTitle = event.title
  // const initialStartDate = event.startDate
  // const initialEndDate = event.endDate


  const [id, setID] = useState(event.id);
  const [title, setTitle] = useState(event.title);
  const [startDate, setStartDate] = useState(event.startDate);
  // const [starthour, setStarthour] = useState("");
  const [endDate, setEndDate] = useState(event.endDate);
  // const [endhour, setEndhour] = useState("");
  const [duration, setDuration] = useState("01:30");
  const [completed, setCompleted] = useState(false);
  // const [allday, setAllday] = useState(false);
  const [allday, setAllday] = useState(false);
  const [alldayinitial, setAlldayInitial] = useState(event.allDay);
  const [showtime, setShowTime] = useState(event.allDay);
  const onCheckBoxChange = e => {
    if (e.target.checked === true) {
      setShowTime(true);
      setAllday(true);
    } else {
      setShowTime(false);
      setAllday(false);
    }
  }

  const handleUpdateEvent = (prop, value) => {
    setEvent((event) => ({ ...event, [prop]: value }));
  };

  const [more, setMore] = useState(false);
  const [modalsidemode, setModalSideMode] = useState(false);
  const [repeat, setRepeat] = useState(false);

  function showMoreOptions() {
    var x = document.getElementById("myDIV");
    if (x.style.display === "none") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  }



  const handleSaveEvent = (e) => {
    e.preventDefault();
    if (mode === "add") {

      db.collection("space")
        .doc(currentSpaceId)
        .collection("stations")
        .doc(currentStationId)
        .collection("tasks")
        .add({
          title: title,
          startdate: startDate,
          enddate: endDate,
          // starttime: event.startTime,
          duration: duration,
          allDay: allday,
        });
    } else {

    }
    onCancelClick()
  };

  // useEffect(() => {
  //   const listener = event => {
  //     if (event.code === "Enter" || event.code === "NumpadEnter") {
  //       console.log("Enter key was pressed. Run your function.");
  //       event.preventDefault();
  //       // callMyFunction();
  //       handleSaveEvent();
  //     }
  //   };
  //   document.addEventListener("keydown", listener);
  //   return () => {
  //     document.removeEventListener("keydown", listener);
  //   };
  // }, []);

  const handleUpdateTask = (e) => {
    db.collection("space")
      .doc(currentSpaceId)
      .collection("stations")
      .doc(currentStationId)
      .collection("tasks")
      .doc(id)
      .update({
        title: title,
        startdate: startDate,
        enddate: endDate,
        // starttime: event.startTime,
        duration: duration,
        allDay: allday,
      });
    onCancelClick()
  };

  const handleDeleteTask = (e) => {
    db.collection("space")
      .doc(currentSpaceId)
      .collection("stations")
      .doc(currentStationId)
      .collection("tasks")
      .doc(id)
      .delete();
    onCancelClick()
  };

  const [sttaken, setStTaken] = useState([]);
  useEffect(() => {
    //mounts
    const unsub = db
      .collection("space")
      .doc(currentSpaceId)
      .collection("stations")
      // .doc(currentStationId)
      // .collection("tasks")
      .onSnapshot((querySnapshot) => {
        const array = querySnapshot.docs.map((doc) => {
          return {
            id: doc.id,
            name: doc.get("name"),
          };
        });
        setStTaken([...array]);
      });

    return () => {
      unsub();
    };
  }, []);



  const [selected, setSelected] = useState("");

  const handleStationChange = (e) => {
    const value = e.target.value;
    setSelected(value);
    db.collection("space")
      .doc(currentSpaceId)
      .collection("stations")
      .doc(selected)
      .collection("tasks")
      .add({
        title: title,
        startdate: startDate,
        enddate: endDate,
        // starttime: event.startTime,
        duration: duration,
        allDay: allday,
      });

    db.collection("space")
      .doc(currentSpaceId)
      .collection("stations")
      .doc(currentStationId)
      .collection("tasks")
      .doc(id)
      .delete();
  }

  return (
    <form className="EventModal" onSubmit={handleSaveEvent} >
      <div onClick={() => onCancelClick()} className="overlay"></div>
      <div class={!modalsidemode ? "modal-content" : "modal-content-sidemode"}>
        <button className="exit__button" onClick={() => onCancelClick()}> <BsPlusLg size="1.7em" /></button>
        <button className="sidemode__button" onClick={() => setModalSideMode(!modalsidemode)}> <BsReverseLayoutSidebarReverse size="1.2em" /></button>
        <h4>{mode === "add" ? "Add New" : "Edit"}</h4>
        <div className="EventModal__Row">
          <input type="checkbox" onChange={(e) => setCompleted(e.target.checked)} /><label>Complete</label>
          <select>
            {sttaken.map((station) => (
              <option key={id} value={station.id} onChange={handleStationChange}>
                {station.name}
              </option>
            ))}
          </select>
        </div>
        <p>{id}</p>
        <input value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Task / Event Name"></input>
        <input type="checkbox" value={alldayinitial} checked={alldayinitial} onChange={onCheckBoxChange} /><label>All day</label>


        <div className="EventModal__Row">
          {
            !showtime ?
              <div className="twocolumns">
                <DatePicker wrapperClassName="datepicker"
                  isClearable
                  placeholderText="Start"
                  value={event.startDate}
                  showTimeSelect
                  // startDate="start"
                  timeFormat="p"
                  timeIntervals={15}
                  timeCaption="time"
                  dateFormat="MMM d, yyyy  |  hh:mm aa"
                  selected={startDate}
                  onChange={date => setStartDate(date)}
                  // note: this onChange is not needed, because it saves to db when it is not active as well
                  // minDate={moment().toDate()}
                  todayButton="today"
                />
                <DatePicker wrapperClassName="datepicker"
                  isClearable
                  placeholderText="End"
                  // value={event.endDate}
                  showTimeSelect
                  timeFormat="p"
                  timeIntervals={15}
                  dateFormat="MMM d, yyyy  |  hh:mm aa"
                  selected={endDate}
                  onChange={date => setEndDate(date)}
                  todayButton="today"
                />
              </div>
              :
              <div className="twocolumnsnarrow">
                <DatePicker wrapperClassName="datepicker"
                  isClearable
                  placeholderText="Start"
                  value={event.startDate}
                  // showTimeSelect
                  // startDate="start"
                  timeFormat="p"
                  timeIntervals={15}
                  timeCaption="time"
                  dateFormat="MMM d, yyyy"
                  selected={startDate}
                  onChange={date => setStartDate(date)}
                  // note: this onChange is not needed, because it saves to db when it is not active as well
                  // minDate={moment().toDate()}
                  todayButton="today"
                />
                <DatePicker wrapperClassName="datepicker"
                  isClearable
                  placeholderText="End"
                  // value={event.endDate}
                  // showTimeSelect
                  timeFormat="p"
                  timeIntervals={15}
                  dateFormat="MMM d, yyyy"
                  selected={endDate}
                  onChange={date => setEndDate(date)}
                />
              </div>
          }
        </div>


        <div className="Buttons__Row">
          {/* <button
            type="submit"
          >
            Submit
          </button> */}
          <div className="save__button">
            {/* <RetroButton
              onClick={() =>
                onSaveClick(mode, {
                  ...event,
                  startTime: event.allDay ? undefined : event.startTime,
                  endTime: event.allDay ? undefined : event.endTime
                })
              }
            >
              Save
            </RetroButton> */}
            {mode === "add" && (
              <RetroButton
                type="submit"
                onClick={handleSaveEvent}
              >
                Save
              </RetroButton>
            )}
            {mode === "edit" && (
              <RetroButton
                type="submit"
                onClick={handleUpdateTask}
              >
                Save
              </RetroButton>
            )}
            {/* {mode === "edit" && (
              <RetroButton
                onClick={() =>
                  onDeleteClick({
                    ...event,
                    startTime: event.allDay ? undefined : event.startTime,
                    endTime: event.allDay ? undefined : event.endTime
                  })
                }
              >
                Delete
              </RetroButton>
            )} */}
            {mode === "edit" && (
              <RetroButton
                onClick={handleDeleteTask}
              >
                Delete
              </RetroButton>
            )}
          </div>
        </div>
        <button className="repeat_icon" onClick={() => setRepeat(!repeat)}><IoRepeat size="2em" /> Does not repeat </button>
        {repeat ? <div className={repeat ? "more_repeat__column" : null}>
          <div className="EventModal__Row">
            <label>Repeat every</label><input className="inverval_number" placeholder="1"></input>
            <select name="interval" id="interval">
              <option value="1">Day</option>
              <option value="2">Week</option>
              <option value="3">Month</option>
              <option value="4">Year</option>
            </select>
          </div>
          <label>Repeat on</label>
          <div className="EventModal__Row">
            <input type="checkbox" id="mo"></input> <label for="mo">Mo</label>
            <input type="checkbox" id="tu"></input> <label for="tu">Tu</label>
            <input type="checkbox" id="we"></input> <label for="we">We</label>
            <input type="checkbox" id="th"></input> <label for="th">Th</label>
            <input type="checkbox" id="fr"></input> <label for="fr">Fr</label>
            <input type="checkbox" id="sa"></input> <label for="sa">Sa</label>
            <input type="checkbox" id="su"></input> <label for="su">Su</label>
          </div>
          <label>Ends</label>
          <div className="EventModal__Row">
            <input placeholder="A date"></input>
            <input type="checkbox" id="never"></input> <label for="never">Never</label>
          </div>
          <div className="EventModal__Row">
            <input type="checkbox" id="after"></input> <label for="after">After</label>
            <input className="inverval_number" value="1" id="freq"></input><label for="freq">occurence(s)</label>
          </div>
        </div> : null}

        {more ? <div className={more ? "more_right__column" : null}>
          <input placeholder="Assign"></input>
          <input placeholder="Priority"></input>
          <input value={duration} onChange={(e) => setDuration(e.target.value)} placeholder="Duration est."></input>
          <input placeholder="Dependency"></input>
          <textarea rows="6" placeholder="Description"></textarea>
          <input placeholder="Subtasks"></input>
          <textarea rows="6" placeholder="Write a comment or an update"></textarea>
        </div> : null}
        <button className="more_icon" onClick={() => setMore(!more)}><BsArrowsAngleExpand size="1.5em" /> more... </button>
      </div>
    </form>
  );
}

export default EventModal;


// const [show, setShow] = useState(true);

//   return (
//     <>
//       {show ? <h1>Welcome to CodeSandBox</h1> : null}
//       <button onClick={() => setShow(!show)}>Show / Hide</button>
//     </>



{/* Regular Date Time Pickers */ }
{/* <div className="EventModal__Row">
          <input value={event.startDate} type="date" label="Start Date" />
          <input value={event.endDate} type="date" label="End Date" />
        </div>
        <div className="EventModal__Row">
          <input value={event.start} type="time" label="Start Time" />
          <input type="time" label="End Time" />
        </div> */}

{/* Flatpickr */ }
{/* <div className="EventModal__Row">
          <Flatpickr
            style={{
              width: 120,
            }}
            value={event.startDate}
            options={{
              // dateFormat: "M j, Y",
              inline: false,
              enableTime: false,
              noCalendar: false,
            }}
          />
          <Flatpickr
            style={{
              width: 120
            }}
            value={event.endDate}
            options={{
              inline: false,
              enableTime: false,
              noCalendar: false,
            }}
          />
        </div>
        <div className="EventModal__Row">
          <Flatpickr
            style={{
              width: 120
            }}
            value={event.startDate}
            options={{
              inline: false,
              enableTime: true,
              noCalendar: true,
            }}
          />
          <Flatpickr
            style={{
              width: 120
            }}
            value={event.endDate}
            options={{
              inline: false,
              enableTime: true,
              noCalendar: true,
            }}
          />
        </div> */}

{/* React DatePicker */ }
{/* <div className="EventModal__Row">
          <DatePicker wrapperClassName="datepicker"
            isClearable
            placeholderText="Start"
            value={event.startDate}
            showTimeSelect
            // startDate="start"
            timeFormat="p"
            timeIntervals={15}
            timeCaption="time"
            dateFormat="MMM d, yyyy  |  hh:mm aa"
            selected={startDate}
            onChange={date => setStartDate(date)}
            // note: this onChange is not needed, because it saves to db when it is not active as well
            // minDate={moment().toDate()}
            todayButton="today"
          />
          <DatePicker wrapperClassName="datepicker"
            isClearable
            placeholderText="End"
            // value={event.endDate}
            showTimeSelect
            timeFormat="p"
            timeIntervals={15}
            dateFormat="MMM d, yyyy  |  hh:mm aa"
            selected={endDate}
            onChange={date => setEndDate(date)}
          />
        </div> */}
