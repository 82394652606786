import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import "./emoji.styles.scss";
// import data from '@emoji-mart/data'
// import data from '@emoji-mart/data/sets/14/twitter.json'
import Picker from '@emoji-mart/react'

export default function EmojiPicker({ onEmojiSelect, onClickOutside }) {
    const [themeName, setThemeName] = useState("light")
    const theme = useSelector((state) => state.theme.theme);
    const location = useLocation()

    useEffect(() => {
        if (theme === "dark-theme") {
            setThemeName("dark")
        } else {
            setThemeName("light")
        }
    }, [theme, location])

    return (
        <>
            <style>
                {`em-emoji-picker {
                    --rgb-accent: 26, 130, 196;
                    }`}
            </style>
            <Picker
                // data={data}
                native={true}
                // set="twitter"
                theme={themeName}
                showPreview={false}
                showSkinTones={true}
                emojiTooltip={true}
                autoFocus={true}
                // emojiSize={24}
                perLine={7}
                color="#1a82c4"
                // emojiButtonColors={['#1a82c4']}
                sheetSize={15}
                style={{
                    position: "absolute",
                    // maxWidth: "250px",
                    maxHeight: "250px",
                    // with: "100%",
                    outline: "none",
                    // fontSize:"16px"
                }}
                onEmojiSelect={onEmojiSelect}
                // onClickOutside={onClickOutside}
                skinTonePosition="search"
            />
        </>

    );
}
