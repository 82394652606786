import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import MeetingApp from "./MeetingApp";

import firepadRef, {
  db,
  fieldValue,
  dbMeet,
} from "../../firebase/firebase.utils";

import "./MeetingStarter.scss";

import { BsCameraVideo, BsFillTelephoneOutboundFill } from "react-icons/bs";

// function GenerateRoomId({ }) {

//   // const location = useLocation();
//   // const currentSpaceId = location.pathname.split("/")[2];
//   // const currentStationId = location.pathname.split("/")[4];

//   // const workspaceRoomName = "AntLogy/" + currentSpaceId;

//   // const urlparams = new URLSearchParams(window.location.search);
//   // const roomId = urlparams.get("id");

//   const roomID = "mKXpX3ffMATbwmApThhO"

//   return roomIdTest;
// }

// function getCurrentURL() {
//   return window.location.href
// }

// Example

function MeetingStarter({
  callStatus,
  setCallStatus,
  handleStartCall,
  setMeetingFullScreen,
  meetingFullScreen,
  showInstantChat,
  sendtomode,
  sendto,
  currentrecipient,
  currentSpaceName,
  currentStationName,
  selectedGroupName,
}) {
  const currentUser = useSelector((state) => state.user.currentUser);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleCallPerson = () => {
    setCallStatus(true);

    let convRefId =
      currentUser.uid < currentrecipient.uid
        ? currentUser.uid + currentrecipient.uid
        : currentrecipient.uid + currentUser.uid;
    window.history.pushState(null, "Meet", "?meet=" + convRefId);

    // db.collection("users")
    //   .doc(currentrecipient.uid)
    //   .collection("pnotifications")
    //   .add({
    //     title: `${currentUser.userName}`,
    //     body: `is calling you`,
    //     click_action: `https://antlogy.com/app/?meet=${convRefId}`,
    //     icon: currentUser.imageUrl,
    //     type: "incoming call",
    //     seen: false,
    //     created: fieldValue.serverTimestamp(),
    //   });
    db.collection("users")
      .doc(currentrecipient.uid)
      .collection("notifiedcalls")
      .add({
        from: currentUser.uid,
        fromImg: currentUser.imageUrl,
        fromName: currentUser.userName,
        click_action: convRefId,
        type: "privatecall",
        seen: false,
        created: fieldValue.serverTimestamp(),
      });
  };
  // if (sendtomode = "toworkspace") {
  //   GenerateRoomId(sendto)
  // }
  // if (sendtomode = "toproject") {
  //   GenerateRoomId(sendto)
  // }
  // if (sendtomode = "toperson") {
  //   GenerateRoomId(sendto)
  // }
  const realtimeDbRoomRef = "RoomIdDatabaseRefsomething";

  return (
    <>
      {callStatus && (
        <MeetingApp
          // onLeave={() => {
          //   setCallStatus(false);
          // }}
          setMeetingFullScreen={setMeetingFullScreen}
          meetingFullScreen={meetingFullScreen}
          showInstantChat={showInstantChat}
          setCallStatus={setCallStatus}
          callStatus={callStatus}
        />
      )}
      {!callStatus && (
        <div
          classname="meeting__joinbutton"
          style={{
            margin: "55px 0px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {sendto ? (
            <>
              <button style={{ lineHeight: "1.5em" }} onClick={handleStartCall}>
                <BsCameraVideo size="5em" />
                {currentrecipient ? (
                  <h3>Join 1-on-1 meeting</h3>
                ) : (
                  <h3>Join team meeting</h3>
                )}
                <h3>with</h3>
              </button>
              {sendtomode === "toperson" && currentrecipient && (
                <div
                  classname="meeting__callperson"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <button
                    classname="meeting__callperson-name"
                    style={{ lineHeight: "1.5em" }}
                    onClick={handleCallPerson}
                  >
                    <h3>
                      <strong
                        style={{
                          fontWeight: "600",
                        }}
                      >
                        {currentrecipient?.userName}
                      </strong>{" "}
                    </h3>
                  </button>
                </div>
              )}
              {sendtomode === "toworkspace" && (
                <div classname="meeting__callperson">
                  <button
                    style={{ lineHeight: "1.5em" }}
                    classname="meeting__callperson-name"
                    onClick={handleStartCall}
                  >
                    <h3>
                      <strong style={{ fontWeight: "600" }}>
                        {/* {currentSpaceName} */}
                        {selectedGroupName}
                      </strong>{" "}
                      workspace
                    </h3>
                  </button>
                </div>
              )}
              {sendtomode === "toproject" && (
                <div classname="meeting__callperson">
                  <button
                    style={{ lineHeight: "1.5em" }}
                    classname="meeting__callperson-name"
                    onClick={handleStartCall}
                  >
                    <h3>
                      <strong style={{ fontWeight: "600" }}>
                        {/* {currentStationName} */}
                        {selectedGroupName}
                      </strong>{" "}
                      project
                    </h3>
                  </button>
                </div>
              )}
            </>
          ) : (
            <div
              classname="meeting__norecipient"
              style={{
                margin: "55px 30px",
                display: "flex",
                flexDirection: "column",
                color: "#000",
              }}
            >
              <h3
                style={{
                  color: "#000",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                Select a group or a person from below to join a meeting
              </h3>
            </div>
          )}
        </div>
      )}
    </>
  );
}

function RoomIdConfigure() {
  // const currentSpaceId = location.pathname.split("/")[2];
  // const currentStationId = location.pathname.split("/")[4];
  const meetingId = localStorage.getItem("meetingId");
  var full_url = window.location.pathname; // Get current url
  const currentSpaceId = full_url.split("/")[2];
  const currentStationId = full_url.split("/")[4];
  // var currentSpaceId = url_array[2];  // Get the last part of the array (-1)
  const urlparams = new URLSearchParams(window.location.search);
  const roomIdRef = urlparams.get("meet");
  // const roomId = roomIdRef?.length > 0 ? roomIdRef : "public";
  // const roomId = currentSpaceId?.length > 0 ? currentSpaceId : "public";
  const roomId = roomIdRef?.length > 0 ? roomIdRef : "public";

  return roomId;

  // var full_url = window.location.pathname; // Get current url
  // var url_array = full_url.split('/') // Split the string into an array with / as separator
  // // var last_segment = url_array[url_array.length - 1];  // Get the last part of the array (-1)
  // var currentSpaceId = url_array[2];  // Get the last part of the array (-1)
  // var currentStationId = url_array[4];  // Get the last part of the array (-1)
  // // if (last_segment.length > 0) { return last_segment } else return full_url
  // if (currentStationId?.length > 0) { return currentStationId } else if (currentSpaceId?.length > 0) { return currentSpaceId } else return full_url
}

const roomIdTest = RoomIdConfigure();

export { MeetingStarter, roomIdTest };
