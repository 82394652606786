import React, { useState, useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import { db, fieldValue } from "../../firebase/firebase.utils";

import Client from "./lead-client/lead-client.component";

// import Tooltip from "../retro/tooltip/tooltip.component";
import { Tooltip } from "react-tooltip";

import "./leads-clients.styles.scss";

import { BsPlus } from "react-icons/bs";
import {
  TbTransform,
  TbBrandLinkedin,
  TbBrandFacebook,
  TbBrandInstagram,
  TbWorldWww,
  TbUserCheck,
  TbUserPlus,
  TbUserX,
  TbAffiliate,
} from "react-icons/tb";

import { FiSquare, FiCheckSquare } from "react-icons/fi";

const clientlibrary = [
  {
    name: "Bathe",
    id: "bathe",
    icon: "🛁",
  },
  {
    name: "Clean",
    id: "clean",
    icon: "🧹",
  },
  {
    name: "Skincare",
    id: "skincare",
    icon: "🧴",
  },
  {
    name: "Read",
    id: "read",
    icon: "📚",
  },
];

export default function LeadsClients({ currentUser }) {
  const [clients, setClients] = useState([]);
  const [clientIcon, setClientIcon] = useState("");
  const [clientName, setClientName] = useState("");
  const [clientPlatform, setClientPlatform] = useState("LinkedIn");
  const [clientEmail, setClientEmail] = useState("");
  const [clientPhone, setClientPhone] = useState("");
  const [referredBy, setReferredBy] = useState("");
  const [type, setType] = useState("Lead");
  const [clientSocialLink, setClientSocialLink] = useState("");
  const [clientWebLink, setClientWebLink] = useState("");
  const [clientNotes, setClientNotes] = useState("");
  const [clientProjects, setClientProjects] = useState([]);

  const [clientmodalMode, setClientModalMode] = useState("");
  const [showclientmodal, setShowClientModal] = useState(false);

  const [showAllClients, setShowAllClients] = useState(false);

  const [activeClient, setActiveClient] = useState("");

  const [activeTab, setActiveTab] = useState("Lead");

  const [checkedToday, setCheckedToday] = useState(0);

  const history = useNavigate();
  const location = useLocation();
  const currentSpaceId = location.pathname.split("/")[2];

  let newDate = new Date();
  let date = ("0" + newDate.getDate()).slice(-2);
  let month = ("0" + (newDate.getMonth() + 1)).slice(-2);
  let year = newDate.getFullYear();

  const [selectedDate, setSelectedDate] = useState(`${year}-${month}-${date}`);

  useEffect(() => {
    //mounts
    const unsub = db
      .collection("space")
      .doc(currentSpaceId)
      .collection("clients")
      .onSnapshot((querySnapshot) => {
        if (querySnapshot) {
          const array = querySnapshot.docs.map((doc) => {
            return {
              id: doc.id,
              email: doc.get("email"),
              history: doc.get("history"),
              name: doc.get("name"),
              notes: doc.get("notes"),
              phone: doc.get("phone"),
              platform: doc.get("platform"),
              projects: doc.get("projects"),
              referredBy: doc.get("referredBy"),
              type: doc.get("type"),
              weblink: doc.get("weblink"),
            };
          });
          setClients([...array]);
        }
      });

    return () => {
      unsub();
    };
  }, [currentSpaceId]);

  const handleClientClick = (client) => {
    setClientModalMode("edit");
    setActiveClient(client);
    setClientIcon(client.icon);
    setClientName(client.name);
    setShowClientModal(true);
  };

  const handleAddBtnClick = () => {
    setClientModalMode("add");
    setClientIcon("❔");
    setClientName("");
    setShowClientModal(true);
  };

  const handleSaveClient = (e) => {
    e.preventDefault();
    if (clientmodalMode === "add") {
      const recordobjzero = {
        date: selectedDate,
        checked: 0,
      };
      db.collection("space")
        .doc(currentSpaceId)
        .collection("clients")
        .add({
          email: clientEmail,
          name: clientName,
          notes: clientNotes,
          phone: clientPhone,
          history: [recordobjzero],
          platform: clientPlatform,
          projects: clientProjects,
          referredBy: referredBy,
          type: type,
          weblink: clientWebLink,
          created: new Date(),
          archived: false,
        });
    } else {
      db.collection("space")
        .doc(currentSpaceId)
        .collection("clients")
        .doc(activeClient.id)
        .update({
          email: clientEmail,
          name: clientName,
          notes: clientNotes,
          phone: clientPhone,
          platform: clientPlatform,
          projects: clientProjects,
          referredBy: referredBy,
          type: type,
          weblink: clientWebLink,
        });
    }
    setShowClientModal(false);
  };

  const handleConvertClient = (e) => {
    // e.preventDefault();
    if (activeClient.type === "Client") {
      db.collection("space")
        .doc(currentSpaceId)
        .collection("clients")
        .doc(activeClient.id)
        .update({
          type: "Lead",
        });
    } else if (activeClient.type === "Lead") {
      db.collection("space")
        .doc(currentSpaceId)
        .collection("clients")
        .doc(activeClient.id)
        .update({
          type: "Client",
        });
    }
  };

  const handleConvertClientFromList = (client) => {
    // e.preventDefault();
    if (client.type === "Client") {
      db.collection("space")
        .doc(currentSpaceId)
        .collection("clients")
        .doc(client.id)
        .update({
          type: "Lead",
        });
    } else if (client.type === "Lead") {
      db.collection("space")
        .doc(currentSpaceId)
        .collection("clients")
        .doc(client.id)
        .update({
          type: "Client",
        });
    }
  };

  const handleDeleteClient = (e) => {
    e.preventDefault();
    db.collection("space")
      .doc(currentSpaceId)
      .collection("clients")
      .doc(activeClient.id)
      .delete();
    setShowClientModal(false);
  };

  const handleCloseClient = () => {
    if (clientmodalMode === "add") {
      setShowClientModal(false);
      setClientName("");
      setClientIcon("");
    } else {
      setShowClientModal(false);
      setClientName("");
      setClientIcon("");
    }
  };

  function checkTodaysHistory(client) {
    const historyarray = client.history;
    const findIndex = historyarray.findIndex(
      (item) => item.date === selectedDate
    );
    if (findIndex === -1 || historyarray[findIndex].checked === 0)
      return <FiSquare />;
    else return <FiCheckSquare />;
  }

  const handleCheckInClient = (client) => {
    setCheckedToday(!checkedToday);
    document.getElementById("client-check_tone").play();

    const recordobj = {
      date: selectedDate,
      checked: 1,
    };

    const recordobjzero = {
      date: selectedDate,
      checked: 0,
    };

    const historyarray = client.history;
    //check if today's object exist
    const findIndex = historyarray.findIndex(
      (item) => item.date === selectedDate
    );
    if (findIndex === -1) {
      historyarray.push(recordobj);
    } else if (historyarray[findIndex].checked === 0) {
      historyarray.splice(findIndex, 1);
      historyarray.splice(findIndex, 0, recordobj);
    } else {
      historyarray.splice(findIndex, 1);
      historyarray.splice(findIndex, 0, recordobjzero);
    }

    db.collection("space")
      .doc(currentSpaceId)
      .collection("clients")
      .doc(client.id)
      .set(
        {
          history: historyarray,
        },
        { merge: true }
      );
  };

  return (
    <div id="clients">
      <div className="client__header-tabs">
        <button
          className={
            activeTab === "Lead"
              ? "client__header-tab"
              : "client__header-tab-nonactive"
          }
          onClick={() => setActiveTab("Lead")}
        >
          <h3>Leads</h3>
        </button>
        <button
          className={
            activeTab === "Client"
              ? "client__header-tab"
              : "client__header-tab-nonactive"
          }
          onClick={() => setActiveTab("Client")}
        >
          <h3>Clients</h3>
        </button>
      </div>
      <div className="clients">
        <div className="widgetbtns">
          <button className="client__today-btn">Archive</button>
        </div>
        <div className="clientcontent">
          {clients
            .filter((cl) => cl.type === activeTab)
            .map((client) => {
              const { name, type, id, platform } = client;
              return (
                <div key={id} className="clientline-main">
                  <div
                    onClick={() => handleClientClick(client)}
                    className="clientinfo"
                  >
                    <div className="client-progress-icon">
                      {platform === "LinkedIn" && <TbBrandLinkedin />}
                      {platform === "Facebook" && <TbBrandFacebook />}
                      {platform === "Instagram" && <TbBrandInstagram />}
                      {platform === "Web" && <TbWorldWww />}
                      {platform === "Client Referral" && <TbUserCheck />}
                      {platform === "Friend Referral" && <TbUserPlus />}
                      {platform === "Former Client" && <TbUserX />}
                      {platform === "Affiliate" && <TbAffiliate />}
                    </div>
                    <h5>{name}</h5>
                  </div>
                  <div className="client-lead-btngroup">
                    <div
                      className="client-lead-convert-btn"
                      onClick={() => handleConvertClientFromList(client)}
                      data-tooltip-id="client-convert-tooltip"
                      data-tooltip-content={
                        activeTab === "Lead"
                          ? "Convert to client"
                          : "Convert to lead"
                      }
                    >
                      <TbTransform />
                    </div>
                    <Tooltip id="client-convert-tooltip" />
                    <div
                      onClick={() => handleCheckInClient(client)}
                      className="client-checkbox"
                      data-tooltip-id="client-checkin-box"
                      data-tooltip-content={
                        activeTab === "Lead" ? "Check-in" : "Check-in"
                      }
                    >
                      {/* {checkedToday > 0 ? <FiCheckSquare /> : <FiSquare />} */}
                      {checkTodaysHistory(client)}
                    </div>
                    <Tooltip id="client-checkin-box" />
                    <audio
                      id="client-check_tone"
                      src="/media/mixkit-pen-clicking-twice-2371.wav"
                    ></audio>
                  </div>
                </div>
              );
            })}
        </div>
        <div className="widgetbtns">
          <button
            onClick={() => handleAddBtnClick() & setType(activeTab)}
            className="clientadd-btn"
          >
            <BsPlus />
            Add
          </button>
        </div>
      </div>
      {showclientmodal && (
        <Client
          currentUser={currentUser}
          client={activeClient}
          clientIcon={clientIcon}
          setClientIcon={setClientIcon}
          clientName={clientName}
          setClientName={setClientName}
          handleCloseClient={handleCloseClient}
          handleSaveClient={handleSaveClient}
          handleDeleteClient={handleDeleteClient}
          clientmodalMode={clientmodalMode}
          clientPlatform={clientPlatform}
          setClientPlatform={setClientPlatform}
          clientProjects={clientProjects}
          setClientProjects={setClientProjects}
          clientEmail={clientEmail}
          setClientEmail={setClientEmail}
          clientPhone={clientPhone}
          setClientPhone={setClientPhone}
          referredBy={referredBy}
          setReferredBy={setReferredBy}
          type={type}
          setType={setType}
          clientSocialLink={clientSocialLink}
          setClientSocialLink={setClientSocialLink}
          clientWebLink={clientWebLink}
          setClientWebLink={setClientWebLink}
          clientNotes={clientNotes}
          setClientNotes={setClientNotes}
          activeTab={activeTab}
          handleConvertClient={handleConvertClient}
          currentSpaceId={currentSpaceId}
          setSelectedDate={setSelectedDate}
        />
      )}
    </div>
  );
}
