import React, { useRef, useState } from 'react';
import { Chess } from 'chess.js';

import { Chessboard } from 'react-chessboard';

import "./chess.styles.scss";

import {
    BsArrowCounterclockwise,
    BsCup,
} from "react-icons/bs";

export default function SingleChessBoard({ boardWidth }) {
    const [game, setGame] = useState(new Chess());
    const [currentTimeout, setCurrentTimeout] = useState(undefined);
    const chessboardRef = useRef();

    const [themedarkcolor, setThemeDarkColor] = useState('#787878');
    const [themelightcolor, setThemeLightColor] = useState('#F2F3F5');

    const pieces = ['wP', 'wN', 'wB', 'wR', 'wQ', 'wK', 'bP', 'bN', 'bB', 'bR', 'bQ', 'bK'];
    const customPieces = () => {
        const returnPieces = {};
        pieces.map((p) => {
            returnPieces[p] = ({ squareWidth }) => (
                <div
                    style={{
                        width: squareWidth,
                        height: squareWidth,
                        backgroundImage: `url(/media/${p}.png)`,
                        backgroundSize: '100%',
                    }}
                />
            );
            return null;
        });
        return returnPieces;
    };

    const [piecesPreference, setPiecesPreference] = useState(customPieces());

    function onChangeTheme(event) {
        if (event.target.value === "Green") {
            setThemeDarkColor('#779952');
            setThemeLightColor('#edeed1')
        }
        if (event.target.value === "Blue") {
            setThemeDarkColor('#4B7399');
            setThemeLightColor('#EAE9D2')
        }
        if (event.target.value === "Mono") {
            setThemeDarkColor('#787878');
            setThemeLightColor('#F2F3F5')
        }
        if (event.target.value === "Brown") {
            setThemeDarkColor('#B58863');
            setThemeLightColor('#F0D9B5')
        }
    }

    function safeGameMutate(modify) {
        setGame((g) => {
            const update = { ...g };
            modify(update);
            return update;
        });
    }

    function makeRandomMove() {
        const possibleMoves = game.moves();

        // exit if the game is over
        if (game.game_over() || game.in_draw() || possibleMoves.length === 0) return;

        const randomIndex = Math.floor(Math.random() * possibleMoves.length);
        safeGameMutate((game) => {
            game.move(possibleMoves[randomIndex]);
        });
        document.getElementById("chess_slide").play();
    }

    function onDrop(sourceSquare, targetSquare) {
        document.getElementById("chess_slide").play();
        const gameCopy = { ...game };
        const move = gameCopy.move({
            from: sourceSquare,
            to: targetSquare,
            promotion: 'q' // always promote to a queen for example simplicity
        });
        setGame(gameCopy);

        // illegal move
        if (move === null) return false;

        // store timeout so it can be cleared on undo/reset so computer doesn't execute move
        const newTimeout = setTimeout(makeRandomMove, 2000);
        setCurrentTimeout(newTimeout);
        return true;
    }

    return (
        <div className="chess-container">
            <div className="chess__buttongroup">
                <button
                    className="chess__button"
                    onClick={() => {
                        // undo twice to undo computer move too
                        safeGameMutate((game) => {
                            game.undo();
                            game.undo();
                        });
                        // clear premove queue
                        chessboardRef.current.clearPremoves();
                        // stop any current timeouts
                        clearTimeout(currentTimeout);
                    }}
                >
                    <BsArrowCounterclockwise />
                    Undo
                </button>
                <button
                    className="chess__button"
                    onClick={() => {
                        safeGameMutate((game) => {
                            game.reset();
                        });
                        // clear premove queue
                        chessboardRef.current.clearPremoves();
                        // stop any current timeouts
                        clearTimeout(currentTimeout);
                    }}
                >
                    <BsCup />
                    Reset
                </button>
            </div>
            <audio
                id="chess_slide"
                src="/media/mixkit-slide-click-1130.wav"
            ></audio>
            <Chessboard
                id="PlayVsRandom"
                animationDuration={200}
                arePremovesAllowed={true}
                boardWidth={boardWidth}
                position={game.fen()}
                isDraggablePiece={({ piece }) => piece[0] === 'w'}
                onPieceDrop={onDrop}
                customBoardStyle={{
                    borderRadius: '4px',
                    boxShadow: '0 7px 17px rgba(0, 0, 0, 0.5)',
                    outline: '2px solid',
                    outlineColor: themedarkcolor,
                }}
                customDarkSquareStyle={{ backgroundColor: themedarkcolor }}
                customLightSquareStyle={{ backgroundColor: themelightcolor }}
                customPieces={piecesPreference}
                ref={chessboardRef}
            />
            <div className="chess__theme-settings">
                <div className="chess__buttongroup">
                    <h5>Pieces Set</h5>
                    <button className="chess__button" onClick={() => setPiecesPreference("customPieces")}>Classic</button>
                    <button className="chess__button" onClick={() => setPiecesPreference(customPieces)}>Smooth</button>
                </div>
                <div className="chess__buttongroup" onChange={onChangeTheme}>
                    <h5>Chessboard</h5>
                    <div className="chess__themebutton chess__forest"><input type="radio" value="Green" name="theme" /> <label>Forest</label></div>
                    <div className="chess__themebutton chess__sea"><input type="radio" value="Blue" name="theme" /> <label>Sea</label></div>
                    <div className="chess__themebutton chess__mono"><input type="radio" value="Mono" name="theme" defaultChecked /> <label>Mono</label></div>
                    <div className="chess__themebutton chess__coffee"><input type="radio" value="Brown" name="theme" /> <label>Coffee</label></div>
                </div>
            </div>
        </div>
    );
}