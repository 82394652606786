import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Draggable } from "react-beautiful-dnd";

import {
  db,
  updateTaskAsDone,
  fieldValue,
} from "../../../../firebase/firebase.utils";
import { useNavigate } from "react-router-dom";

import {
  autoUpdateTasksToUser,
  personFilterFunction,
  statusFilterFunction,
  timeFilterFunction,
  setTaskClassDone,
  filterLogicFunction,
} from "../../task.util";
import "./task-list.styles.scss";

import LoadModule from "../../../modules/load-module.component.jsx/load-module.component";
import LargeTask from "../../../large-task/large-task.component";
import BoxRight from "../../../retro/box-right/box-right.component";
import CheckBox from "../../../retro/check-box/check-box.component";
import Subtasks from "../../subtasks/subtasks.component";

import { BsGripVertical } from "react-icons/bs";
import { FaGripLinesVertical } from "react-icons/fa";
import { FiSquare, FiCheckSquare } from "react-icons/fi";

const TaskList = ({ task, index, status, stationTasksFL }) => {
  const activeModules = useSelector((state) => state.space.activeModulesData);
  const users = useSelector((state) => state.user.users);
  const filter = useSelector((state) => state.filter);

  const activeSpaceId = useSelector((state) => state.history.spaceId);
  const activeStationId = useSelector((state) => state.history.stationId);

  const [taskref, setTaskRef] = useState(false);
  const [taskdone, setTaskDone] = useState(false);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMode, setModalMode] = useState("edit");
  const [activeEvent, setActiveEvent] = useState({});

  const navigate = useNavigate();

  const [showLargeTask, setShowLargeTask] = useState(false);
  // const [statusFilter, setStatusFilter] = useState(true);
  // const [personFilter, setPersonFilter] = useState(true);
  // const [timeFilter, setTimeFilter] = useState(true);
  const [taskClass, setTaskClass] = useState("");
  // const [filterLogic, setFilterLogic] = useState(true);

  // useEffect(() => {
  //   setStatusFilter(statusFilterFunction(task, filter));
  //   setTimeFilter(timeFilterFunction(task, filter));
  //   setPersonFilter(personFilterFunction(task, filter));
  // }, [task, filter]);

  const taskrefinfo = {
    fromSpaceId: activeSpaceId,
    fromStationId: activeStationId,
    id: task,
    done: !taskdone,
  };

  const handleCheck = () => {
    document.getElementById("done_tone").play();
    if (taskdone) {
      updateTaskAsDone(activeSpaceId, activeStationId, task, false);
    } else {
      updateTaskAsDone(activeSpaceId, activeStationId, task, true);
    }
    if (taskref.assign) {
      const assignedUserRef = db.collection("users").doc(taskref.assign);
      assignedUserRef.get().then((userRefData) => {
        let assignedTasks = userRefData.data().assignedTasks;
        const findIndex = assignedTasks.findIndex((item) => item.id === task);
        assignedUserRef.update({
          assignedTasks: fieldValue.arrayRemove(assignedTasks[findIndex]),
        });
        assignedUserRef.update({
          assignedTasks: fieldValue.arrayUnion(taskrefinfo),
        });
      });
    }
  };

  // useEffect(() => {
  //   const taskIndex = stationTasksFL.findIndex((t) => (t.id = task));
  //   if (taskIndex !== -1) {
  //     setTaskRef(stationTasksFL[taskIndex]);
  //     setTaskDone(stationTasksFL[taskIndex].done);
  //   }
  // }, []);

  useEffect(() => {
    db.collection("space")
      .doc(activeSpaceId)
      .collection("stations")
      .doc(activeStationId)
      .collection("tasks")
      .doc(task)
      .onSnapshot((doc) => {
        if (doc.exists) {
          let currenttask = doc.data();
          setTaskRef(currenttask);
          setTaskDone(currenttask.done);
        }
      });
  }, []);

  const handleCloseModal = () => {
    setModalIsOpen(false);
    setModalMode("");
    setActiveEvent({});
  };

  const handleOpenModal = (mode, event) => {
    // setModalMode(mode);
    // setActiveEvent(event);
    // setModalIsOpen(true);
    navigate(`task/${event.id}`)
  };

  const handleEventClick = (taskref) => {
    // const { id } = event.toPlainObject();
    if (taskref.startdate === null)
      handleOpenModal(
        "edit",
        {
          id: taskref.id,
          title: taskref.title,
          description: taskref.description,
          done: taskref.done,
          startDate: taskref.startdate,
          endDate: taskref.enddate,
          allDay: taskref.allDay,
          duration: taskref.duration,
          done: taskref.done,
          createdBy: taskref.createdBy,
          createdAt: taskref.createdAt,
          phase: taskref.phase,
          priority: taskref.priority,
          progress: taskref.progress,
          type: taskref.type,
          fromSpaceId: taskref.fromSpaceId,
          fromStationId: taskref.fromStationId,
          subtasks: taskref.subtasks,
        }
        // events.find((e) => e.id.toString() === id.toString())
      );
    else
      handleOpenModal(
        "edit",
        {
          id: taskref.id,
          title: taskref.title,
          description: taskref.description,
          done: taskref.done,
          startDate: taskref.startdate.toDate(),
          endDate: taskref.enddate.toDate(),
          allDay: taskref.allDay,
          duration: taskref.duration,
          done: taskref.done,
          createdBy: taskref.createdBy,
          createdAt: taskref.createdAt,
          phase: taskref.phase,
          priority: taskref.priority,
          progress: taskref.progress,
          type: taskref.type,
          fromSpaceId: taskref.fromSpaceId,
          fromStationId: taskref.fromStationId,
          subtasks: taskref.subtasks,
        }
        // events.find((e) => e.id.toString() === id.toString())
      );
  };

  useEffect(() => {
    autoUpdateTasksToUser(users, task);
    setTaskClass(setTaskClassDone(task));
  }, [task]);

  // useEffect(() => {
  //   setFilterLogic(filterLogicFunction(statusFilter, timeFilter, personFilter));
  // }, [statusFilter, timeFilter, personFilter]);

  return (
    <Draggable draggableId={task} index={index}>
      {(provided) => {
        const style = {
          ...provided.draggableProps.style,
          opacity: status.open ? "1" : "0",
          height: status.open ? "auto" : "10px",
        };
        return (
          <div
            {...provided.draggableProps}
            ref={provided.innerRef}
            className="taskList__logic"
            style={style}
          >
            <div className="tl__drag" {...provided.dragHandleProps}>
              <BsGripVertical />
            </div>
            {/* {filterLogic && ( */}
            {stationTasksFL.findIndex((item) => item.id === task) !== -1 && (
              <div className={`taskList ${taskClass}`}>
                <div
                  className="tl__clickable"
                  onClick={() => handleEventClick(taskref)}
                  {...provided.dragHandleProps}
                />
                <div
                  className={
                    taskref.done ? "task__listcard-done" : "task__listcard"
                  }
                >
                  {/* <CheckBox task={task} /> */}
                  <i onClick={handleCheck}>
                    {taskref.done ? <FiCheckSquare /> : <FiSquare />}
                  </i>
                  <audio
                    id="done_tone"
                    src="/media/mixkit-cool-interface-click-tone-2568.wav"
                  ></audio>
                  <p>{taskref.title}</p>
                  <div
                    className="sl__subtasks"
                    onClick={() => handleEventClick(taskref)}
                  >
                    <Subtasks task={taskref} length />
                  </div>
                </div>

                <div className="tl__modules">
                  {activeModules?.map((module) => {
                    return (
                      <LoadModule
                        style="vertical"
                        module={module}
                        key={module.name}
                        task={taskref}
                      />
                    );
                  })}
                </div>
              </div>
            )}

            {/* )} */}
          </div>
        );
      }}
    </Draggable>
  );
};

export default TaskList;
