import { useState, useEffect, useMemo } from "react";
import "./MyChart.styles.scss";
import BarChart from "./BarChart";
import LineChart from "./LineChart";
import PieChart from "./PieChart";
import { UserData } from "./Data";

import {
  getStartOfDay,
  getEndOfDay,
  getStartOfWeek,
  getEndOfWeek,
  getStartOfMonth,
  getEndOfMonth,
  secondsToHM,
} from "../../utils/dateUtils";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  IoChevronBackOutline,
  IoChevronForwardOutline,
  IoPieChart,
  IoPieChartOutline,
  IoBarChart,
  IoBarChartOutline,
} from "react-icons/io5";
import { BsHourglassSplit } from "react-icons/bs";

import { db } from "../../firebase/firebase.utils";

import { useSelector } from "react-redux";
// import { setUser } from "../../redux/meeting/meeting.actions";

function HomeChart({ spaceData }) {
  const currentUser = useSelector((state) => state.user.currentUser);
  const [assignedTasks, setAssignedTasks] = useState([]);
  const [activeTab, setActiveTab] = useState("day");
  const [activeChart, setActiveChart] = useState("pie");
  const [userTimeLogs, setUserTimelogs] = useState([]);
  let todayref = new Date();
  const [queryStart, setQueryStart] = useState(getStartOfDay(todayref));
  const [queryEnd, setQueryEnd] = useState(getEndOfDay(todayref));

  // console.log("QUERYSTART", queryStart);
  // console.log("QUERYEND", queryEnd);

  //copied from journal
  // let newDate = new Date();
  // let date = newDate.getDate();
  // let month = newDate.getMonth() + 1;
  // let year = newDate.getFullYear();

  // let yesterday = new Date(newDate - 86400000);

  const [pickerDate, setPickerDate] = useState(new Date());

  const today = new Date();

  // ✅ Get the first day of the current week (Sunday)
  const firstDay = new Date(today.setDate(today.getDate() - today.getDay()));

  // ✅ Get the last day of the current week (Saturday)
  const lastDay = new Date(today.setDate(today.getDate() - today.getDay() + 6));

  const timelogsRef = db
    .collection("users")
    .doc(currentUser.uid)
    .collection("timelogs");

  useEffect(() => {
    const unsub = timelogsRef
      .where("logTime", "<=", queryEnd)
      .where("logTime", ">", queryStart)
      .onSnapshot((collection) => {
        let logs = [];
        collection.forEach((doc) => {
          logs.push({ ...doc.data(), id: doc.id });
        });
        setUserTimelogs(logs);
      });
    return () => {
      unsub();
    };
  }, [queryStart, queryEnd]);

  const [userData, setUserData] = useState({
    labels: spaceData.map((data) => data.name),
    datasets: [
      {
        label: "My First Dataset",
        data: spaceData.map((data) => data.tracked),
        backgroundColor: spaceData.map((data) => data.color),
        // backgroundColor: [
        //     "rgba(75,192,192,1)",
        //     "#ecf0f1",
        //     "#50AF95",
        //     "#f3ba2f",
        //     "#2a71d0",
        //     "#2a71d0",
        // ],
        // borderColor: "black",
        // borderWidth: 2,
      },
    ],
  });

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    layout: {
      padding: 4,
    },
    plugins: {
      datalabels: {
        render: "image",
        display: true,
        formatter: function (value, context) {
          // return context.chart.data[context.dataIndex];
          return secondsToHM(value);
        },
        color: "#404040",
        backgroundColor: "#fff",
      },
      tooltip: {
        callbacks: {
          title: function (tooltipItem, data) {
            return `${tooltipItem[0].label} Workspace`;
          },
          label: function (context, data) {
            let label = context.dataset.label || "";
            let convertedVal = secondsToHM(
              context.dataset.data[context.dataIndex]
            );
            return `${label}: ${convertedVal}`;
          },
        },
      },
    },
  };

  function calculateSum(array, property) {
    let sum = 0;

    array.forEach((element) => {
      sum += element[property];
    });

    return sum;
  }

  useMemo(() => {
    const pluss = spaceData
      .filter((s) =>
        userTimeLogs.map((log) => log.fromSpaceId).includes(s.spaceId)
      )
      .map((space) =>
        calculateSum(
          userTimeLogs.filter((t) => t.fromSpaceId === space.spaceId),
          "duration"
        )
      );
    setUserData({
      labels: spaceData
        .filter((s) =>
          userTimeLogs.map((log) => log.fromSpaceId).includes(s.spaceId)
        )
        .map((data) => data.name),
      datasets: [
        {
          label: "Worked on",
          data: pluss,
          backgroundColor: spaceData
            .filter((s) =>
              userTimeLogs.map((log) => log.fromSpaceId).includes(s.spaceId)
            )
            .map((data) => data.color),
          hoverOffset: 4,
          // backgroundColor: [
          //     "rgba(75,192,192,1)",
          //     "#ecf0f1",
          //     "#50AF95",
          //     "#f3ba2f",
          //     "#2a71d0",
          //     "#2a71d0",
          // ],
          // borderColor: "black",
          // borderWidth: 2,
        },
      ],
    });
  }, [userTimeLogs, queryStart, queryEnd]);

  useEffect(() => {
    if (!currentUser) return;
    db.collection("users")
      .doc(currentUser.uid)
      .onSnapshot((userData) => {
        if (userData.exists) {
          setAssignedTasks(userData.data().assignedTasks);
        }
      });
  }, [currentUser]);

  const handleChangeTab = (e) => {
    if (e.target.value === "day") {
      handleDaySelect(new Date());
    } else if (e.target.value === "week") {
      handleWeekSelect(new Date());
    } else if (e.target.value === "month") {
      handleMonthSelect(new Date());
    }
    setActiveTab(e.target.value);
  };

  const handleDaySelect = (date) => {
    setPickerDate(date);
    const qstart = getStartOfDay(date);
    const qend = getEndOfDay(date);
    setQueryStart(qstart);
    setQueryEnd(qend);
  };

  const handleWeekSelect = (date) => {
    setPickerDate(date);
    const qstart = getStartOfWeek(date);
    const qend = getEndOfWeek(date);
    setQueryStart(qstart);
    setQueryEnd(qend);
  };

  const handleMonthSelect = (date) => {
    setPickerDate(date);
    const qstart = getStartOfMonth(date);
    const qend = getEndOfMonth(date);
    setQueryStart(qstart);
    setQueryEnd(qend);
  };

  return (
    <div className="custom__chart">
      {/* <div style={{ width: 200 }}>
                <BarChart chartData={userData} />
            </div>
            <div style={{ width: 200 }}>
                <LineChart chartData={userData} />
            </div> */}
      <div className="chart__settings-top">
        <div className="chart__switch">
          <button
            className={
              activeChart === "pie" ? "chart-btn" : "chart-btn-passive"
            }
            onClick={() => setActiveChart("pie")}
          >
            {activeChart === "pie" ? <IoPieChart /> : <IoPieChartOutline />}
          </button>
          <button
            className={
              activeChart === "bar" ? "chart-btn" : "chart-btn-passive"
            }
            onClick={() => setActiveChart("bar")}
          >
            {activeChart === "bar" ? <IoBarChart /> : <IoBarChartOutline />}
          </button>
        </div>
        <select onChange={handleChangeTab}>
          <option value="day">Daily</option>
          <option value="week">Weekly</option>
          <option value="month">Monthly</option>
        </select>
      </div>
      <div className="custom__chart-graph" style={{ width: "100%" }}>
        {userTimeLogs.length > 0 ? (
          <>
            {activeChart === "pie" ? (
              <PieChart chartData={userData} options={options} />
            ) : (
              <BarChart chartData={userData} />
            )}
          </>
        ) : (
          <div className="custom__chart-graph-nodata">
            <h4>No tracked time found for the selected period.</h4>
            <BsHourglassSplit size="5em" />
          </div>
        )}
      </div>
      <div className="chart__settings-bottom">
        <div className="avatar">
          <img src={currentUser?.imageUrl}></img>
        </div>
        {activeTab === "day" && (
          <div className="chart__btn-group">
            <div className="chart__btn-group-nav">
              <button
                onClick={() => handleDaySelect(new Date(pickerDate - 86400000))}
              >
                <IoChevronBackOutline />
              </button>
              <button className="chart__datepicker-day">
                <DatePicker
                  popperProps={{ strategy: "fixed" }}
                  wrapperClassName="chart__datepicker-wrp"
                  // isClearable
                  placeholderText="..."
                  value={pickerDate}
                  // showTimeSelect
                  dateFormat="MMMM dd"
                  selected={pickerDate}
                  maxDate={new Date()}
                  onChange={(date) => handleDaySelect(date)}
                />
              </button>
              <button
                onClick={() =>
                  handleDaySelect(
                    new Date(pickerDate.setDate(pickerDate.getDate() + 1))
                  )
                }
              >
                <IoChevronForwardOutline />
              </button>
            </div>
            <button
              onClick={() => handleDaySelect(new Date())}
              className={
                pickerDate.toDateString() === new Date().toDateString()
                  ? "journal__today-btn"
                  : "journal__today-btn-passive"
              }
            >
              Today
            </button>
          </div>
        )}
        {activeTab === "week" && (
          <div className="chart__btn-group">
            <div className="chart__btn-group-nav">
              <button
                onClick={() =>
                  handleWeekSelect(
                    new Date(pickerDate - 7 * 24 * 60 * 60 * 1000)
                  )
                }
              >
                <IoChevronBackOutline />
              </button>
              <button className="chart__datepicker-week">
                <DatePicker
                  popperProps={{ strategy: "fixed" }}
                  wrapperClassName="chart__datepicker-wrp"
                  // isClearable
                  placeholderText="..."
                  value={`${getStartOfWeek(pickerDate).toLocaleDateString(
                    undefined,
                    { month: "short", day: "numeric" }
                  )} - ${getEndOfWeek(pickerDate).toLocaleDateString(
                    undefined,
                    {
                      month: "short",
                      day: "numeric",
                    }
                  )}`}
                  // showTimeSelect
                  dateFormat="dd"
                  selected={pickerDate}
                  maxDate={new Date()}
                  showWeekNumbers
                  startDate={getStartOfWeek(pickerDate)}
                  endDate={getEndOfWeek(pickerDate)}
                  // showMonthYearPicker
                  onChange={(date) => handleWeekSelect(date)}
                />
              </button>
              <button
                onClick={() =>
                  handleWeekSelect(
                    new Date(pickerDate.setDate(pickerDate.getDate() + 7))
                  )
                }
              >
                <IoChevronForwardOutline />
              </button>
            </div>
            <button
              onClick={() => handleWeekSelect(new Date())}
              className={
                getStartOfWeek(pickerDate).toDateString() ===
                getStartOfWeek(new Date()).toDateString()
                  ? "journal__today-btn"
                  : "journal__today-btn-passive"
              }
            >
              This Week
            </button>
          </div>
        )}
        {activeTab === "month" && (
          <div className="chart__btn-group">
            <div className="chart__btn-group-nav">
              <button
                onClick={() =>
                  handleMonthSelect(
                    new Date(pickerDate.setMonth(pickerDate.getMonth() - 1))
                  )
                }
              >
                <IoChevronBackOutline />
              </button>
              <button className="chart__datepicker-month">
                <DatePicker
                  popperProps={{ strategy: "fixed" }}
                  wrapperClassName="chart__datepicker-wrp"
                  // isClearable
                  placeholderText="..."
                  value={pickerDate}
                  // showTimeSelect
                  dateFormat="MMMM, yyyy"
                  selected={pickerDate}
                  maxDate={new Date()}
                  showMonthYearPicker
                  onChange={(date) => handleMonthSelect(date)}
                />
              </button>
              <button
                onClick={() =>
                  handleMonthSelect(
                    new Date(pickerDate.setMonth(pickerDate.getMonth() + 1))
                  )
                }
              >
                <IoChevronForwardOutline />
              </button>
            </div>
            <button
              onClick={() => handleMonthSelect(new Date())}
              className={
                pickerDate.toDateString() === new Date().toDateString()
                  ? "journal__today-btn"
                  : "journal__today-btn-passive"
              }
            >
              This Month
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default HomeChart;
