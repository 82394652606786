import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { useLocation, useNavigate } from "react-router-dom";

import RetroButton from "../retro/button/retro-button.component";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import StationIteminFolder from "../station-item-infolder/station-item-infolder.component.class";

import { BsGripVertical } from "react-icons/bs";
import { MdOutlineReadMore } from "react-icons/md";

import { BsFolder, BsFolder2Open } from "react-icons/bs";
import { FaGripLines } from "react-icons/fa";

import EmojiPick from "../emoji/emoji.component";

import "./folder-item.styles.scss";
import { useState } from "react";

function FolderItem({ data }) {
  const activeSpaceId = useSelector((state) => state.history.spaceId);
  const history = useNavigate();
  const location = useLocation();
  const activeStationId = location.pathname.split("/")[4];

  const [fdopen, setFdOpen] = useState(false);

  const handleFdClick = () => {
    setFdOpen(!fdopen);
  };

  return (
    <div className={fdopen ? "foldersItem" : "foldersItem-close"}>
      {/* <div> */}
      <Droppable droppableId={data?.id} key={data?.id}>
        {(provided, snapshot) => {
          return (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{
                background: snapshot.isDraggingOver ? "darkgrey" : "",
                // paddingTop: 4,
                // width: 150,
                // minHeight: 50,
              }}
              className={fdopen ? "foldersItemOn" : ""}
            >
              <div
                className={
                  fdopen ? "foldersItemHeader" : "foldersItemHeader-close"
                }
                onClick={handleFdClick}
              >
                <RetroButton>
                  {/* <BsListCheck size="1.2em" /> */}
                  {fdopen ? (
                    <BsFolder2Open size="1.2em" />
                  ) : (
                    <BsFolder size="1.2em" />
                  )}
                  {/* {folder.name} */}
                  {/* <div className="EmojiIcon">{data.emojicon}</div> */}
                  {data?.name}
                  {/* <Link to={`/s/${activeSpaceId}/e/${data.folderId}/b`}>
          <MdOutlineReadMore />
          </Link> */}
                </RetroButton>
              </div>
              <div className={fdopen ? "foldersItemIn" : ""}>
                {data?.folderitems?.map((item, index) => {
                  return (
                    <Draggable key={item} draggableId={item} index={index}>
                      {(provided, snapshot) => {
                        return (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            // style= {{margin: "0 0 6px 0",}}
                            // style={{
                            //   userSelect: "none",
                            //   padding: 5,
                            //   margin: "0 0 6px 0",
                            //   minHeight: "12px",
                            //   backgroundColor: snapshot.isDragging
                            //     ? "#263B4A"
                            //     : "#456C86",
                            //   color: "white",
                            //   ...provided.draggableProps.style,
                            // }}
                            className="infolder__items"
                          >
                            <div
                              // className="stations__item"
                              className={
                                item === activeStationId
                                  ? "stations__item-active"
                                  : "stations__item"
                              }
                            >
                              <div
                                className="project__drag"
                                {...provided.dragHandleProps}
                              >
                                <BsGripVertical />
                              </div>
                              {/* {item} */}
                              <StationIteminFolder data={item} />
                            </div>
                          </div>
                        );
                      }}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            </div>
          );
        }}
      </Droppable>
      {/* </div> */}
    </div>
  );
}

export default FolderItem;
