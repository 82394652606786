import React from "react";

import "./retro-button.styles.scss";

function RetroButton({
  children,
  mode,
  spaceColor,
  charAt,
  size,
  color,
  ...otherProps
}) {
  return (
    <button className={`retroButton ${mode} ${size} ${color} `} {...otherProps}>
      {charAt ? (
        <div className="charAt">
          <span
            style={{
              color: spaceColor,
              fontSize: "16px",
              fontWeight: "600",
            }}
          >
            {children.charAt(0)}
          </span>
          <div className="charAtFull">{children}</div>
        </div>
      ) : (
        <div className="children">{children}</div>
      )}
    </button>
  );
}

export default RetroButton;
