import React from "react";
import "./legaltext.scss";

function Cookies() {
  return (
    <div className="sec_legal">
      <p>
        <h1>How we use cookies</h1>
      </p>

      <p>
        A cookie is a small file which asks permission to be placed on your
        computer&rsquo;s hard drive. Once you agree, the file is added and the
        cookie helps analyze web traffic or lets you know when you visit a
        particular site. Cookies allow web applications to respond to you as an
        individual. The web application can tailor its operations to your needs,
        likes and dislikes by gathering and remembering information about your
        preferences.
      </p>

      <p>
        We use traffic log cookies to identify which pages are being used. This
        helps us analyze data about web page traffic and improve our website in
        order to tailor it to customer needs. We only use this information for
        statistical analysis purposes and then the data is removed from the
        system.
      </p>

      <p>
        Overall, cookies help us provide you with a better website by enabling
        us to monitor which pages you find useful and which you do not. A cookie
        in no way gives us access to your computer or any information about you,
        other than the data you choose to share with us.
      </p>

      <p>
        You can choose to accept or decline cookies. Most web browsers
        automatically accept cookies, but you can usually modify your browser
        setting to decline cookies if you prefer. This may prevent you from
        taking full advantage of the website.
      </p>
    </div>
  );
}

export default Cookies;
