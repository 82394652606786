import Afro from './head/Afro';
import Bangs from './head/Bangs';
import Bangs2 from './head/Bangs2';
import BantuKnots from './head/BantuKnots';
import Bear from './head/Bear';
import Bun from './head/Bun';
import Bun2 from './head/Bun2';
import Buns from './head/Buns';
import Cornrows from './head/Cornrows';
import Cornrows2 from './head/Cornrows2';
import FlatTop from './head/FlatTop';
import FlatTopLong from './head/FlatTopLong';
import GrayBun from './head/GrayBun';
import GrayMedium from './head/GrayMedium';
import Grayshort from './head/GrayShort';
import HatBeanie from './head/HatBeanie';
import HatHip from './head/HatHip';
import Hijab from './head/Hijab';

import NoneAccessory from './accessories/None';
import Eyepatch from './accessories/Eyepatch';
import Glasses from './accessories/Glasses';
import Glasses2 from './accessories/Glasses2';
import Glasses3 from './accessories/Glasses3';
import Glasses4 from './accessories/Glasses4';
import Glasses5 from './accessories/Glasses5';
import Sunglasses from './accessories/Sunglasses';
import Sunglasses2 from './accessories/Sunglasses2';

import BlazerBlackTee from './body/BlazerBlackTee';
import ButtonShirt1 from './body/ButtonShirt1';
import ButtonShirt2 from './body/ButtonShirt2';
import Coffee from './body/Coffee';
import Device from './body/Device';

import AngryWithFang from './face/AngryWithFang';
import Awe from './face/Awe';
import Blank from './face/Blank';
import Calm from './face/Calm';
import Cheeky from './face/Cheeky';

import NoneFacialHair from './facialHair/None';
import Chin from './facialHair/Chin';
import Full from './facialHair/Full';
import Full2 from './facialHair/Full2';
import Full3 from './facialHair/Full3';

const avatarParts = {
  head: {
    afro: Afro,
    bangs: Bangs,
    bangs2: Bangs2,
    bantuKnots: BantuKnots,
    bear: Bear,
  },
  accessories: {
    none: NoneAccessory,
    eyepatch: Eyepatch,
    glasses: Glasses,
    glasses2: Glasses2,
    glasses3: Glasses3,
    glasses4: Glasses4,
    glasses5: Glasses5,
    sunglasses: Sunglasses,
    sunglasses2: Sunglasses2,
  },
  body: {
    blazerBlackTee: BlazerBlackTee,
    buttonShirt1: ButtonShirt1,
    buttonShirt2: ButtonShirt2,
    coffee: Coffee,
    device: Device,
  },
  face: {
    angryWithFang: AngryWithFang,
    awe: Awe,
    blank: Blank,
    calm: Calm,
    cheeky: Cheeky,
  },
  facialHair: {
    none: NoneFacialHair,
    chin: Chin,
    full: Full,
    full2: Full2,
    full3: Full3,
  },
};

export default avatarParts;
