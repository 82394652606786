import React, { useEffect, useState, useRef } from "react";
import { createPortal } from "react-dom";
import {
  db,
  fieldValue,
  createMessageToWorkspace,
  createMessageToProject,
  createMessageToPerson,
  // changeEmojiReactionOfMessage,
} from "../../firebase/firebase.utils";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { motion } from "framer-motion";

import useCurrentMembers from "../../hooks/useCurrentMembers.hook";
import { useActiveSpaceData } from "../../hooks/useActiveSpaceData.hook";

import Stations from "../../components/stations/stations.component";
// import JitsiApp from "../../components/jitsi/Jitsiapp";
import { MeetingStarter } from "../../components/meeting/MeetingStarter";
// import MeetingApp from "../../components/meeting/MeetingApp";

import Discussion from "../../components/discussion/discussion.component";

import RetroButton from "../../components/retro/button/retro-button.component";
import Avatar from "../../components/retro/avatar/avatar.component";
import Message from "../../components/message/message.component";

import { setOpen } from "../../redux/user/user.actions";

import {
  BsCameraVideo,
  BsChatRightDots,
  BsEmojiSmileUpsideDown,
  BsPaperclip,
  BsTrash,
  BsPlusLg,
  BsArrowBarDown,
  BsChatRightDotsFill,
  BsChatRight,
} from "react-icons/bs";
import { IoMdChatboxes } from "react-icons/io";
import { IoPeopleOutline } from "react-icons/io5";
import { HiOutlineChatBubbleLeftEllipsis, HiXMark } from "react-icons/hi2";

import { FaPaperPlane } from "react-icons/fa";

import { TfiClose } from "react-icons/tfi";

// import { setOpenFb } from "../../firebase/firebase.utils";

import { Picker } from "emoji-mart";
import EmojiPicker from "../../components/emoji/emojiPicker";

import "./chat.styles.scss";

function Chat() {
  const users = useSelector((state) => state.user.users);
  const spaceData = useSelector((state) => state.space.spaceData);
  const [allUsers, setAllUsers] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const [chatIsOpen, SetChatIsOpen] = useState(false);
  const [chatx, setChatX] = useState(0);
  const activeSpaceData = useActiveSpaceData();
  // const activeSpaceData = useActiveSpaceData();
  const moduleData = useSelector((state) => state.space.moduleData);
  const currentUser = useSelector((state) => state.user.currentUser);
  const [msgs, setMsgs] = useState([]);
  const [projectMsgs, setProjectMsgs] = useState([]);
  const [personalMsgs, setPersonalMsgs] = useState([]);
  const [chatmessage, setChatMessage] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const currentSpaceId = location.pathname.split("/")[2];
  const currentStationId = location.pathname.split("/")[4];

  const spaceId = useSelector((state) => state.history.spaceId);
  const [inputName, setInputName] = useState("");
  const inputRefName = useRef();

  const textareaRef = useRef();

  const { fromSpaceId, fromStationId } = chatmessage;

  const activeSpaceId = useSelector((state) => state.history.spaceId);

  const [memberData, setMemberData] = useState([]);
  const inputRef = useRef();

  // const memberPromise = useCurrentMembers();

  const [emojiOpen, setEmojiOpen] = useState(false);
  const [emoji, setEmoji] = useState();
  // const onSelectEmoji = (emoji) => {
  //   setEmoji(emoji);
  //   setEmojiOpen(!emojiOpen);
  //   // changeEmojiconOfStation(currentSpaceId, currentStationId, emoji.native);
  // };

  const [reactionpickerOpen, setReactionPickerOpen] = useState(false);
  const [reactionemoji, setReactionemoji] = useState([]);
  const [sendtomode, setSendtomode] = useState("");
  const [sendto, setSendto] = useState(null);
  const [sendtoWsId, setSendtoWsId] = useState(null);
  const [currentStationName, setCurrentStationName] = useState("");
  const [currentStationIcon, setCurrentStationIcon] = useState("");
  const [buttonlook, setButtonLook] = useState("nopersonselected");
  const [contactsopen, setContactsOpen] = useState(false);
  const [currentrecipient, setCurrentRecipient] = useState("");
  const [meetingFullScreen, setMeetingFullScreen] = useState(false);
  const [showInstantChat, setShowInstantChat] = useState(true);

  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState("");
  const [projectOptions, setProjectOptions] = useState([]);

  const [showGroupSelect, setShowGroupSelect] = useState(false);
  const [selectedGroupName, setSelectedGroupName] = useState(null);
  const [selectedGroupColor, setSelectedGroupColor] = useState(null);
  const [selectedGroupIcon, setSelectedGroupIcon] = useState(null);

  const [callStatus, setCallStatus] = useState(false);

  const wschat = searchParams.get("wschat");
  const prchat = searchParams.get("prchat");
  const dmchat = searchParams.get("dmchat");
  const meet = searchParams.get("meet");

  useEffect(() => {
    if (wschat?.length > 0) {
      SetChatIsOpen(true);
      setSendtomode("toworkspace");
      setSendto(wschat);
      setSelectedWorkspaceId(wschat);
      setSelectedGroupName(activeSpaceData?.name);
      setSelectedGroupColor(activeSpaceData?.color);
    }
    if (prchat?.length > 0) {
      SetChatIsOpen(true);
      setSendtomode("toproject");
      setSendto(prchat);
      setSelectedWorkspaceId(currentSpaceId);
      setSelectedGroupName(currentStationName);
      setSelectedGroupIcon(currentStationIcon);
    }
    if (dmchat?.length > 0) {
      SetChatIsOpen(true);
      setSendtomode("toperson");
      const findSendto = () => {
        const convertedConvId = dmchat.replace(currentUser.uid, "");
        const memberIndex = memberData.findIndex(
          (member) => member.uid === convertedConvId
        );
        // handleSendtoPerson(memberData[memberIndex])
        // setSendtomode("toperson");
        setSendto(convertedConvId);
        setButtonLook("personselected");
        setCurrentRecipient(memberData[memberIndex]);
        setPersonalMessages(memberData[memberIndex]);
        setContactsOpen(false);
      };
      findSendto();

      // setSendto(member?.uid);
      // setButtonLook("personselected");
      // setCurrentRecipient(member);
      // setPersonalMessages(member);

      // console.log("CONVERTEDCONVID", convertedConvId);
      // console.log("CURRENTRECIPIENT", currentrecipient);
      // setSendtomode("toperson");
      // setSendto(convertedConvId);
      // setButtonLook("personselected");
      // setCurrentRecipient(memberData[memberIndex]);
      // setPersonalMessages(memberData[memberIndex]);
      // setContactsOpen(false);
    }
  }, [memberData, wschat, prchat, dmchat, currentStationIcon]);

  // useEffect(() => {
  //   if (!users) return;
  //   if (activeSpaceData) {
  //     const { members } = activeSpaceData;
  //     let list = [];
  //     list = members.map((memberId) => {
  //       const memberFind = users.filter((item) => item.uid === memberId);
  //       return memberFind[0];
  //     });
  //     setMemberData(list);
  //   }
  // }, [activeSpaceData, users]);

  useEffect(() => {
    let reflist = [];
    if (spaceData) {
      spaceData.forEach((space) => reflist.push(...space.members));
    }

    if (users) {
      const membs = users.filter((user) => reflist.includes(user.uid));
      setMemberData(membs);
    }
    // console.log("ALLMEMBS", membs);
  }, [spaceData, users]);

  //Read Current Station Name
  useEffect(() => {
    //mounts
    if (currentStationId) {
      const unsub = db
        .collection("space")
        .doc(currentSpaceId)
        .collection("stations")
        .doc(currentStationId)
        .onSnapshot((docSnap) => {
          const stationNameRef = docSnap.data().name;
          const stationIconRef = docSnap.data().emojicon;
          setCurrentStationName(stationNameRef);
          setCurrentStationIcon(stationIconRef);
        });

      return () => {
        unsub();
      };
    }
  }, [currentSpaceId, currentStationId]);

  useEffect(() => {
    if (currentUser?.onCall) {
      SetChatIsOpen(true);
      const currentrecipientref = memberData?.filter(
        (member) => member?.uid === currentUser?.onCall
      );
      setSendtomode("toperson");
      // handleSendtoPerson(currentrecipientref[0])
      setSendto(currentUser.onCall);
      setButtonLook("personselected");
      setPersonalMessages(currentrecipientref[0]);
      // setCurrentRecipient(currentrecipientref);
      // setPersonalMessages(currentrecipientref);
      // setButtonLook("personselected");
      // setCurrentRecipient(currentrecipientref[0]);
    }
  }, [currentUser.onCall]);

  useEffect(() => {
    if (currentUser?.onCall) {
      const currentrecipientref = memberData?.filter(
        (member) => member?.uid === currentUser?.onCall
      );
      setCurrentRecipient(currentrecipientref[0]);
    }
  }, [currentUser.onCall, personalMsgs]);

  //Set the Meeting ID
  // useEffect(() => {
  //   if (sendtomode === "toworkspace") {
  //     localStorage.setItem("meetingId", sendto);
  //   } else if (sendtomode === "toproject") {
  //     localStorage.setItem("meetingId", sendto);
  //   } else if (sendtomode === "toperson") {
  //     let convRefId =
  //       currentUser.uid < currentrecipient?.uid
  //         ? currentUser.uid + currentrecipient?.uid
  //         : currentrecipient?.uid + currentUser.uid;
  //     localStorage.setItem("meetingId", convRefId);
  //   } else {
  //     localStorage.setItem("meetingId", "public");
  //   }
  // }, [currentSpaceId, currentStationId, sendtomode, sendto]);

  const handleStartCall = () => {
    if (sendtomode === "toworkspace") {
      localStorage.setItem("meetingId", sendto);
      setSearchParams({ meet: sendto });
      // navigate(`?meet=${sendto}`)
    } else if (sendtomode === "toproject") {
      localStorage.setItem("meetingId", sendto);
      setSearchParams({ meet: sendto });
      // navigate(`?meet=${sendto}`)
    } else if (sendtomode === "toperson") {
      let convRefId =
        currentUser.uid < currentrecipient?.uid
          ? currentUser.uid + currentrecipient?.uid
          : currentrecipient?.uid + currentUser.uid;
      localStorage.setItem("meetingId", convRefId);
      setSearchParams({ meet: convRefId });

      db.collection("users")
        .doc(currentrecipient.uid)
        .collection("notifiedcalls")
        .add({
          from: currentUser.uid,
          fromImg: currentUser.imageUrl,
          fromName: currentUser.userName,
          click_action: convRefId,
          type: "privatecall",
          seen: false,
          created: fieldValue.serverTimestamp(),
        });
    } else {
      localStorage.setItem("meetingId", "public");
      setSearchParams({ meet: "public" });
    }
    // const timer = setTimeout(() => {
    //   setCallStatus(true);
    // }, 1000);
    // return () => clearTimeout(timer);
  };

  useEffect(() => {
    if (meet?.length > 0) {
      SetChatIsOpen(true);
      const timer = setTimeout(() => {
        setCallStatus(true);
      }, 1000);
      return () => clearTimeout(timer);
    } else setCallStatus(false);
  }, [meet]);

  //   useEffect(() => {
  //   // const dmUsersRef = spaceData.filter((userId) => users.includes(userId))
  //   const data = spaceData.filter((data) => data.spaceId === activeSpaceId);
  //   // const members = data[0].members;

  //   // spaceData.filter((data) => data.spaceId === activeSpaceId)
  //     setMemberData(data[0].members);
  // }, [spaceId]);

  // useEffect(() => {
  //   memberPromise.then((data) => {
  //     setMemberData(data);
  //   });
  // }, [spaceId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!chatmessage) {
      alert("no message");
      return;
    }
    if (sendtomode === "toworkspace") {
      createMessageToWorkspace(
        currentSpaceId,
        chatmessage,
        currentUser?.uid,
        sendto,
        sendtomode,
        currentUser?.userName,
        currentUser.imageUrl,
        activeSpaceData.name
      );
      textareaRef.current.value = "";
      setChatMessage("");
    }
    if (sendtomode === "toproject") {
      createMessageToProject(
        currentSpaceId,
        currentStationId,
        chatmessage,
        currentUser.uid,
        sendto,
        sendtomode,
        currentUser?.userName,
        currentUser?.imageUrl,
        currentStationName
      );
      textareaRef.current.value = "";
      setChatMessage("");
    }
    if (sendtomode === "toperson") {
      createMessageToPerson(
        chatmessage,
        currentUser?.uid,
        sendto,
        currentUser?.userName,
        currentUser?.imageUrl
      );
      textareaRef.current.value = "";
      setChatMessage("");
    }
  };

  // useEffect(() => {
  //   const urlparams = new URLSearchParams(window.location.search);
  //   const roomId = urlparams.get("meet");
  //   if (!roomId) {
  //     window.history.pushState(null, "Meet", "?meet=" + sendto);
  //   }
  // }, [sendto, currentSpaceId]);

  useEffect(() => {
    if (chatIsOpen) {
      setChatX(0);
    } else {
      setChatX("-320px");
    }
  }, [chatIsOpen]);

  //Read Workspace Messages
  useEffect(() => {
    //mounts
    if (sendtomode === "toworkspace") {
      const unsub = db
        .collection("space")
        .doc(selectedWorkspaceId)
        .collection("chats")
        // .where("type", "==", "toworkspace")
        .orderBy("created", "desc")
        .limit(15)
        .onSnapshot((querySnapshot) => {
          const array = querySnapshot.docs.map((msg) => {
            return {
              id: msg.id,
              created: msg.get("created"),
              from: msg.get("from"),
              fromSpaceId: msg.get("fromSpaceId"),
              message: msg.get("message"),
              reaction: msg.get("reaction"),
              to: msg.get("to"),
            };
          });
          setMsgs([...array]);
        });

      return () => {
        unsub();
      };
    }
  }, [currentSpaceId, sendtomode, sendto]);

  //Read Project Messages
  useEffect(() => {
    //mounts
    if (sendtomode === "toproject") {
      const unsub = db
        .collection("space")
        .doc(selectedWorkspaceId)
        .collection("stations")
        .doc(sendto)
        .collection("chats")
        // .where("type", "==", "toworkspace")
        .orderBy("created", "desc")
        .limit(15)
        .onSnapshot((querySnapshot) => {
          const array = querySnapshot.docs.map((msg) => {
            return {
              id: msg.id,
              created: msg.get("created"),
              from: msg.get("from"),
              fromSpaceId: msg.get("fromSpaceId"),
              message: msg.get("message"),
              reaction: msg.get("reaction"),
              to: msg.get("to"),
            };
          });
          setProjectMsgs([...array]);
        });

      return () => {
        unsub();
      };
    }
  }, [currentStationId, sendtomode, sendto]);

  //Read Personal Messages
  useEffect(() => {
    //mounts
    let convRefId =
      currentUser.uid < sendto
        ? currentUser.uid + sendto
        : sendto + currentUser.uid;

    const unsub = db
      .collection("dmchat")
      .where("conversationRef", "==", convRefId)
      .orderBy("created", "desc")
      .limit(15)
      .onSnapshot((querySnapshot) => {
        const array = querySnapshot.docs.map((msg) => {
          return {
            id: msg.id,
            created: msg.get("created"),
            from: msg.get("from"),
            message: msg.get("message"),
            reaction: msg.get("reaction"),
            to: msg.get("to"),
          };
        });
        setPersonalMsgs([...array]);
      });

    return () => {
      unsub();
    };
  }, [currentrecipient, sendto]);

  const handleDeleteMessage = (msg) => {
    db.collection("space")
      .doc(currentSpaceId)
      .collection("chats")
      .doc(msg.id)
      .delete();
  };
  const handleDeleteProjectMessage = (msg) => {
    db.collection("space")
      .doc(currentSpaceId)
      .collection("stations")
      .doc(currentStationId)
      .collection("chats")
      .doc(msg.id)
      .delete();
  };
  const handleDeletePersonalMessage = (msg) => {
    db.collection("chats").doc(msg.id).delete();
  };

  const [reactionmessageref, setReactionMessageRef] = useState();
  const [reaction, setReaction] = useState();

  const onSelectWsReaction = (emoji) => {
    setReaction(emoji);
    setReactionPickerOpen(!reactionpickerOpen);
    db.collection("space")
      .doc(sendto)
      .collection("chats")
      .doc(reactionmessageref)
      .update({ reaction: emoji.native });
  };

  const onSelectPrjReaction = (emoji) => {
    setReaction(emoji);
    setReactionPickerOpen(!reactionpickerOpen);
    db.collection("space")
      .doc(sendtoWsId)
      .collection("stations")
      .doc(sendto)
      .collection("chats")
      .doc(reactionmessageref)
      .update({ reaction: emoji.native });
  };

  const onSelect1on1Reaction = (emoji) => {
    setReaction(emoji);
    setReactionPickerOpen(!reactionpickerOpen);
    db.collection("dmchat")
      .doc(reactionmessageref)
      .update({ reaction: emoji.native });
  };

  const onSelectEmoji = (emojiObject) => {
    // textareaRef.current.focus();
    setEmoji(emojiObject);
    const emoji = emojiObject.native;
    // console.log(emojiObject);
    setChatMessage(chatmessage ? chatmessage + emoji : emoji);
    setEmojiOpen(!emojiOpen);
  };

  const handleSendtomode = (mode) => {
    setSendtomode(mode);
    if (mode === "toworkspace") {
      setCurrentRecipient("");
      // window.history.pushState(null, "Meet", "?meet=" + currentSpaceId);
      setSearchParams({ wschat: sendto });
    } else if (mode === "toproject") {
      setCurrentRecipient("");
      setSearchParams({ prchat: sendto });
      // window.history.pushState(null, "Meet", "?meet=" + currentStationId);
    } else if (mode === "toperson") {
      let convRefId =
        currentUser.uid < sendto
          ? currentUser.uid + sendto
          : sendto + currentUser.uid;
      setSearchParams({ dmchat: convRefId });
    }
  };

  const handleSendtoPerson = (member) => {
    setSendto(member?.uid);
    setButtonLook("personselected");
    setCurrentRecipient(member);
    setPersonalMessages(member);
    // handleSendtomode("toperson"); //Might be problematic for the future functions, check it later...
    setContactsOpen(false);
    let convRefId =
      currentUser.uid < sendto
        ? currentUser.uid + sendto
        : sendto + currentUser.uid;
    setSearchParams({ dmchat: convRefId });

    // window.history.pushState(null, "Meet", "?meet=" + convRefId);
  };

  const setPersonalMessages = (member) => {
    // setSendto(member.uid);
  };

  // const [messageemoji, setMessageEmoji] = useState([]);

  // const handleMessageEmoji = (msg) => {
  //   setMessageEmoji(msg.id);
  // };
  const handleChatHide = () => {
    // const urlparams = new URLSearchParams(window.location.search);
    // const roomId = urlparams.get("meet");
    // if (!roomId) {
    //   window.history.replaceState(null, "Meet", "?meet=" + currentSpaceId);
    // }
    SetChatIsOpen(!chatIsOpen);
  };

  const handleWorkspaceSelect = (space) => {
    // space.preventDefault();
    // setSendtomode("toworkspace");
    setSelectedWorkspaceId(space.spaceId);
    if (projectOptions[0]?.fromSpaceId === space.spaceId) {
      // handleSendtomode("toworkspace");
      setSendtomode("toworkspace");
      setSendto(space.spaceId);
      setShowGroupSelect(false);
      setSelectedGroupName(space.name);
      setSelectedGroupColor(space.color);
    } else {
      db.collection("space")
        .doc(space.spaceId)
        .collection("stations")
        // .doc(currentStationId)
        // .collection("tasks")
        .onSnapshot((querySnapshot) => {
          const array = querySnapshot.docs.map((doc) => {
            return {
              id: doc.id,
              name: doc.get("name"),
              emojicon: doc.get("emojicon"),
              fromSpaceId: doc.get("fromSpaceId"),
            };
          });
          setProjectOptions([...array]);
          // handleProjectSelect([...array][0].id);
        });
    }
  };

  const handleProjectSelect = (project) => {
    // e.preventDefault();
    setSendtomode("toproject");
    // setButtonLook("nopersonselected");
    setSendto(project.id);
    setSendtoWsId(project.fromSpaceId);
    setShowGroupSelect(false);
    setSelectedGroupName(project.name);
    setSelectedGroupIcon(project.emojicon);
  };

  return (
    <motion.div
      className={!meetingFullScreen ? "allchat" : "allchatFS"}
      animate={{ marginRight: chatx }}
      transition={{ ease: "easeOut" }}
      style={{
        zIndex: chatIsOpen ? "200" : "10",
        position: !chatIsOpen && "0px",
      }}
    >
      {!meetingFullScreen && (
        <motion.div onClick={handleChatHide}>
          <button className={chatIsOpen ? "chat__hideMoved" : "chat__hide"}>
            {/* <BsChatRightDots size="1.4em" /> */}
            {chatIsOpen ? (
              <div className="chat__exit__btn">
                <HiXMark size="3em" />
              </div>
            ) : (
              <div className="chat__exit__btn-flipped">
                <HiOutlineChatBubbleLeftEllipsis size="1.8em" />
              </div>
            )}
          </button>
          {/* <BsCameraVideo /> */}
        </motion.div>
      )}
      {chatIsOpen && (
        <div className={!meetingFullScreen ? "chat" : "chatFS"}>
          <div className="meetingVideoChat-mockheader"> </div>
          <div
            className={
              !meetingFullScreen ? "meetingVideoChat" : "meetingVideoChatFS"
            }
          >
            {/* <JitsiApp /> */}
            {/* <MeetingApp /> */}
            <MeetingStarter
              callStatus={callStatus}
              setCallStatus={setCallStatus}
              handleStartCall={handleStartCall}
              setMeetingFullScreen={setMeetingFullScreen}
              meetingFullScreen={meetingFullScreen}
              showInstantChat={showInstantChat}
              sendtomode={sendtomode}
              sendto={sendto}
              setSendto={setSendto}
              currentrecipient={currentrecipient}
              currentSpaceName={activeSpaceData?.name}
              currentStationName={currentStationName}
              selectedGroupName={selectedGroupName}
            />
          </div>
          <div
            className={
              meetingFullScreen
                ? "ch__breadcrumb-headerFS"
                : "ch__breadcrumb-header"
            }
          >
            {showInstantChat ? (
              <h5>{sendtomode.replace("to", "")} messages</h5>
            ) : (
              <h5></h5>
            )}
            <button onClick={() => setShowInstantChat(!showInstantChat)}>
              <IoMdChatboxes size="2.2em" />
              {/* {showInstantChat ? (
                <BsArrowBarDown size="1.5em" />
              ) : (
                <IoMdChatboxes size="2.4em" />
              )} */}
            </button>
          </div>
          <div
            className={
              showInstantChat ? "ch__writeUpdate" : "ch__writeUpdateHidden"
            }
          >
            {sendtomode === "toworkspace" && (
              <div className="ch__writeUpdate-msgs">
                {msgs.map((msg) => {
                  return (
                    <div
                      key={msg.id}
                      className={
                        msg.from === currentUser?.uid
                          ? "ch__messageLine-Self"
                          : "ch__messageLine"
                      }
                    >
                      <div className="ch__messagewithReaction">
                        <Message key={msg.id} msg={msg} />
                        <button
                          className={
                            msg.from === currentUser?.uid
                              ? "ch__messageReaction-Self"
                              : "ch__messageReaction"
                          }
                        >
                          {msg.reaction}
                        </button>
                      </div>
                      {/* <p>{msg.id}</p> */}
                      <button
                        className="ch__EmojiPick"
                        onClick={() => {
                          setReactionPickerOpen(!reactionpickerOpen);
                        }}
                      >
                        <button
                          className="ch__EmojiPickIcon"
                          onClick={() => setReactionMessageRef(msg.id)}
                        >
                          <BsEmojiSmileUpsideDown />
                        </button>
                      </button>
                      <button
                        className="ch__MessageDelete"
                        onClick={() => handleDeleteMessage(msg)}
                      >
                        <BsTrash />
                      </button>
                    </div>
                  );
                })}{" "}
                <div className="ch__ReactionPickerOn">
                  {reactionpickerOpen && (
                    <EmojiPicker
                      onEmojiSelect={onSelectWsReaction}
                      onClickOutside={() => setReactionPickerOpen(false)}
                    />
                  )}
                </div>
              </div>
            )}
            {sendtomode === "toproject" && (
              <div className="ch__writeUpdate-msgs">
                {projectMsgs?.map((msg) => {
                  return (
                    <div
                      key={msg.id}
                      className={
                        msg.from === currentUser?.uid
                          ? "ch__messageLine-Self"
                          : "ch__messageLine"
                      }
                    >
                      <div className="ch__messagewithReaction">
                        <Message key={msg.id} msg={msg} />
                        <button
                          className={
                            msg.from === currentUser?.uid
                              ? "ch__messageReaction-Self"
                              : "ch__messageReaction"
                          }
                        >
                          {msg.reaction}
                        </button>
                      </div>
                      {/* <p>{msg.id}</p> */}
                      <button
                        className="ch__EmojiPick"
                        onClick={() => {
                          setReactionPickerOpen(!reactionpickerOpen);
                        }}
                      >
                        <button
                          className="ch__EmojiPickIcon"
                          onClick={() => setReactionMessageRef(msg.id)}
                        >
                          <BsEmojiSmileUpsideDown />
                        </button>
                      </button>
                      <button
                        className="ch__MessageDelete"
                        onClick={() => handleDeleteProjectMessage(msg)}
                      >
                        <BsTrash />
                      </button>
                    </div>
                  );
                })}{" "}
                <div className="ch__ReactionPickerOn">
                  {reactionpickerOpen && (
                    <EmojiPicker
                      onEmojiSelect={onSelectPrjReaction}
                      onClickOutside={() => setReactionPickerOpen(false)}
                    />
                  )}
                </div>
              </div>
            )}
            {sendtomode === "toperson" && (
              <div className="ch__writeUpdate-msgs">
                {personalMsgs?.map((msg) => {
                  return (
                    <div
                      key={msg.id}
                      className={
                        msg.from === currentUser?.uid
                          ? "ch__messageLine-Self"
                          : "ch__messageLine"
                      }
                    >
                      <div className="ch__messagewithReaction">
                        <Message key={msg.id} msg={msg} />
                        <button
                          className={
                            msg.from === currentUser?.uid
                              ? "ch__messageReaction-Self"
                              : "ch__messageReaction"
                          }
                        >
                          {msg.reaction}
                        </button>
                      </div>
                      {/* <p>{msg.id}</p> */}
                      <button
                        className="ch__EmojiPick"
                        onClick={() => {
                          setReactionPickerOpen(!reactionpickerOpen);
                        }}
                      >
                        <button
                          className="ch__EmojiPickIcon"
                          onClick={() => setReactionMessageRef(msg.id)}
                        >
                          <BsEmojiSmileUpsideDown />
                        </button>
                      </button>
                      <button
                        className="ch__MessageDelete"
                        onClick={() => handleDeletePersonalMessage(msg)}
                      >
                        <BsTrash />
                      </button>
                    </div>
                  );
                })}{" "}
                <div className="ch__ReactionPickerOn">
                  {reactionpickerOpen && (
                    <EmojiPicker
                      onEmojiSelect={onSelect1on1Reaction}
                      onClickOutside={() => setReactionPickerOpen(false)}
                    />
                  )}
                </div>
              </div>
            )}
            <div className="ch__writeUpdate-send">
              <div className="ch__textbox">
                <div className="ch__sendtomode-tabs">
                  <div style={{ display: "flex", width: "100%" }}>
                    <button
                      className={
                        sendtomode === "toworkspace" ||
                        sendtomode === "toproject"
                          ? "ch__sendtomode-tab-active"
                          : "ch__sendtomode-tab"
                      }
                      onClick={() => setShowGroupSelect(!showGroupSelect)}
                    >
                      {sendtomode === "toworkspace" ? (
                        <div className="chat_grpoption-selected">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              background: selectedGroupColor,
                              borderRadius: "6px",
                              width: "20px",
                              height: "20px",
                            }}
                          >
                            {selectedGroupName?.charAt(0)}
                          </div>
                          {selectedGroupName}
                        </div>
                      ) : sendtomode === "toproject" ? (
                        <div className="chat_grpoption-selected">
                          {selectedGroupIcon} {"  "}
                          {selectedGroupName}
                        </div>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            columnGap: "5px",
                          }}
                        >
                          <IoPeopleOutline size="1.4em" /> Group
                        </div>
                      )}
                    </button>
                    {showGroupSelect && (
                      <div className="chat_grpselect">
                        {spaceData?.map((space) => {
                          return (
                            <>
                              <button
                                key={space.spaceId}
                                value={space.spaceId}
                                selected={selectedWorkspaceId === space.spaceId}
                                className={
                                  sendto === space.spaceId
                                    ? "chat_grpoption-active"
                                    : "chat_grpoption"
                                }
                                style={{
                                  textDecoration: `underline 2px ${space.color}`,
                                }}
                                onClick={() => handleWorkspaceSelect(space)}
                              >
                                {space.name}
                              </button>
                              {projectOptions[0]?.fromSpaceId ===
                                space.spaceId && (
                                <motion.div
                                  initial={{
                                    y: -50,
                                    height: "0px",
                                    opacity: 0,
                                  }}
                                  animate={{
                                    y: 0,
                                    height: "auto",
                                    opacity: 1,
                                  }}
                                  transition={{
                                    type: "ease-in-out",
                                    duration: 0.4,
                                  }}
                                >
                                  {/* <option className="chat_grpsuboption">
                                    All {space.name} workspace
                                  </option> */}
                                  {projectOptions.map((project) => (
                                    <button
                                      key={project.id}
                                      value={project.id}
                                      className={
                                        sendto === project.id
                                          ? "chat_grpsuboption-active"
                                          : "chat_grpsuboption"
                                      }
                                      onClick={() =>
                                        handleProjectSelect(project)
                                      }
                                    >
                                      {project.emojicon} {project.name}
                                    </button>
                                  ))}
                                </motion.div>
                              )}
                            </>
                          );
                        })}
                        <details className="chat_grpoption">
                          <summary>Shared With Me</summary>
                          {currentUser?.sharedWith?.map((project) => {
                            return (
                              <button
                                key={project.stationId}
                                className="chat_grpsuboption"
                              >
                                {project.stationId}
                                {/* <SharedStationItem project={project} />  */}
                              </button>
                            );
                          })}
                        </details>
                      </div>
                    )}
                  </div>

                  <div style={{ display: "flex", width: "100%" }}>
                    <div
                      className={
                        sendtomode === "toperson"
                          ? "ch__sendtomode-tab-active"
                          : "ch__sendtomode-tab"
                      }
                      onClick={() =>
                        handleSendtomode("toperson") &
                        setContactsOpen(!contactsopen)
                      }
                    >
                      {buttonlook === "personselected" ? (
                        <button
                          className="ch__personselected"
                          onClick={() =>
                            handleSendtomode("toperson") &
                            setContactsOpen(!contactsopen)
                          }
                        >
                          <Avatar
                            src={currentrecipient?.imageUrl}
                            onClick={() => setContactsOpen(!contactsopen)}
                          />
                          {currentrecipient?.userName}
                        </button>
                      ) : (
                        <button
                          className="ch__personNonselected"
                          onClick={() =>
                            handleSendtomode("toperson") &
                            setContactsOpen(!contactsopen)
                          }
                        >
                          <Avatar src={currentrecipient?.imageUrl} />
                          Person
                        </button>
                      )}
                    </div>
                    {sendtomode === "toperson" && (
                      <div>
                        {/* <p>Just to a Person</p> */}
                        {contactsopen && (
                          <div className="ch__selectperson">
                            {memberData
                              ?.filter(
                                (member) => member.uid !== currentUser.uid
                              )
                              .map((member) => {
                                return (
                                  <button
                                    key={member.uid}
                                    member={member}
                                    image={member.imageUrl}
                                    // activeSpaceData={activeSpaceData}
                                    // activeSpaceId={activeSpaceId}
                                    className="ch__selectperson_item"
                                    onClick={() => handleSendtoPerson(member)}
                                  >
                                    <Avatar src={member.imageUrl} />
                                    {member.userName}
                                  </button>
                                );
                              })}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>

                <div className="ch__writeUpdate-message">
                  <form onSubmit={(e) => handleSubmit(e)} id="MessageForm">
                    <textarea
                      onChange={(e) => setChatMessage(e.target.value)}
                      ref={textareaRef}
                      placeholder="Write a message"
                      value={chatmessage}
                    />
                  </form>
                  <div className="ch__writeUpdate-buttongroup">
                    <div className="ch__writeUpdate-buttongroup-sub">
                      {/* <div className="ch__writeUpdate-avatar">
                        <Avatar src={currentUser.imageUrl} />
                      </div> */}
                      <div className="ch__EmojiIcon">
                        <button
                          className="ch__EmojiPick"
                          onClick={() => {
                            setEmojiOpen(!emojiOpen);
                          }}
                        >
                          <button>
                            <BsEmojiSmileUpsideDown size="1.2em" />
                          </button>
                          {/* <MdOutlineEmojiFoodBeverage /> */}
                        </button>
                        <div className="ch__PickerOn">
                          {emojiOpen &&
                            createPortal(
                              <div
                                style={{
                                  position: "absolute",
                                  bottom: "95px",
                                  // bottom: document.getElementById("emojiBtn").getBoundingClientRect().top > (window.innerHeight/2) ? `${document.getElementById("emojiBtn").getBoundingClientRect().bottom}px` : "unset",
                                  // top: `${document.getElementById("emojiBtn").getBoundingClientRect().top + 30}px`,
                                  right: "35px",
                                  zIndex: "201",
                                }}
                              >
                                <EmojiPicker onEmojiSelect={onSelectEmoji} />
                              </div>,
                              document.body
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="ch__writeUpdate-btn">
                      <RetroButton type="submit" form="MessageForm">
                        {/* <a>Send</a> */}
                        <FaPaperPlane size="1.5em" />
                      </RetroButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </motion.div>
  );
}

export default Chat;
