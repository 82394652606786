import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  db,
  personNotfUpdateSeenAll,
  personNotfUpdateDeleteAll,
  getToken,
  onMessageListener,
  fieldValue,
  personNotfUpdateSeen,
} from "../../firebase/firebase.utils";

import { Link, useNavigate } from "react-router-dom";

import Avatar from "../retro/avatar/avatar.component";

import MobileBurger from "../mobile-drawer/mobileBurger";

import { AnimatePresence, motion } from "framer-motion";
import "./person-notifications.styles.scss";
import PersonNotification from "./notification/person-notification.component";
import {
  BsBell,
  BsBellFill,
  BsFillTelephoneInboundFill,
  BsTelephoneXFill,
} from "react-icons/bs";

function PersonNotifications() {
  const currentUser = useSelector((state) => state.user.currentUser);

  const [notifications, setNotifications] = useState([]);
  const [shownotifications, setShowNotifications] = useState(false);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [showIncomingCall, setShowIncomingCall] = useState(false);

  const [isTokenFound, setTokenFound] = useState(false);

  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });

  const [showMobileMenus, setShowMobileMenus] = useState(false);

  const navigate = useNavigate();

  // console.log("Token found", isTokenFound);

  const getDeviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return "mobile";
    }
    if (
      /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return "mobile";
    }
    return "desktop";
  };

  function iOS() {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  }

  const isSupportedBrowser = iOS();

  // To load once
  useEffect(() => {
    let data;

    async function tokenFunc() {
      if (!isSupportedBrowser) { 
      data = await getToken(setTokenFound);
      if (data) {
        // console.log("Token is", data);
        const dbTokenRef = db.collection("fcmTokens").doc(data);
        dbTokenRef.get().then((docSnapshot) => {
          if (!docSnapshot.exists) {
            const deviceType = getDeviceType();
            dbTokenRef.set({
              user: currentUser.uid,
              timestamp: fieldValue.serverTimestamp(),
              type: deviceType,
            });
          }
        });
      }
      return data;
    }
    }
      tokenFunc();
  }, [setTokenFound]);

  // onMessageListener()
  //   .then((payload) => {
  //     setShow(true);
  //     setNotification({
  //       title: payload.notification.title,
  //       body: payload.notification.body,
  //       icon: payload.notification.icon,
  //     });
  //     console.log(payload);
  //   })
  //   .catch((err) => console.log("failed: ", err));

  useEffect(() => {
    //mounts
    const unsub = db
      .collection("users")
      .doc(currentUser.uid)
      .collection("pnotifications")
      .orderBy("created", "desc")
      .limit(9)
      .onSnapshot((querySnapshot) => {
        if (querySnapshot) {
          const array = querySnapshot.docs.map((notification) => {
            return {
              id: notification.id,
              title: notification.get("title"),
              body: notification.get("body"),
              click_action: notification.get("click_action"),
              icon: notification.get("icon"),
              type: notification.get("type"),
              seen: notification.get("seen"),
              created: notification.get("created"),
            };
          });
          if (array.length > notifications.length) {
            document.getElementById("person_notification").play();
          }
          setShowSnackBar(true);
          setTimeout(() => {
            setShowSnackBar(false);
          }, 4000);
          setNotifications([...array]);
        }
      });
    return () => {
      unsub();
    };
  }, []);

  const snack = notifications[0];
  const ringtone = document.getElementById("person_call_notification");

  function playAudio() {
    ringtone.play();
  }

  function pauseAudio() {
    ringtone.pause();
  }

  useEffect(() => {
    const newsnack = notifications[0];
    if ((newsnack?.type === "incoming call") & !newsnack?.seen) {
      playAudio();
      setShowIncomingCall(true);
      setTimeout(() => {
        pauseAudio();
        setShowIncomingCall(false);
        personNotfUpdateSeen(currentUser.uid, newsnack.id);
      }, 12000);
    }
  }, [notifications, snack]);

  // useEffect(() => {
  //   if (showIncomingCall) {
  //     playAudio();
  //   } else {
  //     pauseAudio();
  //   }
  // }, [showIncomingCall]);

  const handleMarkSeenAll = (e) => {
    e.preventDefault();
    personNotfUpdateSeenAll(currentUser.uid);
  };

  const handleDeleteAll = (e) => {
    e.preventDefault();
    personNotfUpdateDeleteAll(currentUser.uid);
  };

  const handleAcceptCall = (e) => {
    e.preventDefault();
    navigate(e.target.value);
    setShowIncomingCall(false);
    pauseAudio();
    // personNotfUpdateSeen(currentUser.uid, newsnack.id);
  };

  const handleDeclineCall = (e) => {
    e.preventDefault();
    setShowIncomingCall(false);
    pauseAudio();
    // personNotfUpdateSeen(currentUser.uid, newsnack.id);
    // document.getElementById("person_call_notification").pause();
    // personNotfUpdateSeen(currentUser.uid, snack.id);
  };

  return (
    <div style={{ height: "50px", position: "relative" }}>
      <div className="app__notifications-container">
        <MobileBurger />
        <div className="app__notifications">
          {/* <div className="app__mobile-burger">
        <button onClick={() => setShowMobileMenus(!showMobileMenus)}>M-</button>
      </div>{" "} */}
          <div className="notification__sliding">
            <AnimatePresence>
              {showSnackBar && (
                <motion.h4
                  initial={{ x: 80, opacity: 0 }}
                  animate={{ x: 32, opacity: 1 }}
                  transition={{ type: "ease" }}
                  exit={{ x: -100, opacity: 0, transition: { duration: 1 } }}
                >
                  {notifications?.filter((n) => n.seen === false).length >
                    0 && <p>new {snack?.type}</p>}
                </motion.h4>
              )}
            </AnimatePresence>
          </div>
          <div className="notification__sliding-call">
            {showIncomingCall && (
              <AnimatePresence>
                <motion.div
                  initial={{ x: 80, opacity: 0 }}
                  animate={{ x: 32, opacity: 1 }}
                  transition={{ type: "ease" }}
                  exit={{ x: -100, opacity: 0, transition: { duration: 1 } }}
                >
                  {notifications?.filter((n) => n.seen === false).length >
                    0 && (
                    <div className="notification__call">
                      <Avatar src={snack?.icon} />
                      <h4>{snack?.title} is calling you</h4>
                      <div className="notification__call-btns">
                        <button
                          onClick={handleDeclineCall}
                          className="notf_call-btn-decline"
                        >
                          <BsTelephoneXFill size="1.4em" /> Decline
                        </button>
                        <button
                          value={snack?.click_action}
                          onClick={handleAcceptCall}
                          className="notf_call-btn-accept"
                        >
                          <BsFillTelephoneInboundFill size="2em" /> Accept
                        </button>
                      </div>
                    </div>
                  )}
                </motion.div>
              </AnimatePresence>
            )}
          </div>
          {shownotifications && (
            <div className="person__notifications">
              <div className="notifications__btngroup">
                {notifications?.filter((n) => n.seen === false).length > 0 && (
                  <button onClick={(e) => handleMarkSeenAll(e)}>
                    Mark all as read
                  </button>
                )}
                {notifications?.length > 0 && (
                  <button onClick={(e) => handleDeleteAll(e)}>Clear all</button>
                )}
              </div>
              {notifications?.filter((n) => n.seen === false).length === 0 && (
                <p>No new notification</p>
              )}
              {notifications?.map((notification) => {
                return (
                  // <div key={notification.id}>
                  <PersonNotification
                    notification={notification}
                    userId={currentUser.uid}
                    key={notification.id}
                  />
                  // </div>
                );
              })}
            </div>
          )}
          <AnimatePresence>
            {showSnackBar && (
              <motion.i
                animate={{
                  rotate: [0, 20, 0, -20, 0, 15, 0, -15, 0],
                  transformOrigin: "top",
                }}
                transition={{
                  repeat: 1,
                  // repeatType: "reverse",
                  duration: 2,
                }}
              >
                <BsBellFill
                  size="1.2em"
                  onClick={() => setShowNotifications(!shownotifications)}
                />
              </motion.i>
            )}

            {!showSnackBar && (
              <BsBellFill
                size="1.2em"
                onClick={() => setShowNotifications(!shownotifications)}
              />
            )}
          </AnimatePresence>
          {/* <p>{snack?.type}</p> */}
          {notifications?.filter((n) => n.seen === false).length > 0 && (
            <div className="notifications__badge">
              <i>{notifications?.filter((n) => n.seen === false).length}</i>
            </div>
          )}
          <audio
            id="person_notification"
            src="/media/mixkit-guitar-notification-alert-2320.wav"
          ></audio>
          <audio
            id="person_call_notification"
            src="/media/mixkit-waiting-ringtone-1354.wav"
          ></audio>
        </div>
      </div>
    </div>
  );
}

export default PersonNotifications;
