import React from "react";
import { useSelector } from "react-redux";
import Avatar from "../../../components/retro/avatar/avatar.component";
import "../webadmin.styles.scss";

const UserManagement = () => {
  const currentUser = useSelector((state) => state.user.currentUser);
  const users = useSelector((state) => state.user.users);

  const headers = [
    { key: "id", label: "ID" },
    { key: "image", label: "Image" },
    { key: "user_name", label: "Username" },
    { key: "email", label: "Email" },
    { key: "plan", label: "Plan" },
    { key: "role", label: "Role" },
    { key: "online", label: "Online" },
  ];

  return (
    <div className="admin__users">
      <h3>Free Users</h3>
      <table>
        <thead>
          <tr>
            {headers.map((row) => {
              return <td key={row.key}>{row.label}</td>;
            })}
          </tr>
        </thead>
        <tbody>
          {users
            ?.filter((item) => item.plan === "Free")
            .filter((item) => item.userName !== "Camiya")
            .map((member) => {
              return (
                <tr key={member.uid}>
                  <td>
                    <button
                      className="ch__selectperson_item"
                      onClick={() => navigator.clipboard.writeText(member.uid)}
                    >
                      Get id
                    </button>
                  </td>
                  <td>
                    <Avatar src={member.imageUrl} />
                  </td>
                  <td>{member.userName}</td>
                  <td>{member.email}</td>
                  <td>{member.plan}</td>
                  <td>{member.role}</td>
                  <td>{member.online}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <h3>Freemium Users</h3>
      <table>
        <thead>
          <tr>
            {headers.map((row) => {
              return <td key={row.key}>{row.label}</td>;
            })}
          </tr>
        </thead>
        <tbody>
          {users
            ?.filter((item) => item.plan === "Freemium")
            .filter((item) => item.userName !== "Camiya")
            .map((member) => {
              return (
                <tr key={member.uid}>
                  <td>
                    <button
                      className="ch__selectperson_item"
                      onClick={() => navigator.clipboard.writeText(member.uid)}
                    >
                      Get id
                    </button>
                  </td>
                  <td>
                    <Avatar src={member.imageUrl} />
                  </td>
                  <td>{member.userName}</td>
                  <td>{member.email}</td>
                  <td>{member.plan}</td>
                  <td>{member.role}</td>
                  <td>{member.online}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <h3>Premium Users</h3>
      <table>
        <thead>
          <tr>
            {headers.map((row) => {
              return <td key={row.key}>{row.label}</td>;
            })}
          </tr>
        </thead>
        <tbody>
          {users
            ?.filter((item) => item.plan === "Premium")
            .filter((item) => item.userName !== "Camiya")
            .map((member) => {
              return (
                <tr key={member.uid}>
                  <td>
                    <button
                      className="ch__selectperson_item"
                      onClick={() => navigator.clipboard.writeText(member.uid)}
                    >
                      Get id
                    </button>
                  </td>
                  <td>
                    <Avatar src={member.imageUrl} />
                  </td>
                  <td>{member.userName}</td>
                  <td>{member.email}</td>
                  <td>{member.plan}</td>
                  <td>{member.role}</td>
                  <td>{member.online}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default UserManagement;
