import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import Views from "../../views/views.component";
import Modules from "../../modules/modules.component";
import StationInfo from "../../station-info/station-info.component";
import Filter from "../../filter/filter-component";
import PersonFilter from "../../filter/person-filter/person-filter.component";

import { TbFilter } from "react-icons/tb";
import { BsFunnel, BsFunnelFill, BsSearch } from "react-icons/bs";
import { IoCloseOutline } from "react-icons/io5";

import "./station-menu.styles.scss";

const StationMenu = () => {
  const stationData = useSelector((state) => state.space.stationData);
  const assignedTasks = useSelector((state) => state.tasks.tasks);
  const projectTasks = useSelector((state) => state.prjtasks.prjtasks);
  const projectNotes = useSelector((state) => state.prjnotes.prjnotes);
  // console.log("ASSIGNEDTASKS IN MENU", assignedTasks)
  const navigate = useNavigate();
  const location = useLocation();
  const currentStationId = location.pathname.split("/")[4];
  const currentSpaceId = location.pathname.split("/")[2];
  const view = location.pathname.split("/")[5];
  const [data, setData] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    if (!stationData) return;
    const data = stationData.filter(
      (item) => item.stationId === currentStationId
    );
    setData(data[0]);
  }, [stationData, currentStationId]);

  return (
    <div className="stationMenu">
      <div className="sm__upperRow">
        <div>
          <StationInfo
            data={data}
            currentStationId={currentStationId}
            currentSpaceId={currentSpaceId}
          />
        </div>
        
        <div className="filterandsearch">
        {(view === "b" || view === "l" || view === "c" || view === "g") && (
          <div className="sm__filters">
            {showFilters && (
              <div className="sm__filters-group">
                <div className="filter__byPerson">{/* <PersonFilter /> */}</div>
                <Filter />
              </div>
            )}

            <div className="sm__filters-togglegroup">
              <button
                onClick={() =>
                  setShowFilters(!showFilters) & setShowSearchBar(false)
                }
                className={
                  showFilters
                    ? "sm__filters-toggle-active"
                    : "sm__filters-toggle"
                }
              >
                {showFilters ? (
                  <BsFunnelFill size="1.6em" />
                ) : (
                  <BsFunnel size="1.6em" />
                )}
              </button>
            </div>
          </div>
        )}
        {!showFilters && (
          <div
            className={
              showSearchBar ? "sm__filter-search-active" : "sm__filters-search"
            }
            onClick={() => setShowSearchBar(!showSearchBar)}
          >
            <BsSearch size="1.4em" />
            {showSearchBar && (
              <>
                <input
                  disabled={!showSearchBar}
                  type="text"
                  placeholder="search"
                  autoFocus
                  onChange={(e) => setSearchValue(e.target.value)}
                  value={searchValue}
                />
                <IoCloseOutline onClick={() => setSearchValue("")} />
                {searchValue.length > 1 && (
                  <div className="sm__search-results">
                    {projectTasks.filter((t) =>
                      t.title.toLowerCase().includes(searchValue.toLowerCase())
                    ).length > 0 && <h4>Tasks in this project</h4>}
                    {projectTasks
                      .filter((t) =>
                        t.title
                          .toLowerCase()
                          .includes(searchValue.toLowerCase())
                      )
                      .map((task) => {
                        return (
                          <div
                            key={task.id}
                            className="sm__search-result"
                            onClick={() =>
                              navigate(`${location.pathname}/task/${task.id}`)
                            }
                          >
                            {task.title}
                          </div>
                        );
                      })}
                    {assignedTasks
                      .filter((t) =>
                        t.title
                          .toLowerCase()
                          .includes(searchValue.toLowerCase())
                      )
                      .filter((t) => t.fromStationId !== currentStationId)
                      .length > 0 && <h4>Your tasks elsewhere</h4>}
                    {assignedTasks
                      .filter((t) =>
                        t.title
                          .toLowerCase()
                          .includes(searchValue.toLowerCase())
                      )
                      .filter((t) => t.fromStationId !== currentStationId)
                      .map((task) => {
                        return (
                          <div
                            key={task.id}
                            className="sm__search-result"
                            onClick={() =>
                              navigate(
                                `/s/${task.fromSpaceId}/e/${task.fromStationId}/b/task/${task.id}`
                              )
                            }
                          >
                            {task.title}
                          </div>
                        );
                      })}
                    {projectNotes.filter((n) =>
                      n.title.toLowerCase().includes(searchValue.toLowerCase())
                    ).length > 0 && <h4>Notes in this project</h4>}
                    {projectNotes
                      .filter((n) =>
                        n.title
                          .toLowerCase()
                          .includes(searchValue.toLowerCase())
                      )
                      .map((note) => {
                        return (
                          <div
                            key={note.id}
                            className="sm__search-result"
                            onClick={() =>
                              navigate(`${location.pathname}/note/${note.id}`)
                            }
                          >
                            {note.title}
                          </div>
                        );
                      })}
                  </div>
                )}
              </>
            )}
          </div>
        )}
        </div>
      </div>
      <div className="sm__buttomRow">
        <Views
          view={view}
          currentStationId={currentStationId}
          currentSpaceId={currentSpaceId}
        />
      </div>
    </div>
  );
};

export { StationInfo };
export default StationMenu;
