import * as React from "react"
const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={392} height={138} {...props}>
    <title>{"accessories/Glasses"}</title>
    <path
      fill="#000"
      fillRule="evenodd"
      d="M389.09 63.282c-9.534 64.49-76.088 71.621-87.913 7.6 1.898-.832 3.263-2.468 2.978-5.644-.067-13.952 4.264-28.698 16.85-36.457 13.006-8.006 28.227-8.8 43.278-8.598 28.722-.684 28.118 23.323 24.807 43.1m-98.332-28.048c-9.014-.38-20.418-.062-28.493 5.43-2.22-5.978-7.988-12.36-13.816-15.774 21.662.634 43.96-1.498 65.784-3.153-5.224 3.561-10.084 8.911-13.243 15.096-2.93-1.563-7.129-1.417-10.232-1.599M251.026 70.12c-24.995 86.036-87.386 48.536-94.188 37.51-12.269-18.246-14.577-62.6-3.89-74.459 15.298-10.724 36.955-7.964 55.027-8.227 33.201.448 56.781 10.38 43.051 45.176m167.577-39.57c-6.79-.889-14.048-1.068-20.362 1.922-1.944-8.263-8.047-15.17-14.938-18.21-.834-1.047-.709-1.609-3.558-1.452-17.495-5.258-37.072-2.476-53.884 2.916-26.552 2.236-54.409 4-81.184 7.08C219.595 11.541 143.902 9.89 139.198 37.653c-5.917-.885-15.994-5.542-20.966.775-36.47-6.183-74.156 2.41-110.785 6.237-9.22 1.698-13.969 19.996-3.503 19.746 5.884-1.597-.18-11.394 7.742-12.13 35.341-3.86 69.91-9.337 105.612-9.978-1.242 6.165 23.614 6.566 23.981 2.966.053.027.11.045.163.07-6.142 22.89-.598 55.831 10.502 70.025 15.552 18.586 54.597 23.918 71.91 11.96 22.333-11.037 45.566-66.439 39.04-84.845 11.844-3.754 23.553-2.627 35.845-.715-2.814 7.95-7.672 27.517-.683 29.214-1.283 38.85 34.074 63.091 66.668 47.154 28.211-13.243 38.077-55.16 34.625-80.604 3.512 6.786 33-.602 19.254-6.98"
    />
  </svg>
)
export default SvgComponent
