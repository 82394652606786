import * as React from "react"
const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={473} height={567} {...props}>
    <title>{"head/mono/Bangs 2"}</title>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#FFF"
        d="M389.53 161.685c-60.131-39.803-180.6-35.48-246.793-11.094-64.588 20.483-87.201 77.558-97.925 139.221-13.035 69.303-11.283 169.207 82.45 167.998 13.179 6.373 27.778 9.137 41.413 14.414 18.662 6.452 36.96 13.942 55.897 19.594 37.298 12.012 78.802 10.966 114.183-6.61 19.547-9.443 27.638-24.148 40.421-39.817 37.19-45.585 55.904-110.823 54.228-168.787-2.941-40.814-9.692-88.062-43.873-114.918Z"
      />
      <path
        fill="#000"
        d="M465.624 329.695c-6.17-38.3-7.88-77.25-15.96-115.25-7.6-35.759-21.81-69.55-46.64-96.81-22.22-24.39-50.95-42.3-81.79-53.65-60.08-22.11-129.18-17.11-187.3 8.701-54.4 24.15-97.79 68.83-123.08 122.45-16.43 34.84-24.69 71.88-28.36 110.11-4.07 42.47-4.51 85.38-6.17 128-.2 5.26-.49 10.52-.56 15.78-.08 5.76 2.25 10.81 4.15 16.17.13.38.32.74.55 1.08-1.43.18-2.86.39-4.29.62-1.6.25-1.53 2.85 0 3.16 12.95 2.64 26.26 3.01 39.43 3.66 13.65.68 27.32 1.05 40.99 1.51l39.99 1.35c7 .24 14 .48 21 .71 3.33.11 6.66.23 10 .34 1.66.06 3.33.108 4.99.17 1.35.04 3.55.9 3.69-.09.25.15.52.278.81.37.32.17.63.37.93.59 1.63 1.12 4.1.98 5.75 0 .15-.09.31-.19.47-.29a6.082 6.082 0 0 0 2.57-2.99c6.84 8.26 18.95-1.02 11.95-9.76-3.76-4.7-41.16-119.93-25.68-207.71l62.08-8.22c7.566-.767 18.39-1.914 32.47-3.44 3.446-11.247 5.663-18.68 6.65-22.3.11-.4.22-.81.34-1.21 1.48 2.61 2.97 5.21 4.47 7.81l7.66 15.57c75.373-1.26 122.3-1.83 140.78-1.71.47 0 2.7 33.7 1.69 48.54-2.04 29.8-4.21 59.58-7.23 89.3-1.928 19.03-6.574 37.693-13.495 55.232-10.932 27.707-42.675 54.042-73.625 54.042-19.266 0-62.8-15.603-69.14-18.754-6.34-3.15-10.118 5.45-4.34 8.89 11.704 6.968 43.965 21.75 73.48 21.75 42.863 0 75.22-40.67 79.19-46.5 39.03-2.14 78.95-1.15 117.93 2.72 3.27.32 6.09-3.62 5.04-6.63-14-40.07-24.65-81.4-31.39-123.31Z"
      />
    </g>
  </svg>
)
export default SvgComponent
