import React,{useState} from "react";
import { Link, useLocation } from "react-router-dom";
import { motion } from "framer-motion";

import "./note-add-btn.styles.scss";

import { BsSticky, BsPencilSquare } from "react-icons/bs";
import { HiOutlinePencilSquare } from "react-icons/hi2";

const variants = {
  open: { opacity: 0, x: "-100%", width: "594px", height: "calc(100% - 90px)", borderRadius: "6px", right: "0px", translateX: "36%", translateY: "-2%" },
  closed: { opacity: 1, x: 0 },
}

const NoteAddButton = () => {
  // const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const isOpen = location.pathname.includes("addnote")
  return (
    <Link to="addnote">
      <motion.button
        className="addnote__btn"
        animate={isOpen ? "open" : "closed"}
        variants={variants}
        transition={{ type: "ease-in-out", duration: 0.4 }}
      >
        <HiOutlinePencilSquare size="1.8em" />
      </motion.button>
    </Link>
  );
};

export default NoteAddButton;
