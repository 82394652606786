import MainScreen from "./components/MainScreen/MainScreen.component";
import { useSelector } from "react-redux";
// import { firepadRef } from "./MeetingStarter";
import { roomIdTest } from "./MeetingStarter";
import firepadRef, { dbMeet } from "../../firebase/firebase.utils";
// import firepadRef, { dbMeet, userName } from "./server/firebase";
import "./MeetingApp.scss";
import { useEffect } from "react";
import {
  setMainStream,
  addParticipant,
  setUser,
  removeParticipant,
  updateParticipant,
} from "../..//redux/meeting/meeting.actions";
import { connect } from "react-redux";


function MeetingApp(props) {

  // useEffect(() => {
  //   // export const userName = prompt("What's your name?");
  //   const urlparams = new URLSearchParams(window.location.search);
  //   const roomId = urlparams.get("id");

  //   if (roomId) {
  //     firepadRef = firepadRef.child(roomId);
  //   } else {
  //     firepadRef = firepadRef.push();
  //     window.history.replaceState(null, "Meet", "?id=" + firepadRef.key);
  //   }
  // }, []);

  const currentUser = useSelector((state) => state.user.currentUser);
  const { userName } = currentUser;
  // const { imageUrl } = currentUser;

  const getUserStream = async () => {
    const localStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: true,
    });

    return localStream;
  };
  useEffect(async () => {
    const stream = await getUserStream();
    stream.getVideoTracks()[0].enabled = false;
    props.setMainStream(stream);

    connectedRef.on("value", (snap) => {
      if (snap.val()) {
        const defaultPreference = {
          audio: true,
          video: false,
          screen: false,
        };
        const userStatusRef = participantRef.push({
          userName,
          preferences: defaultPreference,
        });
        props.setUser({
          [userStatusRef.key]: { name: userName, ...defaultPreference },
        });
        userStatusRef.onDisconnect().remove();
      }
    });
  }, []);

  const urlparams = new URLSearchParams(window.location.search);
  const roomIdRef = urlparams.get("meet");
  const roomIdRefFinal = (roomIdRef) ? roomIdRef : "public";

  const connectedRef = dbMeet.database().ref(".info/connected");
  const participantRef = firepadRef.child(roomIdRefFinal).child("participants");

  const isUserSet = !!props.user;
  const isStreamSet = !!props.stream;

  useEffect(() => {
    if (isStreamSet && isUserSet) {
      participantRef.on("child_added", (snap) => {
        const preferenceUpdateEvent = participantRef
          .child(snap.key)
          .child("preferences");
        preferenceUpdateEvent.on("child_changed", (preferenceSnap) => {
          props.updateParticipant({
            [snap.key]: {
              [preferenceSnap.key]: preferenceSnap.val(),
            },
          });
        });
        const { userName: name, preferences = {} } = snap.val();
        props.addParticipant({
          [snap.key]: {
            name,
            ...preferences,
          },
        });
      });
      participantRef.on("child_removed", (snap) => {
        props.removeParticipant(snap.key);
      });
    }
  }, [isStreamSet, isUserSet]);

  return (
    <div className="meeting_App">
      <MainScreen setMeetingFullScreen={props.setMeetingFullScreen} meetingFullScreen={props.meetingFullScreen} showInstantChat={props.showInstantChat} setCallStatus={props.setCallStatus} />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    stream: state.meets.mainStream,
    user: state.meets.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMainStream: (stream) => dispatch(setMainStream(stream)),
    addParticipant: (user) => dispatch(addParticipant(user)),
    setUser: (user) => dispatch(setUser(user)),
    removeParticipant: (userId) => dispatch(removeParticipant(userId)),
    updateParticipant: (user) => dispatch(updateParticipant(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MeetingApp);
