import * as React from "react"
const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={392} height={138} {...props}>
    <title>{"accessories/Eyepatch"}</title>
    <path
      fill="#000"
      fillRule="evenodd"
      d="M335.397-59.613c5.78-1.981 10.39 4.03 9.28 9.269-1.31 6.17-7.75 11.122-12.02 15.47-4.48 4.58-9.05 9.07-13.79 13.39-9.05 8.262-18.43 16.04-28.32 23.29l-3.025 2.217c-9.066 6.633-18.104 13.112-27.815 18.854-5.26 3.1-10.57 6.1-15.95 8.99-1.92 1.03-4.26 2.619-6.67 3.8 8.81 6.8 15.59 17.18 17.06 26.85 3.64 24.02-11.84 46.28-33.681 55.13-10.89 4.41-23.01 5.69-34.65 4.36-11.84-1.351-23.84-4.851-31.53-14.55-6.83-8.61-9.29-19.6-9.78-30.39-.23-5.12-.18-10.161.9-14.69-4.71.04-9.42.02-14.09.07-11.54.11-23.1.16-34.65-.16-11.91-.34-23.76-.87-35.63-1.93-5.21-.47-10.39-1.24-15.57-1.98-5.03-.71-10.58-.98-15.28-3.04-.32-.13-.186-.59.09-.681 5.01-1.62 10.7-1.33 15.92-1.65l5.088-.308c4.238-.253 8.473-.486 12.723-.622 9.817-.323 19.627-.402 29.442-.57l4.208-.08c11.55-.23 23.1-.36 34.65-.73 4.79-.15 9.59-.43 14.4-.69.56-1.27 1.38-2.43 2.17-3.56 2.36-3.38 5.36-6.36 8.47-9.06 6.369-5.56 13.54-9.69 21.54-12.46 7.927-2.735 19.522-4.092 27.91-3.915 8.386.176 20.339 1.36 24.18 8.216.4.71 7.71-.66 9.88-2.321a438.548 438.548 0 0 0 14.939-11.93c9.08-7.59 18.42-15.06 27.02-23.18 8.87-8.38 17.67-16.9 26.11-25.7 4.24-4.41 8.47-8.83 12.58-13.35 4.2-4.61 7.81-10.29 13.89-12.36Z"
    />
  </svg>
)
export default SvgComponent
