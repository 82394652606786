import React, { useState } from "react";
import styled from "styled-components";
import Burger from "./Burger";

import SignIn from "../../sign-in/sign-in.component.class";
import SignUp from "../../sign-up/sign-up.component.class";
import RetroButton from "../../../components/retro/button/retro-button.component";
import BoxLayer from "../../../components/retro/box-layer/box-layer.component";

import { Link } from "react-scroll";
import { HashLink, NavHashLink } from "react-router-hash-link";

const Nav = styled.nav`
  width: 100%;
  height: 90px;
  padding: 0 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 10;
  background: white;
  box-shadow: rgb(0 0 0 / 20%) 0px 9px 30px -10px;

  .logo {
    display: flex;
    // padding: 10px 0;
    align-items: center;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    // padding: 0px 20px;
    padding: 0 10px;

    .logo {
      display: flex;
      align-items: center;
      cursor: pointer;
      img {
        height: 44px;
      }
    }
  }
`;

const Navbar = () => {
  const [showLogin, setShowLogin] = useState(false);
  const [showRegister, setShowRegister] = useState(false);

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  return (
    <Nav>
      <Link
        to="product"
        spy={true}
        smooth={true}
        offset={-80}
        duration={500}
        className="logo"
      >
        <img
          src="/antlogy_logo_full.png"
          height="64px"
          alt=""
          style={{ objectFit: "cover" }}
        />
      </Link>
      <div className="navbar__mobile-signbtns">
        <RetroButton
          style={{ background: "#1d1d1d", color: "white" }}
          onClick={() => setShowLogin(!showLogin)}
        >
          Sign In | Up
        </RetroButton>
        {/* <RetroButton
          style={{ background: "#1d1d1d", color: "white" }}
          onClick={() => setShowRegister(!showRegister)}
        >
          Get Started
        </RetroButton> */}
      </div>
      {showLogin && (
        <BoxLayer setLayer={setShowLogin}>
          <SignIn />
        </BoxLayer>
      )}
      {showRegister && (
        <BoxLayer setLayer={setShowRegister}>
          <SignUp />
        </BoxLayer>
      )}
      <Burger />
    </Nav>
  );
};

export default Navbar;

// .wel__header {
//     width: 100vw;
//     height: 90px;
//     background:rgba(197, 197, 197, 0.5);
//     box-shadow: rgb(0 0 0 / 20%) 0px 9px 30px -10px;
//   }
