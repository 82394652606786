import { useState, useEffect } from "react";
import { Purchases } from "@revenuecat/purchases-js";

const useEntitlement = () => {
  const [entitlement, setEntitlement] = useState(null); // Start with no entitlement
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchEntitlement = async () => {
      try {
        const customerInfo = await Purchases.getSharedInstance().getCustomerInfo();
        console.log("Customer Info:", JSON.stringify(customerInfo, null, 2));
        console.log("Active Entitlements:", JSON.stringify(customerInfo.entitlements.active, null, 2));

        const offerings = await Purchases.getSharedInstance().getOfferings();
        console.log("Offerings:", JSON.stringify(offerings, null, 2));

        // Check if 'dualExplorer' is active
        if (customerInfo.entitlements.active.pro && customerInfo.entitlements.active.pro.isActive) {
          setEntitlement("pro");
        } else if (customerInfo.entitlements.active.business && customerInfo.entitlements.active.business.isActive) {
          setEntitlement("business");
        } else {
          setEntitlement("free");
        }
      } catch (error) {
        console.error("Error fetching customer info:", error);
        setEntitlement("free");
      } finally {
        setLoading(false);
      }
    };
    fetchEntitlement();
  }, []);

  return { entitlement, loading };
};

export default useEntitlement;
