import * as React from "react"
const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={392} height={138} {...props}>
    <title>{"accessories/Sunglasses"}</title>
    <path
      fill="#000"
      fillRule="evenodd"
      d="M337.809 15c23.472 0 36.867 5.048 41.775 5.44l.148.011c2.041.13 3.873.302 5.794 1.045 7.066 2.72 8.217 10.131 4.093 14.802-.289 9.663.982 20.955-1.199 30.65-2.69 40.87-21.91 47.768-32.836 50.285-5.486 1.264-11.987 1.767-17.813.433-9.94-.37-19.767-3.482-28.174-8.9-11.71-7.553-20.406-19.16-23.229-32.936-1.337-6.566-1.962-13.401-1.234-20.076l.108-.92c-3.806-1.82-8.217-2.164-12.763-2.325-2.172-.077-4.313-.146-6.392-.054-2.033.227-4.042.53-6.056.94a15.93 15.93 0 0 0-6.196 3.495l-.132 2.533A130.31 130.31 0 0 1 251.23 78.3c-1.892 9.158-4.934 17.904-11.409 24.4a36.677 36.677 0 0 1-8.196 8.725c-11.96 9.209-28.21 12.59-43.124 11.317-30.277-2.584-47.778-26.619-51.81-36.892l-.242-.622c-2.913-7.638-5.235-17.547-14.441-19.188l-1.958-.352c-4.201-.789-8.078-1.886-10.541-5.985-1.08-1.79-1.635-3.79-1.771-5.831-1.438.11-2.873.216-4.303.318-11.408 2.413-79.836 4.458-85.298 4.458 0 0-10.499 4.42-14.931.995C.134 57.263-.877 54.09.815 50.495c2.552-5.409 14.325-8.662 19.553-9.692 0 0 84.365.7 90.437-.45 3.757-3.875 10.477-3.345 16.093-3.957 4.648-3.315 11.151-5.535 16.288-7.02 1.047-.398 2.084-.781 3.092-1.143 9.417-3.353 19.206-5.13 29.144-6.084 10.248-.974 20.526-.533 30.714.863 4.293.582 8.546 1.335 12.77 2.32 7.562.997 15.08 2.64 22.645 4.587l1.514.394c7.966 2.088 16.152 3.895 24.399 2.48 6.713-1.146 13.17-3.73 19.36-6.524l.805-.365C301.24 19.689 322.496 15 337.81 15ZM218.087 37.722c-5.022-1.104-7.167 6.639-2.129 7.746 3.568.785 6.591 2.584 8.634 5.631l.215.332c2.741 4.362 9.673.335 6.913-4.055-3.106-4.94-7.932-8.4-13.633-9.654Zm147.086-4.8c-1.77-1.247-4.45-.322-5.477 1.44-1.131 1.941-.39 4.102 1.268 5.371l.169.124c1.52 1.072 3.002 2.994 3.188 4.962l.017.247c.199 5.152 8.206 5.178 8.006 0-.195-5.066-3.129-9.299-7.17-12.144Z"
    />
  </svg>
)
export default SvgComponent
