import React from "react";
import { Link } from "react-router-dom";
import { readingTime } from "./readingTime";
import "./blogPost.scss";

export default function BlogPostCard({ post }) {
  return (
    <div className="blogpost-card">
      <Link to={`/blog/${post.title.replace(/\s+/g, "-").toLowerCase()}`}>
        <div>
          {post.featuredImage && <img src={post.featuredImage} alt="" />}
        </div>
        <div>
          <h4 className="blogpost-card-header">{post.title}</h4>
          <p className="blogpost-card-readtime">
            {readingTime(post.content)}
          </p>
          {/* <p className="blogpost-card-text">{post.content}</p> */}
          <div
            className="blogpost-card-text"
            dangerouslySetInnerHTML={{ __html: post.content }}
            // style={{ textAlign: "left" }}
          />
        </div>
      </Link>
    </div>
  );
}
