import React, { useState, useEffect } from "react";
import { db, updateUser } from "../../firebase/firebase.utils";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import "./assigned-tasks.styles.scss";

import AssignedTaskItem from "./assigned-tasks-item";
import InboxTaskItem from "./inbox-tasks-item";

import DaysLeft from "../modules/days-left/days-left.component";
import Priority from "../modules/priority/priority.component";
import CreatedBy from "../modules/created-by/created-by.component";
import RetroButton from "../retro/button/retro-button.component";

import SectionBox from "../section-box/section-box.component";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";

import "./mini-calendar.styles.scss";

import { IoCheckmarkDone, IoChevronForwardOutline, IoArrowForward } from "react-icons/io5";
import { BsChevronRight, BsChevronDown, BsList } from "react-icons/bs";

import moment from "moment";
import { setTasks } from "../../redux/tasks/tasks.actions";
// import { isTab } from "lexical/LexicalUtils";

const AssingedTasks = ({
  setNumOfPrTasks,
  setNumOfPrTasksToday,
  setNumOfPrTasksPastDue,
}) => {
  const currentUser = useSelector((state) => state.user.currentUser);
  const assignedTasks = useSelector((state) => state.tasks.tasks);
  // const [assignedTasks, setAssignedTasks] = useState([]);
  const [assignedColTasks, setAssignedColTasks] = useState([]);
  const [assignedColSpaces, setAssignedColSpaces] = useState([]);
  const [task, setTask] = useState([]);

  const navigate = useNavigate();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMode, setModalMode] = useState("");
  const [activeEvent, setActiveEvent] = useState({});
  const [events, setEvents] = useState([]);

  //Calendar related
  const [otherProjects, setOtherProjects] = useState(false);

  const [activeCalShow, setActiveCalShow] = useState(false);

  const [activeCalStart, setActiveCalStart] = useState("06:00:00");
  const [activeCalEnd, setActiveCalEnd] = useState("23:00:00");
  //------

  const spaceData = useSelector((state) => state.space.spaceData);

  // const [showList, setShowList] = useState(true);
  const [showtaskColumn, setShowTaskColumn] = useState(false);

  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (!currentUser) return;
  //   db.collection("users")
  //     .doc(currentUser.uid)
  //     .onSnapshot((userData) => {
  //       if (userData.exists) {
  //         setAssignedTasks(userData.data().assignedTasks);
  //       }
  //     });
  // }, [currentUser]);

  const [inboxTasks, setInboxTasks] = useState([]);
  useEffect(() => {
    //mounts
    const unsub = db
      .collection("users")
      .doc(currentUser.uid)
      .collection("tasks")
      // .where("startdate", "!=", null)
      .onSnapshot((querySnapshot) => {
        const array = querySnapshot.docs.map((doc) => {
          return {
            id: doc.id,
            title: doc.get("title"),
            description: doc.get("description"),
            done: doc.get("done"),
            start: doc.get("startdate"),
            end: doc.get("enddate"),
            allDay: doc.get("allDay"),
            durationn: doc.get("duration"),
            createdBy: doc.get("createdBy"),
            createdAt: doc.get("createdAt"),
            assign: doc.get("assign"),
            phase: doc.get("phase"),
            priority: doc.get("priority"),
            progress: doc.get("progress"),
            type: doc.get("type"),
            // className: "this_project-items",
            // color: "#2c2e33",
            fromSpaceId: doc.get("fromSpaceId"),
            fromStationId: doc.get("fromStationId"),
            // backgroundColor: spaceData
            //   .filter((space) => space.spaceId === doc.get("fromSpaceId"))
            //   .map((data) => data.color),
            // borderColor: spaceData
            //   .filter((space) => space.spaceId === doc.get("fromSpaceId"))
            //   .map((data) => data.color),
          };
        });
        // console.log("Current data in array: ", array);
        setInboxTasks([...array]);
      });

    return () => {
      unsub();
    };
  }, []);

  // useEffect(() => {
  //   if (!currentUser) return;
  //   var newtasks = db
  //     .collectionGroup("tasks")
  //     .where("assign", "==", currentUser.uid);
  //   newtasks.get().then((querySnapshot) => {
  //     let list = [];
  //     let listspace = [];
  //     querySnapshot.forEach((doc) => {
  //       list.push(doc.data());
  //       listspace.push(doc.data().fromSpaceId);
  //     });
  //     setAssignedColTasks(list);
  //     setAssignedColSpaces(listspace);
  //   });
  // }, []);
  useEffect(() => {
    if (!currentUser) return;
    var newtasks = db
      .collectionGroup("tasks")
      .where("assign", "==", currentUser.uid)
      .onSnapshot((querySnapshot) => {
        let list = [];
        let listspace = [];
        querySnapshot.forEach((doc) => {
          list.push(doc.data());
          listspace.push(doc.data().fromSpaceId);
          // (events.filter((e) => e.id !== event.id))
        });
        let tasksnr = list.filter((task) => task.done === false).length;
        setNumOfPrTasks(tasksnr);
        setAssignedColTasks(list);
        dispatch(setTasks(list));
        setAssignedColSpaces(listspace);
      });
  }, []);

  const [trassignedTasks, setTrAssignedTasks] = useState([]);
  useEffect(() => {
    const assignedTasksTransformed = assignedColTasks.map((astask) => {
      return {
        id: astask.id,
        title: astask.title,
        description: astask.description,
        done: astask.done,
        start: astask.startdate != null && astask.startdate.toDate(),
        end: astask.enddate != null && astask.enddate.toDate(),
        allDay: astask.allDay,
        durationn: astask.duration,
        createdBy: astask.createdBy,
        createdAt: astask.createdAt,
        assign: astask.assign,
        phase: astask.phase,
        priority: astask.priority,
        progress: astask.progress,
        type: astask.type,
        // color: "#909296",
        backgroundColor: spaceData
          .filter((space) => space.spaceId === astask.fromSpaceId)
          .map((data) => data.color),
        borderColor: spaceData
          .filter((space) => space.spaceId === astask.fromSpaceId)
          .map((data) => data.color),
        // className: "this_project-items",
        fromSpaceId: astask.fromSpaceId,
        fromStationId: astask.fromStationId,
      };
    });
    setTrAssignedTasks([...assignedTasksTransformed]);
  }, [assignedColTasks]);

  useEffect(() => {
    let containerEl = document.getElementById("external-assignedTasks");

    // // initialize the external events

    new Draggable(containerEl, {
      itemSelector: ".fc-event",
      eventData: function (eventEl) {
        return {
          id: eventEl.getAttribute("id"),
          title: eventEl.getAttribute("title"),
          start: eventEl.getAttribute("start"),
          end: eventEl.getAttribute("end"),
          duration: eventEl.getAttribute("duration"),
          fromSpaceId: eventEl.getAttribute("fromSpaceId"),
          fromStationId: eventEl.getAttribute("fromStationId"),
        };
      },
    });
  }, []);

  const handleEventResize = (checkInfo) => {
    const id = checkInfo.event.id;
    if (
      checkInfo.event.extendedProps.fromSpaceId === `${currentUser.uid}-Inbox`
    ) {
      db.collection("users")
        .doc(currentUser.uid)
        .collection("tasks")
        .doc(id)
        .update({
          enddate: checkInfo.event.end,
        });
    } else {
      db.collection("space")
        .doc(checkInfo.event.extendedProps.fromSpaceId)
        .collection("stations")
        .doc(checkInfo.event.extendedProps.fromStationId)
        .collection("tasks")
        .doc(id)
        .update({
          enddate: checkInfo.event.end,
        });
    }
  };

  const handleEventDrop = (checkInfo) => {
    const id = checkInfo.event.id;
    if (
      checkInfo.event.extendedProps.fromSpaceId === `${currentUser.uid}-Inbox`
    ) {
      db.collection("users")
        .doc(currentUser.uid)
        .collection("tasks")
        .doc(id)
        .update({
          startdate: checkInfo.event.start,
          enddate: checkInfo.event.end,
        });
    } else {
      db.collection("space")
        .doc(checkInfo.event.extendedProps.fromSpaceId)
        .collection("stations")
        .doc(checkInfo.event.extendedProps.fromStationId)
        .collection("tasks")
        .doc(id)
        .update({
          startdate: checkInfo.event.start,
          enddate: checkInfo.event.end,
        });
    }
  };

  const handleEventReceive = (checkInfo) => {
    const id = checkInfo.event.id;
    if (
      checkInfo.event.extendedProps.fromSpaceId === `${currentUser.uid}-Inbox`
    ) {
      db.collection("users")
        .doc(currentUser.uid)
        .collection("tasks")
        .doc(id)
        .update({
          startdate: checkInfo.event.start,
          enddate: checkInfo.event.end,
        });
    } else {
      db.collection("space")
        .doc(checkInfo.event.extendedProps.fromSpaceId)
        .collection("stations")
        .doc(checkInfo.event.extendedProps.fromStationId)
        .collection("tasks")
        .doc(id)
        .update({
          startdate: checkInfo.event.start,
          enddate: checkInfo.event.end,
        });
    }
  };

  var scrollTime = moment().format("HH") + ":00:00";

  const handleOpenModal = (mode, event) => {
    // setModalMode(mode);
    // setActiveEvent(event);
    // setModalIsOpen(true);
    navigate(`task/${event.id}`);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
    setModalMode("");
    setActiveEvent({});
  };

  const handleSaveEvent = (mode, event) => {
    if (mode === "add") {
      setEvents([...events, { id: events.length + 1, ...event }]);
    } else {
      setEvents(events.map((e) => (e.id === event.id ? event : e)));
    }
    handleCloseModal();
  };

  const handleDeleteEvent = (event) => {
    setEvents(events.filter((e) => e.id !== event.id));
    handleCloseModal();
  };

  const handleEventClick = (clickInfo) => {
    // const { id } = event.toPlainObject();
    handleOpenModal(
      "edit",
      {
        id: clickInfo.event.id,
        title: clickInfo.event.title,
        description: clickInfo.event.extendedProps.description,
        done: clickInfo.event.extendedProps.done,
        startdate: clickInfo.event.start,
        enddate: clickInfo.event.end,
        allDay: clickInfo.event.allDay,
        duration: clickInfo.event.extendedProps.durationn,
        createdBy: clickInfo.event.extendedProps.createdBy,
        createdAt: clickInfo.event.extendedProps.createdAt,
        assign: clickInfo.event.extendedProps.assign,
        phase: clickInfo.event.extendedProps.phase,
        priority: clickInfo.event.extendedProps.priority,
        progress: clickInfo.event.extendedProps.progress,
        type: clickInfo.event.extendedProps.type,
        fromSpaceId: clickInfo.event.extendedProps.fromSpaceId,
        fromStationId: clickInfo.event.extendedProps.fromStationId,
      }
      // events.find((e) => e.id.toString() === id.toString())
      // console.log(clickInfo)
    );
  };

  // const handleSelect = (selectionInfo) => {
  //   handleOpenModal("add", {
  //     startdate: selectionInfo.start,
  //     startTime: selectionInfo.startDateTime,
  //     enddate: selectionInfo.end,
  //   });
  // };

  const handleSelect = (selectionInfo) => {
    navigate("addtask", {
      state: {
        startdate: selectionInfo.start,
        startTime: selectionInfo.startDateTime,
        enddate: selectionInfo.end,
        endTime: selectionInfo.endDateTime,
      },
    });
    // handleOpenModal("add", {
    //   startdate: selectionInfo.start,
    //   startTime: selectionInfo.startDateTime,
    //   enddate: selectionInfo.end,
    // });
  };

  return (
    <div className="assigned__cal">
      <div
        className={
          !showtaskColumn
            ? "assigned__taskscolumn"
            : "assigned__taskscolumn-half"
        }
      >
        {/* <div>
            <button onClick={() => console.log(assignedColTasks)}>Show</button>
            {assignedColTasks.map((data) => (<div>
              {data.title}
            </div>))}
          </div> */}
        <div
          className={
            !showtaskColumn
              ? "asgntaskwidgetheader"
              : "asgntaskwidgetheader-rotated"
          }
        >
          <h3>{currentUser.userName}'s Tasks</h3>
          <button
            onClick={() => setShowTaskColumn(!showtaskColumn)}
            className={
              showtaskColumn
                ? "asgntaskwidgetheader-btn"
                : "asgntaskwidgetheader-btn-rotated"
            }
          >
            {" "}
            <IoArrowForward size="18px" />
          </button>
        </div>
        <div
          className={showtaskColumn ? "assignedTasksHide" : "assignedTasks"}
          id="external-assignedTasks"
        >
          <div className="at__fromspace-list">
            <InboxTaskItem
              color={"#1A82C4"}
              name={"Inbox"}
              inboxTasks={inboxTasks}
            />
          </div>
          {assignedColTasks.length === 0 ? (
            <div className="at__noTasks">
              <IoCheckmarkDone size="4em" />
              <p>All tasks are done!</p>
            </div>
          ) : (
            <>
              {spaceData
                // .filter((space) => space.spaceId.includes(assignedColTasks.fromSpaceId))
                .filter((space) => assignedColSpaces.includes(space.spaceId))
                // .filter((space) => assignedColSpaces.includes(assignedColTasks.done === false))
                .map((data) => {
                  const { spaceId, color, name } = data;
                  return (
                    // <Link to={`/s/${data.spaceId}`}>
                    <div key={spaceId} className="at__fromspace-list">
                      <AssignedTaskItem
                        spaceId={spaceId}
                        color={color}
                        name={name}
                        assignedColTasks={assignedColTasks}
                      />
                    </div>
                    // </Link>
                  );
                })}
            </>
          )}
        </div>
      </div>
      <div
        className="assigned__taskscolumn-hide"
        onClick={() => setShowTaskColumn(!showtaskColumn)}
        style={{ display: !modalIsOpen && "hidden" }}
      >
        <div className="assigned__taskscolumn-hidetext">MyTasks</div>
        <div
          className={
            !showtaskColumn
              ? "assigned__taskscolumn-hidetasks"
              : "assigned__taskscolumn-hidetasks-rotated"
          }
        >
          <IoChevronForwardOutline size="1em" />
        </div>
      </div>
      <div>
        <div className="minicalwidgetheader">
          <h3>Calendar</h3>
        </div>
        <div
          className={showtaskColumn ? "minical minical-wide" : "minical"}
          id="minical"
        >
          <FullCalendar
            initialView="timeGridDay"
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={
              !showtaskColumn
                ? {
                    // left: "prev,next today WorkHoursButton ProjectsButton",
                    left: "prev,today,next",
                    center: "title",
                    right: "WorkHoursButton timeGridDay,timeGridThreeDay",
                  }
                : {
                    // left: "prev,next today WorkHoursButton ProjectsButton",
                    left: "prev,today,next",
                    center: "title",
                    right: "WorkHoursButton timeGridDay",
                  }
            }
            views={{
              timeGridThreeDay: {
                type: "timeGrid",
                duration: { days: 3 },
                buttonText: "3 day",
                dayHeaderFormat: { weekday: "short" },
                dateIncrement: { days: 1 },
                titleFormat: { month: "short", day: "numeric" },
              },
              day: {
                dayHeaderFormat: { weekday: "long" },
                titleFormat: { month: "short", day: "numeric" },
              },
              dayGrid: {
                dayMaxEventRows: 4,
              },
            }}
            dayHeaderFormat={{ weekday: "short" }}
            customButtons={{
              class: "workhours__btn",
              WorkHoursButton: {
                text: activeCalShow ? "24h" : "18h",
                click: function () {
                  setActiveCalShow(!activeCalShow);
                  if (activeCalShow) {
                    setActiveCalStart("05:00:00");
                    setActiveCalEnd("23:00:00");
                  } else {
                    setActiveCalStart("00:00:00");
                    setActiveCalEnd("24:00:00");
                  }
                },
              },
              class: "ProjectsButton",
              ProjectsButton: {
                text: "Show All Projects",
                click: function () {
                  setOtherProjects(!otherProjects);
                },
              },
              class: "BackNavButton",
              // BackButton: {
              //   text: "Back1day",
              //   click: function () {
              //     const duration = day;
              //     calendar.incrementDate( duration );
              //   },
              // },
            }}
            eventClassNames={function (arg) {
              if (arg.event.extendedProps.done) {
                return ["cal__event-done"];
              }
              // else {
              //   return [ 'normal' ]
              // }
            }}
            height="100%"
            // height="100%"
            events={trassignedTasks}
            eventClick={handleEventClick}
            handleWindowResize={true}
            select={handleSelect}
            editable={true}
            duration={"00:15"} // 2 hours
            selectable={true}
            displayEventEnd
            dayMaxEventRows
            moreLinkClick="day"
            nowIndicator="true"
            slotDuration="00:15:00"
            slotLabelInterval="01:00:00"
            eventResize={handleEventResize}
            eventDrop={handleEventDrop}
            eventReceive={handleEventReceive}
            slotMinTime={activeCalStart}
            slotMaxTime={activeCalEnd}
            scrollTime={scrollTime}
          />
        </div>
      </div>
    </div>
  );
};

export default AssingedTasks;
