import { MdDelete, MdFileCopy } from "react-icons/md";
import {
  BsTrash3Fill,
  BsFillCheckCircleFill,
  BsInputCursorText,
  BsFillClipboardFill,
} from "react-icons/bs";
import { IoIosCopy, IoIosCheckmarkCircleOutline } from "react-icons/io";
import { TbArrowsExchange2 } from "react-icons/tb";
export const contextMenuItemsFolder = [
  {
    label: "Rename (soon..)",
    icon: <BsInputCursorText />,
  },
  {
    label: "Delete",
    icon: <BsTrash3Fill />,
  },
];
