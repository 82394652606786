import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import { db } from "../../firebase/firebase.utils";

import RetroButton from "../retro/button/retro-button.component";

import { BsPeopleFill } from "react-icons/bs";

import EmojiPick from "../emoji/emoji.component";

import "./station-item.styles.scss";

function SharedStationItem({ project, shortcut }) {
  const [item, setItem] = useState({});
  const [showAddToWorkspace, setShowAddToWorkspace] = useState(false);

  const currentUser = useSelector((state) => state.user.currentUser);
  const spaceData = useSelector((state) => state.space.spaceData);

  const location = useLocation();
  const activeSpaceId = location.pathname.split("/")[2];

  useEffect(() => {
    const docRef = db
      .collection("space")
      .doc(project.fromSpaceId)
      .collection("stations")
      .doc(project.stationId);

    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          setItem(doc.data());
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  }, []);

  const addProjectShortcut = (space) => {
    const id = space.spaceId;
    db.collection("users").doc(currentUser.uid).update({
      shortCutSpace: id,
    });
  };

  return (
    <div className="stationItem">
      {shortcut && (
        <div className="stationItem_sharedIcon">
          <BsPeopleFill size="0.8em" />
        </div>
      )}
      <Link to={`/s/${project.fromSpaceId}/e/${project.stationId}/b`}>
        <RetroButton style={{ width: "100%" }}>
          {/* <BsListCheck size="1.2em" /> */}
          <div className="EmojiIcon">{item.emojicon}</div>
          <a>{item.name}</a>
        </RetroButton>
      </Link>
      {activeSpaceId === "sharedwithme" && (
          <button
            className="stationItem_addtows"
            onClick={() => setShowAddToWorkspace(!showAddToWorkspace)}
          >
            Add to workspace
          </button>
        )}
      {showAddToWorkspace && (
        <div>
          {spaceData?.map((space) => {
            return (
              <button
                key={space.spaceId}
                value={space.spaceId}
                // selected={selectedWorkspaceId === space.spaceId}
                // className={
                //   sendto === space.spaceId
                //     ? "chat_grpoption-active"
                //     : "chat_grpoption"
                // }
                // style={{
                //   textDecoration: `underline 2px ${space.color}`,
                // }}
                onClick={() => addProjectShortcut(space)}
              >
                {space.name}
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default SharedStationItem;
