import React from "react";
import { useSelector } from "react-redux";

import RetroButton from "../../retro/button/retro-button.component";

import "./icon.styles.scss";



function shadeColor(color, percent) {
  var R = parseInt(color.substring(1, 3), 16);
  var G = parseInt(color.substring(3, 5), 16);
  var B = parseInt(color.substring(5, 7), 16);

  R = parseInt((R * (100 + percent)) / 100);
  G = parseInt((G * (100 + percent)) / 100);
  B = parseInt((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  R = Math.round(R);
  G = Math.round(G);
  B = Math.round(B);

  var RR = R.toString(16).length == 1 ? "0" + R.toString(16) : R.toString(16);
  var GG = G.toString(16).length == 1 ? "0" + G.toString(16) : G.toString(16);
  var BB = B.toString(16).length == 1 ? "0" + B.toString(16) : B.toString(16);

  return "#" + RR + GG + BB;
}

const Icon = ({ space }) => {
  const spaceId = useSelector((state) => state.history.spaceId);
  const buttonStyle = {
    background: `${space.color}`,
    // textDecoration: `underline 4px ${space.color}`,
    filter: space.spaceId === spaceId ? "" : "grayscale(50%)",
    transform: space.spaceId === spaceId ? "scale(1.2)" : "scale(1)",
    boxShadow:
      space.spaceId === spaceId ? `4px 0px 5px -2px ${space.color}` : "",
  };
  return (
    <div className="icon">
      {spaceId ? (
        <div className="icon__button-active">
          <RetroButton style={buttonStyle} spaceColor={space.spaceId === spaceId ? `${shadeColor(space.color, -90)}` : `${shadeColor(space.color, -70)}` } charAt size="box">
            {space.name}
          </RetroButton>
        </div>
      ) : (
        <div className="icon__button-null">
          <RetroButton
            style={{ background: `${space.color}`, textShadow: "1px 1px rgba(0, 0, 0, 0.1)" }}
            spaceColor={`${shadeColor(space.color, -80)}`}
            charAt
            size="box"
          >
            {space.name}
          </RetroButton>
        </div>
      )}
    </div>
  );
};

export default Icon;
