import { useState, useRef } from "react";
import "./antListens.scss";

// import AntReport from "./antReport";

const speechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
const recognition = new speechRecognition();
// const speechRecognitionList = new SpeechGrammarList();
// recognition.grammars = speechRecognitionList;
recognition.continuous = true;
recognition.lang = "en-US";
recognition.interimResults = true;
recognition.partial = false;
// recognition.maxAlternatives = 1;

export default function AntListens({ setSpokenText }) {
  // const [speechToText, setSpeechToText] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const finalSpeechToTextRef = useRef(null);

  // const report = AntReport()

  recognition.onstart = function () {
    console.log("speech start");
    setIsRecording(true);
  };

  recognition.speechend = function () {
    console.log("speech end");
    // setIsRecording(false);
  };

  // recognition.result = function (event) {
  //   const resultItem = event.results.item(event.resultIndex)
  //   const { transcript } = resultItem.item(0)
  //   report(transcript)
  //   // setIsRecording(false);
  // };

  recognition.onerror = function (e) {
    console.log("try again", e);
  };

  let finalTranscript = "";
  // let finalTranscript = finalSpeechToTextRef?.current?.value;
  recognition.onresult = function (event) {
    console.log("running");
    let interimTranscript = "";
    for (let i = event.resultIndex; i < event.results.length; ++i) {
      if (event.results[i].isFinal) {
        finalTranscript += event.results[i][0].transcript;
      } else {
        interimTranscript += event.results[i][0].transcript;
      }
    }
    console.log("final", finalTranscript);
    console.log("interim", interimTranscript);
    finalSpeechToTextRef.current.value = finalTranscript + interimTranscript;
    setSpokenText(finalTranscript)

    // if (finalTranscript) {
    //   finalSpeechToTextRef.current.value = finalTranscript;
    // }
  };

  const handleClick = () => {
    console.log("i have been clicked");
    if (isRecording) {
      recognition.stop();
      setIsRecording(false);
    } else {
      recognition.start();
    }
  };

  return (
    <div className="ant-microphone">
      <button
        ref={finalSpeechToTextRef}
        onClick={handleClick}
        className={
          "action-button action-button-mic " +
          (isRecording ? "active" : "")
        }
        title="Speech To Text"
        aria-label={`${isRecording ? "Enable" : "Disable"} speech to text`}
      >
        <i className="mic" />
      </button>
    </div>
  );
}
