import React, { useState, useEffect } from "react";
import {
  db,
  updateFolderDrag,
  fieldValue,
} from "../../firebase/firebase.utils";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate, Link } from "react-router-dom";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import {
  setFolderData,
  setStationData,
  setModules,
  setActiveModules,
} from "../../redux/space/space.actions";
import { setSpaceId, setStationId } from "../../redux/history/history.actions";

import RetroButton from "../retro/button/retro-button.component";
import SharedStationItem from "../station-item/shared-station-item";
import FolderItem from "../folder-item/folder-item.component.class";
import CreateStation from "../create-station/create-station.component";
import CreateFolder from "../create-folder/create-folder.component";

import { BsGripVertical } from "react-icons/bs";

import InvitetoPremium from "../marketing/invite-to-premium.component";

import "./stations.styles.scss";

const SharedWithMe = () => {
  const stationData = useSelector((state) => state.space.stationData);
  const folderData = useSelector((state) => state.space.folderData);
  const [createStation, setCreateStation] = useState(false);

  const [createFolder, setCreateFolder] = useState(false);

  const history = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const activeSpaceId = location.pathname.split("/")[2];
  const activeStationId = location.pathname.split("/")[4];

  const currentUser = useSelector((state) => state.user.currentUser);

  const [state, setState] = useState([]);

  const [folders, setFolders] = useState();

  const [fdopen, setFdOpen] = useState(false);

  const [showInvitation, setShowInvitation] = useState(false);

  const [isOpen, SetIsOpen] = useState(true);
  const [x, setX] = useState(0);

  const [sharedPrSpaces, setSharedPrSpaces] = useState([]);
  const [sharedProjects, setSharedProjects] = useState([]);

  // useEffect(() => {
  //   if (isOpen) {
  //     setX(0);
  //   } else {
  //     setX("-220px");
  //   }
  // }, [isOpen]);

  useEffect(() => {
    const spaceValues = [];
    const stationValues = [];

    currentUser?.sharedWith?.forEach((space) => {
      spaceValues.push(space.fromSpaceId);
      stationValues.push(space.stationId);

      // let projectDocs = [];
      // const ref = db
      //   .collection("space")
      //   .doc(space.fromSpaceId)
      //   .collection("stations")
      //   .doc(space.stationId);
      // const docs = await ref.get();
      // docs.forEach((doc) => {
      //   projectDocs.push(doc.data());
      // });
      // setSharedProjects(projectDocs);
    });
    setSharedPrSpaces(spaceValues);
    setSharedProjects(stationValues);

    const getSharedProjectSpaces = async () => {
      let listDocs = [];
      const ref = db.collection("space").where("spaceId", "in", spaceValues);
      const docs = await ref.get();
      docs.forEach((doc) => {
        listDocs.push(doc.data());
      });
      setSharedPrSpaces(listDocs);
    };
    const getSharedProjects = async () => {
      let listDocs = [];
      const ref = db
        .collection("space")
        .doc("fromSpaceId")
        .collection("stations")
        .doc("stationId");
      const docs = await ref.get();
      docs.forEach((doc) => {
        listDocs.push(doc.data());
      });
      setSharedPrSpaces(listDocs);
    };
    // call the function
    getSharedProjectSpaces()
      // make sure to catch any error
      .catch(console.error);
  }, [activeSpaceId]);

  console.log("SHAREDPROJECTS", sharedProjects);

  useEffect(() => {
    if (isOpen) {
      setX(0);
    } else {
      setX("-220px");
    }
  }, [isOpen]);

  useEffect(() => {
    db.collection("space")
      .doc(activeSpaceId)
      .collection("stations")
      .orderBy("name", "asc")
      // .where("folder", "==", null)
      .onSnapshot((querySnapshot) => {
        let docs = [];
        querySnapshot.forEach((doc) => {
          docs.push({ id: doc.id, ...doc.data() });
        });
        dispatch(setStationData(docs));
      });
    if (activeSpaceId) {
      dispatch(setSpaceId(activeSpaceId));
    }
  }, [activeSpaceId, dispatch]);

  useEffect(() => {
    db.collection("space")
      .doc(activeSpaceId)
      .collection("folders")
      .orderBy("name", "asc")
      .onSnapshot((querySnapshot) => {
        let docs = [];
        querySnapshot.forEach((doc) => {
          docs.push({
            id: doc.id,
            folderitems: doc.data().items,
            ...doc.data(),
          });
        });
        dispatch(setFolderData(docs));
      });
    if (activeSpaceId) {
      dispatch(setSpaceId(activeSpaceId));
    }
  }, [activeSpaceId, dispatch]);

  useEffect(() => {
    if (activeStationId) {
      db.collection("space")
        .doc(activeSpaceId)
        .collection("stations")
        .doc(activeStationId)
        .collection("modules")
        .doc("modules")
        .onSnapshot((doc) => {
          if (doc.exists) {
            const modules = doc.data().modules;
            const filterData = modules.filter((item) => item.active === true);
            dispatch(setModules(modules));
            dispatch(setActiveModules(filterData));
          }
        });
      dispatch(setStationId(activeStationId));
    }
  }, [activeStationId, activeSpaceId, dispatch]);

  // UPDATE DATABASE FOLDERS ON DRAG

  // UPDATE STATE AND TRIGGER DB UPDATE ON DRAG

  const onDragEnd = (result, folders, setFolders) => {
    if (!result.destination) return;
    const { source, destination, draggableId } = result;

    if (source.droppableId !== destination.droppableId) {
      // const sourceFolder = folders[source.droppableId];
      // const destFolder = folders[destination.droppableId];
      // const sourceItems = [...sourceFolder.items];
      // const destItems = [...destFolder.items];
      // const [removed] = sourceItems.splice(source.index, 1);
      // destItems.splice(destination.index, 0, removed);
      // setFromFolder(sourceItems);
      // setIntoFolder(destItems);
      // setFolders({
      //   ...folders,
      //   [source.droppableId]: {
      //     ...sourceFolder,
      //     items: sourceItems,
      //   },
      //   [destination.droppableId]: {
      //     ...destFolder,
      //     items: destItems,
      //   },
      // });
      db.collection("space")
        .doc(activeSpaceId)
        .collection("folders")
        .doc(source.droppableId)
        .update({ items: fieldValue.arrayRemove(draggableId) });
      db.collection("space")
        .doc(activeSpaceId)
        .collection("folders")
        .doc(destination.droppableId)
        .update({ items: fieldValue.arrayUnion(draggableId) });
    }
    if (
      source.droppableId !== destination.droppableId &&
      source.droppableId === activeSpaceId
    ) {
      db.collection("space")
        .doc(activeSpaceId)
        .collection("stations")
        .doc(draggableId)
        .update({ folder: destination.droppableId });
      db.collection("space")
        .doc(activeSpaceId)
        .collection("folders")
        .doc(destination.droppableId)
        .update({ items: fieldValue.arrayUnion(draggableId) });
    }
    if (
      source.droppableId !== destination.droppableId &&
      destination.droppableId === activeSpaceId
    ) {
      db.collection("space")
        .doc(activeSpaceId)
        .collection("stations")
        .doc(draggableId)
        .update({ folder: null });
      db.collection("space")
        .doc(activeSpaceId)
        .collection("folders")
        .doc(destination.droppableId)
        .update({ items: fieldValue.arrayRemove(draggableId) });
    } else {
      const folder = folders[source.droppableId];
      const copiedItems = [...folder.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setFolders({
        ...folders,
        [source.droppableId]: {
          ...folder,
          items: copiedItems,
        },
      });
      // updateFolderDrag(folders);
    }
  };

  const handleFdClick = () => {
    setFdOpen(!fdopen);
  };

  return (
    <div className="stations">
      <p className="stations__pre">Shared With Me</p>
      {sharedPrSpaces.map((space) => {
        return (
          <div className="sharedwithme">
            <details key={space.spaceId} open>
              <summary>
                <div className="at__fromspace-list-btn">
                  <RetroButton style={{ background: space.color }}>
                    {space.name?.charAt(0)}
                  </RetroButton>
                  <h4>{space.name}</h4>
                </div>
              </summary>
              {currentUser?.sharedWith
                ?.filter((station) => station.fromSpaceId === space.spaceId)
                .map((project) => {
                  return (
                    // <PrMember
                    //   key={member.uid}
                    //   member={member}
                    //   activeSpaceData={activeSpaceData}
                    //   activeSpaceId={activeSpaceId}
                    // />
                    <div key={project.stationId} className="sharedwithme-item">
                      <SharedStationItem project={project} /> 
                    </div>
                  );
                })}
            </details>
          </div>
        );
      })}
    </div>
  );
};

export default SharedWithMe;
