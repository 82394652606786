import React, { useEffect } from "react";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { db, personNotfUpdateSeen } from "../../../firebase/firebase.utils";

import Avatar from "../../retro/avatar/avatar.component";

import "./person-notification.styles.scss";

import {
  BsFillPersonCheckFill,
  BsFillChatSquareQuoteFill,
  BsFillChatSquareTextFill,
  BsBookmarkCheckFill,
} from "react-icons/bs";
import { IoChatbubblesSharp } from "react-icons/io5";

function PersonNotification({ notification, userId }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleMarkSeen = (e) => {
    e.preventDefault();
    personNotfUpdateSeen(userId, notification.id);
  };

  const handleSetSearchParams = (paramsRef) => {
    // if (mode === "toworkspace") {
    //   setCurrentRecipient("");
    //   setSearchParams({ wschat: sendto });
    // } else if (mode === "toproject") {
    //   setSearchParams({ prchat: sendto });
    // } else
    // if (notification.type === "private message") {
    setSearchParams({ dmchat: paramsRef });
    // navigate({
    //   search: `?dmchat: ${paramsRef}`,
    // });
    // }
  };

  return (
    <>
      <div className="person__notification" onClick={(e) => handleMarkSeen(e)}>
        {notification.type === "assign" ? (
          <BsFillPersonCheckFill
            color={notification.seen && "lightgray"}
            size="1.4em"
          />
        ) : // <Avatar src={notification.icon} />
        notification.type === "task starting" ? (
          <BsBookmarkCheckFill
            color={notification.seen && "lightgray"}
            size="1.4em"
          />
        ) : notification.type === "comment" ? (
          <BsFillChatSquareQuoteFill
            color={notification.seen && "lightgray"}
            size="1.4em"
          />
        ) : notification.type === "private message" ? (
          <BsFillChatSquareTextFill
            color={notification.seen && "lightgray"}
            size="1.4em"
          />
        ) : // <Avatar src={notification.icon} />
        notification.type === "project message" ? (
          <IoChatbubblesSharp
            color={notification.seen && "lightgray"}
            size="1.4em"
          />
        ) : // <Avatar src={notification.icon} />
        notification.type === "workspace message" ? (
          <IoChatbubblesSharp
            color={notification.seen && "lightgray"}
            size="1.4em"
          />
        ) : (
          // <Avatar src={notification.icon} />
          <div></div>
        )}
        {notification.type === "msgprivate" ? (
          <button
            className="person__notification-msg"
            onClick={() => handleSetSearchParams(notification.click_action)}
          >
            <p>
              <b>{notification.title}</b>
              <br />
              {notification.body}
            </p>
          </button>
        ) : (
          <Link
            className="person__notification-msg"
            to={notification.click_action}
          >
            <p>
              <b>{notification.title}</b>
              <br />
              {notification.body}
            </p>
          </Link>
        )}
      </div>
    </>
  );
}

export default PersonNotification;
