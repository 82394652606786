import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { useActiveSpaceData } from "../../../hooks/useActiveSpaceData.hook";

import { AnimatePresence } from "framer-motion";

import { FaUserSlash } from "react-icons/fa";

import {
  db,
  assignMember,
  // personAssignNotify,
  unAssign,
  updateUser,
  fieldValue,
} from "../../../firebase/firebase.utils";

import Avatar from "../../retro/avatar/avatar.component";
import BoxLayerLite from "../../retro/box-layer-lite/box-layer-lite.component";
import Tooltip from "../../retro/tooltip/tooltip.component";

import "./assign-styles.scss";

const Assign = ({ task }) => {
  const users = useSelector((state) => state.user.users);
  const spaceId = useSelector((state) => state.history.spaceId);
  const stationId = useSelector((state) => state.history.stationId);
  // const filter = useSelector((state) => state.stationId);
  const spaceData = useActiveSpaceData();

  const currentUser = useSelector((state) => state.user.currentUser);

  const [showMembers, setShowMembers] = useState(false);
  const [assignedUser, setAssignedUser] = useState({});
  const [allMembers, setAllMembers] = useState([]);

  const [projectMembers, setProjectMembers] = useState([])

  const getProjectMembers = async () => {
    try {
      const refProject = db.collection("space").doc(spaceId).collection("stations").doc(stationId);
      const projectDoc = await refProject.get();
  
      if (!projectDoc.exists) {
        console.error("Project document not found");
        return;
      }
  
      const prjMembers = projectDoc.data()?.members || [];
      if (prjMembers.length === 0) {
        console.log("No members found in the project");
        setProjectMembers([]);
        return;
      }
  
      let listDocs = [];
      const ref = db.collection("users").where("uid", "in", prjMembers);
      const docs = await ref.get();
  
      if (docs.empty) {
        console.log("No user documents found");
        setProjectMembers([]);
        return;
      }
  
      docs.forEach((doc) => {
        listDocs.push(doc.data());
      });
  
      setProjectMembers(listDocs);
    } catch (error) {
      console.error("Error fetching project members:", error);
    }
  };

  useEffect(() => {
    if (task.fromSpaceId !== `${currentUser.uid}-Inbox`) {
    // call the function
    getProjectMembers();
  }
    //   // make sure to catch any error
    //   .catch(console.error);
  }, [stationId]);


  useMemo(() => {
    if (!users) return;
    if (spaceData) {
      const { members } = spaceData;
      let list = [];
      list = members.map((memberId) => {
        const memberFind = users.filter((item) => item.uid === memberId);
        return memberFind[0];
      });
      setAllMembers(list);
    }
  }, [spaceData, users]);

  useMemo(() => {
    if (!users) return;
    if (!task) return;
    const { assign } = task;
    const getAssignUser = users.filter((item) => item.uid === assign);
    setAssignedUser(getAssignUser[0]);
  }, [task, users]);

  const handleAssignMember = (userId) => {
    // if (task.assign) {
    //   let oldAssign = users.filter((user) => user.uid === task.assign);
    //   let oldUser = oldAssign[0];
    //   if (oldUser !== undefined) {
    //     oldUser.assignedTasks = oldUser.assignedTasks.filter(
    //       (item) => item.id !== task.id
    //     );

    //     updateUser(oldUser.uid, oldUser);
    //   }
    // }
    // let findUser = users.filter((user) => user.uid === userId);
    // let theUser = findUser[0];

    // const taskrefinfo = {
    //   fromSpaceId: task.fromSpaceId,
    //   fromStationId: task.fromStationId,
    //   id: task.id,
    //   done: false,
    // };

    // theUser.assignedTasks.push(taskrefinfo);
    //za db.users
    // updateUser(theUser.uid, handleAssignMember);
    //za db.tasks
    assignMember(spaceId, stationId, task.id, userId, currentUser?.userName);
    // if (userId !== currentUser.userName) {
    //   personAssignNotify(
    //     spaceId,
    //     stationId,
    //     task.id,
    //     userId,
    //     currentUser.userName
    //   );
    // }
    setShowMembers(false);
  };

  const handleUnAssignMember = () => {
    if (task.assign) {
      // const assignedUserRef = db.collection("users").doc(task.assign);
      // assignedUserRef.get().then((userRefData) => {
      //   let assignedTasks = userRefData.data().assignedTasks;
      //   const findIndex = assignedTasks.findIndex(
      //     (item) => item.id === task.id
      //   );
      //   assignedUserRef.update({
      //     assignedTasks: fieldValue.arrayRemove(assignedTasks[findIndex]),
      //   });
      // });
    }
    if (task.assign) {
      unAssign(spaceId, stationId, task.id);
    }
    setShowMembers(false);
  };
  return (
    <div className="assign">
      <div
        className="assign__assigned"
        onClick={() => setShowMembers(!showMembers)}
      >
        <Avatar src={assignedUser ? assignedUser.imageUrl : ""} />
        {assignedUser ? (
          <Tooltip text={assignedUser.userName} />
        ) : (
          <Tooltip text="Assign" />
        )}
      </div>
      <AnimatePresence>
        {showMembers && (
          <div className="assign__choose">
            <BoxLayerLite setLayer={setShowMembers}>
              {allMembers?.map((member) => {
                const { imageUrl, userName, uid } = member;
                return (
                  <div
                    className="assign__member"
                    key={uid}
                    onClick={() => handleAssignMember(uid)}
                  >
                    <Avatar src={imageUrl} />
                    <p>{userName}</p>
                  </div>
                );
              })}
              {projectMembers?.map((member) => {
                const { imageUrl, userName, uid } = member;
                return (
                  <div
                    className="assign__member"
                    key={uid}
                    onClick={() => handleAssignMember(uid)}
                  >
                    <Avatar src={imageUrl} />
                    <p>{userName}</p>
                  </div>
                );
              })}
              <div
                className="assign__remove"
                onClick={() => handleUnAssignMember()}
              >
                <FaUserSlash />
              </div>
            </BoxLayerLite>
          </div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Assign;
